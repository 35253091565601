import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    MatchSummaryWidget,
    MatchSummaryWidgetProps,
} from './MatchSummaryWidget'

export const MatchSummaryWidgetRegistration = createRegisterableComponent(
    'match-summary-widget',
    (props: MatchSummaryWidgetProps, services) => {
        return <MatchSummaryWidget {...props} />
    },
)
