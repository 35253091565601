import styled from '@emotion/styled'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface StickyProps {
    isHidden: boolean
    isTop: boolean
    verticalOffset?: number
    height?: number
    transitionDuration?: number
}

export const StyledStickyContainer = styled('div')<StickyProps>((props) => {
    const height = props.height ? props.height : 0
    const offset = props.verticalOffset ? height + props.verticalOffset : height
    const transitionDuration = props.transitionDuration
        ? props.transitionDuration
        : 500
    return {
        [breakpoint('sm')]: [
            {
                position: 'sticky',
                overflow: 'hidden',
                transformOrigin: 'top center',
                transform: 'translateZ(0) scaleY(1)',
                zIndex: zIndex.common.stickyVideo,
                top: calcRem(offset),
                transition: `transform 100ms ease`, // Very short transition on show
            },
            !props.isTop &&
                props.isHidden && {
                    transform: 'translateZ(0) scaleY(0.001)', // DPO-635 scaleY(0.001) fixes a rare bug in chrome where scaleY(0) leaves a click handler behind
                    transition: `transform ${transitionDuration}ms ease`,
                },
        ],
    }
})
