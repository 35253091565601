import styled from '@emotion/styled'
import { RenderTarget } from '@news-mono/web-common'

import { tokens } from '@news-mono/design-tokens'
import {
    breakpoint,
    calcRem,
    colors,
    easing,
    fonts,
    metrics,
} from '../../__styling'
import { ResponsivePicture } from '../../content/Picture/Picture'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
const shareIcon = require('./assets/share.png')
interface StyledHeroContainerProps {
    renderTarget?: RenderTarget
}

export const StyledBreadcrumbDesktopWrapper = styled('div')({
    display: 'block',
    marginBottom: calcRem(16),
})

export const StyledHeroContainer = styled('div')<StyledHeroContainerProps>(
    ({ renderTarget }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(24),
        alignItems: 'self-start',
        marginBottom: calcRem(metrics.sevennews.margins.lg),
        textDecoration: 'none',
        color: 'inherit',

        [breakpoint('md')]: {
            display: 'grid',
            alignItems: 'self-start',
            gridTemplateColumns: '40% calc(60% - 1rem)',
            gridTemplateRows: 'auto',
            columnGap: calcRem(16),
        },
        '& [class*="StyledFigcaption"]': {
            marginTop: 0,
        },

        paddingBottom: renderTarget === 'amp' ? calcRem(20) : 'inherit',
    }),
)

export const StyledTextWrap = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    gridRow: 1,
    [breakpoint('md')]: {
        marginRight: calcRem(30),
        gridRow: 1,
        height: '100%',
        justifyContent: 'space-between',
    },
}))

export const StyledTextWrapHeader = styled('div')(({}) => ({
    marginBottom: calcRem(12),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.black20}`,
    height: '100%',
    [breakpoint('sm')]: {
        height: calcRem(34),
    },
    [breakpoint('md')]: {
        height: calcRem(39),
    },
}))

export const StyledInformationWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
})

export const StyledControlsWrapper = styled('div')({
    display: 'flex',
})

interface ShareWrapperProps {
    isOpened: boolean
}

export const StyledShareWrapper = styled('div')<ShareWrapperProps>(
    ({ isOpened }) => ({
        width: calcRem(18),
        height: '100%',
        cursor: 'pointer',
        marginRight: calcRem(18),
        borderBottom: `${calcRem(3)} solid ${colors.sevennews.red}`,
        '&:hover': {
            '& svg path': {
                fill: colors.sevennews.red,
            },
        },
        '& svg path': {
            fill: isOpened ? colors.sevennews.red : undefined,
        },
        'amp-social-share': {
            backgroundColor: 'transparent',
            backgroundImage: `url(${shareIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            '&:focus': {
                outline: `${calcRem(1)} solid ${
                    tokens.sevennews.colors.palette.red
                }}`,
            },
        },
    }),
)

export const StyledTimingsWrapper = styled('div')(({}) => ({
    fontSize: calcRem(12),
    color: colors.sevennews.nero60,
    fontWeight: 600,
    span: {
        color: colors.sevennews.nero30,
    },
}))
interface StyledEstimatedTimeProps {
    hasMoreAuthors?: boolean
}
export const StyledEstimatedTime = styled('span')<StyledEstimatedTimeProps>(
    ({ hasMoreAuthors }) => ({
        color: colors.sevennews.nero30,
        fontSize: calcRem(12),
        fontWeight: 600,
        marginLeft: hasMoreAuthors ? calcRem(0) : calcRem(16),
    }),
)
export const StyledTextToSpeachWrapper = styled('div')({
    marginRight: calcRem(15),
    cursor: 'pointer',
})
export const StyledFontSizeWrapper = styled('div')({
    cursor: 'pointer',
})

interface StyledImageCounterProps {
    isGallery?: boolean
}
export const StyledImageCounter = styled('h1')<StyledImageCounterProps>(
    ({ isGallery }) => ({
        fontWeight: 400,
        fontFamily: fonts.sevennews.sansSerif,
        color: colors.sevennews.nero,
        fontSize: calcRem(22),
        lineHeight: calcRem(29),
        textAlign: 'left',
        marginTop: isGallery ? 0 : calcRem(4),
        marginBottom: calcRem(4),
        [breakpoint('sm')]: {
            fontSize: calcRem(30),
            lineHeight: calcRem(39),
            marginTop: isGallery ? 0 : calcRem(8),
            marginBottom: calcRem(8),
        },
        [breakpoint('md')]: {
            fontSize: calcRem(36),
            lineHeight: calcRem(39),
            marginTop: isGallery ? calcRem(8) : calcRem(16),
            marginBottom: calcRem(16),
        },
    }),
)

export const StyledCaption = styled('div')(({}) => ({
    margin: 0,
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.nero,
    fontSize: calcRem(20),
    lineHeight: calcRem(27),
    textAlign: 'left',
    marginBottom: calcRem(8),
    marginTop: calcRem(8),
    display: 'block',
    [breakpoint('sm')]: {
        marginBottom: calcRem(24),
        marginTop: calcRem(20),
    },
    [breakpoint('md')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(28),
        marginBottom: 'inherit',
        display: 'block',
    },
}))

interface StyledShareWrapperProps {
    isOpened: boolean
}
export const StyeldShareWrapper = styled('div')<StyledShareWrapperProps>(
    ({ isOpened }) => ({
        position: 'absolute',
        left: calcRem(18),
        top: 0,
        bottom: 0,
        background: colors.white,
        width: isOpened ? '100%' : 0,
        maxWidth: calcRem(230),
        borderBottom: `3px solid ${colors.sevennews.red}`,
        overflow: 'hidden',
        transition: `width ${easing.easeOut.med}, padding-bottom ${easing.easeOut.med}`,
        paddingBottom: isOpened ? calcRem(20) : 0,
        [breakpoint('xs')]: {
            paddingBottom: 0,
        },
    }),
)

interface ScrollButtonProps {
    position: 'left' | 'right'
    isActive?: boolean
    isModal?: boolean
    isFullModalWIdth?: boolean
}
export interface StyledScrollWrapperProps {
    isModal?: boolean
}

export const StyledScrollButtonWrapper = styled('div')<ScrollButtonProps>(
    ({ position, isActive, isModal }) => ({
        position: 'absolute',
        top: isModal ? '42%' : '50%',
        right:
            position === 'right' && isModal
                ? calcRem(30)
                : position === 'right' && !isModal
                ? calcRem(10)
                : 'unset',
        left:
            position === 'left' && isModal
                ? calcRem(30)
                : position === 'left' && !isModal
                ? calcRem(10)
                : 'unset',
        transform: 'translateY(-50%)',
        zIndex: 10,
        cursor: isActive ? 'pointer' : 'not-allowed',
        display: 'block',
        img: {
            rotate: position === 'right' ? '180deg' : undefined,
            opacity: isActive ? 1 : 0.2,
        },

        [breakpoint('xs')]: {
            display: 'block',
            right:
                position === 'right' && isModal
                    ? '18%'
                    : position === 'right' && !isModal
                    ? calcRem(10)
                    : 'unset',
            left:
                position === 'left' && isModal
                    ? '18%'
                    : position === 'left' && !isModal
                    ? calcRem(10)
                    : 'unset',
        },
    }),
)
interface StyledGalleryImageContainerProps {
    index: number
    isModal?: boolean
}

export const StyledDescriptionAuthor = styled('span')({}, (props) => [
    props.theme.kind === 'sevennews' && {
        fontFamily: fonts.sevennews.body,
        fontSize: calcRem(9),
        color: colors.black50,
        display: 'block',
        marginTop: calcRem(4),
    },
])

export const StyledModalContainer = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 251, //higher than sticky header
}))
interface StyledModalFooterProps {
    isFullWidth?: boolean
}
export const StyledModalFooter = styled('div')<StyledModalFooterProps>(
    ({ isFullWidth }) => ({
        display: isFullWidth ? 'none' : 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background: 'rgba(0, 0, 0, 0.48)',
        color: colors.white,
        minHeight: calcRem(128),
        padding: calcRem(12, 23),
        fontWeight: 300,
        position: isFullWidth ? 'absolute' : 'unset',
        bottom: 0,
        width: '100%',
        [breakpoint('xs')]: {
            display: 'flex',
        },
    }),
)
export const StyledImageCounterModal = styled('h1')(({}) => ({
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.white,
    fontSize: calcRem(16),
    fontWeight: 800,
    marginBottom: calcRem(8),
}))

export const StyledCaptionModal = styled('div')(({}) => ({
    margin: 0,
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.white,
    fontSize: calcRem(14),
    lineHeight: calcRem(22),
    textAlign: 'left',

    display: 'block',
}))
export const StyledDescriptionAuthorModal = styled('span')({}, (props) => [
    props.theme.kind === 'sevennews' && {
        fontFamily: fonts.sevennews.body,
        fontSize: calcRem(14),
        lineHeight: calcRem(22),
        color: colors.white,
        display: 'block',
    },
])
interface StyledFullScreenIconContaineProps {
    isFullWidth?: boolean
}
export const StyledFullScreenIconContainer = styled(
    'div',
)<StyledFullScreenIconContaineProps>(({ isFullWidth }) => ({
    position: 'absolute',
    top: isFullWidth ? calcRem(20) : calcRem(20),
    right: isFullWidth ? calcRem(40) : calcRem(18),
    zIndex: 2,
    cursor: 'pointer',
}))

export const StyledDesktopInfoContainer = styled('div')({
    display: 'none',
    [breakpoint('sm')]: { display: 'block' },
})

export const StyledMobileInfoContainer = styled('div')({
    display: 'block',
    [breakpoint('sm')]: { display: 'none' },
})
export const ResponsivePictureGallery = styled(ResponsivePicture)({
    background: colors.black,
    '& img': {
        height: '100%',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
})

export const StyledSwiperContainer = styled('div')({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
})
export const StyledSwiperModalContainer = styled('div')({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4%',
    [breakpoint('xxs')]: { padding: '14%' },
    [breakpoint('xs')]: { padding: '18%' },
    [breakpoint('sm')]: { padding: '2%' },
    [breakpoint('md')]: { padding: '16%' },
})
interface StyledEstimatedaTimeToReadProps {
    isShareOpened?: boolean
}
export const StyledEstimatedaTimeToRead = styled(
    'span',
)<StyledEstimatedaTimeToReadProps>(({ isShareOpened }) => ({
    color: colors.sevennews.nero50,
    opacity: isShareOpened ? 0 : '30%',
    fontSize: calcRem(12),
    fontWeight: 600,
    lineHeight: calcRem(16),
    visibility: isShareOpened ? 'hidden' : 'visible',
    transition: `opacity ${easing.easeOut.med}`,
}))
export const StyledArticleTimeContainer = styled('span')(() => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: calcRem(8),
    flexWrap: 'wrap',
    paddingBottom: calcRem(12),
    width: '80%',
    [breakpoint('xs')]: {
        width: '100%',
    },
}))
export const StyledPublishedTimeWrapper = styled('div')(({}) => ({
    fontSize: calcRem(12),
    color: colors.sevennews.nero60,
    fontWeight: 600,
    span: {
        color: colors.sevennews.nero30,
    },
}))
interface StyledLastUpdatedTimeWrapperProps {
    isShareOpened?: boolean
}

export const StyledLastUpdatedTimeWrapper = styled(
    'div',
)<StyledLastUpdatedTimeWrapperProps>(({ isShareOpened }) => ({
    fontSize: calcRem(12),
    color: colors.sevennews.nero,
    opacity: isShareOpened ? 0 : '30%',
    fontWeight: 600,
    lineHeight: calcRem(16),
    visibility: isShareOpened ? 'hidden' : 'visible',
    transition: `opacity ${easing.easeOut.med}`,
}))
