import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getTechnologyPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Technology',
        id: 'technology',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Technology',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                page: 1,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                pageOffset: 5,
                page: 1,
                pageSize: 6,
            },
        },
        contentToFormat: [
            {
                pillMenuName: 'Science',
                pillMenuLink: '/technology/science',
                collectionTopics: ['technology/science'],
            },
            {
                pillMenuName: 'Smartphones',
                pillMenuLink: '/technology/smartphones',
                collectionTopics: ['technology/smartphones'],
            },
            {
                pillMenuName: 'Space',
                pillMenuLink: '/technology/space',
                collectionTopics: ['technology/space'],
            },
            {
                pillMenuName: 'Reviews',
                pillMenuLink: '/technology/reviews',
                collectionTopics: ['technology/reviews'],
            },
            {
                pillMenuName: 'Gaming',
                pillMenuLink: '/technology/gaming',
                collectionTopics: ['technology/gaming'],
            },
            {
                pillMenuName: 'Gadgets',
                pillMenuLink: '/technology/gadgets',
                collectionTopics: ['technology/gadgets'],
            },
        ],
        promoCardName: '7news-tech-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
