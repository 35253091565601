import {
    CollectionEvent,
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CollectionRouteProps } from '../../../collections'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { getVideoQueue } from '../../../templates'
import HomeHeroCardCollection from './HomeHeroCardCollection'
import { HomeHeroCardCollectionSkeleton } from './HomeHeroCardCollectionSkeleton'

export interface HomeHeroCardCollectionRouteProps
    extends CollectionRouteProps,
        ResponsiveContainer {
    onEvent: (event: CollectionEvent) => void
    adUnitPath?: string
}

export const HomeHeroCardCollectionRegistration =
    createRegisterableComponentWithData(
        'home-page-hero-collection',
        ContentDataDefinitionLoader,
        (props: HomeHeroCardCollectionRouteProps, data, services) => {
            if (
                !data.loaded ||
                (data.loaded && data.result.publications.length <= 0)
            ) {
                return (
                    <HomeHeroCardCollectionSkeleton
                        verticalSpacing={props.verticalSpacing}
                    />
                )
            }

            return (
                <HomeHeroCardCollection
                    items={
                        data.loaded
                            ? { loaded: true, result: data.result.publications }
                            : { loaded: false }
                    }
                    onEvent={props.onEvent}
                    verticalSpacing={props.verticalSpacing}
                    getVideoQueue={() =>
                        getVideoQueue(services, data.result.publications[0].id)
                    }
                />
            )
        },
    )
