import { SevenNewsSection } from '@news-mono/component-library'
import {
    BaseClientConfig,
    createDiagExample,
    createHeading,
    errorRoutesList,
    ListBlock,
    noIndexMeta,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { breadcrumb } from '../shared-components/breadcrumb'
import { box } from '../shared-components/compositions'

export interface StaticRoutesWithInfo {
    [key: string]: PageType<SevenNewsSection>
}

const fatwireRedirectRoute = createDiagExample(
    '/business/reality-bites-naive-hopefuls/story-e6frg2t3-1111112598276',
    'Example of an old fatwire publication url',
)

const newsCorpRedirectRoute = createDiagExample(
    '/news/western-australia/irukandji-jellyfish-warning-for-visitors-to-was-ningaloo-reef/news-story/e9f2263e36907415115ead7a72e93df1',
    'Example of an old news corp publication url',
)

const httpRedirectRoute = (config: BaseClientConfig) =>
    createDiagExample(
        `http://${config.publicHostname}/news/wa/article-with-images-containing-only-1-crop-ng-b88453367z`,
        'Example of an newsgate publication url with http (only redirects behind akamai)',
    )

const ampRedirectRoute = (config: BaseClientConfig) =>
    createDiagExample(
        `http://amp.${config.publicHostname}/politics/pm-meeting-trump-on-asia-summit-sidelines-ng-s-1796932`,
        'Example of an amp redirect',
    )

const trailingSlashRedirectRoute = createDiagExample(
    `/news/`,
    'Example of an trailing slash redirect',
)

const redirectList = (config: BaseClientConfig): ListBlock => ({
    kind: 'ordered-list',
    items: [
        fatwireRedirectRoute,
        httpRedirectRoute(config),
        newsCorpRedirectRoute,
        ampRedirectRoute(config),
        trailingSlashRedirectRoute,
    ].map((block) => ({ kind: 'list-item', blocks: [block] })),
})

export function createDiagPage(
    config: BaseClientConfig,
    section: SevenNewsSection,
): PageType<SevenNewsSection> {
    return {
        kind: 'page',
        heading: 'Diag Routes overview',
        hideHeading: true,
        pageType: 'static',
        adTargeting: {
            pageId: 'diag',
            adUnitPath: '',
            ssAdUnits: [],
            topics: [],
        },
        pageMeta: { meta: [noIndexMeta] },
        section,
        compositions: [
            breadcrumb({
                title: 'Diag Routes overview',
            }),
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['md', 'md'],
                },
                main: [
                    layout.component({
                        type: 'inline-content',
                        props: {
                            blocks: [
                                ...errorRoutesList,
                                createHeading('Redirects'),
                                redirectList(config),
                            ],
                        },
                    }),
                ],
            }),
        ],
    }
}
