import loadable from '@loadable/component'
import { createRenderTarget } from '@news-mono/component-library'
import { Product } from '@news-mono/web-common'
import { SiteProps } from '../Site'
import { BaseSevenNewsPreview } from './BaseSevenNews.preview'
import { BaseSevenNewsWeb } from './BaseSevenNews.web'

export interface BaseSevenNewsProps
    extends Omit<SiteProps, 'facebookAppId' | 'publicUrl'> {
    pageUrl: string
    product: Product
}

export const LazyBaseSevenNewsAmp = loadable(
    () => import('./BaseSevenNews.amp'),
)

export const BaseSevenNews = createRenderTarget('BaseSevenNews', {
    web: BaseSevenNewsWeb,
    amp: LazyBaseSevenNewsAmp,
    preview: BaseSevenNewsPreview,
    rss: BaseSevenNewsWeb,
    app: BaseSevenNewsWeb,
})
