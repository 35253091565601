import React from 'react'
import { StandingsWidgetContainer } from './StandingsWidget.styled'

export interface StandingsWidgetProps {
    competition: string
    season: string
}
export const StandingsWidget = ({
    competition,
    season,
}: StandingsWidgetProps) => {
    return (
        <StandingsWidgetContainer>
            <opta-widget
                widget="standings"
                template="normal"
                live="true"
                competition={competition}
                season={season}
                navigation="none"
                default_nav="1"
                show_key="false"
                show_crests="true"
                points_in_first_column="true"
                competition_naming="full"
                team_naming="full"
                sorting="false"
                show_live="false"
                show_logo="false"
                show_title="true"
                breakpoints="400, 700"
                sport="cricket"
            ></opta-widget>
        </StandingsWidgetContainer>
    )
}
