import styled from '@emotion/styled'
import { breakWords, calcRem, colors, fonts, metrics } from '../../../..'
import { WebLink } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'

export interface StyledisV2ArticlePageEnabled {
    isV2ArticlePageEnabled: boolean
}

export const StyledParagraph = styled('p')<StyledisV2ArticlePageEnabled>(
    ({ theme, isV2ArticlePageEnabled }) => [
        {
            ...breakWords,
            margin: `0 0 ${calcRem(metrics.sevennews.textMargin)} 0`,
            lineHeight: 1.7,
            fontFamily: fonts.sevennews.sansSerif,
            fontWeight: 'bold',
            fontSize: calcRem(18),
            ...(isV2ArticlePageEnabled
                ? {
                      background:
                          tokens.sevennews.colors.palette.articlesV2
                              .contextualBackground,
                      padding: `${calcRem(metrics.sevennews.textMargin)}`,
                  }
                : {}),
        },
    ],
)

export const StyledContextualLink = styled(WebLink)({
    textDecoration: 'none',
    color: tokens.sevennews.colors.palette.red,
})
