import styled from '@emotion/styled'
import { breakpoint, calcRem, colors, fonts } from '../../../__styling'

interface StyeldArticleCaptionWrapperProps {
    isCaptionShown: boolean
}
export const StyeldArticleCaptionWrapper = styled(
    'div',
)<StyeldArticleCaptionWrapperProps>(({ isCaptionShown }) => ({
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: isCaptionShown
        ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.74))'
        : 'unset',
    paddingLeft: calcRem(16),
    paddingBottom: calcRem(8),
    paddingTop: calcRem(4),
    fontSize: calcRem(14),
    width: '100%',

    [breakpoint('md')]: {
        marginBottom: calcRem(10),
        fontSize: calcRem(16),
    },
}))

export const StyeldArticleCaptionText = styled('span')(() => ({
    color: `${colors.white}`,
    width: '100%',
    fontWeight: 400,
    fontFamily: fonts.sevennews.sansSerif,
    marginRight: calcRem(60),
    [breakpoint('sm')]: {
        marginRight: calcRem(0),
    },
}))

export const StyeldOpenArticleCaption = styled('div')(() => ({
    cursor: 'pointer',
    alignSelf: 'flex-end',
}))
