import {
    DataLayerEventName,
    NavEvent,
    NavEventArgs,
    NavItem,
} from '@news-mono/web-common'
import H from 'history'
import React from 'react'
import { isCurrent } from '../SevenNewsMainNavigationV2'
import {
    StyledDesktopNavLink,
    StyledSVG,
} from './SevenNewsDesktopNavigationV2.styled'
import kebabCase from 'lodash'

export interface Props {
    navItem: NavItem
    location: H.Location
    onEvent: (event: NavEvent) => void
}

export const SevenNewDesktopNavItemV2: React.FC<Props> = (props) => {
    const { navItem, location, onEvent } = props
    const handleClick = (args: NavEventArgs) => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'MainNavigation',
            payload: {
                navName: 'MainNavigation.SevenNews',
                navLocation: 'Header',
                navPos: args.navPos,
                navText: args.navText,
                navLink: args.navLink,
                navPath: args.navParent
                    ? `${args.navParent} > ${args.navText}`
                    : `${args.navText}`,
            },
        })
    }
    return (
        <StyledDesktopNavLink
            to={navItem.link}
            rel={navItem.newWindow ? 'noopener' : undefined}
            target={navItem.newWindow ? '_blank' : undefined}
            isCurrent={isCurrent(navItem, location.pathname)}
            linkOut={navItem.linkOut}
            onClick={() => {
                handleClick &&
                    handleClick({
                        navPos: 0,
                        navText: navItem.name,
                        navLink: navItem.link,
                        navParent: parent.name,
                        navPath: parent.name,
                    })
            }}
            id={`${kebabCase(navItem.name).lowerCase()}-quicklink`}
        >
            {navItem.name}
            {navItem.linkOut && <StyledSVG />}
        </StyledDesktopNavLink>
    )
}
