import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { StaticHeroCard, SectionHeroCardProps } from './StaticHeroCard'

export const StaticHeroCardRegistration = createRegisterableComponent(
    'static-hero-card',
    (props: SectionHeroCardProps) => {
        return (
            <StaticHeroCard
                primaryTopic={props.primaryTopic}
                lastUpdated={props.lastUpdated}
                description={props.description}
                logo={props.logo}
                heroImage={props.heroImage}
                byLine={props.byLine}
                share={props.share}
                title={props.title}
            />
        )
    },
)
