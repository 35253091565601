import {
    Topic,
    createRegisterableComponent,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import { HorizontalPillMenu } from './HorizontalPillMenu'
import {
    PillItem,
    PillTabItem,
    PillTopics,
    pillMenuNavigation,
} from './helpers/subtopicList'
import { ThemeMargins } from '../../__styling'
import { VideoPillTopics, videoPilsMenu } from './helpers/videoSubtopicList'
import { useLocation } from 'react-router'

interface PillMenu {
    type: 'navigation'
    topic: PillTopics
    labelText?: string
}

interface DefaultPillMenu {
    type: 'default'
    topic: Topic
    labelText?: string
}

interface PillTabMenu {
    type: 'tab'
    mainPill: PillTabItem
    subPills?: PillTabItem[]
}

interface PillMenuOverrider {
    type: 'single'
    name: string
    link?: string
    onClick?: () => void
    withoutHoverState?: boolean
}
interface PillMenuVideo {
    type: 'videos'
    topic: VideoPillTopics | string
}

export interface HorizontalPillMenuRouteProps {
    data:
        | PillMenu
        | DefaultPillMenu
        | PillTabMenu
        | PillMenuOverrider
        | PillMenuVideo
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isWrapperCentered?: boolean
    withoutHoverState?: boolean
}
export type ShowName =
    | 'home-and-away'
    | 'dream-home'
    | 'the-voice-australia'
    | 'sas-australia'
    | 'my-kitchen-rules'
    | 'farmer-wants-a-wife'
    | 'big-brother-australia'
    | 'australian-idol'
    | 'dancing-with-the-stars'

const subtopicWithWatch7News = [
    'home-and-away',
    'dream-home',
    'the-voice-australia',
    'sas-australia',
    'my-kitchen-rules',
    'farmer-wants-a-wife',
    'big-brother-australia',
    'australian-idol',
    'dancing-with-the-stars',
]
const subtopicsWatch7Pluslinks = [
    {
        'home-and-away':
            'https://7plus.com.au/home-and-away?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=home-and-away',
    },
    {
        'dream-home':
            'https://7plus.com.au/dream-home?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=dream-home',
    },
    {
        'the-voice-australia':
            'https://7plus.com.au/the-voice?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=the-voice',
    },
    {
        'sas-australia':
            'https://7plus.com.au/sas-australia?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=sas-australia',
    },
    {
        'my-kitchen-rules':
            'https://7plus.com.au/my-kitchen-rules?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=mkr',
    },
    {
        'farmer-wants-a-wife':
            'https://7plus.com.au/farmer-wants-a-wife?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=farmer-wants-a-wife',
    },
    {
        'big-brother-australia':
            'https://7plus.com.au/big-brother?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=big-brother-aus',
    },
    {
        'australian-idol':
            'https://7plus.com.au/australian-idol?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=australian-idol',
    },
    {
        'dancing-with-the-stars':
            'https://7plus.com.au/dancing-with-the-stars?utm_source=7NEWS&utm_medium=show-page-menu&utm_campaign=dwts',
    },
]

export const HorizontalPillMenuRegistration = createRegisterableComponent(
    'horizontal-pill-menu',
    (props: HorizontalPillMenuRouteProps) => {
        const location = useLocation()
        const is7NewsOlympics2024Enabled = useFeature('7-news-olympics-2024')
        switch (props.data.type) {
            case 'single': {
                const { name, link, onClick, withoutHoverState } = props.data
                return (
                    <HorizontalPillMenu
                        mainPill={{
                            name,
                            link,
                            onClick,
                        }}
                        withoutHoverState={withoutHoverState}
                        verticalGutters={props.verticalGutters}
                        isWrapperCentered={props.isWrapperCentered}
                    />
                )
            }
            case 'default': {
                const { topic, labelText } = props.data
                const primaryTopic: PillTopics = topicHandler(topic)
                const subtopic = location.pathname.split('/')[2]

                if (primaryTopic) {
                    const navigationMenu = pillMenuNavigation[primaryTopic]

                    let pillMenu: PillItem[]
                    if (location.pathname.includes('/fixtures')) {
                        pillMenu = matchCentreHandler(
                            [...navigationMenu],
                            location.pathname,
                        )
                    } else if (subtopicWithWatch7News.includes(subtopic)) {
                        pillMenu = showsPillsHandler(
                            [...navigationMenu],
                            subtopic as ShowName,
                        )
                    } else {
                        pillMenu = navigationMenu
                    }

                    if (isSportsTopicWithFixturesPage(topic)) {
                        if (
                            !pillMenu.find(
                                (pill) =>
                                    pill.name === topic.title + ' Fixtures',
                            )
                        ) {
                            pillMenu.unshift({
                                name: topic.title + ' Fixtures',
                                link: `/${topic.id}/fixtures`,
                                isMainTopic: false,
                            })
                        }
                    }

                    if (pillMenu) {
                        if (
                            is7NewsOlympics2024Enabled &&
                            !pillMenu.find(
                                (pill) => pill.name === 'Olympics',
                            ) &&
                            primaryTopic === 'sport'
                        ) {
                            pillMenu.unshift({
                                link: '/sport/olympics',
                                name: 'Olympics',
                            })
                        }

                        const mainTopic = pillMenu.find(
                            (item) => item.link === location.pathname,
                        ) || {
                            name: topic.title,
                            link: `/${topic.id}`,
                        }
                        const subTopics = pillMenu.filter(
                            (pill) => pill.link !== mainTopic.link,
                        )
                        return (
                            <HorizontalPillMenu
                                mainPill={mainTopic}
                                subPills={subTopics}
                                labelText={labelText}
                                verticalGutters={props.verticalGutters}
                            />
                        )
                    }
                }
                return null
            }
            case 'navigation': {
                const { topic, labelText } = props.data
                const pillMenu = pillMenuNavigation[topic]

                const mainTopic =
                    pillMenu.find((item) => item.isMainTopic) || pillMenu[0]
                const subTopics = pillMenu.filter(
                    (pill) => pill.link !== mainTopic.link,
                )
                if (
                    is7NewsOlympics2024Enabled &&
                    (topic === 'trending' || topic === 'sport') &&
                    !subTopics.find((pill) => pill.name === 'Olympics')
                ) {
                    subTopics.unshift({
                        link: '/sport/olympics',
                        name: 'Olympics',
                    })
                }

                return (
                    <HorizontalPillMenu
                        mainPill={mainTopic}
                        subPills={subTopics}
                        labelText={labelText}
                        verticalGutters={props.verticalGutters}
                    />
                )
            }
            case 'tab': {
                const { mainPill, subPills } = props.data

                return (
                    <HorizontalPillMenu
                        mainPill={mainPill}
                        subPills={subPills}
                        verticalGutters={props.verticalGutters}
                    />
                )
            }
            case 'videos': {
                const { topic } = props.data
                const mainTopicName = topic.includes('video')
                    ? (topic.split('/')[1] as VideoPillTopics)
                    : (topic.split('/')[0] as VideoPillTopics)

                const pillMenu =
                    videoPilsMenu[mainTopicName] || videoPilsMenu['video']

                const mainTopic =
                    pillMenu.find((item) => {
                        return item.link?.includes(topic)
                    }) || pillMenu[0]

                const subTopics = pillMenu.filter(
                    (pill) => pill.link !== mainTopic.link,
                )

                mainTopicName === 'sport' &&
                    is7NewsOlympics2024Enabled &&
                    subTopics.push({
                        name: 'Olympics',
                        link: '/video/sport',
                    })
                return (
                    <HorizontalPillMenu
                        mainPill={mainTopic as PillItem}
                        subPills={subTopics}
                        verticalGutters={props.verticalGutters}
                    />
                )
            }
            default: {
                return null
            }
        }
    },
)

function topicHandler(topic: Topic): PillTopics {
    const parsedTopic = topic.id.split('/')
    const mainTopic = parsedTopic[0]
    const subtopic = parsedTopic[1]

    if (mainTopic.startsWith('news')) {
        if (subtopic) {
            switch (subtopic) {
                case 'world':
                case 'asia':
                case 'north-america':
                case 'middle-east':
                case 'europe':
                case 'africa':
                case 'latin-america':
                    return 'world'
                default:
                    return 'news/local'
            }
        } else return 'news/local'
    }
    return mainTopic as PillTopics
}

function matchCentreHandler(items: PillItem[], location: string): PillItem[] {
    // '/sport/afl/fixtures' we need sport subtopic name extracted
    const sportName = location.split('/')[2]
    switch (sportName) {
        case 'afl':
            items.push({
                name: 'AFL Fixtures',
                link: '/sport/afl/fixtures',
                isMainTopic: true,
            })
            return items
        case 'aflw':
            items.push({
                name: 'AFLW Fixtures',
                link: '/sport/aflw/fixtures',
                isMainTopic: true,
            })
            return items
        case 'cricket':
            items.push({
                name: 'Cricket Fixtures',
                link: '/sport/cricket/fixtures',
                isMainTopic: true,
            })
            return items
        default:
            return items
    }
}

function isSportsTopicWithFixturesPage(topic: Topic) {
    switch (topic.id) {
        case 'sport/afl':
            return true
        case 'sport/aflw':
            return true
        case 'sport/cricket':
            return true
        default:
            return false
    }
}
function showsPillsHandler(items: PillItem[], showName: ShowName): PillItem[] {
    let watch7Pluslink

    for (const obj of subtopicsWatch7Pluslinks) {
        if (obj[showName]) {
            watch7Pluslink = obj[showName]
            break
        }
    }

    items.unshift({ link: watch7Pluslink, name: 'Watch on 7plus' })
    return items
}
