import React from 'react'
import { useOptaWidgetStart } from '../useOptaWidgetStart'
import { ScoreCardWidgetContainer } from './ScoreCardWidget.styled'

export interface ScoreCardWidgetProps {
    competition: string
    season: string
    match: string
    isSummaryTab?: boolean
}
export const ScoreCardWidget = ({
    competition,
    season,
    match,
    isSummaryTab,
}: ScoreCardWidgetProps) => {
    useOptaWidgetStart()
    return (
        <ScoreCardWidgetContainer isSummaryTab={isSummaryTab}>
            <opta-widget
                widget="score_card"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                show_match_header="true"
                show_crests="false"
                show_competition_name="true"
                show_match_description="true"
                show_date="true"
                date_format="dddd D MMMM YYYY HH:mm"
                show_venue="true"
                show_officials="on_field"
                show_toss="true"
                show_innings_breakdown="true"
                show_current_batting="true"
                show_best_batting="2"
                show_best_bowling="2"
                show_state_of_play="true"
                navigation="tabs"
                default_nav="1"
                show_batting="true"
                show_mins_batted="false"
                show_fours="true"
                show_sixes="true"
                show_strike_rate="true"
                show_bowling="true"
                show_economy="true"
                show_dot_balls="false"
                show_bowling_extras="true"
                show_fow="true"
                show_partnerships="true"
                show_unfinished_partnerships="true"
                team_naming="full"
                player_naming="last_name"
                show_logo="false"
                show_title="false"
                breakpoints="400, 700"
                sport="cricket"
            ></opta-widget>
        </ScoreCardWidgetContainer>
    )
}
