import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    fonts,
    metrics,
    visuallyhidden,
} from '../../__styling'
import { Timestamp } from '../../typography/Timestamp/Timestamp'
import { AmpImage } from '../Picture/Picture.amp'

const headerHeightMob = calcRem(48)
const headerHeightTablet = calcRem(72)
const headerHeightDesktop = calcRem(88)

export const StyledSevenNewsHeader = styled('div')({
    margin: '0 auto',
    zIndex: 2,
})

export const StyledSiteHeaderContainer = styled('div')({
    maxWidth: metrics.sevennews.siteMetrics.sevenV2MainContentWidth,
    margin: '0 auto',
    padding: calcRem(0, metrics.sevennews.margins.md),
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    alignItems: 'center',
    height: headerHeightMob,

    [breakpoint('sm')]: {
        height: headerHeightTablet,
    },

    [breakpoint('md')]: {
        height: headerHeightDesktop,
        padding: calcRem(0, metrics.sevennews.margins.xl),
    },
})

interface StyledLogoWrapperProps {
    hide?: boolean
}

export const StyledLogoWrapper = styled('div')<StyledLogoWrapperProps>(
    ({ hide }) => ({
        flex: '1 1 0%',
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        justifyContent: 'flex-end',
        visibility: hide ? 'hidden' : 'visible',
        '&.hide': {
            visibility: 'hidden',
        },
        [breakpoint('xxs')]: {
            visibility: 'visible',
            '&.hide': {
                visibility: 'visible',
            },
        },
        [breakpoint('md')]: {
            justifyContent: 'center',
        },
    }),
)
export const StyledLogoSRHeading = styled('span')(visuallyhidden)

type LogoProps = {
    heights: {
        desktop: number
        tablet: number
        mobile: number
    }
    is7NewsSearchBarEnabled?: boolean
}

export const StyledLogo = styled('img')<LogoProps>(
    ({ heights, is7NewsSearchBarEnabled }) => ({
        display: 'block',
        zIndex: 0,
        width: 'auto',
        height: calcRem(heights.mobile),

        [breakpoint('sm')]: {
            height: calcRem(heights.tablet),
            marginRight: calcRem(9),
            marginLeft: is7NewsSearchBarEnabled ? calcRem(100) : 0,
        },
        [breakpoint('md')]: {
            height: calcRem(heights.desktop),
            marginRight: 0,
            marginLeft: is7NewsSearchBarEnabled ? calcRem(300) : 0,
        },
    }),
)

export const StyledAmpLogo = styled(AmpImage)({
    display: 'block',
    zIndex: 0,
})

export const StyledLogoLink = styled(WebLink)({
    display: 'block',
    lineHeight: 1,
})

export const StyledPrintLogo = styled('div')({
    display: 'none',
    padding: metrics.sevennews.margins.sm,

    '@media print': {
        display: 'block',
    },
})
interface StyledHeaderLeftContentProps {
    isSearchMobileActive?: boolean
}
export const StyledHeaderLeftContent = styled(
    'div',
)<StyledHeaderLeftContentProps>(({ isSearchMobileActive }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    gap: isSearchMobileActive
        ? calcRem(metrics.sevennews.margins.md)
        : calcRem(metrics.sevennews.margins.lg),
    left: calcRem(metrics.sevennews.margins.md),
    [breakpoint('sm')]: {
        left: calcRem(metrics.sevennews.margins.lg),
    },
    [breakpoint('md')]: {
        left: calcRem(metrics.sevennews.margins.xl),
    },
}))
interface TimestamProps {
    hide?: boolean
}
export const StyledHeaderTimestamp = styled(Timestamp)<TimestamProps>(
    ({ hide }) => ({
        fontSize: calcRem(12),
        lineHeight: calcRem(14),
        fontFamily: fonts.sevennews.sansSerif,
        fontWeight: 400,
        color: tokens.sevennews.colors.palette.charade,
        span: {
            opacity: 0.6,
            marginRight: calcRem(3),
        },
        display: hide ? 'none' : 'block',
        '&.toggleDisplay': {
            display: 'none',
        },
        [breakpoint('sm')]: {
            '&.toggleDisplay': {
                display: 'block',
            },
        },
    }),
)

export const StyledHeaderHamburgerWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
})
export const SearchFormMobileContainer = styled('div')({
    display: 'block',
    width: calcRem(28),
    [breakpoint('md')]: {
        display: 'none',
    },
})
export const SearchFormMDesktopContainer = styled('div')({
    display: 'none',
    [breakpoint('md')]: {
        display: 'flex',
    },
})
