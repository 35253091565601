import React from 'react'
import { PlayerCompareWidgetContainer } from './PlayerCompareWidget.styled'

export interface PlayerCompareWidgetProps {
    competition: string
    season: string
}
export const PlayerCompareWidget = ({
    competition,
    season,
}: PlayerCompareWidgetProps) => {
    return (
        <PlayerCompareWidgetContainer>
            <opta-widget
                widget="player_compare"
                template="normal"
                competition={competition}
                season={season}
                navigation="tabs"
                default_nav="1"
                show_selects="true"
                show_batting="true"
                show_bowling="true"
                data_type="series"
                show_crests="true"
                show_images="true"
                competition_naming="full"
                player_naming="full"
                team_naming="full"
                show_logo="true"
                show_title="true"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </PlayerCompareWidgetContainer>
    )
}
