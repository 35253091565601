import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { HeadingWrapper } from '../../section-header/SectionHeader/SectionHeader.styled'

export const StyledFooterNav = styled('nav')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(20),
    [breakpoint('sm')]: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
})

export const StyledFooterColumn = styled('div')({
    flex: 1,
    marginBottom: calcRem(20),

    [breakpoint('sm')]: {
        marginBottom: 0,
        flexGrow: 1,
        minWidth: calcRem(130),
        maxWidth: calcRem(230),
        '&:nth-child(2)': {
            flexGrow: 0,
        },
    },
    [`${HeadingWrapper}`]: {
        h2: {
            fontSize: calcRem(14),
            textDecoration: 'underline',
            textUnderlineOffset: calcRem(7),
        },
    },
})

export const StyledFooterColumnDoubled = styled(StyledFooterColumn)({
    display: 'flex',
    flexDirection: 'column',
    [breakpoint('md')]: {
        flexDirection: 'row',
        maxWidth: calcRem(320),
    },
})

export const StyledFooterColumnDoubledComponent = styled('div')({
    flex: 1,
    '&:nth-child(1)': {
        flex: 0,
        [breakpoint('md')]: {
            flex: 2,
        },
    },
})

export const StyledSocialLinks = styled('div')({
    display: 'grid',
    gap: calcRem(metrics.sevennews.margins.md),
})

export const StyledLinkList = styled('ul')({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxHeight: undefined,
    li: {
        marginBottom: calcRem(8),
        width: 'auto',
    },
    [breakpoint('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        maxHeight: calcRem(350),
    },
})

export const StyledLink = styled(WebLink)(({ theme }) => ({
    display: 'block',
    fontSize: calcRem(12),
    lineHeight: calcRem(13),
    fontFamily: fonts.sevennews.sansSerif,
    fontWeight: 300,
    color: colors.sevennews.bauhausBlack,
    textDecoration: 'none',
    padding: calcRem(metrics.sevennews.margins.xs, 0),

    '&:hover': {
        textDecoration: 'underline',
        color: colors.sevennews.red,
    },
}))

export const StyledAppPromotionCopy = styled('p')({
    fontSize: calcRem(12),
    lineHeight: calcRem(13),
    fontFamily: fonts.sevennews.sansSerif,
    fontWeight: 300,
    color: colors.sevennews.bauhausBlack,
    paddingRight: calcRem(15),
})

export const StyledPromotionButtonsWrapper = styled('div')({
    paddingTop: calcRem(10),
})
export const StyledPromotionButtonLink = styled(WebLink)({})
export const StyledPromotionButtonImg = styled('img')({})
