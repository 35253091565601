import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ScoreCardWidget, ScoreCardWidgetProps } from './ScoreCardWidget'

export const ScoreCardWidgetRegistration = createRegisterableComponent(
    'score-card-widget',
    (props: ScoreCardWidgetProps, services) => {
        return <ScoreCardWidget {...props} />
    },
)
