import {
    GetRouteAdTargeting,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { getV2PodcastPage } from '../helpers/get-v2-podcasts-page'

export const getPodcastsPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Podcasts',
        id: 'podcasts',
        seoTitle:
            'Latest podcasts from 7NEWS Australia, The Lady Vanishes and Shot In The Dark',
        seoDescription: `7NEWS brings you the latest Podcasts news from Australia and around the world. Stay up to date with all of the breaking Podcast headlines. Today's Podcasts news, live updates & all the latest breaking stories from 7NEWS.`,
        metadata: {},
    }

    const topicCurationPage = getV2PodcastPage({
        topic,
        promoCardName: '7news-entertainment-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
