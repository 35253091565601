import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem } from '../../__styling'

export const StyledSevenNewsPageByStatusWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: calcRem(18),
    gap: calcRem(30),
    [breakpoint('md')]: {
        flexDirection: 'row',
        maxWidth: calcRem(1100),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

export const StyledErrorTextWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
}))

interface StyledErrorTextProps {
    isLarge?: boolean
}

export const StyledErrorText = styled('p')<StyledErrorTextProps>(
    ({ isLarge }) => ({
        padding: 0,
        margin: 0,
        textAlign: 'center',
        fontSize: isLarge ? calcRem(80) : calcRem(20),
        fontWeight: 700,
        color: tokens.sevennews.colors.palette.red,
        lineHeight: 1,
        [breakpoint('sm')]: {
            fontSize: isLarge ? calcRem(103) : calcRem(20),
        },
        [breakpoint('md')]: {
            fontSize: isLarge ? calcRem(120) : calcRem(30),
        },
    }),
)

export const StyledErrorImageWrapper = styled('div')(() => ({
    flex: 2,
}))
interface StyledErrorImageProps {}
export const StyledErrorImage = styled('img')<StyledErrorImageProps>(({}) => ({
    width: '100%',
}))

interface ParagraphProps {
    isSevenNewsV2?: boolean
}
export const StyledErrorParagraph = styled('p')<ParagraphProps>(
    ({ isSevenNewsV2 }) => ({
        color: isSevenNewsV2
            ? tokens.sevennews.colors.palette.charade
            : tokens.sevennews.colors.palette.bauhausBlack,
        textAlign: 'center',
        fontWeight: 400,
        fontSize: isSevenNewsV2 ? calcRem(14) : calcRem(16),
        lineHeight: calcRem(20),
        maxWidth: calcRem(310),
        strong: {
            fontWeight: 700,
        },
        [breakpoint('sm')]: {
            fontSize: isSevenNewsV2 ? calcRem(17) : calcRem(18),
        },
    }),
)

export const StyledLink = styled(WebLink)(({}) => ({
    color: tokens.sevennews.colors.palette.red,
    textDecoration: 'underline',
}))

interface ButtonProps {
    isSevenNewsV2?: boolean
}

export const StyledHomeButton = styled(WebLink)<ButtonProps>(
    ({ isSevenNewsV2 }) => ({
        padding: calcRem(8, 16),
        background: tokens.sevennews.colors.palette.red,
        color: tokens.sevennews.colors.palette.white,
        fontSize: calcRem(14),
        fontWeight: 600,
        textDecoration: 'none',
        borderRadius: calcRem(4),
        width: isSevenNewsV2 ? '90%' : undefined,
        textAlign: isSevenNewsV2 ? 'center' : undefined,
        [breakpoint('md')]: {
            marginTop: calcRem(4),
        },
    }),
)
