import React from 'react'
import { ThemeMargins } from '../../../__styling'
import {
    StyedCollectionListWrapper,
    StyledHeroCollectionWrapper,
} from './HomeHeroCardCollection.styled'
import { StyledHeroContainer } from '../../HeroCard/ArticleHeroCard/ArticleHeroCard.styled'
import { Skeleton } from '../../../content'
import { SkeletonCard } from '../../cards'

interface HomeHeroCardCollectionSkeletonProps {
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}
export const HomeHeroCardCollectionSkeleton: React.FC<HomeHeroCardCollectionSkeletonProps> =
    ({ verticalSpacing }) => {
        return (
            <StyledHeroCollectionWrapper verticalSpacing={verticalSpacing}>
                <SkeletonCard textLines={5} isHomeHeroCard={true} />
                <StyedCollectionListWrapper>
                    {[...new Array(4)].map(() => {
                        return (
                            <SkeletonCard showTimeToRead={true} textLines={2} />
                        )
                    })}
                </StyedCollectionListWrapper>
            </StyledHeroCollectionWrapper>
        )
    }
