import React from 'react'
import {
    CouponsPartnershipContainer,
    CouponsPartnershipDisclaimerText,
    CouponsPartnershipDisclaimerWrapper,
    CouponsPartnershipHeading,
    CouponsPartnershipLi,
    CouponsPartnershipLink,
    CouponsPartnershipMainInfo,
    CouponsPartnershipPower,
    CouponsPartnershipPowerContainer,
    CouponsPartnershipPowerHeader,
    CouponsPartnershipPowerImg,
    CouponsPartnershipPowerImgWrapper,
    CouponsPartnershipText,
    CouponsPartnershipUl,
    NumberOfRetailerHeader,
    NumberOfRetailerParagraph,
    NumberOfRetailersBox,
    NumberOfRetailersContainer,
} from './CouponsPartnership.styled'

export const CouponsPartnership = () => {
    const awinPowerImg = require('./assets/tsg-awin-power-100.png')

    return (
        <CouponsPartnershipContainer>
            <CouponsPartnershipMainInfo>
                The 7NEWS Couponing Team
            </CouponsPartnershipMainInfo>
            <CouponsPartnershipText>
                7NEWS Coupons have partnered with{' '}
                <CouponsPartnershipLink to="https://www.thesavingsgroup.co.uk/">
                    The Savings Group (TSG) since{' '}
                </CouponsPartnershipLink>
                2020.
            </CouponsPartnershipText>
            <CouponsPartnershipText>
                TSG is headquartered in London with offices in New York. TSG has
                a team of over 100 highly skilled content writers, account
                managers and offers executives who are globally distributed
                across 15 countries and speak 20+ languages. They partner with
                many world leading publishers who trust them to curate their
                coupon offering and bring deals and discounts to their readers.{' '}
            </CouponsPartnershipText>
            <CouponsPartnershipText>
                TSG offers a wide range of coupon codes for many of the best
                known online stores in Australia and around the world. For over
                five years, The Savings Group, Inc. have also operated,{' '}
                <CouponsPartnershipLink to="https://www.lovecoupons.com.au/">
                    Love Coupons Australia
                </CouponsPartnershipLink>
                - a direct to consumer platform - which allows them to source
                coupon codes and enable stores to advertise their latest
                promotions, special offers and deals. This repository of offers
                enables 7NEWS Coupons to curate the best deals and offers for
                our audience in partnership with TSG.{' '}
            </CouponsPartnershipText>
            <CouponsPartnershipHeading>
                Our Coupon Code Collaboration
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                Our collective mission is to provide Australian shoppers with
                the best deals online, helping them to save when they shop and
                make their dollars go further.
            </CouponsPartnershipText>
            <CouponsPartnershipText>
                Our collaboration is centred on providing shoppers with the
                widest possible range of stores both in Australia and globally
                so that whether you shop with local, national or international
                stores you have the best chance of finding an offer that works
                for you on 7NEWS Coupons.
            </CouponsPartnershipText>
            <CouponsPartnershipText>
                7NEWS have been closely involved in developing and growing the
                coupon offer with The Savings Group. over the past 4 years.
                Working in partnership with TSG has allowed us to reach millions
                of online shoppers. Similarly, The Savings Group has enabled
                7NEWS to help those shoppers make better purchasing decisions -
                not just with the offers but also with information and content
                about the retailers that informs and educates readers on the
                best ways to shop and save. This is crucial and newsworthy given
                the current cost of living crisis.
            </CouponsPartnershipText>
            {/* THIRD */}
            <CouponsPartnershipHeading>
                How TSG supports 7NEWS journalism
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                When shoppers use our coupons to make a purchase we may receive
                a commission. We don’t receive a commission on every offer, we
                list some to give readers the best chance to save money. When we
                receive a commission it supports our partners storytelling while
                enabling us to continue searching out the best deals for you,
                the consumer.
            </CouponsPartnershipText>
            <CouponsPartnershipText>
                You will never pay more than the advertised price for a product
                when using a coupon from 7NEWS Coupons since our commission is
                funded by the brand's affiliate marketing budget.
            </CouponsPartnershipText>
            <CouponsPartnershipHeading>
                TSG Deals and Offers
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                Combining a coupon code when making an online purchase enables
                you to save more especially if there’s already a discount on the
                items. Coupon codes can offer up to 50% off the purchase price
                as well as additional benefits like free shipping and money off
                future purchases.
            </CouponsPartnershipText>
            <CouponsPartnershipHeading>
                How TSG sources the best deals
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                The Savings Group source the best deals through a number of
                channels:
            </CouponsPartnershipText>
            <CouponsPartnershipUl>
                <CouponsPartnershipLi>
                    <strong>Direct relationships with brands</strong>: The
                    Savings Group’s team of Account Managers work directly with
                    the world’s leading retail brands and their affiliate
                    marketing teams. Through daily interaction we source the
                    best, most relevant coupons and deals - bringing 7NEWS
                    readers market leading savings.
                </CouponsPartnershipLi>
                <CouponsPartnershipLi>
                    <strong>Over 100 affiliate network relationships:</strong>{' '}
                    The Savings Group via the owned and operated{' '}
                    <CouponsPartnershipLink to="https://www.lovesavingsgroup.com/">
                        Love Savings Group{' '}
                    </CouponsPartnershipLink>
                    platform have partnerships with over 100 affiliate networks
                    delivering thousands of merchant ecommerce stores to 7NEWS
                    Coupons. The Savings Group is a key partner for many of
                    these networks. Some of these are listed below. TSG
                    Accreditations
                </CouponsPartnershipLi>
                <CouponsPartnershipLi>
                    <strong>Daily Curation:</strong> Every day our offers team
                    check the affiliate networks for the best and latest deals.
                    Not only that, we're constantly reviewing newsletters,
                    emails and other communications from our brand partners to
                    make sure you never miss out when a new offer is released to
                    the market.
                </CouponsPartnershipLi>
                <CouponsPartnershipLi>
                    <strong>Constant Monitoring:</strong> We are constantly
                    checking the quality of our coupons in terms of the value
                    they provide to the reader and their validity in the market.
                    We welcome user feedback to improve our coupon offering so
                    if you find a code does not meet your needs, or if you’re a
                    brand and you think we should have a different or better
                    offer on the page don’t hesitate to contact us at
                    hello@thesavingsgroup.co.uk.
                </CouponsPartnershipLi>
                <CouponsPartnershipLi>
                    <strong>Industry Awards and Recognition:</strong> Our
                    success in partnering with affiliate networks has led to an
                    industry recognition of our role as a coupon publisher and a
                    number of awards and accreditations.
                </CouponsPartnershipLi>
            </CouponsPartnershipUl>
            <CouponsPartnershipPowerContainer>
                <CouponsPartnershipPower>
                    <CouponsPartnershipPowerHeader>
                        Power 100 2023{' '}
                    </CouponsPartnershipPowerHeader>
                    <CouponsPartnershipPowerImgWrapper>
                        <CouponsPartnershipPowerImg
                            src={awinPowerImg}
                            alt="SG Awin Power 100 2023"
                        ></CouponsPartnershipPowerImg>
                    </CouponsPartnershipPowerImgWrapper>
                </CouponsPartnershipPower>
                <CouponsPartnershipPower>
                    <CouponsPartnershipPowerHeader>
                        Power 100 2022
                    </CouponsPartnershipPowerHeader>
                    <CouponsPartnershipPowerImgWrapper>
                        <CouponsPartnershipPowerImg
                            src={awinPowerImg}
                            alt="SG Awin Power 100 2022"
                        ></CouponsPartnershipPowerImg>
                    </CouponsPartnershipPowerImgWrapper>
                </CouponsPartnershipPower>
                <CouponsPartnershipPower>
                    <CouponsPartnershipPowerHeader>
                        Power 100 2021
                    </CouponsPartnershipPowerHeader>
                    <CouponsPartnershipPowerImgWrapper>
                        <CouponsPartnershipPowerImg
                            src={awinPowerImg}
                            alt="SG Awin Power 100 2021"
                        ></CouponsPartnershipPowerImg>
                    </CouponsPartnershipPowerImgWrapper>
                </CouponsPartnershipPower>
            </CouponsPartnershipPowerContainer>
            <CouponsPartnershipHeading>
                How To Partner with The Savings Group
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                If you’re looking to list coupons or require a savings platform
                or partnership that is similar to the 7NEWS Coupons platform,
                you can email TSG at hello@thesavingsgroup.co.uk and a team
                member will be in touch with you to discuss your requirements.
            </CouponsPartnershipText>
            <CouponsPartnershipHeading>
                7NEWS Coupons Coupon Codes Statistics
            </CouponsPartnershipHeading>
            <CouponsPartnershipText>
                Below you can see some of the information related to the number
                of retailers, offers and the reach of The Savings Group’s
                partnership program.
            </CouponsPartnershipText>
            <NumberOfRetailersContainer>
                <NumberOfRetailersBox>
                    <NumberOfRetailerHeader>
                        Markets Globally
                    </NumberOfRetailerHeader>
                    <NumberOfRetailerParagraph>80+</NumberOfRetailerParagraph>
                </NumberOfRetailersBox>

                <NumberOfRetailersBox>
                    <NumberOfRetailerHeader>
                        Advertisers Listed
                    </NumberOfRetailerHeader>
                    <NumberOfRetailerParagraph>
                        20,000+
                    </NumberOfRetailerParagraph>
                </NumberOfRetailersBox>
                <NumberOfRetailersBox>
                    <NumberOfRetailerHeader>
                        Offers Listed
                    </NumberOfRetailerHeader>
                    <NumberOfRetailerParagraph>
                        180,000+
                    </NumberOfRetailerParagraph>
                </NumberOfRetailersBox>
            </NumberOfRetailersContainer>

            <CouponsPartnershipDisclaimerText>
                <strong>Disclaimer:</strong> 7NEWS Coupons is a destination for
                offers, coupons and discounts from your favourite stores in
                Australia and around the world. We collaborate extremely closely
                with our partner The Savings Group Limited who curate the best
                deals for you the consumer. By using our links you might save on
                your purchases. When you successfully make a purchase we may
                earn a small commission.
            </CouponsPartnershipDisclaimerText>
        </CouponsPartnershipContainer>
    )
}
CouponsPartnership.displayName = 'CouponsPartnership'
