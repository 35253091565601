import React from 'react'
import {
    SpotTheBucketContainer,
    SpotTheBucketHeading,
    SpotTheBucketLink,
    SpotTheBucketMainInfo,
    SpotTheBucketSubText,
    SpotTheBucketTable,
    SpotTheBucketTableTd,
    SpotTheBucketTableTh,
    SpotTheBucketTableTr,
    SpotTheBucketText,
} from './SpotTheBucket.styled'

export const SpotTheBucket = () => {
    return (
        <SpotTheBucketContainer>
            <SpotTheBucketHeading>Winners List:</SpotTheBucketHeading>
            <SpotTheBucketText>Draw #1 Natalie Stoute</SpotTheBucketText>
            <SpotTheBucketText>Draw #2 Peter Hendry</SpotTheBucketText>
            <SpotTheBucketText>Draw #3 Nicole Ashton </SpotTheBucketText>
            <SpotTheBucketText>Draw #4 Tamsyn Dawe </SpotTheBucketText>
            <SpotTheBucketText>Draw #5 Jamie Nielsen </SpotTheBucketText>
            <SpotTheBucketText>Draw #6 Norman Schmahl</SpotTheBucketText>
            <SpotTheBucketMainInfo>
                <SpotTheBucketText>
                    <strong> By entering</strong> “Big Brother KFC Spot the
                    Bucket Competition”
                </SpotTheBucketText>
                <SpotTheBucketText>
                    <strong>
                        (“the Competition”), you are agreeing to the following
                        Terms and Conditions:
                    </strong>{' '}
                </SpotTheBucketText>
            </SpotTheBucketMainInfo>
            {/* FIRST */}
            <SpotTheBucketHeading>1. STANDARD TERMS</SpotTheBucketHeading>
            <SpotTheBucketText>
                1.1 Information on how to enter forms part of the Terms and
                Conditions <strong>(“Terms and Conditions”)</strong>.
                Participation in this Competition is deemed acceptance of these
                Terms and Conditions.{' '}
            </SpotTheBucketText>
            {/* SECOND */}
            <SpotTheBucketHeading>2. WHO CAN ENTER?</SpotTheBucketHeading>
            <SpotTheBucketText>
                2.1 Subject to clause 2.2 and 2.3, entry is open to all
                residents of Australia aged 14 and above, where the Channel
                Seven (and affiliates) signal is received.
            </SpotTheBucketText>
            <SpotTheBucketText>
                2.2 Entrants aged over 14 but under 18 years must have
                parental/guardian approval to enter and the parent/guardian of
                the entrant must read and consent to these Terms and Conditions.
                Parents/guardians may be required by the Promoter to enter into
                a further agreement as evidence of consent to the minor entering
                this promotion.
            </SpotTheBucketText>
            <SpotTheBucketText>
                2.3 Employees and their immediate friends and families of the
                Seven Network (Operations) Limited, Kentucky Fried Chicken (KFC)
                Pty Ltd, Ansible Pty Ltd, and their associated agencies and
                companies are not eligible to enter. Immediate family means any
                of the following: spouse, ex-spouse, de-facto spouse, child, or
                stepchild (whether natural or by adoption), parent, step-parent,
                grandparent, step-grandparent, uncle, aunt, niece, nephew,
                brother, sister, step-brother, step-sister or 1st cousin.
            </SpotTheBucketText>
            {/* THIRD */}
            <SpotTheBucketHeading>3. HOW TO ENTER?</SpotTheBucketHeading>
            <SpotTheBucketText>
                3.1 The Competition commences on{' '}
                <strong> Monday 30th May 2022 at 19:00 AEST </strong> and
                concludes on{' '}
                <strong>
                    {' '}
                    Sunday 12th June 2022 at 19:00 AEST (“the Competition
                    Period”)
                </strong>{' '}
                however, the eligible Registration Period (defined below) for
                each Major Prize Draw will commence and conclude on the
                following dates and times:
            </SpotTheBucketText>
            {/* TABLE */}
            <SpotTheBucketTable>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTh>
                        Registration Period
                    </SpotTheBucketTableTh>
                    <SpotTheBucketTableTh>
                        Start Registratioin Period
                    </SpotTheBucketTableTh>
                    <SpotTheBucketTableTh>
                        Close Registration Period
                    </SpotTheBucketTableTh>
                    <SpotTheBucketTableTh>
                        Draw Date and Time
                    </SpotTheBucketTableTh>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>1</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Monday 30th May 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Tuesday 31st May 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 1st June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>2</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Tuesday 31st May 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 1st June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Thursday 2nd June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>3</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 1st June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Monday 6th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Tuesday 7th June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>4</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Monday 6th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Tuesday 7th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 8th June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>5</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Tuesday 7th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 8th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Thursday 9th June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
                <SpotTheBucketTableTr>
                    <SpotTheBucketTableTd>6</SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Wednesday 8th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Sunday 12th June 2022 at 19:00 AEST
                    </SpotTheBucketTableTd>
                    <SpotTheBucketTableTd>
                        Monday 13th June 2022 at 10:30 AEST
                    </SpotTheBucketTableTd>
                </SpotTheBucketTableTr>
            </SpotTheBucketTable>
            <SpotTheBucketText>
                3.2 For clarification, all entrants who enter during a
                Registration Period will be entered into the Major Prize Draw
                corresponding with the Registration Period in which they
                entered.
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.3 To enter, viewers must watch Big Brother (“the{' '}
                <strong>Program</strong>") on the Seven Network (“the{' '}
                <strong>Network</strong>" ), throughout the Competition Period.
                There will be multiple competition segments aired during the
                episodes of the Program in the Competition Period, which will
                display information on how to enter the Competition, the valid
                codeword for that Registration Period and the SMS number. Each
                entrant must enter the competition with the valid codeword
                within the applicable Registration Period.
            </SpotTheBucketText>{' '}
            <SpotTheBucketText>
                3.4 Entries do not carry over between Registration Periods.
                Entrants must submit an entry within a Registration Period,
                stipulated in the table in clause 3.1, to be in the running for
                the corresponding Major Prize Draw within that Registration
                Period.
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.5 In order to enter the Competition, viewers must send a text
                message/SMS to <strong> 0477 977 077 </strong> including the
                valid codeword for that Registration Period and the entrant’s
                full name (“ <strong> Valid Entry</strong>”). (Standard message
                rates apply. Ansible Pty Ltd SMS Helpline is 1300 851 419).
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.6 All entrants who have submitted an entry in accordance with
                clause 3.5 may receive a bounceback SMS confirming their entry
                into the competition along with a link to the KFC app or to
                download the KFC app. If entrants do not receive a bounceback
                SMS to confirm their entry and they have entered during the
                competition period and in accordance with clause 3.5, their
                entry will still be submitted into the draw.
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.7 All entrants who have submitted an entry in accordance with
                clause 3.5 may receive a bounceback SMS confirming their entry
                into the competition along with a link to the KFC app or to
                download the KFC app. If entrants do not receive a bounceback
                SMS to confirm their entry and they have entered during the
                competition period and in accordance with clause 3.5, their
                entry will still be submitted into the draw.
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.8 Entrants may enter the competition as many times as they
                like, provided that each entry is submitted separately and in
                accordance with entry requirements, however, each winner is only
                eligible to win one (1) Major Prize for the entire competition
                period (this does not apply to SA entrants due to state
                legislation).
            </SpotTheBucketText>
            <SpotTheBucketText>
                3.9 Entries must be received by the date and times stated in
                clause 3.1. The time of entry will in each case be the time the
                SMS message is received by the Ansible Pty Ltd database and NOT
                at the time of transmission by the entrant. SMS entries via the
                internet or computer generation are invalid and will not be
                accepted.
            </SpotTheBucketText>
            {/* FOURTH */}
            <SpotTheBucketHeading>4. PRIZES</SpotTheBucketHeading>
            <SpotTheBucketText>
                4.1 There will be <strong>Six (6) Major Prize Winners </strong>{' '}
                in total, with one (1) Major Prize Winner per Registration
                Period. Each winner will receive the prize detailed in clause
                4.2.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.2 The six (6) Major Prize Winners will each receive the
                following prize:
                {/* TABLE */}
                <SpotTheBucketTable>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTh>Number</SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>
                            Prize Details
                        </SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>Valued at</SpotTheBucketTableTh>
                    </SpotTheBucketTableTr>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Six (6) Major Prizes
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            $5,000.00 Cash (transferred via EFT){' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>$5,000.00 </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTh></SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>
                            TOTAL MAJOR PRIZE POOL VALUE
                        </SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>$30,000.00</SpotTheBucketTableTh>
                    </SpotTheBucketTableTr>
                </SpotTheBucketTable>
                Total maximum prize pool value for the prize is{' '}
                <strong>$30,000.00</strong> (including GST).
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.3 In accepting a prize, the winner agrees to participate in
                and co-operate with all reasonable media editorial requests,
                including but not limited to, being interviewed and
                photographed, and the winner grants the Promoter a perpetual and
                non-exclusive licence to use such footage and photographs in all
                media worldwide and the winner will not be entitled to any fee
                for such use.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.4 Winners agree they will not sell or otherwise provide their
                story and/or photographs to any media or other organisation.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.5 Full details of prizes can be obtained by telephoning the
                Seven Network in each state on Sydney 02 8777 7777; Melbourne 03
                9697 7777; Adelaide 08 8342 7777; Brisbane 07 3369 7777; Perth
                08 9344 0777.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.6 It is a condition of accepting a prize that the winner must
                comply with all the conditions of use of the prize and the
                Promoter and Sponsor’s requirements.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.7 The Major Prize Winners will be selected at random by
                Ansible Pty Ltd and contacted directly by the Ansible Pty Ltd
                team via phone call and in writing by text/SMS message on the
                dates stipulated in the table in clause 5.2.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.8 The prize monies comprising the Major Prizes will be
                transferred to the Major Prize Winners nominated Australian bank
                account via EFT.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.9 Major Prize Winners will be required to provide valid
                identification to Ansible Pty Ltd to prove they meet the valid
                entry requirements. Winners will also be required to provide
                their required bank account details to Ansible Pty Ltd in order
                to facilitate the EFT transfer. Once a Winner has provided all
                required details, prize fulfillment will be conducted within 15
                business days.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.10 If a winner of any prize is under the age of 18 years, the
                prize will be awarded to the winner's nominated parent or
                guardian on the winner’s behalf.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.11 In the event that an element of a prize outlined in the
                above is not available for any reason, the Promoter and Sponsor
                reserves the right to substitute with a similar product of equal
                or greater value, subject to state regulations.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.12 The Promoter and Sponsor may require prize winners to sign
                a waiver of liability and indemnity in the form determined by
                the Promoter and/or Sponsor in its absolute discretion.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.13 Independent financial advice should be sought as tax
                implications may arise as a result of accepting a prize.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.14 The promoter may vary these Terms and Conditions at its
                discretion, subject to state government legislation or any
                written directions from a regulatory authority.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.15 Prizes are not transferable, exchangeable, unless otherwise
                advised.
            </SpotTheBucketText>
            <SpotTheBucketText>
                4.16 Once a prize is accepted, the winner must deal directly
                with Ansible Pty Ltd as distributer of the prizing.
            </SpotTheBucketText>
            {/* FIFTH */}
            <SpotTheBucketHeading>5. HOW TO WIN</SpotTheBucketHeading>
            <SpotTheBucketText>
                5.1 There will be <strong> Six (6) Major Prize Winners </strong>{' '}
                in total, One (1) Major Prize Winner per registration period.
                Each entrant who has entered the Competition over the
                Competition Period (according to the registration periods in
                clause 3.1) and registered all details in accordance with clause
                3 by either using their smartphone or a mobile phone with a
                reply path whose SMS message has passed through the Telco
                carriers during the Competition
                <strong> (“Qualifying Entrant”) </strong> will be entered into
                the relevant prize draw/s corresponding to the registration
                period within which they entered.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.2 The six (6) Major Prize Draws will be held at Ansible Pty
                Ltd, Level 3, 100 Chalmers Street, Surry Hills NSW 2010 on the
                dates stipulated in the table below. The Promoter reserves the
                right to draw additional reserve entries and record them in
                order in case an invalid or ineligible entrant is drawn.
                {/* TABLE */}
                <SpotTheBucketTable>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTh>Prize Draw</SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>
                            Draw Date and Time
                        </SpotTheBucketTableTh>
                        <SpotTheBucketTableTh>
                            Notification and Publication Date
                        </SpotTheBucketTableTh>
                    </SpotTheBucketTableTr>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #1
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Wednesday 1st June 2022 at 10:30 AEST
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Friday 3th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>
                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #2
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Thursday 2nd June 2022 at 10:30 AEST{' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Monday 6th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>

                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #3
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Tuesday 7th June 2022 at 10:30 AEST{' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Thursday 9th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>

                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #4
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Wednesday 8th June 2022 at 10:30 AEST{' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Friday 10th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>

                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #5
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Thursday 9th June 2022 at 10:30 AEST{' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Monday 13th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>

                    <SpotTheBucketTableTr>
                        <SpotTheBucketTableTd>
                            Major Prize #6
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Monday 13th June 2022 at 10:30 AEST{' '}
                        </SpotTheBucketTableTd>
                        <SpotTheBucketTableTd>
                            Wednesday 15th June 2022{' '}
                        </SpotTheBucketTableTd>
                    </SpotTheBucketTableTr>
                </SpotTheBucketTable>
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.3 The first valid entry drawn in each Major Prize Draw will
                win a Major Prize, detailed in clause 4.2.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.4 This is a game of chance and skill plays no part in
                determining the winners.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.5 Entries that do not comply with any of the Terms and
                Conditions, may be deemed invalid in which case the Promoter
                will revert to a reserve entry drawn (if reserve entries are
                drawn) or a redraw will take place.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.6 Major Prize Winners will be notified by a phone call and in
                writing by text/SMS from Ansible Pty Ltd and their names will be
                published on the 7plus website at 7NEWS.com.au/spotthebucket on
                the dates outlined in clause 5.2.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.7 The Promoter’s decision is final, and the Promoter will not
                enter correspondence regarding the result.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.8 The Promoter may in its absolute discretion deem entries
                invalid subsequently to a winner being notified or a winner’s
                name being announced if it is discovered that the winner did not
                enter the Competition in accordance with these Terms and
                Conditions. In these circumstances, the Promoter will revert to
                a reserve entry drawn (if reserves had been drawn), or otherwise
                conduct a redraw.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.9 It is a condition of accepting a prize that the winner may
                be required to sign a legal release in a form determined by the
                Promoter and/or Sponsor in its absolute discretion.
            </SpotTheBucketText>
            <SpotTheBucketText>
                5.10 All reasonable efforts will be made by Ansible Pty Ltd to
                contact the Major Prize Winners. In the event of an unclaimed
                Major Prize, an unclaimed prize draw will take place at Ansible
                Pty Ltd, Level 3, 100 Chalmers Street, Surry Hills, NSW 2010
                Australia on{' '}
                <strong> Friday 5th August 2022 at 10:00 AEST</strong>, subject
                to any written directions from a regulatory authority. Winner/s
                (if any) will be notified by phone and text/SMS on{' '}
                <strong> Tuesday 9th August 2022.</strong> The unclaimed prize
                winner’s details will be published on the 7plus website at
                7NEWS.com.au/spotthebucket from{' '}
                <strong> Tuesday 9th August 2022</strong>.
            </SpotTheBucketText>
            {/* 6. */}
            <SpotTheBucketHeading>6. GENERAL</SpotTheBucketHeading>
            <SpotTheBucketText>
                6.1 The Promoter reserves the right, at any time, to verify the
                validity of entries and entrants (including an entrant’s
                identity, age and place of residence) and reserves the right, in
                its sole discretion, to disqualify any individual who the
                Promoter has reason to believe has breached any of these Terms
                and Conditions, tampered with the entry process including but
                not limited to tampering by way of use of techniques designed to
                avoid the payment of SMS costs, engaging in any unlawful or
                other improper misconduct calculated to jeopardise fair and
                proper conduct of the Competition. Errors and omissions may be
                accepted at the Promoter's discretion. Failure by the Promoter
                to enforce any of its rights at any stage does not constitute a
                waiver of those rights. The Promoter's legal rights to recover
                damages or other compensation from such an offender are
                reserved.
            </SpotTheBucketText>
            <SpotTheBucketText>
                6.2 Incomplete or indecipherable entries will be deemed invalid.
            </SpotTheBucketText>
            <SpotTheBucketText>
                6.3 If there is a dispute as to the identity of an entrant, the
                Promoter reserves the right, in its sole discretion, to
                determine the identity of the entrant.
            </SpotTheBucketText>
            <SpotTheBucketText>
                6.4 Subject to the unclaimed prize draw clause, if for any
                reason a winner does not take and/or redeem a prize at/by the
                time stipulated by the Promoter, then the prize will be
                forfeited.
            </SpotTheBucketText>
            <SpotTheBucketText>
                6.5 The use of any automated entry software or any other
                mechanical or electronic means that allows an entrant to
                automatically enter or vote repeatedly is prohibited and will
                render all entries submitted by that entrant invalid.
            </SpotTheBucketText>
            {/* 7. */}
            <SpotTheBucketHeading>7. NO LIABILITY</SpotTheBucketHeading>
            <SpotTheBucketText>
                7.1 In the event any outside act, agent or event prevents or
                significantly hinders the Promoter’s ability to proceed with the
                Competition on the dates and in the manner described in these
                Terms and Conditions, including but not limited to vandalism,
                power failures, tempests, natural disasters, acts of God, civil
                unrest, strike, war, act of terrorism, pandemic, COVID-19
                related issues, the Promoter may in its absolute discretion
                cancel the Competition, subject to any written directions from a
                regulatory authority.
            </SpotTheBucketText>
            <SpotTheBucketText>
                7.2 Seven Network (Operations) Limited, Kentucky Fried Chicken
                Pty Ltd, Ansible Pty Ltd and their associated agencies and
                companies will take no responsibility for prizes damaged or lost
                in transit, or late, lost or misdirected mail.
            </SpotTheBucketText>
            <SpotTheBucketText>
                7.3 Nothing in these Terms and Conditions limits, excludes or
                modifies or purports to limit, exclude or modify the statutory
                consumer guarantees as provided under the Competition and
                Consumer Act, as well as any other implied warranties under the
                ASIC Act or similar consumer protection laws in the States and
                Territories of Australia{' '}
                <strong> (“Non-Excludable Guarantees”)</strong>. Except for any
                liability that cannot by law be excluded, including the
                Non-Excludable Guarantees, Seven Network (Operations) Limited,
                Kentucky Fried Chicken Pty Ltd, Ansible Pty Ltd and their
                associated agencies and companies excludes all liability
                (including negligence) for any misadventure, accident, injury;
                or any loss or damage (including and loss of opportunity)
                whether direct, indirect, special or consequential arising in
                any way out of the promotion.
            </SpotTheBucketText>
            <SpotTheBucketText>
                7.4 Except for any liability that cannot by law be excluded,
                including the Non-Excludable Guarantees, the Promoter (including
                its respective officers, employees and agents) is not
                responsible for and excludes all liability (including
                negligence), for any personal injury; or any misadventure,
                accident; or any loss or damage (including loss of opportunity);
                whether direct, indirect, special or consequential; or any claim
                arising in any way:
            </SpotTheBucketText>
            {/* SUBITEM */}
            <SpotTheBucketSubText>a) during the draw;</SpotTheBucketSubText>
            <SpotTheBucketSubText>
                b) in the acceptance, participation or use of the prize;
            </SpotTheBucketSubText>
            <SpotTheBucketSubText>
                c) as a consequence of late, lost, altered, damaged or
                misdirected mail, entry or prize claims (whether or not after
                their receipt by the Promoter) due to any reason beyond the
                reasonable control of the Promoter;{' '}
            </SpotTheBucketSubText>
            <SpotTheBucketSubText>
                d) due to the broadcast of any program relating to the
                Competition or the publication of any material, including any
                statements made by any compere, staff member, journalist, other
                entrants or any other person;{' '}
            </SpotTheBucketSubText>
            <SpotTheBucketSubText>
                e) arising from or related to any problem or technical
                malfunction of any telephone network or lines or mobile
                communications network related to or resulting from
                participation in this Competition;{' '}
            </SpotTheBucketSubText>
            <SpotTheBucketSubText>
                f) any theft, unauthorised access or third party interference;
                or{' '}
            </SpotTheBucketSubText>
            <SpotTheBucketSubText>
                g) any tax liability incurred by a winner or entrant
            </SpotTheBucketSubText>
            <SpotTheBucketText>
                7.5 Seven Network (Operations) Limited, Kentucky Fried Chicken
                Pty Ltd, Ansible Pty Ltd and its associated agencies and
                companies assumes no responsibility for any error, omission,
                interruption, deletion, defect, delay in operation or
                transmission, communications line failure, theft or destruction
                or unauthorized access to, or alteration of entries, and
                reserves the right to take any action that may be available,
                subject to state government legislation.
            </SpotTheBucketText>
            <SpotTheBucketText>
                7.6 If for any reason the Competition is not capable of running
                as planned, due to causes including but not limited to
                tampering, unauthorized intervention, fraud, technical failures
                or any other causes beyond the control of the Promoter which
                corrupt or affect the administration security, fairness,
                integrity or proper conduct of the Competition, the Promoter
                reserves the right in its sole discretion to disqualify any
                individual who tampers with the entry process, take any action
                that may be available, and to cancel, terminate, modify or
                suspend the Competition, subject to any written directions from
                a regulatory authority.
            </SpotTheBucketText>
            {/* 8. */}
            <SpotTheBucketHeading>8. PROMOTER’S DETAILS</SpotTheBucketHeading>
            <SpotTheBucketText>
                8.1 The Promoter is Seven Network (Operations) Limited (ABN 65
                052 845 262) of Media City – 8 Central Avenue, Eveleigh NSW 2015
                Australia.
            </SpotTheBucketText>
            <SpotTheBucketText>
                8.2 The Sponsor is Kentucky Fried Chicken Pty Ltd (ABN 79 000
                587 780) of 20 Rodborough Rd, Frenchs Forest NSW 2086 Australia.
            </SpotTheBucketText>
            <SpotTheBucketSubText>
                8.3.1 The personal information collected as part of the entry is
                collected to enable identification of the winners and for
                marketing and promotional purposes for the Promoter and the
                Sponsor. In addition to any required disclosures referred to in
                these Terms and Conditions, your personal information will be
                disclosed to the Promoter, the Sponsor and their related bodies
                corporate for these purposes. The Promoter may disclose your
                personal information to other companies for the purpose of
                facilitating the delivery of prizes to winners. Where you
                provide us with personal information about another individual,
                you must have their consent to provide their personal
                information. No other use of this information will be made
                without your consent. If you do not provide your personal
                information, the Promoter will not be able to enter you into the
                Competition. Information in relation to how the Promoter
                collects, store and uses your personal information can be found
                at [
                <SpotTheBucketLink to="http://www.sevenwestmedia.com.au/privacy-policies">
                    http://www.sevenwestmedia.com.au/privacy-policies
                </SpotTheBucketLink>{' '}
                ]. Information in relation to how the Sponsor collects, store
                and uses your personal information can be found at{' '}
                <SpotTheBucketLink to=" https://www.kfc.com.au/privacy-policy">
                    https://www.kfc.com.au/privacy-policy
                </SpotTheBucketLink>{' '}
                If you would like details of the personal information held by
                the Promoter or Sponsor about you, or if you would like such
                information corrected, you can write to the Promoter or the
                Sponsor at the address stated above or by emailing{' '}
                <SpotTheBucketLink to="privacy@seven.com.au ">
                    privacy@seven.com.au
                </SpotTheBucketLink>{' '}
                (Promoter) or Privacy.OfficerAUS@yum.com (Sponsor). Without
                limiting the foregoing, the Promoter may disclose the entrant’s
                personal information to its related entities, business partners
                and external service providers for research and profiling
                purposes as well as other purposes reasonably related to the
                entrant’s relationship with the Promoter. In addition, by
                entering this Competition, you consent to the Promoter using
                your personal information for the purpose of the Promoter and
                its related entities sending you information regarding programs,
                products and services available through them and/ or through
                their business partners, and to the Promoter from time to time
                sharing your personal information with carefully selected
                business partners for the purpose of them sending you such
                information directly. We will always provide you with the
                ability to opt out of those communications.
                www.sevenwestmedia.com.au
            </SpotTheBucketSubText>
            <SpotTheBucketText>
                Permit approvals: ACT: TP 22/00851.1 NSW: LTP/04019 SA: T22/660
            </SpotTheBucketText>
        </SpotTheBucketContainer>
    )
}

SpotTheBucket.displayName = 'SpotTheBucket'
