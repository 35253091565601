import {
    CardItem,
    CollectionEvent,
    RenderTargetContext,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useContext, useRef } from 'react'
import SwiperClass, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { ThemeMargins } from '../../__styling'
import { amphtml } from '../../google-amp'

import { invertMaybeLoadedItems } from '../../collections/helpers/loading'
import { CTAProps, ContentCard } from '../cards/ContentCard/ContentCard'
import {
    StyledExploreMoreCarouselAmpWrapper,
    StyledExploreMoreCarousellWrapper,
} from './ExploreMoreCarousel.styled'

interface ExploreMoreCarouselProps {
    items: MaybeLoaded<CardItem[]>
    verticalSpacing?: keyof ThemeMargins
    onEvent: (event: CollectionEvent) => void
    cta?: CTAProps
}

interface AmpProps {
    layout: string
    width: string
    height: string
    heights: string
    'visible-count': string
    'advance-count': string
}

export const ExploreMoreCarousel: React.FC<ExploreMoreCarouselProps> = ({
    items,
    onEvent,
    cta,
}) => {
    const { renderTarget } = useContext(RenderTargetContext)
    const loadedItems = invertMaybeLoadedItems(items, 3)
    const swiperRef = useRef<SwiperClass>()

    if (renderTarget === 'amp') {
        const AmpCacrousel = amphtml<AmpProps>('base-carousel')

        return (
            <StyledExploreMoreCarouselAmpWrapper>
                <AmpCacrousel
                    layout="responsive"
                    width="3"
                    height="350px"
                    heights="350px"
                    visible-count="(min-width: 600px) 3.5, 1.5"
                    advance-count="1"
                >
                    {loadedItems.map((item, itemIndex) => (
                        <>{renderCard(item, itemIndex, onEvent, cta)}</>
                    ))}
                </AmpCacrousel>
            </StyledExploreMoreCarouselAmpWrapper>
        )
    }
    return (
        <StyledExploreMoreCarousellWrapper>
            <Swiper
                slidesPerView={'auto'}
                watchSlidesProgress={true}
                spaceBetween={40}
                loop={false}
                watchOverflow={false}
                navigation={{}}
                slideToClickedSlide={true}
                allowSlideNext={true}
                allowSlidePrev={true}
                modules={[Navigation]}
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper
                }}
            >
                {loadedItems.map((item, itemIndex) => {
                    return (
                        <SwiperSlide key={itemIndex} virtualIndex={itemIndex}>
                            {renderCard(item, itemIndex, onEvent, cta)}
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </StyledExploreMoreCarousellWrapper>
    )
}

function renderCard(
    item: MaybeLoaded<CardItem>,
    itemIndex: number,
    onEvent: (event: CollectionEvent) => void,
    cta?: CTAProps,
) {
    return (
        <ContentCard
            item={item}
            cardNumber={itemIndex}
            onEvent={onEvent}
            cardType={'medium'}
            cta={cta}
            displayRightSeparator={true}
        />
    )
}
