import { createRegisterableComponent } from '@news-mono/web-common'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import {
    GalleryPublicationInformation,
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../../templates/Publication/SharedPublication.routing'
import { getPublicationPageProperties } from '../lib/publication-page-properties'
import { SevenNewsGallery } from './SevenNewsGallery'

export const SevenNewsGalleryRegistration = createRegisterableComponent(
    'gallery',
    (props: PublicationRouteProps, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)
        if (publicationInfo.publication.kind !== 'gallery') {
            throw new Error('Gallery component rendered for non-gallery')
        }

        const galleryMeta = props.meta as GalleryPublicationInformation
        return (
            <PageProps
                pageProperties={getPublicationPageProperties(
                    publicationInfo.publication,
                )}
            >
                <SevenNewsGallery
                    adState={services.adState}
                    gallery={publicationInfo.publication}
                    galleryMeta={galleryMeta}
                    location={services.location}
                    onEvent={services.onEvent}
                    log={services.log}
                />
            </PageProps>
        )
    },
)
