import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    colors,
    fonts,
    metrics,
    transition,
} from '../../__styling'
import { StyledHamburgerButton } from '../../buttons/HamburgerButtonV2/HamburgerButtonV2.styled'
import { SocialLinks } from '../SocialLinks/SocialLinks'
import { FlyoutItemIconArrow } from './assets/FlyoutItemIconArrow'
import LinkOutIcon from '../SevenNewsMainNavigationV2/LinkOutIcon'

const FLYOUT_WIDTH = 359

export const commonButtonStyles: CSSObject = {}

interface StyledNavigationBackgroundProps {
    isOpen: boolean | undefined
}

export const StyledNavigationBackground = styled(
    'div',
)<StyledNavigationBackgroundProps>(
    ({ isOpen }) =>
        isOpen && {
            position: 'fixed',
            height: '100vh',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: colors.black50,
            zIndex: 1,
        },
)

export const StyledNavigationBackgroundAMP = styled('div')({
    transition: 'background 0.5s ease',
    '&.isOpen': {
        position: 'fixed',
        height: '100vh',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: colors.black50,
        zIndex: 2,
    },
})
export const commonLinkStyles: CSSObject = {
    color: 'currentColor',
    textDecoration: 'none',
    display: 'block',
}

export const StyledAnimationContainer = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'hidden',
    display: 'none',
    width: 0,
    height: '100%',
    maxHeight: '100vh',
    background: colors.white,

    [breakpointMax('xxs')]: {
        left: 0,
    },

    '&.isOpen': {
        width: 0,
        height: '100vh',
        display: 'block',
        ...transition({ properties: ['width'], speed: 'fast' }),
    },

    '&.isTransitioning': {
        width: '100%',

        [breakpoint('xxs')]: {
            width: FLYOUT_WIDTH,
        },
    },
})

export const StyledHeader = styled('div')({
    margin: calcRem(25, 16, 16, 16),
    [breakpoint('md')]: {
        marginTop: calcRem(38),
    },
    [breakpoint('lg')]: {
        marginTop: calcRem(41),
    },
    [`${StyledHamburgerButton}`]: {
        marginLeft: calcRem(20),
    },
})
export const StyledHeaderAMP = styled('div')({
    margin: calcRem(16, 16, 25, 16),

    [`${StyledHamburgerButton}`]: {
        marginLeft: calcRem(20),
    },
})
export const StyledLocationWrapper = styled('div')({
    border: `${calcRem(1.7)} solid ${colors.black15}`,
    borderRadius: calcRem(4),
    marginTop: calcRem(16),
    padding: calcRem(8, 18),
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
})

export const StyledLocationLabel = styled('p')({
    margin: 0,
    padding: 0,
    color: tokens.sevennews.colors.palette.charade,
    fontWeight: 400,
    lineSpacing: calcRem(0.36),
    fontSize: calcRem(12),
})

interface StyledNavProps {
    flyoutNavHeightFromTop?: number
}

export const StyledNav = styled('nav')<StyledNavProps>(
    ({ flyoutNavHeightFromTop }) => ({
        display: 'grid',
        color: tokens.sevennews.colors.palette.charade,
        fontFamily: fonts.sevennews.sansSerif,
        width: '100%',
        height: `calc(100% - 100px)`, // need this to fix hidden social icons in scroll container
        paddingTop: 0,

        [breakpoint('xxs')]: {
            width: FLYOUT_WIDTH,
        },

        [breakpoint('md')]: {
            height: `calc(100% - ${flyoutNavHeightFromTop}px)`,
        },
    }),
)

export const StyledNavScrollArea = styled('div')({
    direction: 'rtl', // place scrollbar on the left side of div
    overflowY: 'scroll',
    MsOverflowStyle: '-ms-autohiding-scrollbar',
    '&::-webkit-scrollbar': {
        width: calcRem(9),
        backgroundColor: colors.sevennews.nero30,
    },
    '&::-webkit-scrollbar-track': {
        width: calcRem(9),
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.sevennews.red40,
        borderRadius: calcRem(10),
    },
    '*': {
        direction: 'ltr', //reset direction to left to right for all child elements
    },
})

export const StyledMainNav = styled('div')({
    backgroundColor: tokens.sevennews.colors.palette.flyoutV2.flyoutGrey,
    color: tokens.sevennews.colors.palette.charade,
})

export const commonListStyles: CSSObject = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontSize: calcRem(16),
}

export const StyledNavList = styled('ul')({ ...commonListStyles })

export const StyledNavItem = styled('li')({})

export const StyledNavItemText = styled('span')({
    textDecoration: 'none',
    display: 'flex',
    gap: calcRem(7),
    alignItems: 'center',
    svg: {
        path: {
            fill: tokens.sevennews.colors.palette.charade,
            fillOpacity: 'unset',
        },
    },
})
export function commonInteractionStyles(): CSSObject {
    return {
        ...transition({ properties: ['background-color'], speed: 'fast' }),

        '&:active': {
            backgroundColor: colors.sevennews.red,
            color: colors.white,
        },

        '&:focus': {
            outline: `1px solid ${colors.sevennews.red}`,
        },

        '@media (pointer: fine)': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: colors.sevennews.red,
                color: colors.white,
            },
        },
    }
}

export const StyledNavButton = styled('button')({
    color: 'currentColor',
    display: 'flex',
    width: '100%',
    padding: calcRem(17, 20),
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontSize: calcRem(15),
    ...commonInteractionStyles(),
    borderBottom: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    borderTop: `${calcRem(1)} solid ${colors.sevennews.nero15}`,

    svg: {
        width: '1.25rem',
        margin: 0,
        path: {
            fill: tokens.sevennews.colors.palette.charade,
            fillOpacity: 0.3,
        },
    },
    [breakpoint('md')]: {
        '&:hover': {
            svg: {
                path: {
                    fill: colors.white,
                    fillOpacity: 1,
                },
            },
        },
    },
})
export const StyledNavLinkAMP = styled(WebLink)({
    color: 'currentColor',
    display: 'flex',
    width: '100%',
    padding: calcRem(17, 20),
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontSize: calcRem(15),
    ...commonInteractionStyles(),
    borderBottom: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    borderTop: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    textDecoration: 'none',
    svg: {
        width: '1.25rem',
        margin: 0,
        path: {
            fill: tokens.sevennews.colors.palette.charade,
            fillOpacity: 0.3,
        },
    },
    [breakpoint('md')]: {
        '&:hover': {
            svg: {
                path: {
                    fill: colors.white,
                    fillOpacity: 1,
                },
            },
        },
    },
})

export const StyledNavLink = styled(WebLink)({
    color: 'currentColor',
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    padding: calcRem(17, 20),
    fontSize: calcRem(15),
    fontWeight: 500,
    ...commonInteractionStyles(),
    borderBottom: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    borderTop: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
})

export const StyledSubNav = styled('ul')({
    ...commonListStyles,
    backgroundColor: colors.white,
    color: tokens.sevennews.colors.palette.tundora,
    padding: calcRem(
        metrics.sevennews.margins.md,
        metrics.sevennews.margins.xl,
    ),
    fontWeight: 400,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: calcRem(8),
})

export const StyledSubNavLink = styled(WebLink)({
    ...commonLinkStyles,
    padding: calcRem(metrics.sevennews.margins.xs, 0),
    transition: 'opacity 0.3s ease',
    '&:focus': {
        outline: `1px solid ${colors.black}`,
    },

    '@media (pointer: fine)': {
        '&:hover': {
            opacity: '0.7',
        },
    },
})

interface StyledArrowProps {
    subNavIsOpen: boolean
}

export const StyledIconArrow = styled(FlyoutItemIconArrow)<StyledArrowProps>(
    ({ subNavIsOpen }) => ({
        width: calcRem(9),
        height: calcRem(7),
        transform: subNavIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        ...transition({ properties: ['transform'] }),
    }),
)

export const StyledSecondaryNav = styled('div')({
    color: tokens.sevennews.colors.palette.charade,
    backgroundColor: colors.white,
})

export const StyledSecondaryNavList = styled('ul')({
    ...commonListStyles,
})

export const StyledSecondaryNavLink = styled(WebLink)({
    display: 'block',
    padding: calcRem(17, 20),
    fontSize: calcRem(15),
    fontWeight: 500,
    textDecoration: 'none',
    color: tokens.sevennews.colors.palette.charade,
    backgroundColour: colors.white,
    ...transition({
        properties: ['background-color', 'color', 'fill'],
        speed: 'fast',
    }),
    borderBottom: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    borderTop: `${calcRem(1)} solid ${colors.sevennews.nero15}`,

    '&:active': {
        color: colors.white,
        backgroundColor: tokens.sevennews.colors.palette.red,
    },

    '&:focus': {
        outline: `1px solid ${tokens.sevennews.colors.palette.red}`,
    },
    '&:hover': {
        svg: {
            path: {
                fill: colors.white,
            },
        },
    },
    '@media (pointer: fine)': {
        '&:hover': {
            cursor: 'pointer',
            color: colors.white,
            backgroundColor: tokens.sevennews.colors.palette.red,
        },
    },
})

export const StyledNavSection = styled('div')({
    color: tokens.sevennews.colors.palette.charade,
    backgroundColor: colors.white,
    padding: calcRem(
        metrics.sevennews.margins.md,
        metrics.sevennews.margins.lg,
    ),
    borderBottom: `1px solid ${tokens.sevennews.colors.palette.seashell}`,
})

export const StyledSocialLinksWrapper = styled(SocialLinks)({
    marginBottom: calcRem(34),
    gap: calcRem(8),
    a: {
        width: calcRem(21),
        height: calcRem(21),
    },
})

export const StyledButtonControlls = styled('div')({
    borderBottom: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    display: 'flex',
    justifyContent: 'space-between',
})

export const Styled7PlusButton = styled(WebLink)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    color: tokens.sevennews.colors.palette.red,
    fontSize: calcRem(15),
    fontWeight: 500,
    lineHeight: calcRem(18),
    textDecoration: 'none',
    padding: calcRem(18, 0),
    borderRight: `${calcRem(1)} solid ${colors.sevennews.nero15}`,
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: '0.7',
    },
})

export const Styled7PlusLogo = styled('img')({
    marginRight: calcRem(10),
})

export const StyledTvGuideButton = styled(WebLink)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: calcRem(18, 0),
})

export const StyledTvGuideLogo = styled('img')({})

export const StyledFooterWrapper = styled('ul')({
    listStyleType: 'none',
})

export const StyledFooterItem = styled('li')({})

export const StyledFooterItemLink = styled(WebLink)({
    textDecoration: 'none',
    color: tokens.sevennews.colors.palette.charade,
    fontSize: calcRem(12),
    fontWeight: 500,
    lineHeight: calcRem(36),
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: '0.7',
    },
})

export const StyledSeparator = styled('div')({
    height: calcRem(1),
    width: '50%',
    background: colors.black10,
    margin: calcRem(19, 0),
})
export const StyledSVG = styled(LinkOutIcon)(({}) => ({
    path: {
        fill: tokens.sevennews.colors.palette.charade,
    },
}))
