import { SevenNewsSection, LogoTypes } from '@news-mono/component-library'
import { Topic } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { stickyWithOffset } from '../../shared-components/compositions'
import {
    mobileAdOne,
    mobileBespokeNativeAd,
    mobileLeaderboardAdTwo,
} from '../../shared-components/mobile-ads'
import {
    sidebarAdBespokeNative,
    sidebarAdHalfPageMrec,
    sidebarAdMrecOne,
} from '../../shared-components/sidebar-ads'
import { topHeadlines } from 'apps/sevennews/src/app/routes/shared-components/top-headlines'
import { TopicV4DTO } from '@west-australian-newspapers/publication-types'
import { CheckedComponentInformation } from 'json-react-layouts'
import { CTAProps } from 'libs/component-library/src/SevenNewsV2/cards/ContentCard/ContentCard'

// Need the sidebar content to differ based on section used by publication pages article and video
export const sidebarContent = (section: SevenNewsSection, topic?: Topic) =>
    section === 'default'
        ? [
              sidebarAdMrecOne,
              topHeadlines(topic),
              sidebarAdBespokeNative,
              stickyWithOffset([sidebarAdHalfPageMrec], undefined, 60),
          ]
        : [
              sidebarAdMrecOne,
              mobileBespokeNativeAd,
              topHeadlines(topic),
              sidebarAdBespokeNative,
          ]

export const publicationEndContent = (section: SevenNewsSection) => {
    const publicationEndContent = [
        mobileLeaderboardAdTwo,
        layout.component({
            type: 'taboola',
            props: {
                taboolaContainerId: 'taboola-below-article-thumbnails',
                locationType: 'article',
            },
        }),
    ]

    publicationEndContent.push(
        section === 'default' ? mobileBespokeNativeAd : mobileAdOne,
    )

    return publicationEndContent
}

export const doubletLayoutRightColumn = (topic?: Topic) => [
    sidebarAdMrecOne,
    topHeadlines(topic),
    stickyWithOffset(
        [sidebarAdBespokeNative, sidebarAdHalfPageMrec],
        undefined,
        60,
    ),
]

export const exploreMoreCarousel = (
    topics: TopicV4DTO[],
    articleSource: string,
): CheckedComponentInformation[] => {
    if ((!topics || topics.length === 0) && articleSource !== 'The Nightly')
        return []

    const isBestPicks = topics.find(
        (topic) => topic.id === 'best-picks/explore-more',
    )
    if (isBestPicks) {
        return renderCarousel(
            { heading: 'Explore More', url: '/best-picks/explore-more' },
            { name: 'explore-more-carousel' },
            { title: 'Buy Now' },
        )
    }

    const isTheNightly =
        topics.find((topic) => topic.id === 'news/the-nightly') ||
        articleSource === 'The Nightly'
    if (isTheNightly) {
        return renderCarousel(
            { heading: 'More from', logo: 'theNightly' },
            { name: 'explore-more-the-nightly' },
        )
    }

    return []
}

export const renderCarousel = (
    header: { heading: string; url?: string; logo?: LogoTypes },
    content: { name: string },
    cta?: CTAProps,
): CheckedComponentInformation[] => {
    return [
        layout.component({
            type: 'header-v2',
            props: {
                borderWidth: 2,
                specificFontSize: 17,
                sectionHeader: {
                    heading: header.heading,
                    headingUrl: header.url,
                },
                headerLogo: header.logo,
            },
        }),
        layout.component({
            type: 'explore-more-carousel',
            props: {
                verticalSpacing: 'unset',

                dataDefinitionArgs: {
                    type: 'curation',
                    name: content.name,
                    offset: 0,
                    pageSize: 12,
                },
                cta,
            },
        }),
    ]
}
