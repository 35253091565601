import { ImageSourceWithCaptions } from '@news-mono/web-common'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
    StyledImageCounterModal,
    StyledModalContainer,
    StyledModalFooter,
    StyledCaptionModal,
    StyledDescriptionAuthorModal,
    StyledFullScreenIconContainer,
    StyledSwiperModalContainer,
    ResponsivePictureGallery,
    StyledScrollButtonWrapper,
} from './GalleryCarousel.styled'
import { ResponsivePictureLayout } from '../../content'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Swiper as SwiperType } from 'swiper'
import { useHistory } from 'react-router'
import { isMobileViewport } from '@news-mono/component-library'
import { ResponsivePicture } from '../../content/Picture/Picture'
type IndexSetterType = Dispatch<SetStateAction<number>>
export interface GalleryModalProps {
    onClose: (i: number) => void
    gallery: ImageSourceWithCaptions[]
    index: number
    setIndex: IndexSetterType
}

export const GalleryModal: React.FC<GalleryModalProps> = (
    props: GalleryModalProps,
) => {
    const { onClose, gallery, index, setIndex } = props

    const arrowIcon = require('./assets/arrowIconWhite.png')

    const history = useHistory()
    const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(
        null,
    )
    const closeButton = require('./assets/closeButton.png')
    useEffect(() => {
        if (index >= 0) {
            history.replace(`#${index + 1}`)
        }
    }, [index, history])

    useEffect(() => {
        if (swiperInstance) {
            const handleSlideChange = () => {
                const currentIndex = swiperInstance.realIndex

                setIndex(currentIndex)
            }
            swiperInstance.on('slideChange', handleSlideChange)

            return () => {
                swiperInstance.off('slideChange', handleSlideChange)
            }
        }
    }, [swiperInstance, history, setIndex])

    const handleNext = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        setIndex((prevIndex) => {
            if (prevIndex < gallery.length - 1) {
                const newIndex = prevIndex + 1
                swiperInstance && swiperInstance.slideNext()

                return newIndex
            }
            return prevIndex
        })
    }

    const handlePrev = (e: React.SyntheticEvent) => {
        e.stopPropagation()
        setIndex((prevIndex) => {
            if (prevIndex === 0) {
                history.replace(location.pathname)
                return prevIndex
            } else {
                const newIndex =
                    (prevIndex - 1 + gallery.length) % gallery.length
                swiperInstance && swiperInstance.slidePrev()
                history.replace(`#${newIndex + 1}`)
                return newIndex
            }
        })
    }

    return (
        <StyledModalContainer onClick={() => onClose(index)}>
            <StyledSwiperModalContainer>
                <Swiper
                    onSwiper={setSwiperInstance}
                    pagination={{ clickable: true }}
                    spaceBetween={50}
                    slidesPerView={1}
                    initialSlide={index}
                    onSlideChange={(swiper) => {
                        setIndex(swiper.realIndex)
                    }}
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {gallery.map((image, i) => (
                        <SwiperSlide
                            key={i}
                            style={{
                                alignSelf: 'center',
                            }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {isMobileViewport() ? (
                                <ResponsivePicture
                                    layout={
                                        ResponsivePictureLayout.ObjectFitContain
                                    }
                                    fixedRatio="original"
                                    image={image}
                                    imageWidths={{
                                        mobile: '100vw',
                                        tablet: '100vw',
                                        desktop: `650px`,
                                        fallbackWidth: 650,
                                    }}
                                />
                            ) : (
                                <ResponsivePictureGallery
                                    layout={ResponsivePictureLayout.None}
                                    image={image}
                                    fixedRatio="16:9"
                                    allowOriginalCrop
                                    imageWidths={{
                                        mobile: '100vw',
                                        tablet: '100vw',
                                        desktop: `650px`,
                                        fallbackWidth: 650,
                                    }}
                                />
                            )}

                            <StyledFullScreenIconContainer
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onClose(index)
                                }}
                            >
                                <img src={closeButton} alt="Close modal icon" />
                            </StyledFullScreenIconContainer>
                            <StyledModalFooter>
                                <StyledImageCounterModal>{`${index + 1} / ${
                                    gallery.length
                                }`}</StyledImageCounterModal>
                                <StyledCaptionModal>
                                    {gallery[index].captionText}
                                </StyledCaptionModal>
                                <StyledDescriptionAuthorModal>
                                    {gallery[index].byline ||
                                    gallery[index].credit
                                        ? ' Picture: '
                                        : null}
                                    {gallery[index].byline}
                                    {gallery[index].byline &&
                                        gallery[index].credit && (
                                            <span>&#47;</span>
                                        )}
                                    {gallery[index].credit}
                                </StyledDescriptionAuthorModal>
                            </StyledModalFooter>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </StyledSwiperModalContainer>
            <StyledScrollButtonWrapper
                position="left"
                onClick={(e) => handlePrev(e)}
                isActive={true}
                isModal={true}
            >
                <img src={arrowIcon} alt="Scroll" />
            </StyledScrollButtonWrapper>
            <StyledScrollButtonWrapper
                position="right"
                onClick={(e) => handleNext(e)}
                isActive={index !== gallery.length - 1}
                isModal={true}
            >
                <img src={arrowIcon} alt="Scroll" />
            </StyledScrollButtonWrapper>
        </StyledModalContainer>
    )
}
