import styled from '@emotion/styled'
import { breakpoint, calcRem, colors, easing, fonts } from '../../../__styling'

export const StyledMessage = styled('p')(({}) => ({
    width: '100%',
    fontSize: calcRem(24),
    fontWeight: 300,
    lineHeight: calcRem(27),
    marginTop: calcRem(24),
    marginBottom: 0,
    color: colors.sevennews.charade,
}))

export const StyledSearchTerm = styled('span')(({}) => ({
    fontWeight: 800,

    color: colors.sevennews.red,
}))
export const StyledSearchResultsContainer = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: calcRem(182),
    [breakpoint('sm')]: {
        minHeight: calcRem(267),
    },
}))

export const StyledPillMenuWrapper = styled('div')(({}) => ({
    display: 'flex',
    rowGap: calcRem(8),
    columnGap: calcRem(16),
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: calcRem(670),
    flexWrap: 'wrap',
    alignSelf: 'center',
    paddingTop: calcRem(24),
}))
interface TopicPillProps {
    isLeadPill?: boolean
}
export const StyledTopicPill = styled(
    'button',
    {},
)<TopicPillProps>(({ isLeadPill }) => ({
    textDecoration: 'none',
    color: isLeadPill ? colors.white : colors.sevennews.pillMenu.color,
    background: isLeadPill
        ? colors.sevennews.red
        : colors.sevennews.pillMenu.pillBackground,
    padding: calcRem(8, 22),
    border: 'none',
    borderRadius: calcRem(22),
    fontSize: calcRem(14),
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer',
        background: colors.sevennews.red,
        color: colors.white,
    },
}))
export const LoadingElipsisWrap = styled('div')(({}) => ({
    display: 'flex',
    justifyContent: 'center',
}))
