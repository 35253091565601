import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const WagonWheelWidgetContainer = styled('div')({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },

        '& .Opta_C_W_N': {
            '& .Opta-Field .Opta-Segment-Text': {
                fontSize: calcRem(18),
                [breakpoint('sm')]: {
                    fontSize: calcRem(20),
                },
            },
            '& .Opta-Keys .Opta-Keys-Container>div>span': {
                fontSize: calcRem(14),
                [breakpoint('sm')]: {
                    fontSize: calcRem(16),
                },
            },

            '& .Opta-Runs-Summary': {
                '& th span': {
                    fontSize: calcRem(14),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                },
                '& td span': {
                    fontSize: calcRem(14),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                },
            },
        },
    },

    '& .Opta_C_W_N > h2, & .Opta-MatchHeader, & .Opta-CricketStateOfPlay': {
        display: 'none',
    },

    '& .Opta .Opta_C_W_N .Opta-js-main': {
        // innings selector
        '.Opta-Innings': {
            marginBottom: calcRem(1),
            [breakpoint('sm')]: {
                height: calcRem(51),
            },
            '.Opta-Inning': {
                width: `calc(50%)`,
                margin: 0,
                backgroundColor:
                    tokens.sevennews.colors.palette.matchCentre.lightRed,
                fontWeight: 700,
                textTransform: 'uppercase',

                [breakpoint('sm')]: {
                    height: calcRem(51),
                    fontSize: calcRem(14),
                    lineHeight: calcRem(51),
                },
            },
            '.Opta-Inning:last-child': {
                borderLeft: `${calcRem(1)} solid ${
                    tokens.sevennews.colors.palette.white
                }`,
            },
            '.Opta-Inning.Opta-On': {
                backgroundColor: tokens.sevennews.colors.palette.red,
            },
        },

        '& .Opta-Teamsheet-Wrapper button, & .Opta-Genericsheet button': {
            height: calcRem(27),
            fontSize: calcRem(13),
            fontWeight: 700,
            textTransform: 'uppercase',

            paddingTop: calcRem(2),
            color: tokens.sevennews.colors.palette.white,

            [breakpoint('sm')]: {
                height: calcRem(51),
                fontSize: calcRem(14),
                paddingLeft: calcRem(20),
                paddingTop: calcRem(5),
            },
        },
        '& .Opta-Genericsheet button .Opta-Genericsheet-Text': {
            fontSize: calcRem(13),
            [breakpoint('sm')]: {
                fontSize: calcRem(14),
            },
        },
        '& button.Opta-Team-Left': {
            backgroundColor: tokens.sevennews.colors.palette.red,
        },
        '& button.Opta-Genericsheet-Button': {
            backgroundColor: '#B6001E',
            '.Opta-Genericsheet-Icon': {
                float: 'right',
                margin: calcRem(0, 10),
                position: 'relative',
                top: calcRem(6),
                backgroundPosition: '-84px -23px',
            },
            '.Opta-Genericsheet-Text': {
                fontWeight: 700,
                color: tokens.sevennews.colors.palette.white,
            },
        },
        '& button.Opta-Team-Right': {
            backgroundColor: '#A6001E',
        },
        '& .Opta-Teamsheet': {
            top: calcRem(27),
            [breakpoint('sm')]: {
                top: calcRem(52),
            },
        },

        '& .Opta-third-width:first-child': {
            paddingLeft: 0,
        },
        '& .Opta-third-width:last-child': {
            paddingRight: 0,
        },
        '& .Opta-pitch-container': {
            margin: 0,
        },
        '& .Opta-dropdown-buttons': {
            marginBottom: 0,
            [breakpoint('sm')]: {
                marginBottom: calcRem(2),
            },
        },
    },
})
