import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export interface ScoreCardWidgetContainerProps {
    isSummaryTab?: boolean
}

export const ScoreCardWidgetContainer = styled(
    'div',
)<ScoreCardWidgetContainerProps>(({ isSummaryTab }) => ({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
    },

    '& .Opta_C_SC_N > h2': {
        display: 'none',
    },

    '& .Opta.Opta-Narrow .Opta_C_SC .Opta-Ranking-Bars .Opta-Score': {
        paddingBottom: 0,
    },

    '& .Opta .Opta_C_SC .Opta-Cf': {
        '& .Opta-MatchHeader': {
            marginBottom: calcRem(12),
            display: isSummaryTab ? 'none' : '',
            [breakpoint('md')]: {
                marginBottom: calcRem(24),
            },

            '& tr:first-of-type': {
                td: {
                    minWidth: 'fit-content',
                },
            },

            '& tr:first-of-type > td.Opta-Versus': {
                width: '10%',
                [breakpoint('md')]: {
                    width: calcRem(32),
                },
            },

            '& tr:first-of-type > td, & tr:first-of-type > td > abbr': {
                fontSize: calcRem(14),
                textTransform: 'uppercase',
                fontWeight: 700,
                wordBreak: 'normal',
                textAlign: 'center',
                [breakpoint('sm')]: {
                    fontSize: calcRem(16),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(30),
                },
            },

            // match details (name, location, date etc.)
            '& tr.Opta-MatchHeader-Details div.Opta-Matchdata': {
                backgroundColor: 'unset',
                maxWidth: calcRem(290),
                marginTop: calcRem(12),

                // todo verify on desktop
                lineHeight: 'unset',

                [breakpoint('sm')]: {
                    maxWidth: calcRem(438),
                },

                [breakpoint('md')]: {
                    maxWidth: calcRem(734),
                    lineHeight: calcRem(28),
                    marginTop: calcRem(24),
                },
                '& dd, & dl::before, & dt, & dt::after': {
                    fontSize: calcRem(12),
                    fontWeight: 300,
                    color: tokens.sevennews.colors.palette.bauhausBlack,
                    [breakpoint('sm')]: {
                        fontSize: calcRem(14),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(20),
                    },
                },
            },
        },

        // score
        '& div.Opta-CricketInningsBreakdown > div.Opta-Team': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            height: calcRem(35),

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [breakpoint('md')]: {
                height: calcRem(56),
            },

            div: {
                color: tokens.sevennews.colors.palette.black,
                textTransform: 'uppercase',
                fontSize: calcRem(12),
                fontWeight: 700,
                lineHeight: calcRem(22),

                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(18),
                },
                [breakpoint('lg')]: {
                    fontSize: calcRem(22),
                },
            },

            span: {
                color: tokens.sevennews.colors.palette.black,
                textTransform: 'lowercase',
                fontSize: calcRem(12),
                fontWeight: 700,
                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(22),
                },
            },
        },
        '& div.Opta-CricketInningsBreakdown > div.Opta-Player': {
            backgroundColor: `unset`,
            height: calcRem(33),
            display: 'block',

            [breakpoint('md')]: {
                height: calcRem(56),
            },

            div: {
                fontSize: calcRem(12),
                fontWeight: 300,
                color: tokens.sevennews.colors.palette.black,
                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(20),
                },
            },
            '& .Opta-PlayerName': {
                marginRight: calcRem(12),
                width: 'auto',
            },
            '& .Opta-Score': {
                width: 'auto',
                float: 'right',
            },
            '& .Opta-Batters': {
                width: '25%',
            },
            span: {
                color: tokens.sevennews.colors.palette.black,
                fontSize: calcRem(12),
                fontWeight: 300,

                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(20),
                },
            },
        },
        '& div.Opta-CricketInningsBreakdown > div.Opta-Innings': {
            backgroundColor: `unset`,
            minHeight: calcRem(33),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flext-start',
            height: 'auto',

            [breakpoint('md')]: {
                height: calcRem(56),
            },

            div: {
                width: 'auto',
            },
            '& .Opta-Player': {
                lineHeight: calcRem(16),
            },
            '& .Opta-Player:nth-of-type(2)': {
                marginLeft: 0,
            },
            '& .Opta-Bowlers': {
                marginLeft: calcRem(12),
                [breakpoint('sm')]: {
                    marginLeft: calcRem(22),
                },
            },
            '& .Opta-Batters': {
                width: 'auto%',
                [breakpoint('md')]: {
                    width: '50%',
                },
            },
            span: {
                color: tokens.sevennews.colors.palette.black,
                fontSize: calcRem(12),
                fontWeight: 300,
                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(20),
                },
            },
        },

        // red summary line under score
        '& .Opta-CricketStateOfPlay > div': {
            minHeight: calcRem(33),
            height: 'auto',
            color: tokens.sevennews.colors.palette.white,
            fontSize: calcRem(13),
            backgroundColor: '#B6001E',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textTransform: 'uppercase',
            lineHeight: calcRem(14),
            [breakpoint('sm')]: {
                fontSize: calcRem(14),
            },
            [breakpoint('md')]: {
                height: 'auto',
                fontSize: calcRem(22),
                minHeight: calcRem(56),
                padding: calcRem(12),
                lineHeight: 'unset',
            },
        },

        '& .Opta-scorecard': {
            '& > p': {
                fontSize: calcRem(16),
                [breakpoint('sm')]: {
                    fontSize: calcRem(20),
                },
            },
            // red navigation buttons for switching teams
            '& .Opta-Nav': {
                backgroundColor: `unset`,
                marginTop: 0,

                [breakpoint('md')]: {
                    marginTop: calcRem(12),
                },
                ul: {
                    backgroundColor: `unset`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                },
                'li a': {
                    height: calcRem(35),
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    fontSize: calcRem(11),
                    lineHeight: calcRem(14),
                    color: tokens.sevennews.colors.palette.white,
                    backgroundColor: '#E6001E33',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: calcRem(0, 16),
                    textAlign: 'center',

                    [breakpoint('sm')]: {
                        lineHeight: calcRem(16),
                    },
                    [breakpoint('md')]: {
                        height: calcRem(56),
                        padding: calcRem(0, 40),
                        fontSize: calcRem(18),
                        lineHeight: calcRem(20),
                    },
                },
                'li.Opta-On a': {
                    backgroundColor: tokens.sevennews.colors.palette.red,
                },
            },

            // tabbed content section with tables
            table: {
                width: '100%',
            },
            '& .Opta-Striped tbody tr:nth-child(2n+1) th, & .Opta-Striped tbody tr:nth-child(2n+1) td':
                {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                },
            '& .Opta-Striped tbody tr:nth-child(2n) th, & .Opta-Striped tbody tr:nth-child(2n) td':
                {
                    backgroundColor: 'unset',
                },
            '& tbody td, & tbody th': {
                height: calcRem(33),
                fontSize: calcRem(13),
                fontWeight: 300,

                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    height: calcRem(56),
                    fontSize: calcRem(20),
                },
                abbr: {
                    fontSize: calcRem(13),
                    fontWeight: 300,

                    [breakpoint('sm')]: {
                        fontSize: calcRem(14),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(20),
                    },
                },
            },
            '& tfoot th': {
                backgroundColor: `unset`,
                borderTop: '1px solid #00000066',
                fontWeight: 500,
                fontSize: calcRem(13),
                height: calcRem(33),

                [breakpoint('sm')]: {
                    fontSize: calcRem(14),
                },
                [breakpoint('md')]: {
                    fontSize: calcRem(20),
                    height: calcRem(56),
                },
            },
            '& thead th': {
                height: calcRem(33),
                backgroundColor: `unset`,
                fontSize: calcRem(13),
                fontWeight: 500,

                [breakpoint('sm')]: {
                    fontSize: calcRem(16),
                },
                [breakpoint('md')]: {
                    height: calcRem(56),
                    fontSize: calcRem(22),
                },
                abbr: {
                    fontSize: calcRem(13),
                    fontWeight: 500,

                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(22),
                    },
                },
            },
            '& thead th:nth-of-type(1)': {
                textTransform: 'uppercase',
            },

            '& .Opta-batting': {
                marginTop: calcRem(12),
                [breakpoint('md')]: {
                    marginTop: calcRem(24),
                },
                'tbody th:first-of-type, td:nth-of-type(2) ~ td, tfoot th:nth-of-type(1) ~ th':
                    {
                        fontSize: calcRem(13),
                        [breakpoint('sm')]: {
                            fontSize: calcRem(16),
                        },
                        [breakpoint('md')]: {
                            fontSize: calcRem(22),
                        },
                    },
                'tbody tr:last-of-type td:nth-of-type(1) ~ td, tbody tr:last-of-type td:nth-of-type(1) ~ td abbr, tbody tr:last-of-type th':
                    {
                        fontSize: calcRem(13),
                        fontWeight: 500,
                        [breakpoint('sm')]: {
                            fontSize: calcRem(16),
                        },
                        [breakpoint('md')]: {
                            fontSize: calcRem(22),
                        },
                    },
            },

            '& .Opta-bowling': {
                marginTop: calcRem(12),
                [breakpoint('md')]: {
                    marginTop: calcRem(24),
                },
                'tbody th, td:nth-of-type(1) ~ td': {
                    fontSize: calcRem(13),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(22),
                    },
                },
            },

            // table with horizontal bars
            '& .Opta-Ranking-Bars': {
                marginTop: calcRem(12),
                [breakpoint('md')]: {
                    marginTop: calcRem(24),
                },
                'tr td': {
                    fontSize: calcRem(13),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(22),
                    },
                },
                'tr td:nth-of-type(1)': {
                    fontSize: calcRem(13),
                    [breakpoint('sm')]: {
                        fontSize: calcRem(12),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(20),
                    },
                },
                'tbody th': {
                    fontSize: calcRem(13),
                    fontWeight: 500,
                    [breakpoint('sm')]: {
                        fontSize: calcRem(16),
                    },
                    [breakpoint('md')]: {
                        fontSize: calcRem(22),
                    },
                },
                '& .Opta-Score > div': {
                    backgroundColor: tokens.sevennews.colors.palette.red,
                    border: `1px solid ${tokens.sevennews.colors.palette.red}`,
                    height: calcRem(14),
                    [breakpoint('md')]: {
                        height: calcRem(24),
                    },
                },
                '& .Opta-Score.Opta-unbroken > div': {
                    backgroundColor: tokens.sevennews.colors.palette.white,
                },
            },
        },
    },
}))
