import { FeatureState, toFeatureState } from '@etrigan/feature-toggles-client'
import {
    DataLayerEventName,
    NavEvent,
    NavItem,
    isComponentEnabled,
    usePageEvents,
    useToggleState,
} from '@news-mono/web-common'
import H from 'history'
import React, { useEffect, useState } from 'react'
import { SevenNewDesktopNavItemV2 } from './SevenNewsDesktopNavigationItemV2'
import { StyledDesktopNavList } from './SevenNewsDesktopNavigationV2.styled'

export interface Props {
    navItems: NavItem[]
    location: H.Location
    onEvent: (event: NavEvent) => void
}

export const SevenNewsDesktopNavigationV2: React.FC<Props> = ({
    location,
    navItems,
    onEvent,
}) => {
    const featureState = toFeatureState(useToggleState())

    const [impression, setImpression] = useState(false)

    const mainNavItems = filterItems(navItems, featureState)

    usePageEvents(() => {
        setImpression(true)
    }, ['page-load-complete'])

    useEffect(() => {
        if (impression) {
            onEvent({
                type: DataLayerEventName.navAvailable,
                originator: 'SevenNewsDesktopNavigation',
                payload: {
                    navName: 'MainNavigation.SevenNews',
                },
            })

            setImpression(false)
        }
    }, [impression, onEvent])

    return (
        <StyledDesktopNavList>
            {mainNavItems &&
                mainNavItems.map((navItem, index) => {
                    return (
                        <li key={navItem.name}>
                            <SevenNewDesktopNavItemV2
                                location={location}
                                navItem={navItem}
                                onEvent={onEvent}
                            />
                        </li>
                    )
                })}
        </StyledDesktopNavList>
    )
}

function filterItems(navItems: NavItem[], featureState: FeatureState) {
    return navItems.filter(
        ({ feature, invertFeatureToggle, hideFromMainNav }) => {
            if (feature) {
                return isComponentEnabled(featureState, {
                    feature,
                    invertFeatureToggle,
                })
            }
            if (hideFromMainNav) {
                return false
            }
            return true
        },
    )
}
