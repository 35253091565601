import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { StaticPageTitle } from './StaticPageTitle'

export interface Props {
    title: string
}

export const StaticPageTitleRegistration = createRegisterableComponent(
    'static-page-title',
    ({ title }: Props) => <StaticPageTitle title={title} />,
)
