import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { SevenNewsPageNotFound } from './SevenNewsPageNotFound'

export const SevenNewsPageNotFoundRegistration = createRegisterableComponent(
    'sevennews-404-page',
    () => (
        <RouteStatus statusCode={404}>
            <SevenNewsPageNotFound />
        </RouteStatus>
    ),
)
