import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ContainerWidth } from '../../__styling'
import { HeaderProps, TabV2, TabPillSpacingProps } from './TabV2'

export interface TabPillRouteProps extends TabPillSpacingProps {
    containerWidth?: ContainerWidth
    headerData: Array<HeaderProps>
    one?: React.ReactElement<any>
    two?: React.ReactElement<any>
    three?: React.ReactElement<any>
    fourt?: React.ReactElement<any>
    five?: React.ReactElement<any>
    six?: React.ReactElement<any>
    seven?: React.ReactElement<any>
    eight?: React.ReactElement<any>
    nine?: React.ReactElement<any>
}

export const TabV2Registration = createRegisterableComposition<
    | 'one'
    | 'two'
    | 'three'
    | 'four'
    | 'five'
    | 'six'
    | 'seven'
    | 'eight'
    | 'nine'
>()(
    'tab-v2',
    (contentAreas, props: TabPillRouteProps) => (
        <TabV2
            {...props}
            one={contentAreas.one}
            two={contentAreas.two}
            three={contentAreas.three}
            four={contentAreas.four}
            five={contentAreas.five}
            six={contentAreas.six}
            seven={contentAreas.seven}
            eight={contentAreas.eight}
            nine={contentAreas.nine}
        />
    ),
    (
        contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: TabPillRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        return {
            containerWidthRatios: {
                desktop: 0.5 * containerWidthRatios.desktop,
                tablet: 0.5 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
