export type PillTopics =
    | 'news/local'
    | 'video'
    | 'lifestyle'
    | 'sport'
    | 'entertainment'
    | 'politics'
    | 'business'
    | 'best-picks'
    | 'technology'
    | 'world'
    | 'weather'
    | 'motoring'
    | 'sunrise'
    | 'the-morning-show'
    | 'spotlight'
    | 'trending'

type PillNavigation = {
    [K in PillTopics]: PillItem[]
}

export interface PillItem {
    name: string
    link?: string
    isMainTopic?: boolean
}

export interface PillTabItem {
    name: string
    onClick: () => void
}

export const showPills = [
    { link: '/entertainment', name: 'Entertainment', isMainTopic: true },
    { link: '/entertainment/tv', name: 'TV' },
    { link: '/entertainment/celebrity', name: 'Celebrity' },
    { link: '/entertainment/movies', name: 'Movies' },
    { link: '/entertainment/music', name: 'Music' },
    { link: '/entertainment/sunrise', name: 'Sunrise' },
    { link: '/entertainment/the-morning-show', name: 'The Morning Show' },
    { link: '/entertainment/spotlight', name: 'Spotlight' },
    { link: '/entertainment/home-and-away', name: 'Home & Away' },
    { link: '/entertainment/dream-home', name: 'Dream Home' },
    { link: '/entertainment/the-voice-australia', name: 'The Voice' },
    { link: '/entertainment/sas-australia', name: 'SAS Australia' },
    { link: '/entertainment/my-kitchen-rules', name: 'MKR' },
    {
        link: '/entertainment/farmer-wants-a-wife',
        name: 'Farmer Wants a Wife',
    },
    { link: '/entertainment/big-brother-australia', name: 'Big Brother' },
    { link: '/entertainment/australian-idol', name: 'Australian Idol' },
    {
        link: '/entertainment/dancing-with-the-stars ',
        name: 'Dancing with the Stars',
    },
]
export const pillMenuNavigation: PillNavigation = {
    'news/local': [
        {
            link: '/news/local',
            name: 'News',
            isMainTopic: true,
        },
        {
            link: '/news/nsw',
            name: 'NSW',
        },
        {
            link: '/news/vic',
            name: 'VIC',
        },
        {
            link: '/news/qld',
            name: 'QLD',
        },
        {
            link: '/news/wa',
            name: 'WA',
        },
        {
            link: '/news/sa',
            name: 'SA',
        },
        {
            link: '/news/nt',
            name: 'NT',
        },
        {
            link: '/news/act',
            name: 'ACT',
        },
        {
            link: '/news/tas',
            name: 'TAS',
        },
        {
            link: '/news/world',
            name: 'World',
        },
    ],
    video: [
        { link: '/video', name: 'Top Videos' },
        { link: '/video/sport', name: 'Sport' },
        { link: '/video/entertainment', name: 'Entertainment' },
        { link: '/video/lifestyle', name: 'Lifestyle' },
        { link: '/video/news', name: 'News' },
        { link: '/video/politics', name: 'Politics' },
        { link: '/video/sunrise', name: 'Sunrise' },
        { link: '/video/the-morning-show', name: 'The Morning Show' },
        { link: '/video/business', name: 'Business' },
        { link: '/video/technology', name: 'Technology' },
        { link: '/video/travel', name: 'Travel' },
        { link: '/video/world', name: 'World' },
    ],
    lifestyle: [
        { link: '/lifestyle', name: 'Lifestyle', isMainTopic: true },
        { link: '/lifestyle/health-wellbeing', name: 'Health & Wellbeing' },
        { link: '/lifestyle/shopping', name: 'Shopping' },
        { link: '/lifestyle/parenting', name: 'Parenting' },
        { link: '/best-picks', name: 'Best Picks' },
        { link: '/lifestyle/food', name: 'Food' },
        { link: '/lifestyle/personal-finance', name: 'Personal Finance' },
        { link: '/lifestyle/real-estate', name: 'Real Estate' },
        { link: '/lifestyle/pets', name: 'Pets' },
        { link: '/lifestyle/sponsored', name: 'Sponsored' },
    ],
    sport: [
        { link: '/sport', name: 'Sport', isMainTopic: true },
        { link: '/sport/afl', name: 'AFL' },
        { link: '/sport/aflw', name: 'AFLW' },
        { link: '/sport/cricket', name: 'Cricket' },
        { link: '/sport/rugby-league', name: 'NRL' },
        { link: '/sport/soccer', name: 'Football' },
        { link: '/sport/motorsport', name: 'Motorsport' },
        { link: '/sport/horse-racing', name: 'Horse Racing' },
        { link: '/sport/tennis', name: 'Tennis' },
        { link: '/sport/ufc', name: 'UFC' },
        { link: '/sport/rugby-union', name: 'Rugby Union' },
        { link: '/sport/basketball', name: 'Basketball' },
        { link: '/sport/golf', name: 'Golf' },
        { link: '/sport/mixed-martial-arts', name: 'Mixed Martial Arts' },
        { link: '/sport/boxing', name: 'Boxing' },
    ],
    entertainment: [
        { link: '/entertainment', name: 'Entertainment', isMainTopic: true },
        { link: '/entertainment/tv', name: 'TV' },
        { link: '/entertainment/celebrity', name: 'Celebrity' },
        { link: '/entertainment/movies', name: 'Movies' },
        { link: '/entertainment/music', name: 'Music' },
        { link: '/entertainment/sunrise', name: 'Sunrise' },
        { link: '/entertainment/the-morning-show', name: 'The Morning Show' },
        { link: '/entertainment/spotlight', name: 'Spotlight' },
        { link: '/entertainment/home-and-away', name: 'Home & Away' },
        { link: '/entertainment/dream-home', name: 'Dream Home' },
        { link: '/entertainment/the-voice-australia', name: 'The Voice' },
        { link: '/entertainment/sas-australia', name: 'SAS Australia' },
        { link: '/entertainment/my-kitchen-rules', name: 'MKR' },
        {
            link: '/entertainment/farmer-wants-a-wife',
            name: 'Farmer Wants a Wife',
        },
        { link: '/entertainment/big-brother-australia', name: 'Big Brother' },
        { link: '/entertainment/australian-idol', name: 'Australian Idol' },
        {
            link: '/entertainment/dancing-with-the-stars ',
            name: 'Dancing with the Stars',
        },
    ],
    politics: [
        { link: '/politics', name: 'Politics', isMainTopic: true },
        { link: '/politics/federal-politics', name: 'Federal Politics' },
        { link: '/politics/state-politics', name: 'State Politics' },
        { link: '/politics/world-politics', name: 'World Politics' },
    ],
    business: [
        { link: '/business', name: 'Business', isMainTopic: true },
        { link: '/business/finance', name: 'Finance' },
        { link: '/business/banking', name: 'Banking' },
        { link: '/business/retail', name: 'Retail' },
        { link: '/business/economy', name: 'Economy' },
        { link: '/business/markets', name: 'Markets' },
    ],
    'best-picks': [
        { link: '/best-picks', name: 'Best Picks', isMainTopic: true },
        { link: '/best-picks/fashion', name: 'Fashion' },
        { link: '/best-picks/beauty', name: 'Beauty' },
        { link: '/best-picks/appliances', name: 'Electronics' },
        { link: '/best-picks/health', name: 'Health' },
        { link: '/best-picks/fitness', name: 'Fitness' },
        { link: '/best-picks/baby-kids', name: 'Kids' },
        { link: '/best-picks/travel', name: 'Travel' },
        { link: '/best-picks/home-garden', name: 'Home & Garden' },
        { link: '/best-picks/tech', name: 'Tech' },
    ],
    technology: [
        { link: '/technology', name: 'Technology', isMainTopic: true },
        { link: '/technology/reviews', name: 'Reviews' },
        { link: '/technology/space', name: 'Space' },
        { link: '/technology/gaming', name: 'Gaming' },
        { link: '/technology/science', name: 'Science' },
        { link: '/technology/smartphones', name: 'Smartphones' },
        { link: '/technology/gadgets', name: 'Gadgets' },
    ],
    world: [
        { link: '/news/world', name: 'World', isMainTopic: true },
        { link: '/news/asia', name: 'Asia' },
        { link: '/news/north-america', name: 'North America' },
        { link: '/news/middle-east', name: 'Middle East' },
        { link: '/news/europe', name: 'Europe' },
        { link: '/news/africa', name: 'Africa' },
        { link: '/news/latin-america', name: 'Latin America' },
    ],
    weather: [
        { link: '/weather', name: 'Weather', isMainTopic: true },
        { link: '/weather/bom', name: 'BOM' },
        { link: '/weather/natural-disasters', name: 'Natural Disasters' },
    ],
    motoring: [
        { link: '/motoring', name: 'Motoring', isMainTopic: true },
        { link: '/video/motoring', name: 'Videos' },
    ],
    sunrise: [
        { link: '/sunrise', name: 'Sunrise', isMainTopic: true },
        {
            link: 'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=nav-link&utm_campaign=sunrise',
            name: 'Watch on 7plus',
        },
        { link: '/sunrise/win', name: 'Win' },
        { link: '/sunrise/on-the-show', name: 'On the Show' },
        { link: '/sunrise/deals', name: 'Deals' },
        { link: '/sunrise/cooking-with-coles', name: 'Cooking with Coles' },
    ],
    'the-morning-show': [
        {
            link: '/the-morning-show',
            name: 'The Morning Show',
            isMainTopic: true,
        },
        {
            link: 'https://7plus.com.au/the-morning-show?utm_source=7NEWS&utm_medium=nav-link&utm_campaign=the-morning-show',
            name: 'Watch on 7plus',
        },
        { link: '/the-morning-show/story-info', name: 'On The Show' },
        { link: '/the-morning-show/recipes', name: 'Recipes' },
        { link: '/the-morning-show/performances', name: 'Performances' },
        { link: '/the-morning-show/astrology', name: 'Astrology' },
    ],
    spotlight: [
        { link: '/spotlight', name: 'Spotlight', isMainTopic: true },
        {
            link: 'https://7plus.com.au/the-morning-show?utm_source=7NEWS&utm_medium=nav-link&utm_campaign=the-morning-show',
            name: 'Watch on 7plus',
        },
        { link: '/entertainment/tv', name: 'TV' },
        { link: '/entertainment/celebrity', name: 'Celebrity' },
        { link: '/entertainment/movies', name: 'Movies' },
        { link: '/entertainment/music', name: 'Music' },
        { link: '/sunrise', name: 'Sunrise' },
        { link: '/the-morning-show', name: 'The Morning Show' },
        { link: '/spotlight', name: 'Spotlight' },
        { link: '/entertainment/home-and-away', name: 'Home & Away' },
        { link: '/entertainment/the-voice-australia', name: 'The Voice' },
        { link: '/entertainment/sas-australia', name: 'SAS Australia' },
        { link: '/entertainment/my-kitchen-rules ', name: 'MKR' },
        {
            link: '/entertainment/farmer-wants-a-wife',
            name: 'Farmer Wants a Wife',
        },
        { link: '/entertainment/big-brother-australia', name: 'Big Brother' },
        { link: '/entertainment/australian-idol', name: 'Australian Idol' },
    ],
    trending: [
        {
            link: '/news/local',
            name: 'News',
            isMainTopic: true,
        },
        { link: '/sport/afl', name: 'AFL' },
        { link: '/lifestyle', name: 'Lifestyle' },
        { link: '/lifestyle/shopping', name: 'Shopping' },
        { link: '/entertainment', name: 'Entertainment' },
        { link: '/lifestyle/recalls', name: 'Recalls' },
        { link: '/sport/horse-racing', name: 'Racing' },
        { link: '/lifestyle/health-wellbeing', name: 'Health & Wellbeing' },
        { link: '/entertainment/royal-family', name: 'Royal Family' },
        { link: '/technology', name: 'Tech' },
        { link: '/politics', name: 'Politics' },
    ],
}
