import { cx } from '@emotion/css'
import {
    AustralianMarketCodes,
    loadUserLocation,
    marketCodeLabels,
    RenderTargetContext,
    useLocationState,
} from '@news-mono/web-common'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { KeyCode } from '../../__helpers/key-code'
import { useOnClickOutside } from '../../__helpers/on-click-outside'
import {
    LocationDropDownCaret,
    StyledDropdownTrigger,
    StyledLocationDropdown,
    StyledLocationDropdownItem,
    StyledLocationDropdownLink,
    StyledLocationSelectorRefContainer,
    StyledLocationSelectorWrapper,
} from './LocationSelectorV2.styled'

interface LocationSelectorProps {
    isOpen?: boolean /** Used for tests */
    uiLocation?: string
    toggleFlyoutNav?: () => void
}

export const LocationSelectorV2: React.FC<LocationSelectorProps> = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { userSelectedRegion = 'NSW' } = useLocationState()
    const renderTarget = React.useContext(RenderTargetContext)
    const dropdownContainer = React.useRef<HTMLElement | null>(null)
    const locationKeys = Object.keys(marketCodeLabels)
    const [isDropdownOpen, setDropdownOpen] = React.useState(
        props.isOpen || false,
    )
    const [currentFocus, setFocus] = React.useState(
        locationKeys.indexOf(userSelectedRegion),
    )

    const handleClickOutside = () => {
        if (isDropdownOpen) {
            setDropdownOpen(false)
        }
    }

    useOnClickOutside(dropdownContainer, handleClickOutside, {
        enabled: isDropdownOpen,
    })

    const checkFocus = () => {
        if (
            document.activeElement &&
            dropdownContainer.current &&
            !dropdownContainer.current.contains(document.activeElement)
        ) {
            setDropdownOpen(false)
        }
    }

    React.useEffect(() => {
        setFocus(locationKeys.indexOf(userSelectedRegion))

        if (!isDropdownOpen) return
        window.addEventListener('focus', checkFocus, true)
        return function cleanup() {
            window.removeEventListener('focus', checkFocus, true)
        }
    }, [isDropdownOpen, locationKeys, userSelectedRegion])

    const changeFocus = (evt: React.KeyboardEvent, nextFocus: number) => {
        evt.preventDefault()
        setDropdownOpen(true)
        setFocus(nextFocus)

        const itemToFocus = document.querySelector(
            `#location-item-${locationKeys[nextFocus]} a`,
        ) as HTMLElement
        if (itemToFocus) {
            itemToFocus.focus()
        }
    }

    const handleKeyPress = (evt: React.KeyboardEvent) => {
        if (evt.keyCode === KeyCode.DOWN_ARROW) {
            const nextFocus =
                currentFocus + 1 >= locationKeys.length ? 0 : currentFocus + 1
            changeFocus(evt, nextFocus)
        } else if (evt.keyCode === KeyCode.UP_ARROW) {
            const nextFocus =
                currentFocus - 1 < 0
                    ? locationKeys.length - 1
                    : currentFocus - 1
            changeFocus(evt, nextFocus)
        } else if (evt.keyCode === KeyCode.ENTER) {
            selectNewLocation(
                locationKeys[currentFocus] as AustralianMarketCodes,
            )
        }
    }

    const selectNewLocation = (region: AustralianMarketCodes) => {
        const regionName = region === 'QLDREG' ? 'QLD' : region
        dispatch(
            loadUserLocation({
                userSelectedRegion: regionName,
            }),
        )
        // prevent redirecting for home page, instead of that just chagne cards content
        if (history.location.pathname !== '/') {
            history.push(`/news/${regionName.toLowerCase()}`)
        }
        props.toggleFlyoutNav && props.toggleFlyoutNav()
        setDropdownOpen(false)
    }

    if (renderTarget.renderTarget === 'static-error-page') {
        return null
    }

    return (
        <StyledLocationSelectorRefContainer
            role="listbox"
            aria-activedescendant={`${props.uiLocation}-location-item-${userSelectedRegion}`}
            ref={(el) => (dropdownContainer.current = el)}
            aria-labelledby="location-selector-label"
            onKeyDown={handleKeyPress}
        >
            <StyledDropdownTrigger
                onClick={() => setDropdownOpen((state) => !state)}
                isOpen={isDropdownOpen}
            >
                {marketCodeLabels[userSelectedRegion]}
                <LocationDropDownCaret isOpen={isDropdownOpen} />
            </StyledDropdownTrigger>
            <StyledLocationDropdown isOpen={isDropdownOpen}>
                {locationKeys.map((key) => {
                    const label = (marketCodeLabels as any)[key]
                    return (
                        <StyledLocationDropdownItem
                            key={key}
                            aria-selected={key === userSelectedRegion}
                            id={`${props.uiLocation}-location-item-${key}`}
                        >
                            <StyledLocationDropdownLink
                                selected={key === userSelectedRegion}
                                onClick={() =>
                                    selectNewLocation(
                                        key as AustralianMarketCodes,
                                    )
                                }
                                tabIndex={-1}
                            >
                                {String(label).split(',')[0]}
                            </StyledLocationDropdownLink>
                        </StyledLocationDropdownItem>
                    )
                })}
            </StyledLocationDropdown>
        </StyledLocationSelectorRefContainer>
    )
}

LocationSelectorV2.displayName = 'LocationSelectorV2'

export const DesktopLocationSelectorV2 = (props: LocationSelectorProps) => (
    <StyledLocationSelectorWrapper className={cx('hide-print')}>
        <LocationSelectorV2 uiLocation="desktop" {...props} />
    </StyledLocationSelectorWrapper>
)
