import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getWeatherPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Weather',
        id: 'weather',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Weather',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                page: 0,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                pageOffset: 5,
                page: 0,
                pageSize: 6,
            },
        },
        contentToFormat: [],
        promoCardName: '7news-weather-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
