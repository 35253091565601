import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Pubfeed } from './Pubfeed'

export interface PubfeedProps {}

export const PubfeedRegistration = createRegisterableComponent(
    'pubfeed',
    (props: PubfeedProps) => <Pubfeed />,
)
