import { NovemberRouteProps } from '@news-mono/component-library'
import { CurationData, ListingData, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'

interface NewsJustInProps {
    topicId: string
    heading: string
    contentType: 'listing' | 'curation'
    curationId?: string
}

const novemberCollectionProps = (
    options: NewsJustInProps,
): NovemberRouteProps & { dataDefinitionArgs: ListingData | CurationData } => {
    const { topicId, heading } = options
    return {
        sectionHeader: {
            heading,
            headingUrl: `/${topicId}`,
        },
        contentIsTangential: true,
        verticalSpacing: 'md',
        timestamp: 'list-style',
        dataDefinitionArgs: {
            ...(options.contentType === 'listing'
                ? {
                      type: 'listing',
                      /*
                       * Currently 7NEWS does not support video publications so dont return a combined set,
                       * return articles explicitly - thats what listing default is
                       */
                      publicationKind: 'article',
                      topics: [topicId],
                      includeSubTopics: true,
                      paging: {
                          page: 0,
                          pageSize: 8,
                      },
                  }
                : {
                      type: 'curation',
                      name: options.curationId as string,
                      offset: 0,
                      pageSize: 8,
                  }),
        },
    }
}

const getNewsJustInOptions = (
    topic?: Topic,
    currentPath?: string,
): NewsJustInProps => {
    const defaultOptions: NewsJustInProps = {
        topicId: 'news',
        heading: 'Trending',
        contentType: 'listing',
    }
    if (currentPath?.includes('spotlight/contact-us')) {
        return {
            topicId: 'spotlight',
            curationId: 'spotlight-featured',
            heading: 'Trending',
            contentType: 'curation',
        }
    }

    if (currentPath?.includes('sunrise')) {
        return {
            topicId: 'sunrise-news-homepage',
            curationId: 'sunrise-news-homepage',
            heading: 'Trending',
            contentType: 'curation',
        }
    }
    if (currentPath?.includes('the-morning-show')) {
        return {
            topicId: 'the-morning-show-featured',
            curationId: 'the-morning-show-featured',
            heading: 'Trending',
            contentType: 'curation',
        }
    }

    if (currentPath?.includes('contact-us')) {
        return {
            topicId: 'nsw',
            curationId: 'nsw',
            heading: 'Trending',
            contentType: 'curation',
        }
    }

    // if no topic or running in node_env test lets return default
    // returning default in test means only one endpoint to mock
    if (!topic || (process.env && process.env.NODE_ENV === 'test')) {
        return defaultOptions
    }

    // this is an edge case due to the static route /world but it needing /news/world
    if (topic.id === 'news/world') {
        return {
            topicId: 'news/world',
            heading: 'Latest in World News',
            contentType: 'listing',
        }
    }

    if (topic.id === 'entertainment/my-kitchen-rules') {
        return {
            topicId: 'entertainment/my-kitchen-rules',
            heading: 'Trending',
            contentType: 'listing',
        }
    }

    // Get the top most topic id
    const topicId = topic.parent ? topic.parent.id : topic.id

    switch (topicId) {
        case 'politics':
            return {
                topicId: 'politics',
                heading: 'Latest in Politics',
                contentType: 'listing',
            }
        case 'business':
            return {
                topicId: 'business',
                heading: 'Latest in Business',
                contentType: 'listing',
            }
        case 'entertainment':
            return {
                topicId: 'entertainment',
                heading: 'The Latest',
                contentType: 'listing',
            }
        case 'lifestyle':
            return {
                topicId: 'lifestyle',
                heading: 'The Latest',
                contentType: 'listing',
            }
        case 'travel':
            return {
                topicId: 'lifestyle',
                heading: 'The Latest',
                contentType: 'listing',
            }

        case 'sport':
            return {
                topicId: 'sport',
                heading: 'Latest in Sport',
                contentType: 'listing',
            }
        case 'sunrise':
            return {
                topicId: 'entertainment',
                curationId: 'entertainment',
                heading: 'Trending',
                contentType: 'curation',
            }
        case 'spotlight':
            return {
                topicId: 'spotlight',
                curationId: 'spotlight-featured',
                heading: 'Trending',
                contentType: 'curation',
            }
        case 'the-morning-show':
            return {
                topicId: 'entertainment',
                curationId: 'entertainment',
                heading: 'Trending',
                contentType: 'curation',
            }
        case 'technology':
            return {
                topicId: 'technology',
                heading: 'Trending',
                contentType: 'listing',
            }
        case 'news':
            return {
                topicId: 'news',
                heading: 'Trending',
                contentType: 'listing',
            }
        case 'weather':
            return {
                topicId: 'weather',
                heading: 'Trending',
                contentType: 'listing',
            }
        case 'spotthebucket':
            return {
                topicId: 'spotthebucket',
                heading: 'Trending',
                contentType: 'listing',
            }
        case 'best-picks':
            return {
                topicId: 'best-picks',
                curationId: 'best-picks',
                heading: 'Trending',
                contentType: 'curation',
            }

        default:
            return defaultOptions
    }
}

export const topHeadlines = (topic?: Topic, currentPath?: string) =>
    layout.component({
        type: 'november',
        props: novemberCollectionProps(
            getNewsJustInOptions(topic, currentPath),
        ),
    })
