import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { DisclaimerV2 } from './DisclaimerV2'
export interface DisclaimerV2Props extends ResponsiveContainer {
    disclaimerText: string
}

export const DisclaimerV2Registration = createRegisterableComponent(
    'disclaimer-v2',
    (props: DisclaimerV2Props, services) => {
        return <DisclaimerV2 disclaimerText={props.disclaimerText} />
    },
)
