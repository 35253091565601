import {
    BoxRouteProps,
    JanusRouteProps,
    metrics,
    ZeusRouteProps,
} from '@news-mono/component-library'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'

export const zeus = (options: {
    propOverrides?: Partial<ZeusRouteProps>
    main: Array<CheckedComponentInformation>
    sidebar: Array<CheckedComponentInformation>
}) => {
    const { main, sidebar, propOverrides } = options

    return layout.composition({
        type: 'zeus',
        props: {
            stretchSelf: true,
            containerWidth: metrics.sevennews.siteMetrics.mainContentWidth,
            horizontalGutters: 'md',
            verticalGutters: 'md',
            hasBackgroundFill: true,
            stackSidebarContent: true,
            ...propOverrides,
        },
        // Render sidebar first so sidebar ads are higher pos numbers
        contentAreas: {
            sidebar,
            main,
        },
    })
}

export const stickyWithOffset = (
    content: Array<CheckedComponentInformation>,
    offsets?: string[],
    verticalOffset?: number,
) =>
    layout.nestedComposition({
        type: 'sticky',
        props: {
            offsetBy: offsets,
            verticalOffset: verticalOffset || metrics.sevennews.margins.md,
        },
        contentAreas: {
            main: content,
        },
    })

export const box = (options: {
    propOverrides?: BoxRouteProps
    main: Array<CheckedComponentInformation>
}) => {
    const { main, propOverrides } = options

    return layout.composition({
        type: 'box',
        props: {
            horizontalGutters: 'unset',
            verticalGutters: ['unset', 'unset'],
            verticalSpacing: undefined,
            hasTopSpacing: false,
            hasBackgroundFill: true,
            containerWidth: propOverrides?.containerWidth
                ? propOverrides.containerWidth
                : metrics.sevennews.siteMetrics.mainContentWidth,
            ...propOverrides,
        },
        contentAreas: {
            main,
        },
    })
}
