import styled from '@emotion/styled'
import { RenderTarget, WebLink } from '@news-mono/web-common'
import { colors, fonts, metrics } from '../../../__styling'
import { breakpoint } from '../../../__styling/style-functions/breakpoint'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { StyledArticleVideoContainer } from '../../../publication/ArticleVideo/ArticleVideo.styled'
import { StyledArticleByline } from '../ByLine/ByLine.styled'
import { tokens } from '@news-mono/design-tokens'

interface StyledHeroContainerProps {
    renderTarget?: RenderTarget
}

export const StyledHeroContainer = styled('div')<StyledHeroContainerProps>(
    ({ renderTarget }) => ({
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridRowGap: calcRem(24),
        gridTemplateColumns: '1fr',
        alignItems: 'self-start',
        marginBottom: calcRem(metrics.sevennews.margins.lg),
        textDecoration: 'none',
        color: 'inherit',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: '0.7',
        },
        [breakpoint('sm')]: {
            gridRowGap: calcRem(16),
        },

        [breakpoint('md')]: {
            gridTemplateColumns: '1fr 2fr',
            gridTemplateRows: 'auto',
            columnGap: calcRem(16),
        },
        '& [class*="StyledFigcaption"]': {
            paddingBottom: 0,
            marginTop: 0,
        },

        paddingBottom: renderTarget === 'amp' ? calcRem(20) : 'inherit',
        [`${StyledArticleVideoContainer}`]: {
            marginBottom: 0,
        },
    }),
)

export const StyledTextWrap = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    gridRow: 2,
    [breakpoint('md')]: {
        marginRight: calcRem(30),
        gridRow: 1,
        height: '100%',
        paddingTop: '10%',
    },
}))

export const StyledEstimatedTime = styled('span')({
    color: colors.sevennews.nero30,
    fontSize: calcRem(12),
    fontWeight: 600,
})

export const StyledBylineWrap = styled('div')(({}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: calcRem(16),
    position: 'relative',
    [breakpoint('sm')]: {
        marginBottom: 0,
    },
    [breakpoint('md')]: {
        marginBottom: calcRem(8),
    },
    [`${StyledArticleByline}`]: {
        marginRight: 0,
    },
}))
export const StyledDivider = styled('div')({
    width: calcRem(18),
    height: calcRem(3),
    background: colors.sevennews.red,
    margin: calcRem(16, 0),
    [breakpoint('sm')]: {
        margin: calcRem(14, 0),
    },
    [breakpoint('md')]: {
        margin: calcRem(13, 0),
    },
    [breakpoint('lg')]: {
        margin: calcRem(20, 0),
    },
})

export const StyledTitle = styled('h1')({
    fontWeight: 500,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.nero,
    fontSize: calcRem(22),
    lineHeight: calcRem(29),
    textAlign: 'left',
    margin: 0,
    [breakpoint('sm')]: {
        fontSize: calcRem(22),
        lineHeight: calcRem(28),
    },
    [breakpoint('md')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(26),
    },
    [breakpoint('lg')]: {
        fontSize: calcRem(27),
        lineHeight: calcRem(34),
    },
})

interface StyledTeaserProps {}

export const StyledTeaser = styled('div')<StyledTeaserProps>(({}) => ({
    margin: 0,
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.nero,
    fontSize: calcRem(16),
    lineHeight: calcRem(22),
    textAlign: 'left',
    marginTop: calcRem(8),
    display: 'block',
    [breakpoint('sm')]: {
        marginTop: calcRem(14),
        fontSize: calcRem(18),
        lineHeight: calcRem(25),
    },
    [breakpoint('md')]: {
        marginTop: calcRem(13),
        fontSize: calcRem(14),
        lineHeight: calcRem(20),
    },
    [breakpoint('lg')]: {
        marginTop: calcRem(20),
        fontSize: calcRem(20),
        lineHeight: calcRem(28),
    },
}))

export const StyledCardMediaWrap = styled('div')(({}) => ({
    gridRow: 1,
    minHeight: calcRem(200),
    [breakpoint('md')]: {
        alignSelf: 'center',
        display: 'grid',
        [`& [class*="image"], &[class*=StyledVideo]`]: {
            aspectRatio: '3/2',
            '& img': {
                width: 'auto',
                height: '100%',
                translate: '-8%',
            },
        },
    },
    '& [class*="StyledMedia"]': {
        marginBottom: 0,
    },
    '& [class*="StyledPlayIcon"]': {
        top: 'unset',
        left: 'unset',
        bottom: calcRem(32),
        right: calcRem(32),
        width: calcRem(40),
        height: calcRem(40),
        border: `${calcRem(1.92)} solid ${colors.white}`,
        '&::before': {
            left: '42%',
            top: '68%',
            backgroundSize: `${calcRem(9.26)} ${calcRem(16.24)}`,
        },
    },
}))

export const StyledLinkWrapper = styled(WebLink)({
    textDecoration: 'none',
})
export const StyledLiveStatus = styled('p')(() => [
    {
        display: 'flex',
        alignItems: 'center',
        marginBottom: calcRem(6),
        fontSize: calcRem(12),
    },
])

export const StyledDotLogo = styled('div')({
    background: tokens.sevennews.colors.palette.red,
    height: calcRem(5),
    width: calcRem(5),
    transform: 'translateX(-0%) translateY(-0%)',
    marginBottom: 0,
    marginRight: calcRem(4),
    borderRadius: '50%',
})
export const StyledLiveLabel = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
        margin: 0,
    },
])
export const StyledBreakingLabel = styled('p')(() => [
    {
        color: colors.sevennews.red,
        fontSize: calcRem(12),
        marginBottom: calcRem(6),
    },
])
