import { FeatureState, isFeatureEnabled } from '@etrigan/feature-toggles-client'
import {
    DataLayerEventName,
    NavEvent,
    NavEventArgs,
    NavItem,
} from '@news-mono/web-common'
import React from 'react'
import {
    StyledIconArrow,
    StyledNavButton,
    StyledNavItem,
    StyledNavItemText,
    StyledNavLink,
    StyledSVG,
    StyledSubNav,
    StyledSubNavLink,
} from './SevenNewsFlyoutNav2.styled'

export interface SevenNewsFlyoutNavProps {
    navItem: NavItem
    subNavId: string
    onEvent: (event: NavEvent) => void
    toggleFlyoutNav?: () => void
    setShowSubNav: (show: boolean) => void
    showSubNav: boolean
    featureState: FeatureState
}

export const SevenNewsFlyoutNavItem: React.FC<SevenNewsFlyoutNavProps> = ({
    navItem,
    subNavId,
    onEvent,
    toggleFlyoutNav,
    setShowSubNav,
    showSubNav,
    featureState,
}) => {
    const handleClick = (args: NavEventArgs) => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'Flyout',
            payload: {
                navName: 'Flyout.SevenNews',
                navLocation: 'Flyout',
                navPos: args.navPos,
                navText: args.navText,
                navLink: args.navLink,
                navParent: args.navParent,
                navPath: args.navParent
                    ? `${args.navParent} > ${args.navText}`
                    : `${args.navText}`,
            },
        })

        toggleFlyoutNav && toggleFlyoutNav()
    }

    return (
        <StyledNavItem role="menuitem">
            {!navItem.subNavLinks && (
                <StyledNavLink
                    to={navItem.link}
                    onClick={() => {
                        handleClick &&
                            handleClick({
                                navPos: 0,
                                navText: navItem.name,
                                navLink: navItem.link,
                                navParent: parent.name,
                                navPath: parent
                                    ? `${parent.name} > ${navItem.name}`
                                    : `${navItem.name}`,
                            })
                    }}
                >
                    <StyledNavItemText>
                        {navItem.name}
                        {navItem.linkOut && <StyledSVG />}
                    </StyledNavItemText>
                </StyledNavLink>
            )}

            {navItem.subNavLinks && (
                <StyledNavButton
                    onClick={() => setShowSubNav(!showSubNav)}
                    aria-controls={subNavId}
                    aria-haspopup
                >
                    <span>
                        <StyledNavItemText>
                            {navItem.name}
                            {navItem.linkOut && <StyledSVG />}
                        </StyledNavItemText>
                    </span>

                    <StyledIconArrow
                        fill={'currentColor'}
                        subNavIsOpen={showSubNav}
                    />
                </StyledNavButton>
            )}

            {navItem.subNavLinks && showSubNav && (
                <StyledSubNav id={subNavId} role="menu">
                    <li>
                        <StyledSubNavLink
                            to={navItem.link}
                            onClick={() => {
                                handleClick &&
                                    handleClick({
                                        navPos: 0,
                                        navText: navItem.name,
                                        navLink: navItem.link,
                                        navParent: parent.name,
                                        navPath: parent
                                            ? `${parent.name} > ${navItem.name}`
                                            : `${navItem.name}`,
                                    })
                            }}
                        >
                            {navItem.name} Home
                        </StyledSubNavLink>
                    </li>

                    {navItem.subNavLinks.map((subNavItem, subNavIndex) => {
                        if (
                            subNavItem.feature &&
                            typeof subNavItem.feature === 'string' &&
                            !isFeatureEnabled(featureState, subNavItem.feature)
                        ) {
                            return null
                        }
                        return (
                            <li key={subNavIndex}>
                                <StyledSubNavLink
                                    to={subNavItem.link}
                                    onClick={() => {
                                        handleClick &&
                                            handleClick({
                                                navPos: subNavIndex + 1,
                                                navText: subNavItem.name,
                                                navLink: subNavItem.link,
                                                navParent: navItem.name,
                                                navPath: navItem.name
                                                    ? `${navItem.name} > ${subNavItem.name}`
                                                    : `${subNavItem.name}`,
                                            })
                                    }}
                                >
                                    {subNavItem.name}
                                </StyledSubNavLink>
                            </li>
                        )
                    })}
                </StyledSubNav>
            )}
        </StyledNavItem>
    )
}

SevenNewsFlyoutNavItem.displayName = 'SevenNewsFlyoutNavItem'
