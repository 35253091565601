import React from 'react'
import {
    SevenPlusBtn,
    SevenPlusBtnHeading,
    SevenPlusIcon,
} from './SevenPlusArticleButton.styled'

const icon = require('./assets/7plus.png')

type SevenPlusArticleButtonProps = {
    URL: string
}
export const SevenPlusArticleButton: React.FC<SevenPlusArticleButtonProps> = ({
    URL,
}) => {
    return (
        <SevenPlusBtn target="_blank" href={URL}>
            <SevenPlusBtnHeading>Stream free on</SevenPlusBtnHeading>
            <SevenPlusIcon src={icon} alt="7plus logo" />
        </SevenPlusBtn>
    )
}
SevenPlusArticleButton.displayName = 'SevenPlusArticleButton'
