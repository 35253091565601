import { createRegisterableComposition } from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ContainerWidth, ThemeMargins } from '../../__styling'
import { BreakPointProps } from '../Box/Box'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'
import { ArticleDoublet } from './ArticleDoublet'

export interface ArticleDoubletRouteProps extends BaseCompositionProps {
    hasBackgroundFill?: boolean
    horizontalGutters?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent?: boolean
    item?: ArticleLikeV4DTO
    breakpoints?: BreakPointProps
}

export const ArticleDoubletRegistration = createRegisterableComposition<
    'header' | 'main' | 'sidebarRight' | 'mobileRail'
>()(
    'article-doublet',
    (contentAreas, props: ArticleDoubletRouteProps) => (
        <ContentWrapper
            stretchColumns={props.stretchColumns}
            stretchSelf={props.stretchSelf}
        >
            <ArticleDoublet
                {...props}
                header={contentAreas.header}
                mainContent={contentAreas.main}
                sidebarRight={contentAreas.sidebarRight}
                mobileRail={contentAreas.mobileRail}
            />
        </ContentWrapper>
    ),
    (
        contentArea,
        {
            containerWidthRatios = { desktop: 1, tablet: 1, mobile: 1 },
        }: ArticleDoubletRouteProps & ResponsiveContainer,
    ): ResponsiveContainer => {
        if (contentArea === 'main') {
            return {
                containerWidthRatios: {
                    desktop: 0.66 * containerWidthRatios.desktop,
                    tablet: 0.66 * containerWidthRatios.tablet,
                    mobile: 1 * containerWidthRatios.mobile,
                },
            }
        }

        return {
            containerWidthRatios: {
                desktop: 0.33 * containerWidthRatios.desktop,
                tablet: 0.33 * containerWidthRatios.tablet,
                mobile: 1 * containerWidthRatios.mobile,
            },
        }
    },
)
