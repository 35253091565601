import { useTheme } from '@emotion/react'
import {
    CardItem,
    CollectionEvent,
    PublicationCardItem,
    createCollectionAvailableEvent,
    isPublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { ImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { useProduct } from '../../../__product'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'
import { ResponsivePictureLayout } from '../../../content'
import { applyContainerRatio } from '../../../content/Picture/responsive'
import { GetVideoQueue } from '../../../content/Video/PlayerInterface'
import { ByLine } from '../ByLine/ByLine'
import {
    StyledBreakingLabel,
    StyledBylineWrap,
    StyledCardMediaWrap,
    StyledDivider,
    StyledDotLogo,
    StyledEstimatedTime,
    StyledHeroContainer,
    StyledLinkWrapper,
    StyledLiveLabel,
    StyledLiveStatus,
    StyledTeaser,
    StyledTextWrap,
    StyledTitle,
} from './HomeHeroCard.styled'
import { EditorialType, getEditorialType } from '../../../templates'

export interface HeroCardProps {
    item: MaybeLoaded<CardItem>
    onEvent: (event: CollectionEvent) => void
    adUnitPath?: string
    getVideoQueue?: GetVideoQueue
    tbRegionName?: string
}
export const HomeHeroCard: React.FC<HeroCardProps> = ({
    item,
    onEvent,
    tbRegionName,
    adUnitPath,
    getVideoQueue,
}) => {
    const product = useProduct()
    const theme = useTheme()
    const storyClassification = true
    if (!item?.loaded || (item.loaded && !isPublicationCardItem(item.result))) {
        return null
    }

    const publication = item.result as PublicationCardItem

    const editorialType = item.loaded
        ? getEditorialType(item.result, storyClassification)
        : undefined

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                if (!item.result) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                onEvent(
                    createCollectionAvailableEvent(
                        [item.result],
                        'HomeHeroCard',
                        product,
                        onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <div ref={ref}>
                    <StyledHeroContainer data-tb-region={tbRegionName}>
                        <StyledTextWrap>
                            {editorialType &&
                            editorialType === EditorialType.Live ? (
                                <StyledLiveStatus>
                                    <StyledDotLogo />
                                    <StyledLiveLabel>Live</StyledLiveLabel>
                                </StyledLiveStatus>
                            ) : (
                                editorialType === EditorialType.Breaking && (
                                    <StyledBreakingLabel>
                                        Breaking
                                    </StyledBreakingLabel>
                                )
                            )}
                            <StyledLinkWrapper to={toLinkWithHint(item)}>
                                <StyledTitle>
                                    {publication.shortHeadline}
                                </StyledTitle>
                                <StyledTeaser>
                                    {publication.teaser}
                                </StyledTeaser>
                            </StyledLinkWrapper>
                            <StyledDivider />
                            <StyledBylineWrap>
                                <ByLine
                                    profiles={publication.profiles}
                                    byline={publication.byline}
                                    source={publication.source}
                                    readTimeMinutes={
                                        publication?.readTimeMinutes
                                    }
                                />
                                {publication.profiles &&
                                    publication.profiles.length === 1 && (
                                        <StyledEstimatedTime>
                                            {publication.readTimeMinutes &&
                                                Math.ceil(
                                                    publication.readTimeMinutes,
                                                )}{' '}
                                            min read
                                        </StyledEstimatedTime>
                                    )}
                            </StyledBylineWrap>
                        </StyledTextWrap>
                        <StyledLinkWrapper to={toLinkWithHint(item)}>
                            <StyledCardMediaWrap>
                                <CardMedia
                                    isLarge
                                    item={{
                                        loaded: true,
                                        result: publication,
                                    }}
                                    fixedRatio={['16:9']}
                                    hasBackground={false}
                                    imageWidths={applyContainerRatio(
                                        {
                                            desktop: 1,
                                            tablet: 1,
                                            mobile: 1,
                                        },
                                        {
                                            mobile: '100vw',
                                            tablet: '100vw',
                                            desktop: `${theme.siteMetrics.mainContentWidth}px`,
                                            fallbackWidth:
                                                theme.siteMetrics
                                                    .mainContentWidth,
                                        },
                                    )}
                                    onEvent={onEvent}
                                    willPlayVideoInline={false}
                                    disableImageLazyLoad={true}
                                    displayNoScriptImage={true}
                                    adUnitPath={adUnitPath}
                                    mediaMode="fullHeight"
                                    imageLayout={
                                        ResponsivePictureLayout.ObjectFitContain
                                    }
                                    imageFetchPriority={'high'}
                                />
                            </StyledCardMediaWrap>
                        </StyledLinkWrapper>
                    </StyledHeroContainer>
                </div>
            )}
        </ImpressionAvailable>
    )
}
