import { ComponentServices } from '@news-mono/web-common'

export function getVideoSeriesInfoFromRouteResolution(
    services: ComponentServices,
) {
    if (services.resolution.type !== 'server') {
        throw new Error('Publications must be server resolved')
    }

    if (services.resolution.resolution.type !== 'video-series') {
        throw new Error(
            'Server resolution is not video-series, should not be rendering video series landing',
        )
    }

    return services.resolution.resolution
}
