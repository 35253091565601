import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { WebLink, withClass } from '@news-mono/web-common'
import { Box } from '../../compositions/Box/Box'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition } from '../../__styling/style-mixins'

export type SevenNewsBreakingNewsThemeNames =
    | 'breakingNews'
    | 'live'
    | 'sunrise'
    | 'spotlight'
    | 'developing'
    | 'lifestyle'
    | 'entertainment'
    | 'trending'
    | 'sport'
    | 'afl'
    | 'cricket'
    | '7plus'
    | 'default'

export const StyledSevenNewsBreakingNews = withClass('hide-print')(
    styled(Box)({
        position: 'relative',
        overflow: 'hidden',
        ...transition({ properties: ['max-height'] }),
    }),
)

export const StyledSevenNewsBreakingNewsContent = styled('div')(() => {
    return {
        display: 'flex',
        width: '100%',
        minHeight: calcRem(72),
        alignItems: 'center',
        padding: calcRem(8, 16, 8, 24),
        marginTop: calcRem(metrics.sevennews.margins.md),
        marginBottom: calcRem(metrics.sevennews.margins.sm),
        background: 'linear-gradient(90deg, #9F0015 3.89%, #E6001E 53.06%)',
        [breakpoint('sm')]: {
            minHeight: calcRem(53),
        },
        [breakpoint('md')]: {
            minHeight: calcRem(45),
        },
    }
})

export const StyledSevenNewsBreakingNewsContentLink = styled(WebLink)({
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    paddingRight: calcRem(15),
})

export const StyledThemePill = styled('p')({
    margin: 0,
    padding: calcRem(4, 16),
    color: colors.white,
    background: colors.sevennews.red,
    borderRadius: calcRem(22),
    fontSize: calcRem(14),
    fontWeight: 700,
    letterSpacing: calcRem(0.42),
    marginRight: calcRem(metrics.sevennews.margins.lg),
    display: 'flex',
    alignItems: 'center',
    fontFamily: fonts.sevennews.sansSerif,
})
export const StyledArrow = styled('img')({
    cursor: 'pointer',
})

const PulseRing = keyframes`
0% {
    transform:  scale(.33);
  }
  80%, 100% {
    opacity: 0
  }
`
const PulseDot = keyframes`
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.8);
    }
`
export const StyledPulsatingDotLogo = styled('div')({
    background: colors.white,
    height: calcRem(10),
    width: calcRem(10),
    transform: 'translateX(-0%) translateY(-0%)',
    marginBottom: 0,
    marginRight: calcRem(11),
    position: 'relative',
    borderRadius: '50%',
    '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '300%',
        height: '300%',
        boxSizing: 'border-box',
        marginLeft: '-100%',
        marginTop: '-100%',
        borderRadius: calcRem(45),
        backgroundColor: colors.white,
        animation: `${PulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
    },
    '&::after': {
        content: '""',
        position: 'absolute',
        left: '0',
        top: '0',
        display: 'block',
        width: '100%',
        height: '100%',
        backgroundColor: colors.white,
        borderRadius: calcRem(15),
        boxShadow: '0 0 8px rgba(0,0,0,.3)',
        animation: `${PulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
    },
})

export const StyledBreakingNewsText = styled('h2')(() => {
    return {
        fontFamily: fonts.sevennews.sansSerif,
        fontSize: calcRem(17),
        letterSpacing: '0.14px',
        color: colors.white,
        margin: 0,
        lineHeight: 1.1,
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 3,
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        '&:hover, &:focus': {
            color: colors.white,
            textDecoration: 'underline',

            '+ button > svg': {
                fill: colors.white,
            },
        },
        [breakpoint('sm')]: {
            lineClamp: 2,
            '-webkit-line-clamp': '2',
        },
        [breakpoint('md')]: {
            lineClamp: 1,
            '-webkit-line-clamp': '1',
        },
    }
})
