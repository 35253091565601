import React from 'react'

import {
    StyledHeaderLogo,
    StyledBrandShowHeaderBannerContainer,
    StyledHeaderBackgroundImage,
    StyledHeaderLogoImage,
    StyledHeaderShowBannerLink,
    StyledHeaderShowLogoLink,
    StyledPageHeaderV2,
    StyledPageLogoContainer,
    StyledHeaderSecondLogoImage,
    StyledHeaderLogoLink,
} from './PageHeaderV2.styled'

import { SectionHeaderProps } from '../../section-header'
import { TopicHeaderType } from './PageHeaderV2.routing'

interface PageHeaderProps {
    sectionHeader: SectionHeaderProps
    type?: TopicHeaderType
    headerLink?: string
    brandBackgroundImage: string
    brandLogoImage: string
    logoImage?: string
    imageAltText?: string
    btybLinkLogoImage?: string
    btybLogoLink?: string
    btybLogoLinkAlt?: string
    btybSize?: 'small' | 'large'
}
export const PageHeaderV2: React.FC<PageHeaderProps> = (
    props: PageHeaderProps,
) => {
    const {
        sectionHeader,
        type = 'topic-heading',
        headerLink,
        brandBackgroundImage,
        brandLogoImage,
        logoImage,
        imageAltText,
        btybLinkLogoImage,
        btybLogoLink,
        btybLogoLinkAlt,
        btybSize = 'small',
    } = props

    return type === 'banner-image' ? (
        <StyledBrandShowHeaderBannerContainer>
            <StyledHeaderShowBannerLink href={headerLink}>
                <StyledHeaderBackgroundImage
                    src={brandBackgroundImage}
                    alt={imageAltText}
                    width="100%"
                />

                <StyledHeaderLogoImage
                    src={brandLogoImage}
                    alt={imageAltText}
                />
            </StyledHeaderShowBannerLink>
        </StyledBrandShowHeaderBannerContainer>
    ) : type === 'logo' ? (
        <StyledPageLogoContainer>
            <StyledHeaderShowLogoLink href={headerLink}>
                <StyledHeaderLogo src={logoImage} alt={imageAltText} />
            </StyledHeaderShowLogoLink>
            {btybLinkLogoImage && btybLogoLinkAlt && (
                <StyledHeaderLogoLink href={btybLogoLink} target={'_blank'}>
                    <StyledHeaderSecondLogoImage
                        src={btybLinkLogoImage}
                        alt={btybLogoLinkAlt}
                        size={btybSize}
                    ></StyledHeaderSecondLogoImage>
                </StyledHeaderLogoLink>
            )}
        </StyledPageLogoContainer>
    ) : (
        <StyledPageHeaderV2>
            {sectionHeader?.heading}
            {btybLinkLogoImage && btybLogoLinkAlt && (
                <StyledHeaderLogoLink href={btybLogoLink} target={'_blank'}>
                    <StyledHeaderSecondLogoImage
                        src={btybLinkLogoImage}
                        alt={btybLogoLinkAlt}
                        size={btybSize}
                    ></StyledHeaderSecondLogoImage>
                </StyledHeaderLogoLink>
            )}
        </StyledPageHeaderV2>
    )
}
PageHeaderV2.displayName = 'PageHeaderV2'
