import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    StyledNoLinkTopicPill,
    StyledPillMenuWrapper,
    StyledScrollButtonWrapper,
    StyledSubTopicList,
    StyledSubtopiLabelCopy,
    StyledSubtopicWrapper,
    StyledSubtopicWrapperWithControlls,
    StyledTabPill,
    StyledTopicPill,
} from './HorizontalPillMenu.styled'
import { PillItem, PillTabItem } from './helpers/subtopicList'
import { ThemeMargins } from '../../__styling'

interface HorizontalPillMenuProps {
    mainPill: PillItem
    subPills?: PillItem[]
    labelText?: string
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isWrapperCentered?: boolean
    withoutHoverState?: boolean
}
interface HorizontalTabPillMenuProps {
    mainPill: PillTabItem
    subPills?: PillTabItem[]
    labelText?: string
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isWrapperCentered?: boolean
    withoutHoverState?: boolean
}

const arrowIcon = require('./assets/arrowIcon.png')

export const HorizontalPillMenu: React.FC<
    HorizontalPillMenuProps | HorizontalTabPillMenuProps
> = ({ mainPill, subPills, labelText, verticalGutters, isWrapperCentered }) => {
    const [isPrevButtonActive, setIsPrevButtonActive] = useState<boolean>(false)
    const [isNextButtonActive, setIsNextButtonActive] = useState<boolean>(false)
    //need to use useState hook instead useRef because we need to triger rerender after changing pages
    const [subtopicRef, setSubtopicRef] = useState<HTMLDivElement | null>(null)
    const listElement = subtopicRef
    const [showButtons, setShowButtons] = useState<boolean>(false)

    const checkForActiveButton = useCallback(() => {
        if (listElement) {
            if (listElement.clientWidth < listElement.scrollWidth) {
                if (listElement.scrollLeft === 0) {
                    setIsPrevButtonActive(false)
                    setIsNextButtonActive(true)
                } else if (
                    //In some edge cases scrollWidth isn't intiger so doing calculation in this way is precise in all situation
                    listElement.scrollWidth -
                        listElement.clientWidth -
                        listElement.scrollLeft <
                    2
                ) {
                    setIsPrevButtonActive(true)
                    setIsNextButtonActive(false)
                } else {
                    setIsPrevButtonActive(true)
                    setIsNextButtonActive(true)
                }
            }
        }
    }, [listElement])

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            window.matchMedia('(pointer: coarse)').matches
        ) {
            return
        }

        checkForActiveButton()

        if (listElement) {
            if (listElement.scrollWidth > listElement.clientWidth) {
                setShowButtons(true)
            }

            listElement.addEventListener('scroll', checkForActiveButton)

            return () =>
                listElement.removeEventListener('scroll', checkForActiveButton)
        }
    }, [listElement, checkForActiveButton])

    const handleScroll = (direction: 'next' | 'prev') => {
        if (subtopicRef) {
            if (direction === 'next') {
                subtopicRef.scrollLeft = subtopicRef.scrollLeft + 300
            }
            if (direction === 'prev') {
                subtopicRef.scrollLeft = subtopicRef.scrollLeft - 300
            }
        }
    }

    const getPill = (
        pill: PillItem | PillTabItem,
        isLeadPill?: boolean,
        idx?: number,
        withoutHoverState?: boolean,
    ) => {
        if ('onClick' in pill && pill.onClick) {
            return (
                <StyledTabPill
                    key={idx}
                    onClick={pill?.onClick}
                    isLeadPill={isLeadPill || false}
                    withoutHoverState={withoutHoverState}
                >
                    {pill.name}
                </StyledTabPill>
            )
        } else {
            if ('link' in pill && pill.link) {
                return (
                    <StyledTopicPill
                        key={idx}
                        to={'link' in pill && pill.link ? pill.link : '#'}
                        isLeadPill={isLeadPill || false}
                    >
                        {pill.name}
                    </StyledTopicPill>
                )
            } else {
                return (
                    <StyledNoLinkTopicPill
                        key={idx}
                        isLeadPill={isLeadPill || false}
                    >
                        {pill.name}
                    </StyledNoLinkTopicPill>
                )
            }
        }
    }

    return (
        <StyledPillMenuWrapper
            verticalGutters={verticalGutters}
            isCentered={isWrapperCentered}
        >
            {getPill(mainPill, true)}
            {subPills && subPills.length > 0 && (
                <StyledSubtopicWrapperWithControlls>
                    {showButtons && (
                        <StyledScrollButtonWrapper
                            position="left"
                            onClick={() => handleScroll('prev')}
                            isActive={isPrevButtonActive}
                        >
                            <img src={arrowIcon} alt="Scroll" />
                        </StyledScrollButtonWrapper>
                    )}
                    <StyledSubtopicWrapper>
                        {
                            <StyledSubTopicList
                                ref={(newRef) => setSubtopicRef(newRef)}
                            >
                                {labelText && (
                                    <StyledSubtopiLabelCopy>
                                        {labelText}
                                    </StyledSubtopiLabelCopy>
                                )}
                                {subPills.map((pill, idx) => {
                                    return getPill(pill, false, idx)
                                })}
                            </StyledSubTopicList>
                        }
                    </StyledSubtopicWrapper>
                    {showButtons && (
                        <StyledScrollButtonWrapper
                            position="right"
                            onClick={() => handleScroll('next')}
                            isActive={isNextButtonActive}
                        >
                            <img src={arrowIcon} alt="Scroll" />
                        </StyledScrollButtonWrapper>
                    )}
                </StyledSubtopicWrapperWithControlls>
            )}
        </StyledPillMenuWrapper>
    )
}
