import React from 'react'
import {
    StyledAppPromotionCopy,
    StyledFooterColumn,
    StyledFooterColumnDoubled,
    StyledFooterColumnDoubledComponent,
    StyledFooterNav,
    StyledLink,
    StyledLinkList,
    StyledPromotionButtonImg,
    StyledPromotionButtonLink,
    StyledPromotionButtonsWrapper,
    StyledSocialLinks,
} from '../../navigation/SevenNewsFooterNetworkLinks/SevenNewsFooterNetworkLinks.styled'
import { SocialLinks } from '../../navigation/SocialLinks/SocialLinks'
import { SectionHeader } from '../../section-header/SectionHeader/SectionHeader'
import { sections } from '../../__styling/settings/sections'
import {
    Features,
    useFeature,
    useToggleState,
    isComponentEnabled,
} from '@news-mono/web-common'
import { FeatureState, toFeatureState } from '@etrigan/feature-toggles-client'

const playStoreIcon = require('./assets/play-store.svg')
const appleStoreIcon = require('./assets/apple-store.svg')

interface Link {
    name: string
    link: string
    feature?: Features
}

export interface SevenNewsFooterNetworkLinksProps {
    networkLinks?: Link[]
    partnerLinks?: Link[]
    connectLinks?: Link[]
    newslettersLinks?: Link[]
}

export const SevenNewsFooterNetworkLinks: React.FC<SevenNewsFooterNetworkLinksProps> =
    (props) => {
        const featureState = toFeatureState(useToggleState())
        const isNewsletterFeatureEnabled = useFeature(
            '7-news-footer-newsletter',
        )
        const isAppPromotionEnabled = useFeature('7-news-app-site-promotion')

        const { networkLinks, partnerLinks, connectLinks, newslettersLinks } =
            props
        const sevenSectionValues = sections.sevennews['default']
        const socialLinks = sevenSectionValues.socialLinks

        return (
            <StyledFooterNav id="footer-nav">
                <StyledFooterColumn>
                    <SectionHeader heading="Our Network" hasBottomMargin />
                    {networkLinks && networkLinks.length > 0 && (
                        <StyledLinkList>
                            {filterItems(networkLinks, featureState).map(
                                (linkItem) => {
                                    return (
                                        <li key={linkItem.name}>
                                            <StyledLink to={linkItem.link}>
                                                {linkItem.name}
                                            </StyledLink>
                                        </li>
                                    )
                                },
                            )}
                        </StyledLinkList>
                    )}
                </StyledFooterColumn>
                <StyledFooterColumn>
                    <SectionHeader heading="Our Partners" hasBottomMargin />
                    {partnerLinks && partnerLinks.length > 0 && (
                        <StyledLinkList>
                            {partnerLinks.map((linkItem) => {
                                return (
                                    <li key={linkItem.name}>
                                        <StyledLink to={linkItem.link}>
                                            {linkItem.name}
                                        </StyledLink>
                                    </li>
                                )
                            })}
                        </StyledLinkList>
                    )}
                </StyledFooterColumn>

                {(isNewsletterFeatureEnabled || isAppPromotionEnabled) && (
                    <StyledFooterColumnDoubled>
                        {isAppPromotionEnabled && (
                            <StyledFooterColumnDoubledComponent>
                                <SectionHeader
                                    heading="7NEWS App"
                                    hasBottomMargin
                                />
                                <StyledAppPromotionCopy>
                                    Stay informed with the free 7NEWS app.
                                    Available on iOS & Android.
                                    <StyledPromotionButtonsWrapper>
                                        <StyledPromotionButtonLink to="https://apps.apple.com/au/app/id6471218774">
                                            <StyledPromotionButtonImg
                                                src={appleStoreIcon}
                                            />
                                        </StyledPromotionButtonLink>
                                        <StyledPromotionButtonLink to="https://play.google.com/store/apps/details?id=com.swm.news">
                                            <StyledPromotionButtonImg
                                                src={playStoreIcon}
                                            />
                                        </StyledPromotionButtonLink>
                                    </StyledPromotionButtonsWrapper>
                                </StyledAppPromotionCopy>
                            </StyledFooterColumnDoubledComponent>
                        )}
                        {isNewsletterFeatureEnabled && (
                            <StyledFooterColumnDoubledComponent>
                                <SectionHeader
                                    heading="Newsletters"
                                    hasBottomMargin
                                />
                                {newslettersLinks &&
                                    newslettersLinks.length > 0 && (
                                        <StyledLinkList>
                                            {newslettersLinks.map(
                                                (linkItem) => {
                                                    return (
                                                        <li key={linkItem.name}>
                                                            <StyledLink
                                                                to={
                                                                    linkItem.link
                                                                }
                                                            >
                                                                {linkItem.name}
                                                            </StyledLink>
                                                        </li>
                                                    )
                                                },
                                            )}
                                        </StyledLinkList>
                                    )}
                            </StyledFooterColumnDoubledComponent>
                        )}
                    </StyledFooterColumnDoubled>
                )}

                <StyledFooterColumn>
                    <SectionHeader
                        heading={`Connect With Us`}
                        hasBottomMargin
                    />
                    {connectLinks && connectLinks.length > 0 && (
                        <StyledLinkList>
                            {connectLinks.map((linkItem) => {
                                return (
                                    <li key={linkItem.name}>
                                        <StyledLink to={linkItem.link}>
                                            {linkItem.name}
                                        </StyledLink>
                                    </li>
                                )
                            })}
                        </StyledLinkList>
                    )}
                    <StyledSocialLinks>
                        <SocialLinks
                            sectionTitle={sevenSectionValues.title}
                            {...socialLinks}
                        />
                    </StyledSocialLinks>
                </StyledFooterColumn>
            </StyledFooterNav>
        )
    }
SevenNewsFooterNetworkLinks.displayName = 'SevenNewsFooterNetworkLinks'

function filterItems(items: Link[], featureState: FeatureState) {
    return items.filter(({ feature }) => {
        if (feature) {
            return isComponentEnabled(featureState, {
                feature,
            })
        }
        return true
    })
}
