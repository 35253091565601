import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ArticleLikePublication,
    isArticleLikeType,
    TogglesReduxState,
} from '@news-mono/web-common'
import React from 'react'
import { StyledTextLink } from '../../../../typography/TextLink/TextLink.styled'
import { InlinePositionedContent } from '../../Publication.props'
import {
    InlineArticleContextualLink,
    InlineContentTypes,
} from '../../SharedPublication.routing'
import { StyledContextualLink, StyledParagraph } from './ContextualLink.styled'

const rightRedArrow = require('./assets/right-red-arrow.svg')

export interface GetInlinePositionedContextualLinkProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    toggles: TogglesReduxState
}

export function isArticleContextualLinkComponent(
    content: InlineContentTypes,
): content is InlineArticleContextualLink {
    return content.kind === 'inline-article-contextual-link'
}

export const getInlinePositionedContextualLink = (
    props: GetInlinePositionedContextualLinkProps,
): InlinePositionedContent[] => {
    const { publication, inlinePublicationContent, toggles } = props
    const isSevenNewsAppContextualLinkEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7-news-app-contextual-link',
    )
    const topic = isSevenNewsAppContextualLinkEnabled
        ? 'download-app'
        : publication.primaryTopic.id
    const topicTitle = publication.primaryTopic.title

    const topicData = getTopicInfo(topic, toggles, topicTitle)

    const isToggleEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7news-contextual-link',
    )
    const isV2ArticlePageEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7-news-2-article-page',
    )

    if (!isArticleLikeType(publication)) {
        return []
    }

    if (!isToggleEnabled) {
        return []
    }

    if (topicData && topicData.length === 0) {
        return []
    }

    return inlinePublicationContent
        .filter(isArticleContextualLinkComponent)
        .map((content) => {
            const mappedContent = {
                position: content.insertAfter,
                element: renderContextualLink(
                    topicData,
                    isV2ArticlePageEnabled,
                ),
            }
            return mappedContent
        })
}

interface TopicItem {
    topic: string
    text: string
    link: string
    linkText?: string
    featureToggle?: string
}

const includedTopics: TopicItem[] = [
    {
        topic: 'the-morning-show',
        text: 'Stream The Morning Show free on ',
        link: 'https://7plus.com.au/the-morning-show?utm_source=7news&utm_medium=contextual-link&utm_campaign=september-test&utm_id=The-Morning-Show-articles',
        linkText: '7plus',
    },
    {
        topic: 'entertainment/sas-australia',
        text: 'Stream SAS Australia free on ',
        link: 'https://7plus.com.au/sas-australia?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=sas-australia',
    },
    {
        topic: 'entertainment/big-brother-australia',
        text: 'Stream Big Brother free on ',
        link: 'https://7plus.com.au/big-brother?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=big-brother-australia-articles',
    },
    {
        topic: 'entertainment/dancing-with-the-stars',
        text: 'Stream Dancing with the Stars free on ',
        link: 'https://7plus.com.au/dancing-with-the-stars-all-stars?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=DWTS',
        featureToggle: '7-news-dwts-contextual-link',
    },
    {
        topic: 'entertainment/the-voice-australia',
        text: 'Watch The Voice on Channel 7 and stream it for free on ',
        link: 'https://7plus.com.au/the-voice?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=the-voice',
    },
    {
        topic: 'entertainment/farmer-wants-a-wife',
        text: 'Stream Farmer Wants a Wife free on ',
        link: 'https://7plus.com.au/farmer-wants-a-wife?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=farmer-wants-a-wife',
    },
    {
        topic: 'entertainment/kitchen-nightmares-australia',
        text: 'Stream Kitchen Nightmares Australia free on ',
        link: 'https://7plus.com.au/kitchen-nightmares-australia?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=kitchen-nightmares-australia',
    },
    {
        topic: 'entertainment/holey-moley',
        text: 'Watch Holey Moley on Channel 7 and stream it for free on ',
        link: 'https://7plus.com.au/holey-moley-australia?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=holey-moley',
    },
    {
        topic: 'entertainment/home-and-away',
        text: 'Stream Home and Away free on ',
        link: 'https://7plus.com.au/home-and-away?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=home-and-away',
    },
    {
        topic: 'entertainment/dream-home',
        text: 'Stream Dream Home free on ',
        link: 'https://7plus.com.au/dream-home?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=dream-home',
    },
    {
        topic: 'sport/cricket',
        text: `Watch, Stream & Catch Up with Australia's Home of Cricket on `,
        link: 'https://7plus.com.au/cricket?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=cricket',
    },
    {
        topic: 'sport/horse-racing',
        text: `Stream your favourite races live and free on `,
        link: 'https://7plus.com.au/horse-racing?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=horse-racing',
    },
    {
        topic: 'sport/nfl',
        text: `Stream NFL live and free on `,
        link: 'https://7plus.com.au/nfl?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=nfl',
    },
    {
        topic: 'sport/golf',
        text: `Stream every round of the LIV Golf season live and free on `,
        link: 'https://7plus.com.au/liv-golf?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=liv-golf',
    },
    {
        topic: 'sport/ufc',
        text: `Stream ONE Fight Night, ONE Friday Fights events and get access to highlights and ONE’s library archive of more than 100 past events for free on `,
        link: 'https://7plus.com.au/one-friday-fights?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=one-championship',
    },
    {
        topic: 'sport/boxing',
        text: `Stream ONE Fight Night, ONE Friday Fights events and get access to highlights and ONE’s library archive of more than 100 past events for free on `,
        link: 'https://7plus.com.au/one-friday-fights?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=one-championship',
    },
    {
        topic: 'sport/mixed-martial-arts',
        text: `Stream live fights and ONE's library of 100+ events free on `,
        link: 'https://7plus.com.au/one-friday-fights?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=one-championship',
    },
    {
        topic: 'news/coronavirus',
        text: 'For more Coronavirus related news and videos check out ',
        link: 'https://7news.com.au/news/coronavirus?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=lifestyle-article-sub-topic-page',
        linkText: '7Coronavirus ',
        featureToggle: '7-news-lifestyle-contextual-link',
    },
    {
        topic: 'politics',
        text: 'Stream your local 7NEWS free on ',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=politics-articles',
        featureToggle: '7-news-politics-full-page-article-display',
    },
    {
        topic: 'technology',
        text: 'Watch the latest News on Channel 7 or stream for free on ',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=tech-articles',
        featureToggle: '7-news-tech-full-article-page-display',
    },
    {
        topic: 'news/world',
        text: 'Watch the latest News on Channel 7 or stream for free on ',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=world-articles',
        featureToggle: '7-news-world-full-article-page-display',
    },
    {
        topic: 'news',
        text: 'Newsletters: Breaking news as it happens. ',
        link: 'https://7news.com.au/newsletters',
        linkText: 'Subscribe now',
        featureToggle: '7-news-article-full-page-display',
    },
    {
        topic: 'business/retail',
        text: 'Make huge savings at your favourite stores with ',
        link: 'https://coupons.7news.com.au/?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=retail-articles-coupons',
        linkText: '7NEWS Coupons',
    },
    {
        topic: 'weather',
        text: 'Watch the latest News on Channel 7 or stream for free on ',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=weather-articles',
        featureToggle: '7-news-weather-full-article-page-display',
    },
    {
        topic: 'sport/surfing',
        text: 'Stream every WSL wave live for free on ',
        link: 'https://7plus.com.au/surfing?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=surfing-articles',
    },
    {
        topic: 'sport/motorsport',
        text: 'Stream Supercars and more motorsport action live and free on ',
        link: 'https://7plus.com.au/motorsport?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=motorsport-articles',
    },
    {
        topic: 'spotlight',
        text: 'Stream Spotlight free on ',
        link: 'https://7plus.com.au/7news-spotlight?utm_source=7NEWS&utm_medium=contextual-link-entertainment&utm_campaign=spotlight',
        featureToggle: '7-news-spotlight-full-article-display',
    },
    {
        topic: 'entertainment/my-kitchen-rules',
        text: 'Watch My Kitchen Rules on Channel 7 and stream it for free on ',
        link: 'https://7plus.com.au/my-kitchen-rules?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=mkr-article-page',
    },
    {
        topic: 'sport/afl',
        text: 'Stream analysis, local footy and the biggest moments free on ',
        link: 'https://7plus.com.au/afl?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=afl-article',
    },
    {
        topic: 'sport/aflw',
        text: 'Stream analysis, local footy and the biggest moments free on ',
        link: 'https://7plus.com.au/aflw?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=aflw-article',
    },
    {
        topic: 'sport',
        text: 'Newsletters: Our top sports news direct to your inbox. ',
        link: 'https://7news.com.au/newsletters',
        linkText: 'Subscribe now ',
        featureToggle: '7-news-sport-article-full-page-display',
    },
    {
        topic: 'entertainment/heartbreak-island',
        text: 'Stream Heartbreak Island Australia free on ',
        link: 'https://7plus.com.au/heartbreak-island-australia?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=heartbreak-island-aus',
    },
    {
        topic: 'entertainment/agt',
        text: `Watch Australia's Got Talent on Channel 7 and stream it for free on `,
        link: 'https://7plus.com.au/australias-got-talent?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=australias-got-talent',
    },
    {
        topic: 'entertainment/million-dollar-island',
        text: `Watch Million Dollar Island on Channel 7 and stream it for free on `,
        link: 'https://7plus.com.au/million-dollar-island?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=million-dollar-island',
    },
    {
        topic: 'entertainment/australian-idol',
        text: `Stream Australian Idol free on `,
        link: 'https://7plus.com.au/australian-idol?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=australian-idol',
    },
    {
        topic: 'about-us',
        text: 'Stream news and more for free on ',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=about-us',
    },
    {
        topic: 'best-picks',
        text: 'Supermarkets, shopping and deals: ',
        link: 'https://7news.com.au/newsletters',
        linkText: 'Sign up for newsletters',
    },
    {
        topic: 'travel',
        text: 'Catch the best deals and products hand-picked by our team at ',
        link: 'https://7news.com.au/best-picks?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=best-picks',
        linkText: 'Best Picks',
    },
    {
        topic: 'lifestyle/health-wellbeing',
        text: 'Catch the best deals and products hand-picked by our team at ',
        link: 'https://7news.com.au/best-picks?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=best-picks',
        linkText: 'Best Picks',
    },
    {
        topic: 'lifestyle/food',
        text: 'Catch the best deals and products hand-picked by our team at ',
        link: 'https://7news.com.au/best-picks?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=best-picks',
        linkText: 'Best Picks',
    },
    {
        topic: 'lifestyle/sponsored',
        text: `Supermarkets, shopping and deals: `,
        link: 'https://7news.com.au/newsletters',
        linkText: 'Sign up for newsletters',
    },
    {
        topic: 'lifestyle/food-wine',
        text: `Trends, culture and exclusive features: `,
        link: 'https://7news.com.au/newsletters',
        linkText: 'Sign up for 7LIFE newsletters',
    },
    {
        topic: 'lifestyle',
        text: `Trends, culture and exclusive features: `,
        link: 'https://7news.com.au/newsletters',
        linkText: 'Sign up for 7LIFE newsletters',
    },
    {
        topic: 'motoring',
        text: 'Stream Supercars and more motorsport action live and free on ',
        link: 'https://7plus.com.au/motorsport?utm_source=7NEWS&utm_medium=contextual-link&utm_campaign=motoring-articles',
    },
    {
        topic: 'sunrise',
        text: 'Stream Sunrise free on ',
        link: 'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=contextual-link-entertainment&utm_campaign=sunrise',
    },
    {
        topic: 'entertainment',
        text: `Stream the world's best reality, entertainment and true-crime shows free on `,
        link: 'https://7plus.com.au/7bravo?utm_source=7NEWS&utm_medium=contextual-link-entertainment&utm_campaign=7bravo',
    },
    {
        topic: 'download-app',
        text: 'Know the news with the 7NEWS app: ',
        link: 'https://7news.com.au/app?utm_source=7NEWS&utm_medium=contextual-link-app&utm_campaign=7news-app',
        linkText: 'Download today',
    },
]

function getTopicInfo(
    topic: string,
    toggles: TogglesReduxState,
    topicTitle: string,
): TopicItem[] {
    if (
        topic.includes('entertainment/win') ||
        topic.includes('entertainment/competitions')
    ) {
        return []
    }
    const isPartOfIncludedTopics = includedTopics.some((_) => _.topic === topic)

    let item = []
    item = includedTopics.filter((item) => item.topic === topic)
    if (!item.length) {
        item = includedTopics.filter((item) => topic.includes(item.topic))
    }

    return item.filter((val) => {
        if (!val?.featureToggle) return true
        return (
            val.featureToggle &&
            isFeatureEnabled(toFeatureState(toggles), val.featureToggle)
        )
    })
}

function renderContextualLink(
    topicData: TopicItem[],
    isV2ArticlePageEnabled: boolean,
) {
    const renderArr = topicData.length > 1 ? topicData : [topicData[0]]

    return (
        <StyledParagraph isV2ArticlePageEnabled={isV2ArticlePageEnabled}>
            {renderArr.map((topic, i) => {
                const key = topic.text + i
                return (
                    <span key={key}>
                        {topic.text}
                        {isV2ArticlePageEnabled ? (
                            <StyledContextualLink to={topic.link}>
                                {topic.linkText || '7plus '}{' '}
                                <img
                                    src={rightRedArrow}
                                    alt={topic.linkText || '7plus '}
                                    height={14}
                                    width={20}
                                />
                            </StyledContextualLink>
                        ) : (
                            <StyledTextLink to={topic.link}>
                                {topic.linkText || '7plus '} &gt;&gt;
                            </StyledTextLink>
                        )}
                    </span>
                )
            })}
        </StyledParagraph>
    )
}
