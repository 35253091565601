import styled from '@emotion/styled'
import {
    createPropFilter,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import { playButtonIconClass } from '../../buttons/PlayButton/PlayButton.styled'
import { StyledLink as CardTextInteractions } from '../../cards/CardText/CardTextInteractions.styled'
import { colors } from '../../__styling/settings/colors'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { breakpoint, breakpointMax } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { breakWords } from '../../__styling/style-mixins'
import { themedValue } from '../../__styling/themed-value'
import { CardMedia, MediaMode } from '../CardMedia/CardMedia'
import { CardOrientationOptions } from './VideoCard'

export const styledVideoCardClassName = 'VideoCardCard'

export interface StyledVideoCardCardProps {
    verticalSpacing?: keyof ThemeMargins
    isLarge?: boolean
    fullWidthOnMobile?: boolean
}

export const StyledVideoCard = withClass(styledVideoCardClassName)(
    styled('div')<StyledVideoCardCardProps>(
        {
            ...breakWords,
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: 1,
            alignItems: 'stretch',
        },
        ({ verticalSpacing, theme, fullWidthOnMobile }) => ({
            marginBottom: verticalSpacing && theme.margins[verticalSpacing],
            fontSize: themedValue(theme, {
                thewest: calcRem(13),
                fallback: calcRem(14),
            }),
            borderBottom: themedValue(theme, {
                perthnow: `1px solid ${colors.perthnow.greyMarble}`,
                sevennews: undefined,
                fallback: undefined,
            }),

            [breakpointMax('sm')]: {
                width: fullWidthOnMobile
                    ? `calc(100% + ${theme.margins.outerMargin * 2}px)`
                    : undefined,
                margin: fullWidthOnMobile
                    ? calcRem(0, -theme.margins.outerMargin)
                    : undefined,
                marginBottom: verticalSpacing && theme.margins[verticalSpacing],
            },
        }),
    ),
)

export interface StyledLinkProps {
    isLarge?: boolean
    cardOrientation?: CardOrientationOptions
    fullWidthOnMobile?: boolean
}

export const StyledLink = styled(CardTextInteractions, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['isLarge']),
})<StyledLinkProps>(
    {
        textDecoration: 'none',
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        textDecorationSkipInk: 'auto',
    },
    ({ isLarge, theme }) => ({
        // want to give the VideoCard card a bigger focus border when isLarge because of
        // having a black background on sevennews when hasBackground is set
        '&:focus': {
            outline:
                isLarge && theme.kind === 'sevennews'
                    ? `solid 3px ${colors.sevennews.blueRibbon}`
                    : undefined,
        },

        [`& .${playButtonIconClass}`]: {
            opacity: isLarge ? 1 : 0,
        },

        [`&:hover .${playButtonIconClass}, &:focus .${playButtonIconClass}`]: {
            opacity: 1,
        },
    }),
)
StyledLink.displayName = 'StyledLink'

interface StyledCardMediaProps {
    mediaMode?: MediaMode
}

export const StyledCardMedia = styled(CardMedia)<StyledCardMediaProps>({
    [breakpoint('sm')]: {
        width: '100%',
        margin: 0,
    },
})
