import React, { useState } from 'react'
import { BreakpointKeys, ContainerWidth, ThemeMargins } from '../../__styling'
import { BaseCompositionProps } from '../../compositions/types'
import { StyledTab, StyledTabContent } from './TabV2.styled'
import { HorizontalPillMenu } from '../HorizontalPillMenu/HorizontalPillMenu'

export type HeaderProps = {
    position: string
    title: string
}
export interface TabPillRawProps extends BaseCompositionProps {
    one?: React.ReactElement<any>
    two?: React.ReactElement<any>
    three?: React.ReactElement<any>
    four?: React.ReactElement<any>
    five?: React.ReactElement<any>
    six?: React.ReactElement<any>
    seven?: React.ReactElement<any>
    eight?: React.ReactElement<any>
    nine?: React.ReactElement<any>
    headerData: Array<HeaderProps>
    containerWidth?: ContainerWidth
}

export interface TabPillSpacingProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    horizontalGutters?: keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
}

export type TabPillBreakPointProps = {
    [key in BreakpointKeys]?: TabPillSpacingProps
}

export interface TabPillProps extends TabPillRawProps, TabPillSpacingProps {
    breakpoints?: TabPillBreakPointProps
}

export const TabV2 = ({
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    headerData,
    containerWidth,
    verticalGutters,
    horizontalGutters,
    verticalSpacing,
    horizontalSpacing,
}: TabPillProps) => {
    const [activeTab, setActiveTab] = useState(headerData[0])

    const returnContent = () => {
        switch (activeTab.position) {
            case 'two':
                return two
            case 'three':
                return three
            case 'four':
                return four
            case 'five':
                return five
            case 'six':
                return six
            case 'seven':
                return seven
            case 'eight':
                return eight
            case 'nine':
                return nine
            default:
                return one
        }
    }

    const mainPill = {
        name: activeTab.title,
        onClick: () => {},
    }

    const subPills = headerData
        .filter((val) => {
            return val.position !== activeTab.position
        })
        .map((val) => {
            return {
                name: val.title,
                onClick: () => setActiveTab(val),
            }
        })

    return (
        <StyledTab
            containerWidth={containerWidth}
            verticalGutters={verticalGutters}
            horizontalGutters={horizontalGutters}
            verticalSpacing={verticalSpacing}
            horizontalSpacing={horizontalSpacing}
        >
            <HorizontalPillMenu
                mainPill={mainPill}
                subPills={subPills}
                isWrapperCentered
            />
            <StyledTabContent>{returnContent()}</StyledTabContent>
        </StyledTab>
    )
}
