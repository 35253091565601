export const getBannerLogoUrls = (topic: string) => {
    let bannerLogoImage

    try {
        bannerLogoImage = require(`../assets/${topic}-logo.svg`)
    } catch (error) {
        bannerLogoImage = null
    }

    return bannerLogoImage
}
