import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { HorizontalDivider } from './HorizontalDivider'
import { ThemeMargins } from '../../__styling'

export interface HorizontalDividerRoutingProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
}

export const HorizontalDividerRegistration = createRegisterableComponent(
    'horizontal-divider',
    (props: HorizontalDividerRoutingProps, services) => {
        return <HorizontalDivider {...props} />
    },
)
