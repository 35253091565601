import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    QLDElectionSeatCountWidget,
    QLDElectionSeatCountWidgetProps,
} from './QLDElectionSeatCountWidget'

export const QLDElectionSeatCountWidgetRegistration =
    createRegisterableComponent(
        'qld-election-seat-count-widget',
        (props: QLDElectionSeatCountWidgetProps, services) => {
            return <QLDElectionSeatCountWidget {...props} />
        },
    )
