import styled from '@emotion/styled'
import { ThemeMargins, breakpoint, calcRem, colors } from '../../../__styling'
import { StyledLinkWrapper } from '../../cards/ContentCard/ContentCard.styled'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
}
export const StyledHeroCollectionWrapper = styled('div')<GridProps>(
    ({ theme, verticalSpacing }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingBottom: calcRem(16),
        borderBottom: `1px solid ${colors.black10}`,
        [breakpoint('md')]: {
            borderBottom: 'none',
            paddingBottom: 0,
            gridTemplateColumns: '3fr 1fr',
            gap: calcRem(24),
        },
        [breakpoint('lg')]: {
            borderBottom: 'none',
            paddingBottom: 0,
            gridTemplateColumns: '3fr 1fr',
            gap: calcRem(39),
        },
    }),
)

export const StyedCollectionListWrapper = styled('div')({
    '& [class*="StyledHeader"]': {
        marginTop: 0,
        marginBottom: calcRem(16),
        paddingBottom: calcRem(6),
    },
})
export const StyedCollectionList = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: calcRem(16),
    [breakpoint('sm')]: {
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoint('md')]: {
        gap: calcRem(8),
        gridTemplateColumns: '1fr',
        [`${StyledLinkWrapper}`]: {
            position: 'relative',
            paddingBottom: calcRem(8),
            '&::after': {
                position: 'absolute',
                content: `''`,
                height: calcRem(1),
                width: calcRem(40),
                background: colors.black10,
                bottom: 0,
            },
            '&:last-child': {
                paddingBottom: 0,
                '&::after': {
                    display: 'none',
                },
            },
            '& [class*="StyledHeadline"]': {
                WebkitLineClamp: 3,
                fontSize: calcRem(14),
                marginBottom: 0,
            },
            '& [class*="StyledTimeToRead"]': {
                margin: 0,
            },
            '& [class*="StyledLiveStatus"]': {
                margin: 0,
            },
        },
    },
    '& [class*="StyledPlayIcon"]': {
        top: 'unset',
        left: 'unset',
        bottom: calcRem(10),
        right: calcRem(10),
        width: calcRem(16),
        height: calcRem(16),
        border: `${calcRem(1.92)} solid ${colors.white}`,
        '&::before': {
            left: '42.5%',
            top: '78%',
            backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
        },
    },
})
