// import React from 'react'
import {
    GetRouteAdTargeting,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { getV2TopicPage } from './get-v2-topic-page'
import { SubTopicData } from './get-subtopic-page-data'

export const getV2SubtopicPage = ({
    topic,
    subTopicData,
    ...props
}: {
    topic: Topic
    subTopicData: SubTopicData
    getAdTargeting: GetRouteAdTargeting
    features: TogglesReduxState
    seoPage?: number | null
    currentUrl?: string
    pathname?: string
}) => {
    const displayFromPartners =
        topic.id.includes('lifestyle/shopping') ||
        topic.id.includes('lifestyle/health-wellbeing') ||
        topic.id.includes('lifestyle/hacks') ||
        topic.id.includes('lifestyle/human-interest')

    const promoCardName = displayFromPartners
        ? 'from-our-partners'
        : subTopicData.promoCardsCuration

    const subTopicPage = getV2TopicPage({
        topic,
        isSubTopic: true,
        contentToFormat: [],
        promoCardName,
        ...props,
    })

    return subTopicPage
}
