import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Store,
} from '@news-mono/web-common'
import { TopicV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../App.routing'
import { breakingNewsComponent } from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAd } from '../shared-components/mobile-ads'
import { allTopics } from './topics'

const getLeaderboardAdSlotId = (section: number) => {
    switch (section) {
        case 0:
            return 'leaderboard-billboard-two'
        case 1:
            return 'leaderboard-billboard-three'
        case 2:
            return 'leaderboard-billboard-four'
        case 3:
            return 'leaderboard-billboard-five'
        case 4:
            return 'leaderboard-billboard-six'
        case 5:
            return 'leaderboard-billboard-seven'
    }
}
const getMobileAd = (section: number) => {
    switch (section) {
        case 0:
            return mobileAd({
                id: 'one',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
        case 1:
            return mobileAd({
                id: 'two',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
        case 2:
            return mobileAd({
                id: 'three',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
        case 3:
            return mobileAd({
                id: 'four',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
        case 4:
            return mobileAd({
                id: 'five',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
        case 5:
            return mobileAd({
                id: 'six',
                mrec: true,
                topPadding: 'unset',
                bottomPadding: 'lg',
            })
    }
}

export const getV2VideoTopicPageRoute = ({
    config,
    getAdTargeting,
    topic,
    store,
}: {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    topic: TopicV4DTO
    store: Store
}): PageType<SevenNewsSection> => {
    const section = 'default'
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )
    // override ad unit path to allow video hub specific sponsorships
    const updatedAdUnitPath = adUnitPath.replace('7news', '7news/video')
    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath: updatedAdUnitPath,
        ssAdUnits,
        topics,
    }

    const isV2LeaderBoardBilbordToggleEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-2-top-leaderboard-ad',
    )

    const isElectionHeaderWidgetEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-qld-election-widgets',
    )

    let renderData: any = []

    const checkTopic = (topic: any) => {
        const top: any = topic.parent ? topic.parent.id : topic.id

        for (let i = 0; i < allTopics.length; i++) {
            if (top == 'travel') {
                renderData = allTopics[0].routes.filter(
                    (item) => item.id != topic.id,
                )
            }
            if (
                top == 'sunrise' ||
                top == 'the-morning-show' ||
                top == 'spotlight'
            ) {
                renderData = allTopics[1].routes.filter(
                    (item) => item.id != topic.id,
                )
            }
            if (top == allTopics[i].id) {
                renderData = allTopics[i].routes.filter(
                    (item) => item.id != topic.id,
                )
            }
        }
        return renderData
    }

    const findTopic = (topic: TopicV4DTO) => {
        if (topic.parent) {
            return topic.parent.id
        } else {
            return topic.id
        }
    }

    const makeTitle = (title: string) => {
        return title.charAt(0).toUpperCase() + title.slice(1)
    }

    const metaTitle = `Videos - Watch the Latest ${makeTitle(
        topic.title,
    )} Videos | 7News `

    const createMetaDescription = () => {
        let metaDescription = ''
        switch (findTopic(topic)) {
            case 'lifestyle':
                metaDescription =
                    "Watch the latest lifestyle videos from Australia and around the world on 7News. Stay up to date with all of the parenting, health & wellbeing, travel, food, shopping, real estate and personal finance video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'entertainment':
                metaDescription =
                    "Watch the latest entertainment videos from Australia and around the world on 7News. Stay up to date with all of the celebrity, TV, music, movies, Sunrise and The Morning Show video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'sport':
                metaDescription =
                    "Watch the latest sport videos from Australia and around the world on 7News. Stay up to date with all of the AFL, NRL, AFLW, Cricket, Motorsport, Football, Rugby Union, Horse Racing, UFC and Tennis video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'business':
                metaDescription =
                    "Watch the latest business videos from Australia and around the world on 7News. Stay up to date with all of the finance, banking, retail, economy and markets video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'technology':
                metaDescription =
                    "Watch the latest tech videos from Australia and around the world on 7News. Stay up to date with all of the tech reviews, gaming, gadgets, space, science and smartphones video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'finance':
                metaDescription =
                    "Watch the latest finance videos from Australia and around the world on 7News. Stay up to date with all of the finance and economy video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'travel':
                metaDescription =
                    "Watch the latest travel videos from Australia and around the world on 7News. Stay up to date with all of the travel and lifestyle video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'weather':
                metaDescription =
                    "Watch the latest weather videos from Australia and around the world on 7News. Stay up to date with all of the weather forecasts, environment, BOM and natural disasters video headlines.  Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'news':
                metaDescription =
                    "Watch the latest news videos from Australia and around the world on 7News. Stay up to date with all of the national, international, local video headlines. Today's news, live updates & all the latest breaking stories from 7News."
                break
            case 'motoring':
                metaDescription =
                    "7News brings you the latest Motoring news from Australia and around the world. Stay up to date with all of the breaking Motoring headlines & news updates. Today's Motoring news, live updates & all the latest breaking stories from 7News."
                break
            default:
                metaDescription = ''
                break
        }
        return metaDescription
    }

    const populateSubTopics: any = () => {
        checkTopic(topic)
        const components: any = []
        let counter = 0

        for (let i = 0; i < renderData.length; i++) {
            if (i % 2 == 0) {
                components.push(
                    layout.component({
                        type: 'horizontal-pill-menu',
                        props: {
                            data: {
                                type: 'videos',
                                topic: renderData[i].id,
                            },
                            verticalGutters: ['md', 'md'],
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                            dataDefinitionArgs: {
                                type: 'video-listing',
                                topics: [renderData[i].id],
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            shouldHideTeaser: true,
                        },
                    }),
                )
            } else {
                const leaderBoardSlotId = getLeaderboardAdSlotId(counter)
                const mobileAd = getMobileAd(counter)
                components.push(
                    layout.component({
                        type: 'horizontal-pill-menu',
                        props: {
                            data: {
                                type: 'videos',
                                topic: renderData[i].id,
                            },
                            verticalGutters: ['md', 'md'],
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                            dataDefinitionArgs: {
                                type: 'video-listing',
                                topics: [renderData[i].id],
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            shouldHideTeaser: true,
                        },
                    }),
                    layout.component({
                        type: 'promo-cards-seven',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'video-hub-promo-cards',
                                offset: counter % 2 == 0 ? 0 : 3,
                                pageSize: 3,
                            },
                        },
                    }),
                    ...(leaderBoardSlotId && mobileAd
                        ? [
                              layout.nestedComposition({
                                  type: 'box',
                                  props: {},
                                  contentAreas: {
                                      main: [
                                          layout.component({
                                              type: 'ad-unit',
                                              props: {
                                                  noticePosition:
                                                      'above-center',
                                                  hiddenUntilLoaded: false,
                                                  padding: [
                                                      0,
                                                      0,
                                                      metrics.sevennews.margins
                                                          .lg,
                                                      0,
                                                  ],
                                                  slot: {
                                                      id: leaderBoardSlotId,
                                                      size: 'leaderboardBillboard',
                                                  },
                                                  adType: 'inline',
                                              },
                                          }),
                                          mobileAd,
                                      ],
                                  },
                              }),
                          ]
                        : []),
                )
                counter++
            }
        }
        return components
    }

    return {
        kind: 'page',
        heading: metaTitle,
        hideHeading: true,
        pageType: 'publication',
        pageMeta: {
            title: metaTitle,
            description: createMetaDescription(),
        },
        socialMeta: {
            title: metaTitle,
            description: createMetaDescription(),
        },
        noMetaTitleTemplate: false,
        adTargeting,
        section: section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    id: '7newsHeaderAd',
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition(
                            box({
                                main: [
                                    ...(isV2LeaderBoardBilbordToggleEnabled
                                        ? []
                                        : [
                                              layout.component({
                                                  type: 'ad-unit',
                                                  props: {
                                                      noticePosition:
                                                          'above-center',
                                                      hiddenUntilLoaded: false,
                                                      preventCollapse: true,
                                                      padding: [
                                                          metrics.sevennews
                                                              .margins.lg,
                                                          0,
                                                          metrics.sevennews
                                                              .margins.sm,
                                                          0,
                                                      ],
                                                      slot: {
                                                          id: 'leaderboard-billboard-one',
                                                          size: 'leaderboardBillboard',
                                                      },
                                                      adType: 'inline',
                                                  },
                                              }),
                                          ]),
                                ],
                            }),
                        ),
                    ],
                },
            }),
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'page-header-v2',
                            props: {
                                sectionHeader: {
                                    heading: `${topic.title} Videos`,
                                },
                            },
                        }),
                    ],
                },
            }),

            layout.composition({
                type: 'box',
                props: {
                    verticalGutters: ['lg', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    main: [
                        breakingNewsComponent,
                        ...(isElectionHeaderWidgetEnabled
                            ? [
                                  layout.component({
                                      type: 'qld-election-header-widget',
                                      props: {
                                          verticalGutters: ['md', 'sm'],
                                      },
                                  }),
                              ]
                            : []),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'videos',
                                    topic: topic.id,
                                },
                            },
                        }),
                        layout.component({
                            type: 'video-hub',
                            props: {
                                adUnitPath: updatedAdUnitPath,
                                verticalGutters: ['unset', 'lg'],
                            },
                        }),
                        ...populateSubTopics(),
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                                isFourColumns: isTabletOrMobileViewport()
                                    ? false
                                    : true,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
