export const getLogoImageUrl = (topic: string) => {
    let image

    try {
        image = require(`../assets/${topic}.svg`)
    } catch (svgError) {
        try {
            image = require(`../assets/${topic}.png`)
        } catch (pngError) {
            image = null
        }
    }

    return image
}
