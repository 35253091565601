import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const MatchActionWidgetContainer = styled('div')({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
    },

    '& .Opta .Opta_C_MA_N .Opta-Cf': {
        '& .Opta-header': {
            '& .Opta-CricketInningsBreakdown .Opta-Team:nth-of-type(1)': {
                backgroundColor: tokens.sevennews.colors.palette.red,

                [breakpoint('sm')]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: calcRem(40),
                    alignItems: 'center',
                },
            },
            '& .Opta-CricketInningsBreakdown .Opta-Team:nth-of-type(2)': {
                backgroundColor: '#A6001E',
                [breakpoint('sm')]: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: calcRem(40),
                    alignItems: 'center',
                },
            },
            '& .Opta-TeamName': {
                fontSize: calcRem(14),
                fontWeight: 700,
                textTransform: 'uppercase',
            },
            '& .Opta-LiveScore': {
                fontSize: calcRem(14),
                fontWeight: 700,
            },
        },
        '& .Opta-match-info': {
            [breakpoint('sm')]: {
                fontSize: calcRem(14),
            },
        },
        '& .Opta-batter-bowler-strip': {
            'abbr, span, div': {
                color: tokens.sevennews.colors.palette.white,
            },
            '& .Opta-Player.Opta-bowler': {
                backgroundColor: tokens.sevennews.colors.palette.red,
                'abbr, span, div': {
                    color: tokens.sevennews.colors.palette.white,
                },
            },
            '& .Opta-Player.Opta-batter': {
                backgroundColor: '#A6001E',
                'abbr, span, div': {
                    color: tokens.sevennews.colors.palette.white,
                },
            },
        },
        '& .Opta-info-overlay': {
            '& .Opta-titleset': {
                '& .Opta-Home': {
                    backgroundColor: tokens.sevennews.colors.palette.red,
                },
                '& .Opta-Away': {
                    backgroundColor: '#A6001E',
                },
            },
            '& .Opta-statset': {
                '& .Opta-Value': {
                    color: tokens.sevennews.colors.palette.black,
                },
            },
        },
    },
})
