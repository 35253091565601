import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const CommentaryWidgetContainer = styled('div')({
    display: 'block',
    maxHeight: calcRem(900),
    overflowY: 'scroll',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
    },

    '& .Opta_C_C_N > h2': {
        display: 'none',
    },

    '& .Opta_C_C_N ul li.Opta-over-end': {
        backgroundColor: '#B6001E80',
        fontSize: calcRem(13),
        textTransform: 'uppercase',
        fontWeight: 600,

        [breakpoint('sm')]: {
            fontSize: calcRem(16),
        },
    },

    '& .Opta_C_C_N ul li.Opta-Event .Opta-comment': {
        p: {
            fontSize: calcRem(13),

            [breakpoint('sm')]: {
                fontSize: calcRem(16),
            },
        },
    },

    '& .Opta_C_C_N ul.Opta-Striped li.Opta-over-end ~ li.Opta-Event:nth-child(2n+1)':
        {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    '& .Opta_C_C_N ul.Opta-Striped li.Opta-over-end ~ li.Opta-Event:nth-child(2n)':
        {
            backgroundColor: 'unset',
        },
})
