import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    FixturesScoreboardWidget,
    FixturesScoreboardWidgetProps,
} from './FixturesScoreboardWidget'

export const FixturesScoreboardWidgetRegistration = createRegisterableComponent(
    'fixtures-scoreboard',
    (props: FixturesScoreboardWidgetProps, services) => {
        return <FixturesScoreboardWidget {...props} />
    },
)
