import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { breakpoint, calcRem, colors, fonts } from '../../__styling'

export const CouponsPartnershipContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(18),
}))

export const CouponsPartnershipMainInfo = styled('h1')(() => ({
    marginBottom: calcRem(32),
    fontSize: calcRem(36),
    fontWeight: 700,
}))

export const CouponsPartnershipHeading = styled('h2')(() => ({
    fontWeight: 700,
    fontSize: calcRem(24),
    marginTop: calcRem(48),
    marginBottom: calcRem(24),
}))

export const CouponsPartnershipText = styled('p')(() => ({
    marginBottom: calcRem(20),
    marginTop: 0,
    padding: 0,
    strong: {
        fontWeight: 700,
    },
}))

export const CouponsPartnershipLink = styled(WebLink)(() => ({
    marginBottom: calcRem(25),
    fontWeight: 700,
}))

export const CouponsPartnershipPowerContainer = styled('div')(() => ({
    display: 'flex',
    gap: calcRem(16),
    flexDirection: 'column',
    [breakpoint('sm')]: {
        flexDirection: 'row',
    },
}))
export const CouponsPartnershipPower = styled('div')(() => ({
    position: 'relative',
    borderRadius: calcRem(4),
    width: '100%',
}))
export const CouponsPartnershipPowerHeader = styled('div')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    borderTopLeftRadius: calcRem(4),
    borderTopRightRadius: calcRem(4),
    backgroundColor: colors.black,
    color: colors.white,
    paddingTop: calcRem(4),
    paddingBottom: calcRem(4),
    opacity: 1,
    textAlign: 'center',
}))
export const CouponsPartnershipPowerImgWrapper = styled('div')(() => ({
    marginTop: calcRem(16),
}))
export const CouponsPartnershipPowerImg = styled('img')(() => ({
    width: '100%',
}))
export const CouponsPartnershipUl = styled('ul')(() => ({
    listStyleType: 'disc',
    marginTop: calcRem(20),
    marginBottom: calcRem(20),
}))
export const CouponsPartnershipLi = styled('li')(() => ({
    marginTop: calcRem(8),
    padding: 0,
    strong: {
        fontWeight: 700,
    },
    '&::marker': {
        color: 'rgb(209, 213, 219)',
    },
}))
export const NumberOfRetailersContainer = styled('div')(() => ({
    display: 'flex',
    gap: calcRem(16),
    flexDirection: 'column',
    [breakpoint('sm')]: {
        flexDirection: 'row',
    },
}))
export const NumberOfRetailersBox = styled('div')(() => ({
    backgroundColor: ' rgb(229, 231, 235)',
    padding: calcRem(32),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}))
export const NumberOfRetailerHeader = styled('h3')(() => ({
    fontWeight: 700,
    marginBottom: calcRem(16),
    fontSize: calcRem(20),
}))
export const NumberOfRetailerParagraph = styled('p')(() => ({
    fontSize: calcRem(20),
    lineHeight: calcRem(28),
    marginBottom: calcRem(20),
}))

export const CouponsPartnershipDisclaimerWrapper = styled('p')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(6),
}))

export const CouponsPartnershipDisclaimerText = styled('p')(() => ({
    fontSize: calcRem(14),
}))
