import React, { useEffect, useState } from 'react'
import {
    StyledResetPasswordLink,
    StyledShowHidePasswordToggle,
    StyledSignInContainer,
    StyledSignInDescription,
    StyledSignInError,
    StyledSignInEyeIcon,
    StyledSignInForm,
    StyledSignInFormContainer,
    StyledSignInFormInnerContainer,
    StyledSignInHeading,
    StyledSignInHeadingIcon,
    StyledSignInHero,
    StyledSignInInput,
    StyledSignInLabel,
    StyledSignInSubmitButton,
    StyledSignUpCTA,
    StyledSignUpCTALink,
} from './SignIn.styled'
import { useGigyaService } from '../authentication/GigyaAuthenticationProvider'
import { LoadingEllipsis } from '../../../LoadingEllipsis'

const signInHero = require('./assets/hero_desktop.png')
const sevenPlusIcon = require('./assets/7plus.svg')
const eyeIcon = require('./assets/EYE.svg')
const eyeIconDark = require('./assets/EYEdark.svg')

export const SignIn = () => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    )
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [passwordInputType, setPasswordInputType] = useState<
        'password' | 'text'
    >('password')
    const authService = useGigyaService()

    useEffect(() => {
        if (email !== '' && password !== '') {
            setIsSubmitDisabled(false)
        } else {
            setIsSubmitDisabled(true)
        }
    }, [email, password])

    const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
        setErrorMessage(undefined)
    }

    const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
        setErrorMessage(undefined)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const loginResult = await authService?.onLoginClick(
            {
                email: event.currentTarget.email.value,
                password: event.currentTarget.password.value,
            },
            setIsLoading,
        )
        if (!loginResult || (loginResult && loginResult.statusCode !== 200)) {
            setErrorMessage('Incorrect email or password!')
        }
    }

    const toggleShowHidePassword = () => {
        if (passwordInputType === 'password') {
            setPasswordInputType('text')
        } else {
            setPasswordInputType('password')
        }
    }

    return (
        <StyledSignInContainer>
            <StyledSignInHero
                src={signInHero}
                alt={'Sign in page hero image'}
            />
            <StyledSignInFormContainer>
                <StyledSignInFormInnerContainer>
                    <StyledSignInHeading>
                        Sign in using your{' '}
                        <StyledSignInHeadingIcon src={sevenPlusIcon} /> account
                    </StyledSignInHeading>
                    <StyledSignInDescription>
                        In order to subscribe to 7NEWS news newsletters, first
                        sign in with your 7plus account
                    </StyledSignInDescription>
                    <StyledSignInForm
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <StyledSignInLabel>
                            <StyledSignInInput
                                name="email"
                                value={email}
                                placeholder="Email"
                                onChange={onEmailChange}
                                autoComplete={'off'}
                            />
                        </StyledSignInLabel>
                        <StyledSignInLabel>
                            <StyledSignInInput
                                name="password"
                                value={password}
                                type={passwordInputType}
                                placeholder="Password"
                                onChange={onPasswordChange}
                                autoComplete={'off'}
                            />
                            <StyledShowHidePasswordToggle
                                onClick={toggleShowHidePassword}
                            >
                                <StyledSignInEyeIcon
                                    src={
                                        passwordInputType === 'password'
                                            ? eyeIcon
                                            : eyeIconDark
                                    }
                                />
                            </StyledShowHidePasswordToggle>
                            {errorMessage && (
                                <StyledSignInError>
                                    {errorMessage}
                                </StyledSignInError>
                            )}
                        </StyledSignInLabel>
                        <StyledSignInSubmitButton
                            type="submit"
                            disabled={isSubmitDisabled}
                        >
                            {isLoading ? <LoadingEllipsis /> : 'Sign in'}
                        </StyledSignInSubmitButton>
                        {errorMessage && (
                            <StyledResetPasswordLink
                                target={'_blank'}
                                href={
                                    process.env.NODE_ENV !== 'production'
                                        ? 'https://webalt-staging.inferno.digital/?logout=true&utm_source=7NEWS&utm_medium=newsletter-link&utm_campaign=7plus-register-7news-newsletter&overlay=forgot-password'
                                        : 'https://7plus.com.au/?overlay=forgot-password&utm_source=7NEWS&utm_medium=newsletter-link&utm_campaign=7plus-register-7news-newsletter'
                                }
                            >
                                Forgot your 7plus email or password?
                            </StyledResetPasswordLink>
                        )}
                    </StyledSignInForm>
                    <StyledSignUpCTA>
                        Don’t have a 7plus account? Set one up{' '}
                        <StyledSignUpCTALink
                            target={'_blank'}
                            href={
                                process.env.NODE_ENV !== 'production'
                                    ? 'https://webalt-staging.inferno.digital/?logout=true&overlay=register&utm_source=7NEWS&utm_medium=newsletter-link&utm_campaign=7plus-register-7news-newsletter'
                                    : 'https://7plus.com.au/?logout=true&overlay=register&utm_source=7NEWS&utm_medium=newsletter-link&utm_campaign=7plus-register-7news-newsletter'
                            }
                        >
                            here
                        </StyledSignUpCTALink>
                    </StyledSignUpCTA>
                </StyledSignInFormInnerContainer>
            </StyledSignInFormContainer>
        </StyledSignInContainer>
    )
}
