import { breakpoints, useWindowWidth } from '@news-mono/component-library'
import { closeNavigation } from '@news-mono/web-common'
import React from 'react'
import { useDispatch } from 'react-redux'

export const CloseNavOnDesktop: React.FC = () => {
    const dispatch = useDispatch()
    const windowWidth = useWindowWidth()
    React.useEffect(() => {
        if (!windowWidth) return

        if (windowWidth > breakpoints.md) {
            dispatch(closeNavigation())
        }
    }, [windowWidth, dispatch])

    return null
}
