import React, { useState } from 'react'
import { A11y, Keyboard, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { isTabletOrMobileViewport } from '../../__helpers'
import { ShareIcon } from '../HeroCard/ArticleHeroCard/assets/ShareIcon'
import { Share } from '../HeroCard/Share/Share'
import {
    StyledAppImage,
    StyledCopy,
    StyledLogo,
    StyledAppDetailsWrapper,
    StyledSevenAppHeroContent,
    StyledSevenAppHeroContentHeader,
    StyledSevenAppHeroMedia,
    StyledSevenAppHeroWrapper,
    StyledShareIconWrapper,
    StyledShareWrapper,
    StyledDownloadMobileButtons,
    StyledLink,
    StyledStoreImage,
    StyledDownload,
    StyledDetailsParagraph,
    StyledEmphasizedSpan,
    Styled7NewsSupportLink,
    StyledSevenAppHeroContentHeaderMobile,
    StyledLogoMobile,
    StyledDetailsParagrapMobile,
} from './SevenNewsApp.styled'

const sevenLogo = require('./assets/7newslogo.png')

const playStoreIcon = require('./assets/playStore.svg')
const appStoreIcon = require('./assets/appStore.svg')

const appImage = require('./assets/appImage.jpg')
export const SevenNewsApp = () => {
    const [showSharePopup, setShowSharePopup] = useState(false)
    const isMobileOrTablet = isTabletOrMobileViewport()

    const handleShare = async () => {
        if (isMobileOrTablet) {
            if (navigator && typeof navigator.share !== 'undefined') {
                try {
                    await navigator.share({
                        url: '',
                        text: '',
                    })
                    setShowSharePopup(false)
                } catch (e) {
                    setShowSharePopup(false)
                    console.error(e, 'Error while sharing')
                }
            } else {
                setShowSharePopup((prev) => !prev)
            }
        } else {
            setShowSharePopup((prev) => !prev)
        }
    }

    return (
        <>
            <StyledSevenAppHeroContentHeaderMobile>
                <StyledShareWrapper isOpened={showSharePopup}>
                    <Share
                        url="https://7news.com.au/app"
                        text="Download the 7NEWS app today"
                        onEvent={() => {}}
                    />
                </StyledShareWrapper>
                <StyledShareIconWrapper
                    isOpened={showSharePopup}
                    onClick={handleShare}
                >
                    <ShareIcon />
                </StyledShareIconWrapper>
            </StyledSevenAppHeroContentHeaderMobile>
            <StyledLogoMobile src={sevenLogo} />
            <StyledSevenAppHeroWrapper>
                <StyledSevenAppHeroContent>
                    <StyledSevenAppHeroContentHeader>
                        <StyledShareWrapper isOpened={showSharePopup}>
                            <Share
                                url="https://7news.com.au/app"
                                text="Download the 7NEWS app today"
                                onEvent={() => {}}
                            />
                        </StyledShareWrapper>
                        <StyledShareIconWrapper
                            isOpened={showSharePopup}
                            onClick={handleShare}
                        >
                            <ShareIcon />
                        </StyledShareIconWrapper>
                    </StyledSevenAppHeroContentHeader>
                    <StyledLogo src={sevenLogo} />
                    <StyledCopy>
                        {' '}
                        Stay informed with the free 7NEWS app.
                    </StyledCopy>
                    <StyledDownload>
                        <StyledDownloadMobileButtons>
                            <StyledLink to="https://play.google.com/store/apps/details?id=com.swm.news">
                                <StyledStoreImage src={playStoreIcon} />
                            </StyledLink>
                            <StyledLink to="https://apps.apple.com/au/app/id6471218774">
                                <StyledStoreImage src={appStoreIcon} />
                            </StyledLink>
                        </StyledDownloadMobileButtons>
                    </StyledDownload>
                    <StyledAppDetailsWrapper>
                        <StyledDetailsParagraph>
                            The 7NEWS app brings you the latest local news from
                            Australia and around the world. Stay up to date with
                            all of the breaking sport, lifestyle, entertainment,
                            finance, politics and business headlines. Today's
                            news, live updates & all the latest breaking stories
                            from 7NEWS all available in one place.
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            <StyledEmphasizedSpan>
                                Personalise
                            </StyledEmphasizedSpan>{' '}
                            your 7NEWS app experience by choosing your favourite
                            topics of interest.
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            <StyledEmphasizedSpan>Watch</StyledEmphasizedSpan>{' '}
                            curated videos from your favourite shows like
                            Sunrise, The Morning Show, The Voice and more. text3
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            <StyledEmphasizedSpan>Live </StyledEmphasizedSpan>{' '}
                            sport and fixtures across AFL, AFLW and Cricket.
                            Never miss a stat, team or player update via the
                            7NEWS app Sport hub. Follow live matches via the
                            7Sport Match Centre and catch up on your favourite
                            7AFL, AFLW and Cricket news and shows.
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            <StyledEmphasizedSpan>Deals</StyledEmphasizedSpan>,
                            offers, competitions and more. Be the first to know
                            the latest products you can get your hands on right
                            now. Our team of experts hand select everything,
                            providing reviews, advice and first-person
                            recommendations to help you with your next purchase.
                        </StyledDetailsParagraph>

                        <StyledDetailsParagraph>
                            Get{' '}
                            <StyledEmphasizedSpan>
                                notifications
                            </StyledEmphasizedSpan>
                            , on breaking stories and follow rolling coverage
                            via 7NEWS live blogs.
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            Switch to{' '}
                            <StyledEmphasizedSpan>
                                {' '}
                                dark mode{' '}
                            </StyledEmphasizedSpan>{' '}
                            , and save battery life (not to mention the eye
                            strain late at night).
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            Have questions or feedback on the 7NEWS app?
                        </StyledDetailsParagraph>
                        <StyledDetailsParagraph>
                            Reach out to us here to let us{' '}
                            <Styled7NewsSupportLink href="https://support.7news.com.au/hc/en-au">
                                here
                            </Styled7NewsSupportLink>{' '}
                            to let us know.
                        </StyledDetailsParagraph>
                        <StyledDetailsParagrapMobile>
                            Download the 7NEWS app now.
                        </StyledDetailsParagrapMobile>
                    </StyledAppDetailsWrapper>
                </StyledSevenAppHeroContent>
                <StyledSevenAppHeroMedia>
                    <StyledAppImage src={appImage} />
                </StyledSevenAppHeroMedia>
            </StyledSevenAppHeroWrapper>
        </>
    )
}
