import React from 'react'
import { StyledClearButton, StyledCrossIcon } from './SearchForm.styled'
import { createRenderTarget } from '../../../render-target'
export interface ClearButtonProps {
    onClick: () => void
    hide: boolean

    isLarge: boolean
}
const ClearButtonWeb: React.FC<ClearButtonProps> = ({
    onClick,
    hide,
    isLarge,
}) => {
    return (
        <StyledClearButton
            type="reset"
            onClick={onClick}
            hide={hide}
            isLarge={isLarge}
        >
            <StyledCrossIcon aria-label="Clear Search Input" />
        </StyledClearButton>
    )
}
ClearButtonWeb.displayName = 'ClearButtonWeb'
const ClearButtonAmp: React.FC<ClearButtonProps> = ({ hide, isLarge }) => {
    return (
        <StyledClearButton
            type="reset"
            hide={hide}
            isLarge={isLarge}
            on="tap:search-form.toggleClass(class='isActive'),
            search-input.toggleClass(class='isActive'), 
            search-container.toggleClass(class='isActive'), 
            logo-wrapper.toggleClass(class='hide'), 
            header-timestamp.toggleClass(class='toggleDisplay'),
            search-button.toggleClass(class='isInactive'),
            search-button-active.toggleClass(class='isActive'),
            clear-button.toggleClass(class='isActive')"
            id="clear-button"
        >
            <StyledCrossIcon aria-label="Clear Search Input" />
        </StyledClearButton>
    )
}
ClearButtonAmp.displayName = 'SearchButtonAmp'
export const ClearButton = createRenderTarget<ClearButtonProps>('ClearButton', {
    web: ClearButtonWeb,
    amp: ClearButtonAmp,
    rss: null,
    preview: ClearButtonWeb,
    app: null,
})
