import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import {
    AppState,
    BaseClientConfig,
    clipStringToLength,
    getBaseUrl,
    GetRouteAdTargeting,
    getSectionMetaInfo,
    PageType,
} from '@news-mono/web-common'
import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import { AnyAction, Store } from 'redux'
import { layout } from '../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAdTwo } from '../shared-components/mobile-ads'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { getProfilePageLdJson } from '@news-mono/common'

export interface Props {
    profile: ProfileV4DTO
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
    section: SevenNewsSection
    store: Store<AppState, AnyAction>
}

export const getProfilePageRouteInfo = ({
    profile,
    section,
    config,
    getAdTargeting,
    location,
    store,
}: Props): PageType<SevenNewsSection> => {
    const adTargeting = getAdTargeting('profile', section, profile.slug)
    const baseUrl = getBaseUrl(
        config.publicUrl,
        getSectionMetaInfo(store.getState().meta.sectionMeta, section),
    )
    const profileUrl = `${baseUrl}${location.pathname}`

    // Profiles need point back to the author's canonical site
    const canonicalUrl = profile.canonicalSite
        ? `${profile.canonicalSite}${location.pathname}`
        : profileUrl

    const isV2LeaderBoardBillboardToggleEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-2-top-leaderboard-ad',
    )
    const profileContentListing = [
        layout.component({
            type: 'six-card-collection',
            props: {
                verticalSpacing: 'lg',
                verticalGutters: ['unset', 'md'],
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: [],
                    authors: [profile.slug],
                    paging: {
                        page: 1,
                        pageSize: 6,
                    },
                },
            },
        }),
        layout.component({
            type: 'five-card-collection',
            props: {
                verticalSpacing: 'lg',
                verticalGutters: ['unset', 'md'],
                loadMoreEnabled: true,
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: [],
                    authors: [profile.slug],
                    paging: {
                        page: 1,
                        pageSize: 5,
                        pageOffset: 6,
                    },
                },
            },
        }),
    ]

    return {
        kind: 'page',
        heading: 'Profiles',
        hideHeading: true,
        pageType: 'profile',
        pageMeta: {
            title: `${profile.name} - ${profile.position}`,
            description: profile.biography
                ? clipStringToLength(profile.biography, 160)
                : 'Journalist profile from 7News.com.au',
            link: [
                {
                    rel: 'canonical',
                    href: canonicalUrl,
                },
            ],
            ...(profile.mainPhoto && {
                meta: [
                    {
                        property: 'og:image',
                        content: profile.mainPhoto,
                    },
                    {
                        name: 'twitter:image',
                        content: profile.mainPhoto,
                    },
                    {
                        property: 'og:image:height',
                        content: '861',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                ],
            }),
            ldJson: getProfilePageLdJson(
                profile,
                config.publicUrl,
                '7News.com.au',
            ),
        },
        socialMeta: {
            title: `${profile.name} | ${profile.position}`,
            description: profile.biography
                ? clipStringToLength(profile.biography, 160)
                : 'Journalist profile from 7News.com.au',
        },
        adTargeting,
        section,
        compositions: [
            ...(isV2LeaderBoardBillboardToggleEnabled ? [] : [headerAd()]),
            breakingNews,
            box({
                propOverrides: {
                    verticalGutters: ['lg', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                main: [
                    layout.component({
                        type: 'profile',
                        props: { profile, profileUrl },
                    }),
                    ...profileContentListing,
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'md',
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,
                                        padding: [
                                            metrics.sevennews.margins.md,
                                            0,
                                            0,
                                            0,
                                        ],
                                        slot: {
                                            id: 'leaderboard-billboard-two',
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                                mobileAdTwo,
                            ],
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: { verticalSpacing: 'lg' },
                    }),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                            isFourColumns: isTabletOrMobileViewport()
                                ? false
                                : true,
                        },
                    }),
                ],
            }),
        ],
    }
}
