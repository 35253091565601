import React from 'react'
import { WagonWheelWidgetContainer } from './WagonWheelWidget.styled'

export interface WagonWheelWidgetProps {
    competition: string
    season: string
    match: string
}
export const WagonWheelWidget = ({
    competition,
    season,
    match,
}: WagonWheelWidgetProps) => {
    return (
        <WagonWheelWidgetContainer>
            <opta-widget
                widget="wagonwheel"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                show_match_header="false"
                show_crests="false"
                show_state_of_play="false"
                show_team_sheets="true"
                show_innings="all"
                side="both"
                show_key="true"
                show_runs_summary="true"
                show_segments="true"
                show_tooltips="true"
                team_naming="full"
                player_naming="full"
                show_logo="false"
                show_live="false"
                show_title="false"
                breakpoints="400, 9999"
                sport="cricket"
            ></opta-widget>
        </WagonWheelWidgetContainer>
    )
}
