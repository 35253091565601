import { createRegisterableComponent } from '@news-mono/web-common'

import React from 'react'
import { ArticleImageCaption } from './ArticleImageCaption'

export interface ArticleImageCaptionProps {
    captionText?: string
}

export const ArticleImageCaptionRegistration = createRegisterableComponent(
    'sevennews-article-image-caption',

    (props: ArticleImageCaptionProps, services) => {
        return <ArticleImageCaption captionText={props.captionText} />
    },
)
