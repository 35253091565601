import { NavItem } from '@news-mono/web-common'
import React from 'react'
import {
    StyledNavItem,
    StyledNavItemText,
    StyledSVG,
} from './SevenNewsFlyoutNav2.styled'
import { StyledNavLinkAMP } from '../SevenNewsFlyoutNavV2/SevenNewsFlyoutNav2.styled'

export interface SevenNewsFlyoutNavProps {
    navItem: NavItem
}

export const SevenNewsFlyoutNavItemAMP: React.FC<SevenNewsFlyoutNavProps> = ({
    navItem,
}) => {
    return (
        <StyledNavItem role="menuitem">
            <StyledNavLinkAMP to={navItem.link}>
                <StyledNavItemText>
                    {navItem.name} {navItem.linkOut && <StyledSVG />}
                </StyledNavItemText>
            </StyledNavLinkAMP>
        </StyledNavItem>
    )
}

SevenNewsFlyoutNavItemAMP.displayName = 'SevenNewsFlyoutNavItemAMP'
