import styled from '@emotion/styled'
import {
    breakpointMax,
    calcRem,
    colors,
    metrics,
} from '@news-mono/component-library'

export interface Props {
    isAmpRendered?: boolean
}

export const footerAdOffset = calcRem(80)
export const footerAdOffsetAmp = calcRem(30)

export const StyledBaseGrid = styled('div')<Props>(
    {
        margin: '0 auto',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '100%',
        backgroundColor: colors.white,

        // If grid is supported use css grid
        [breakpointMax('sm')]: {
            marginBottom: footerAdOffset,
        },
    },
    (props) => ({
        maxWidth:
            props.isAmpRendered === true
                ? metrics.sevennews.ampContentWidth
                : undefined,
    }),
)

export const StyledBaseGridAmp = styled(StyledBaseGrid)<Props>({
    // If grid is supported use css grid
    [breakpointMax('md')]: {
        marginBottom: footerAdOffsetAmp,
    },
})

export const StyledAmpContentContainer = styled('div')({
    width: '100%',
})

export const StyledAmpContent = styled('div')({
    padding: calcRem(metrics.sevennews.margins.md),
    backgroundColor: colors.white,
})

interface CellProps {
    paddingTop?: number
}
export const StyledCell = styled('div')<CellProps>(({ paddingTop }) => ({
    display: 'block',
    paddingTop: paddingTop ? calcRem(paddingTop) : undefined,
}))

export const StyledNestedGrid = styled('div')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
})

export interface StyledNestedCellProps {
    disablePadding?: boolean
    fullWidth?: boolean
    isTransparentBg?: boolean
}

export const StyledNestedCell = styled('div')<StyledNestedCellProps>(
    ({ disablePadding, fullWidth, isTransparentBg }) => ({
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: fullWidth
            ? '100%'
            : metrics.sevennews.siteMetrics.mainContentWidth,
        padding: disablePadding
            ? undefined
            : `0 ${calcRem(metrics.sevennews.outerMargin)}`,
        margin: '0 auto',
        backgroundColor: isTransparentBg ? 'transparent' : colors.white,
    }),
)

export const StyledFooter = styled('footer')({
    marginTop: calcRem(metrics.sevennews.margins.xl),
    background: colors.sevennews.seashell,
    padding: calcRem(16, 0),
})
