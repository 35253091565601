import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ManhattanWidget, ManhattanWidgetProps } from './ManhattanWidget'

export const ManhattanWidgetRegistration = createRegisterableComponent(
    'manhattan-widget',
    (props: ManhattanWidgetProps, services) => {
        return <ManhattanWidget {...props} />
    },
)
