import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { StandingsWidget, StandingsWidgetProps } from './StandingsWidget'

export const StandingsWidgetRegistration = createRegisterableComponent(
    'standings-widget',
    (props: StandingsWidgetProps, services) => {
        return <StandingsWidget {...props} />
    },
)
