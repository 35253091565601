import styled from '@emotion/styled'
import { Sharing } from '../../../buttons/Sharing/Sharing'
import { fixedPlaylistHeightCompact } from '../../../content/Video/components/VideoPlaylist/VideoPlaylist.styled'
import { stickyVideoClassName } from '../../../publication/ArticleVideo/ArticleVideo.styled'
import { styledListClassName } from '../../../typography/List/List.styled'
import { metrics } from '../../../__styling/settings/metrics'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'

const gridGapSize = metrics.sevennews.margins.md
const largeGapPadding = metrics.sevennews.margins.lg

export const StyledGrid = styled('article')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: calcRem(gridGapSize),
})

export const StyledCell = styled('div')<{ largeGap?: boolean }>(
    ({ largeGap }) => ({
        padding: largeGap === true ? `0 0 ${calcRem(largeGapPadding)} 0` : 0,
    }),
)

export const StyledVideoCell = styled('div')<{ cellHeight?: number }>({
    '--stickyContainerHeight': 0,
    minHeight: `var(--stickyContainerHeight)`,
})

export const StyledNestedGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: calcRem(gridGapSize),

    [breakpoint('md')]: {
        gridTemplateColumns: '40px auto',
    },

    '@media print': {
        display: 'block',
    },
})

export const SevenNewsStyledSharing = styled(Sharing)({
    position: 'sticky',
    top: metrics.sevennews.margins.xxl, // pixel value to prevent rounding
})

export const StyledArticleContent = styled('div')({
    /*
            - Adds the horizontal padding to the article content
            - Excludes the figure component so we can center images & video of unknown width
              but also have the image asset full bleed to the edge on desktop.
        */
    width: '100%',
    gridColumn: 2,

    '& *:last-child': {
        marginBottom: 0,
        zIndex: 0,
    },

    // If the last item in an article is an image then remove the border on the figcaption so it doesn't double up with topics
    '& figure:last-child figcaption': {
        borderBottom: 'none',
    },

    // Fix for SWM-4447
    '& .twin-image-wrapper': {
        marginBottom: calcRem(metrics.sevennews.margins.md),

        [breakpoint('sm')]: {
            marginBottom: 0,
        },
    },

    // TODO replace with component not exported classname
    [`& .${styledListClassName}`]: {
        paddingLeft: calcRem(metrics.sevennews.margins.lg),
    },
})

const condensedStickyVideoWidth = 321
const condensedStickyVideoWidthEnt = 300
const condensedStickyVideoHeight = 180 + fixedPlaylistHeightCompact
export interface StyledStickyProps {
    largeGap?: boolean
}

export const StyledStickyCell = styled('div')<StyledStickyProps>(
    ({ largeGap }) => ({
        display: 'block',
        [`&.${stickyVideoClassName}`]: {
            left: `calc(51vw - ((100vw - 100%)/2) + ${
                condensedStickyVideoWidth / 2
            }px)`,
            position: 'fixed',
            top: `calc(100vh - ${condensedStickyVideoHeight}px)`,
            width: condensedStickyVideoWidth,
            zIndex: zIndex.common.stickyVideo,
            pointerEvents: 'auto',
            paddingBottom: 0,
        },
        [breakpoint('sm')]: {
            padding:
                largeGap === true ? `0 0 ${calcRem(largeGapPadding)} 0` : 0,
        },
    }),
)
StyledStickyCell.displayName = 'StickyContainer'

interface StyledEntStickyCellProps {
    isGallery?: boolean
}
export const StyledEntStickyCell = styled('div')<StyledEntStickyCellProps>(
    ({ isGallery }) => ({
        display: isGallery ? 'flex' : 'block',
        gap: calcRem(20),
    }),
)
StyledEntStickyCell.displayName = 'EntStickyContainer'

export const StyledEntStickyCellWrapper = styled('div')({
    display: 'block',
    [`&.${stickyVideoClassName}`]: {
        width: '100%',
        position: 'fixed',
        maxWidth: calcRem(
            metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
        ),
        height: condensedStickyVideoHeight,
        bottom: calcRem(10),
        margin: '0 auto',
        left: 0,
        right: 0,
        padding: calcRem(0, metrics.sevennews.margins.xl),
        zIndex: 1001, //turskin ad have zindex 1000, currently this is only way to enable user to close sticky video
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        [`${StyledEntStickyCell}`]: {
            width: condensedStickyVideoWidthEnt,
        },
    },
})

export const StyledStickyTop = styled('div')({
    height: 1,
})
StyledStickyTop.displayName = 'StickyWatcher'
