import {
    CollectionDataLoaded,
    DataLoaderGlobalParams,
    useOnScreen,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { RefObject, useEffect, useRef } from 'react'
import { useSearchInfiniteScroll } from './useInfiniteScroll'
import { StyledInfiniteScrollContainer } from './InfiniteScroll.styled'
import { useMetadata } from '../../SevenNewsV2/collections/SixCardCollection/useMetaData'
import {
    CurrentPageStyled,
    PaginationLinkStyled,
    PaginationLinksWrapperStyled,
} from '../../SevenNewsV2/collections/SixCardCollection/SixCardCollection.styled'
import { LoadingEllipsis } from '../../LoadingEllipsis'
import { SearchDataLoaderConfig7News } from '../../SevenNewsV2'

export interface InfiniteScrollRouteProps {
    loadMoreEnabled?: boolean
}

interface InfiniteScrollProps {
    dataDefinition: SearchDataLoaderConfig7News
    data: MaybeLoaded<CollectionDataLoaded>
    services: DataLoaderGlobalParams
    render: (
        data: MaybeLoaded<CollectionDataLoaded>,
    ) => JSX.Element | JSX.Element[]
    isVisible?: boolean
    ref?: RefObject<HTMLDivElement> | null
    currentPage?: number | null
    setCurrentPage: (v: number | undefined) => void
    searchTerm: string
    currentUrl?: string
    pathname?: string
}

export const SearchInfiniteScroll = ({
    dataDefinition: initialDataDefinition,
    data: initialData,
    services,
    render,
    currentPage,
    setCurrentPage,
    searchTerm,
    currentUrl,
    pathname,
}: InfiniteScrollProps) => {
    const { loadMore, isLoading, data, dataDefinition } =
        useSearchInfiniteScroll(initialDataDefinition, initialData, services)

    const isLocalEnv = currentUrl?.includes('localhost')
    const http = isLocalEnv ? 'http' : 'https'
    const ref = useRef<HTMLDivElement>(null)

    const isInViewport = useOnScreen(ref)

    const prevLink =
        (currentPage as number) > 1
            ? `${http}://${currentUrl}${pathname}?search=${searchTerm}&page=${
                  (currentPage as number) - 1
              }`
            : null

    const nextLink = `${http}://${currentUrl}${pathname}?search=${searchTerm}&page=${
        (currentPage as number) + 1
    }`

    const canonicalUrl = `${http}://${currentUrl}${pathname}?search=${searchTerm}&page=${currentPage}`

    useEffect(() => {
        if (
            isInViewport &&
            data.loaded &&
            data.result.loadMorePossible &&
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.options.paging &&
            !isLoading
        ) {
            loadMore(data)
        }
    }, [
        isInViewport,
        data,
        loadMore,
        dataDefinition,
        isLoading,
        setCurrentPage,
        currentUrl,
    ])

    function infiniteScrollArea() {
        if (
            data.loaded &&
            data.result.loadMorePossible &&
            dataDefinition &&
            dataDefinition.type === 'listing' &&
            dataDefinition.options.paging &&
            data.result.publications.length > 0
        ) {
            return (
                <>
                    <StyledInfiniteScrollContainer ref={ref}>
                        {isLoading ? <LoadingEllipsis color="grey" /> : null}
                    </StyledInfiniteScrollContainer>
                    <noscript>
                        <PaginationLinksWrapperStyled>
                            {prevLink && (
                                <PaginationLinkStyled
                                    href={prevLink}
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                    Previous
                                </PaginationLinkStyled>
                            )}
                            <CurrentPageStyled>{currentPage}</CurrentPageStyled>
                            {nextLink && (
                                <PaginationLinkStyled
                                    href={nextLink}
                                    onClick={(e) => {
                                        e.preventDefault()
                                    }}
                                >
                                    Next
                                </PaginationLinkStyled>
                            )}
                        </PaginationLinksWrapperStyled>
                    </noscript>
                </>
            )
        }
        return null
    }

    useMetadata({ canonicalUrl, prevLink, nextLink })

    return (
        <>
            {render(data)}
            {infiniteScrollArea()}
        </>
    )
}
