export interface SubTopicData {
    trendingTitle: string
    trendingCuration: string
    promoCardsCuration: string
    //Some topics (eg. Technology) is listing instead of curation
    //Without flag in that situation subtopic page will crash
    isListingTopic?: boolean
}

export interface SevenNewsSubtopic {
    id: string
    data: SubTopicData
}

export function getSubtopicPageData(topic: string): SevenNewsSubtopic | null {
    const sevenSubTopic: SevenNewsSubtopic[] = [
        {
            id: 'lifestyle',
            data: {
                trendingTitle: 'The Latest',
                trendingCuration: 'lifestyle',
                promoCardsCuration: '7news-lifestyle-promo-cards',
            },
        },
        {
            id: 'travel',
            data: {
                trendingTitle: 'The Latest',
                trendingCuration: 'lifestyle',
                promoCardsCuration: '7news-lifestyle-promo-cards',
            },
        },
        {
            id: 'entertainment',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'entertainment',
                promoCardsCuration: '7news-entertainment-promo-cards',
            },
        },
        {
            id: 'sunrise',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'entertainment',
                promoCardsCuration: '7news-entertainment-promo-cards',
            },
        },
        {
            id: 'the-morning-show',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'entertainment',
                promoCardsCuration: '7news-entertainment-promo-cards',
            },
        },
        {
            id: 'politics',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'politics',
                promoCardsCuration: '7news-politics-promo-cards',
            },
        },
        {
            id: 'sport/aflw',
            data: {
                trendingTitle: 'Latest in sport',
                trendingCuration: 'sport',
                promoCardsCuration: 'aflw-promo-cards',
            },
        },
        {
            id: 'sport',
            data: {
                trendingTitle: 'Latest in sport',
                trendingCuration: 'sport',
                promoCardsCuration: 'sport-promo-cards',
            },
        },
        {
            id: 'technology',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'technology',
                promoCardsCuration: '7news-tech-promo-cards',
                isListingTopic: true,
            },
        },
        {
            id: 'weather',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'weather',
                promoCardsCuration: '7news-weather-promo-cards',
                isListingTopic: true,
            },
        },
        {
            id: 'business',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'business/finance',
                promoCardsCuration: '7news-finance-promo-cards',
                isListingTopic: true,
            },
        },
        {
            id: 'news',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'news',
                promoCardsCuration: '7news-home-promo-cards',
                isListingTopic: true,
            },
        },
        {
            id: 'best-picks',
            data: {
                trendingTitle: 'Latest in Best Picks',
                trendingCuration: 'best-picks',
                promoCardsCuration: 'best-picks-promo-cards',
                isListingTopic: true,
            },
        },
        {
            id: 'motoring',
            data: {
                trendingTitle: 'The Latest',
                trendingCuration: 'lifestyle',
                isListingTopic: true,
                promoCardsCuration: 'motoring-promo-cards',
            },
        },
        {
            id: 'podcasts',
            data: {
                trendingTitle: 'Trending',
                trendingCuration: 'entertainment',
                isListingTopic: true,
                promoCardsCuration: '7news-entertainment-promo-cards',
            },
        },
    ]

    const topicData = sevenSubTopic.find((item) => topic.includes(item.id))

    if (!topicData) {
        return null
    }

    return topicData
}
