import { isTabletOrMobileViewport, metrics } from '@news-mono/component-library'
import {
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
    Topic,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../../App.routing'
import { breakingNewsComponent } from '../../shared-components/ad-and-breaking-news'
import {
    mobileAdFive,
    mobileAdFour,
    mobileAdSix,
    mobileAdThree,
    mobileAdTwo,
} from '../../shared-components/mobile-ads'

interface MKRRecipePageProps {
    topic: Topic
    getAdTargeting: GetRouteAdTargeting
}

export const getMKRRecipePage = ({
    topic,
    getAdTargeting,
}: MKRRecipePageProps): PageType<'default'> => {
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        'default',
        topic,
    )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const curationId = 'mkr-recipes'

    const content: CheckedComponentInformation[] = [
        breakingNewsComponent,
        layout.component({
            type: 'horizontal-pill-menu',
            props: {
                data: {
                    type: 'default',
                    topic: {
                        ...topic,
                    },
                },
            },
        }),

        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['unset', 'unset'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'home-page-hero-collection',
                        props: {
                            onEvent: () => {},
                            adUnitPath: adTargeting.adUnitPath,
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 0,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'six-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 5,
                                pageSize: 6,
                            },
                        },
                    }),
                    layout.component({
                        type: 'five-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 11,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 16,
                                pageSize: 4,
                            },
                        },
                    }),
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'unset',
                            verticalGutters: ['unset', 'lg'],
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,
                                        slot: {
                                            id: `leaderboard-billboard-two`,
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                                mobileAdTwo,
                            ],
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {},
                    }),
                    layout.component({
                        type: 'five-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 20,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 25,
                                pageSize: 4,
                            },
                        },
                    }),
                    layout.component({
                        type: 'five-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 29,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 34,
                                pageSize: 4,
                            },
                        },
                    }),
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'unset',
                            verticalGutters: ['unset', 'lg'],
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,
                                        slot: {
                                            id: `leaderboard-billboard-three`,
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                                mobileAdThree,
                            ],
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {},
                    }),
                    layout.component({
                        type: 'five-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 38,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 43,
                                pageSize: 4,
                            },
                        },
                    }),
                    layout.component({
                        type: 'five-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 47,
                                pageSize: 5,
                            },
                        },
                    }),
                    layout.component({
                        type: 'four-card-collection',
                        props: {
                            verticalSpacing: 'lg',
                            verticalGutters: ['unset', 'md'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: curationId,
                                offset: 52,
                                pageSize: 4,
                            },
                        },
                    }),
                ],
            },
        }),
        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['unset', 'lg'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'above-center',
                            hiddenUntilLoaded: false,
                            slot: {
                                id: `leaderboard-billboard-four`,
                                size: 'leaderboardBillboard',
                            },
                            adType: 'inline',
                        },
                    }),
                    mobileAdFour,
                ],
            },
        }),
        layout.component({
            type: 'horizontal-divider',
            props: {},
        }),
        layout.component({
            type: 'promo-cards-seven',
            props: {
                verticalGutters: ['lg', 'lg'],
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'mkr-promo-cards',
                    offset: 0,
                    pageSize: 3,
                },
            },
        }),
        layout.component({
            type: 'horizontal-divider',
            props: {},
        }),
        layout.component({
            type: 'horizontal-pill-menu',
            props: {
                data: {
                    type: 'single',
                    name: 'More from Entertainment',
                    link: '/entertainment',
                },
            },
        }),
        layout.component({
            type: 'five-card-collection',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['unset', 'md'],
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['entertainment'],
                    paging: {
                        page: 0,
                        pageSize: 5,
                    },
                },
            },
        }),
    ]

    const pageComposition = layout.composition({
        type: 'box',
        props: {
            verticalGutters: ['unset', 'unset'],
            horizontalGutters: 'md',
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            breakpoints: {
                sm: {
                    horizontalGutters: 'lg',
                },
                md: {
                    horizontalGutters: 'xl',
                },
            },
        },
        contentAreas: {
            main: [
                ...content,
                layout.component({
                    type: 'taboola',
                    props: {
                        taboolaContainerId: 'taboola-below-category-thumbnails',
                        locationType: 'category',
                        isFourColumns: isTabletOrMobileViewport()
                            ? false
                            : true,
                    },
                }),
            ],
        },
    })

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        pageMeta: {
            title: topic.seoTitle || topic.title,
            description:
                topic.seoDescription || `The latest in ${topic.seoTitle}`,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content:
                        `${topic.seoTitle} | 7NEWS` || `${topic.title} | 7NEWS`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
            ],
        },
        adTargeting,
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'page-header-v2',
                            props: {
                                sectionHeader: {
                                    heading: topic.title,
                                },
                                topic: topic,
                                type: 'logo',
                            },
                        }),
                    ],
                },
            }),
            pageComposition,
        ],
    }
}
