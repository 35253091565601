import React from 'react'

export interface ActiveSearchIconProps {
    className?: string
}

export const CrossIcon: React.FC<ActiveSearchIconProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
    >
        <path
            d="M2.01991 0.266484C1.67221 -0.0888281 1.10848 -0.0888281 0.760776 0.266484C0.413075 0.621796 0.413075 1.19787 0.760776 1.55318L7.25397 8.18851L1.12957 14.447C0.781868 14.8023 0.781868 15.3784 1.12957 15.7337C1.47727 16.089 2.04101 16.089 2.38871 15.7337L8.5131 9.47521L14.6113 15.7069C14.959 16.0622 15.5227 16.0622 15.8704 15.7069C16.2181 15.3516 16.2181 14.7755 15.8704 14.4202L9.77224 8.18851L16.2392 1.57997C16.5869 1.22466 16.5869 0.64858 16.2392 0.293267C15.8915 -0.0620448 15.3278 -0.0620445 14.9801 0.293268L8.5131 6.90181L2.01991 0.266484Z"
            fill="#272626"
            fill-opacity="0.7"
        />
    </svg>
)
