import React from 'react'
import {
    StyledActiveSearchIcon,
    StyledSearchButton,
    StyledSearchButtonActive,
    StyledSearchIcon,
} from './SearchForm.styled'
import { createRenderTarget } from '../../../render-target'
export interface SearchButtonProps {
    onClick: () => void
    isActive?: boolean
    searchHasValue: boolean
    isLarge: boolean
}
const SearchButtonWeb: React.FC<SearchButtonProps> = ({
    onClick,
    isActive,
    isLarge,
    searchHasValue,
}) => {
    return (
        <StyledSearchButton
            type={isActive ? 'submit' : 'button'}
            isLarge={isLarge}
            isActive={isActive}
            onClick={onClick}
        >
            {searchHasValue ? <StyledActiveSearchIcon /> : <StyledSearchIcon />}
        </StyledSearchButton>
    )
}
SearchButtonWeb.displayName = 'SearchButtonWeb'
const SearchButtonAmp: React.FC<SearchButtonProps> = ({
    isActive,
    isLarge,
    searchHasValue,
}) => {
    return (
        <>
            <StyledSearchButton
                type={'button'}
                isLarge={isLarge}
                isActive={isActive}
                id="search-button"
                on="tap:search-form.toggleClass(class='isActive'),
            search-input.toggleClass(class='isActive'), 
            search-container.toggleClass(class='isActive'), 
            logo-wrapper.toggleClass(class='hide'), 
            header-timestamp.toggleClass(class='toggleDisplay'),
            search-button.toggleClass(class='isInactive'),
            search-button-active.toggleClass(class='isActive'),
            clear-button.toggleClass(class='isActive')"
            >
                <StyledSearchIcon />
            </StyledSearchButton>
            <StyledSearchButtonActive
                type={'submit'}
                isLarge={isLarge}
                isActive={isActive}
                id="search-button-active"
            >
                <StyledActiveSearchIcon />
            </StyledSearchButtonActive>
        </>
    )
}
SearchButtonAmp.displayName = 'SearchButtonAmp'
export const SearchButton = createRenderTarget<SearchButtonProps>(
    'SearchButton',
    {
        web: SearchButtonWeb,
        amp: SearchButtonAmp,
        rss: null,
        preview: SearchButtonWeb,
        app: null,
    },
)
