import styled, { CSSObject } from '@emotion/styled'
import { Product, withClass } from '@news-mono/web-common'
import { breakpoints, Breakpoints } from '../../__styling/settings/breakpoints'
import { colors } from '../../__styling/settings/colors'
import {
    ContainerWidth,
    metrics,
    ThemeMargins,
} from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcEm } from '../../__styling/style-functions/calc-em'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { themedValue } from '../../__styling/themed-value'
import { BreakPointProps, SpacingProps } from './Triplet'

type Gutters = boolean | keyof ThemeMargins

export interface StyledTripletProps {
    hasBackgroundFill?: boolean
    horizontalGutters?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent: boolean
    breakpoints?: BreakPointProps
}

interface ThemedMetrics {
    margins: ThemeMargins
}

const sidebarWidth = 300

const getGutterSize = (
    themedMetrics: ThemedMetrics,
    gutter?: Gutters,
): string | number => {
    return gutter === false
        ? 0
        : calcRem(
              typeof gutter === 'string'
                  ? themedMetrics.margins[gutter]
                  : themedMetrics.margins.outerMargin,
          )
}

const gridGapSize = calcRem(21)

const getStylesFromProps = (
    props: SpacingProps,
    kind: Product,
): Array<CSSObject | false | undefined> => {
    const margins = metrics[kind].margins

    const horizontalSpacing =
        props.horizontalSpacing && calcRem(margins[props.horizontalSpacing])
    const verticalSpacing =
        props.verticalSpacing && calcRem(margins[props.verticalSpacing])
    const horizontalGutters =
        props.horizontalGutters && calcRem(margins[props.horizontalGutters])
    const topVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[0]])
    const bottomVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[1]])

    return [
        {
            marginLeft: horizontalSpacing,
            marginRight: horizontalSpacing,
            marginBottom: verticalSpacing,
            marginTop: verticalSpacing,
            paddingLeft: horizontalGutters,
            paddingRight: horizontalGutters,
            paddingTop: topVerticalGutters,
            paddingBottom: bottomVerticalGutters,
        },
    ]
}
type BreakpointKey = keyof Breakpoints
export const StyledTripletWrap = styled('div')<StyledTripletProps>(
    ({ theme }) => ({
        margin: '0 auto',
        width: '100%',
    }),
    ({ theme, stackSidebarContent, breakpoints }) =>
        ({
            theme,
            verticalSpacing,
            horizontalGutters,
            verticalGutters,
            containerWidth,
            hasBackgroundFill,
            horizontalSpacing,
        }) => {
            const themedMetrics = metrics[theme.kind]
            const breakpointStyles = Object.keys(
                breakpoints || {},
            ).reduce<CSSObject>((styles, key) => {
                const passedBreakpoints = breakpoints as BreakPointProps
                const props = passedBreakpoints[key as BreakpointKey]
                if (props) {
                    styles[breakpoint(key as BreakpointKey)] =
                        getStylesFromProps(props, theme.kind)
                }
                return styles
            }, {})

            return [
                ...getStylesFromProps(
                    {
                        horizontalGutters,
                        verticalGutters,
                        verticalSpacing,
                        horizontalSpacing,
                    },
                    theme.kind,
                ),
                {
                    maxWidth: containerWidth,
                    backgroundColor: themedValue(theme, {
                        thewest: undefined,
                        fallback: hasBackgroundFill ? colors.white : undefined,
                    }),
                },
                breakpointStyles,
            ]
        },
)
export const StyledTriplet = styled('div')<StyledTripletProps>(
    ({ theme, verticalGutters }) => {
        const themedMetrics = metrics[theme.kind]

        return {
            display: 'grid',
            gridTemplateColumns: '100%',
            [breakpoint('md')]: {
                gridTemplateColumns: `${calcRem(sidebarWidth)} auto ${calcRem(
                    sidebarWidth,
                )}`,

                gridGap: gridGapSize,
            },
            '@media print': {
                display: 'block',
            },
        }
    },

    ({ theme, stackSidebarContent }) =>
        // required to make tablet breakpoint 50/50 width between 768 and 800
        // whens stack sidebar content is true, this functionality is notrequired for IE
        // fallbacks as we wont be seeing alot of tablet sizes on IE
        theme.kind === 'sevennews' &&
        stackSidebarContent === true && {
            //prettier-ignore
            [`@media (min-width: ${calcEm(breakpoints['sm'],)}) and (max-width: ${calcEm(800)})`]: {
                    gridTemplateColumns: '1fr',
                },
        },
)
StyledTriplet.displayName = 'StyledTriplet'

export const StyledMain = styled('div')<{ hasBackgroundFill?: boolean }>(
    ({ theme, hasBackgroundFill }) => ({
        backgroundColor: themedValue(theme, {
            thewest: hasBackgroundFill ? colors.white : undefined,
            fallback: undefined,
        }),
    }),
)
StyledMain.displayName = 'StyledMain'

export interface StyledSidebarProps extends StyledTripletProps {}
export const StyledSidebar = withClass('hide-print')(
    styled('div')<StyledTripletProps>(({ stackSidebarContent, theme }) => {
        return {
            display: stackSidebarContent ? 'block' : 'none',
            transform: 'translateZ(0)',

            [breakpoint(stackSidebarContent ? 'sm' : 'md')]: {
                display: 'block',
                boxSizing: 'content-box',
            },
        }
    }),
)
StyledSidebar.displayName = 'StyledSidebar'

export const StyledMobileRail = withClass('hide-print')(
    styled('div')<StyledTripletProps>(({ stackSidebarContent }) => {
        return {
            display: stackSidebarContent ? 'none' : 'block',

            [breakpoint('md')]: {
                display: 'none',
            },
        }
    }),
)
