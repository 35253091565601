import {
    CollectionEvent,
    createCardClickedEvent,
    createCollectionAvailableEvent,
    isLocalStorageAvailable,
    Product,
    PublicationCardItem,
} from '@news-mono/web-common'
import React, {
    Component,
    createRef,
    RefObject,
    useEffect,
    useState,
} from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { metrics } from '../../__styling/settings/metrics'
import {
    SevenNewsBreakingNewsThemeNames,
    StyledArrow,
    StyledBreakingNewsText,
    StyledPulsatingDotLogo,
    StyledSevenNewsBreakingNews,
    StyledSevenNewsBreakingNewsContent,
    StyledSevenNewsBreakingNewsContentLink,
    StyledThemePill,
} from './SevenNewsBreakingNews.styled'

const rightArrow = require('./images/right-arrow.svg')

export interface SevenNewsBreakingNewsProps {
    item: PublicationCardItem
    articleId: string
    cardNumber: number
    onEvent: (event: CollectionEvent) => void
    theme?: SevenNewsBreakingNewsThemeNames | undefined
    title?: string

    /** this prop is used for testing, and is required to be set otherwise the banner is hidden */
    testOptions?: {
        dismissedOverride?: boolean
        localStorageMock?: boolean
    }
    nextBreaking: () => void
    prevBreaking: () => void
    totalCards: number
}

export interface SevenNewsBreakingNewsState {
    dismissed: boolean
    height: string | number | undefined
}

export interface BreakingNewsStrapConfig {
    articleId: string
    hideBanner: boolean
}

export const STORAGE_KEY = 'seven-news-breaking-news-strap'

export class SevenNewsBreakingNews extends Component<
    SevenNewsBreakingNewsProps,
    SevenNewsBreakingNewsState
> {
    wrapperRef: RefObject<HTMLDivElement>

    constructor(props: SevenNewsBreakingNewsProps) {
        super(props)
        const { testOptions } = this.props

        this.state = {
            dismissed:
                testOptions && testOptions.dismissedOverride !== undefined
                    ? testOptions.dismissedOverride
                    : true,
            height: 'auto',
        }
        this.wrapperRef = createRef()
    }

    linkClicked = () => {
        this.props.onEvent(
            createCardClickedEvent(
                this.props.onEvent,
                this.props.item,
                'default',
                'SevenNewsBreakingNews',
                this.props.cardNumber,
            ),
        )
    }

    /**
     * This is fired client-side which allows us to read from localstorage
     * to show the banner if applicable or continue it being hidden, unless
     * the dismissed prop is set to true, which it wont do anything
     */
    componentDidMount() {
        const { testOptions } = this.props
        if (
            testOptions === undefined ||
            (testOptions && testOptions.dismissedOverride === undefined)
        ) {
            this.setState({
                dismissed: this.isDismissedInConfig(),
            })
        }
    }

    localStorageAvailable(): boolean {
        const { testOptions } = this.props
        if (testOptions && testOptions.localStorageMock) {
            return true
        }
        return isLocalStorageAvailable()
    }

    isDismissedInConfig() {
        const config = this.getConfig()
        return (
            (config &&
                config.articleId === this.props.articleId &&
                config.hideBanner) ||
            false
        )
    }

    getConfig() {
        try {
            const config = this.localStorageAvailable()
                ? localStorage.getItem(STORAGE_KEY)
                : null
            return config !== null
                ? (JSON.parse(config) as BreakingNewsStrapConfig)
                : null
        } catch (e) {
            // Can happen when local storage gets corruped, returning a NS_ERROR_FILE_CORRUPTED error
            return null
        }
    }

    setConfig(hideBanner: boolean) {
        if (this.localStorageAvailable()) {
            localStorage.setItem(
                STORAGE_KEY,
                JSON.stringify({ hideBanner, articleId: this.props.articleId }),
            )
        }
    }

    handleClose = () => {
        const height = this.wrapperRef.current
            ? this.wrapperRef.current.clientHeight
            : undefined
        this.setState({ height })
        setTimeout(() => this.setState({ dismissed: true }))
        this.setConfig(true)
    }
    getPillCopy = (theme: SevenNewsBreakingNewsThemeNames | undefined) => {
        switch (theme) {
            case '7plus':
                return '7plus'
            case 'afl':
                return 'AFL'
            case 'breakingNews':
                return 'Breaking'
            case 'cricket':
                return 'Cricket'
            case 'default':
                return null
            case 'developing':
                return 'Developing'
            case 'entertainment':
                return 'Entertainment'
            case 'lifestyle':
                return 'Lifestyle'
            case 'live':
                return 'Live'
            case 'sport':
                return 'Sport'
            case 'spotlight':
                return 'Spotlight'
            case 'sunrise':
                return 'Sunrise'
            case 'trending':
                return 'Trending'
            default:
                return null
        }
    }

    render() {
        const { item, theme, title, nextBreaking, prevBreaking, totalCards } =
            this.props

        const boxProps = {
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            hasTopSpacing: false,
            hasBackgroundFill: true,
            verticalGutters: undefined,
        }
        const pillCopy = this.getPillCopy(theme)

        return (
            <ImpressionAvailable
                loading={false}
                available={() => {
                    this.props.onEvent(
                        createCollectionAvailableEvent(
                            [`Breaking News ${title}`],
                            `Breaking News`,
                            Product.SevenNews,
                            this.props.onEvent,
                            { cardFormat: theme ?? 'default' },
                        ),
                    )
                }}
            >
                {() => (
                    <StyledSevenNewsBreakingNews
                        className="js-breakingNews-wrapper"
                        ref={this.wrapperRef}
                        {...boxProps}
                        onClick={this.linkClicked}
                    >
                        <StyledSevenNewsBreakingNewsContent>
                            <StyledSevenNewsBreakingNewsContentLink
                                to={item.link}
                            >
                                {pillCopy && (
                                    <StyledThemePill>
                                        {theme === 'live' && (
                                            <StyledPulsatingDotLogo />
                                        )}
                                        {pillCopy}
                                    </StyledThemePill>
                                )}
                                <StyledBreakingNewsText>
                                    {title !== '' ? title : item.shortHeadline}
                                </StyledBreakingNewsText>
                            </StyledSevenNewsBreakingNewsContentLink>

                            <StyledArrow src={rightArrow} alt={title} />
                        </StyledSevenNewsBreakingNewsContent>
                    </StyledSevenNewsBreakingNews>
                )}
            </ImpressionAvailable>
        )
    }
}

export interface SevenNewsMultipleBreakingNewsProps {
    items: PublicationCardItem[]
    onEvent: (event: CollectionEvent) => void
    metadata: { [key: string]: any }
    metaDataIndexHash: { [key: string]: number }
}

export const SevenNewsMultipleBreakingNews: React.FC<SevenNewsMultipleBreakingNewsProps> =
    ({ items, metadata, metaDataIndexHash, onEvent }) => {
        const [activeCard, setActiveCard] = useState(0)
        const nextBreaking = () => {
            if (activeCard === items.length - 1) {
                setActiveCard(0)
            } else {
                setActiveCard((current) => current + 1)
            }
        }
        const previousBreaking = () => {
            if (activeCard === 0) {
                setActiveCard(items.length - 1)
            } else {
                setActiveCard((current) => current - 1)
            }
        }

        useEffect(() => {
            const nextStrapTimer = setTimeout(() => {
                nextBreaking()
            }, 8000)
            return () => clearTimeout(nextStrapTimer)
        })

        const metaDataId = metaDataIndexHash[items[activeCard].id]

        return (
            <SevenNewsBreakingNews
                theme={metadata[`breaking-news-theme-${metaDataId}`]}
                title={metadata[`breaking-news-title-${metaDataId}`]}
                cardNumber={activeCard}
                item={items[activeCard]}
                articleId={items[activeCard].id}
                onEvent={onEvent}
                nextBreaking={nextBreaking}
                prevBreaking={previousBreaking}
                totalCards={items.length}
            />
        )
    }
