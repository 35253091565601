import {
    GigyaLoginCredentials,
    GigyaNewsletterPreferences,
    GigyaUser,
    postNewsletterLogin,
    postNewsletterPreferences,
} from './helpers'
import React, { createContext, useContext, useState } from 'react'
import { LogoutEvent } from '@news-mono/web-common'

export type GigyaAuthenticationProviderOnEvent = (event: LogoutEvent) => void

interface AuthenticationProviderContextValues {
    user: any
    onLoginClick(
        data: GigyaLoginCredentials,
        setIsLoading: (value: React.SetStateAction<boolean>) => void,
    ): any
    onLogoutClick(onEvent: GigyaAuthenticationProviderOnEvent): void
    onSubmitNewsletterPreferences(
        data: GigyaNewsletterPreferences,
        setIsLoading: (value: React.SetStateAction<boolean>) => void,
    ): any
}

const GigyaAuthenticationContext = createContext<
    AuthenticationProviderContextValues | undefined
>(undefined)

export function useGigyaService() {
    const values = useContext(GigyaAuthenticationContext)
    if (!values) {
        throw new Error(
            'Ensure <AuthenticationProvider /> is rendered above the component using this hook',
        )
    }

    return values
}

const GigyaAuthenticationProvider = ({ children }: any) => {
    const [user, setUser] = useState<GigyaUser | undefined>(undefined)

    const onLoginClick = async (
        data: GigyaLoginCredentials,
        setIsLoading: (value: React.SetStateAction<boolean>) => void,
    ) => {
        try {
            setIsLoading(true)
            const signInResponseJson = await postNewsletterLogin(
                data.email,
                data.password,
            )
            setIsLoading(false)
            if (signInResponseJson.statusCode === 200) {
                setUser(signInResponseJson)
            }
            return signInResponseJson
        } catch (err) {
            setIsLoading(false)
            console.error(err)
        }
    }

    const onSubmitNewsletterPreferences = async (
        newsletterPreferences: GigyaNewsletterPreferences,
        setIsLoading: (value: React.SetStateAction<boolean>) => void,
    ) => {
        try {
            if (!user) {
                throw new Error('Missing user data')
            }
            setIsLoading(true)
            const newsletterPreferencesResponseJson =
                await postNewsletterPreferences(
                    user.UID,
                    user.profile.email,
                    newsletterPreferences,
                )
            setIsLoading(false)
            return newsletterPreferencesResponseJson
        } catch (err) {
            setIsLoading(false)
            console.error(err)
        }
    }

    const onLogoutClick = () => {
        setUser(undefined)
    }

    const values = {
        user,
        onLoginClick,
        onLogoutClick,
        onSubmitNewsletterPreferences,
    }

    return (
        <GigyaAuthenticationContext.Provider value={values}>
            {children}
        </GigyaAuthenticationContext.Provider>
    )
}

export { GigyaAuthenticationContext, GigyaAuthenticationProvider }
