import {
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import InlineTaboola from '../../../advertising/Taboola/InlineTaboola/InlineTaboola.web'
import { InlinePositionedContent } from '../Publication.props'
import {
    InlineContentTypes,
    InlineTaboolaComponent,
} from '../SharedPublication.routing'
export interface GetInlinePositionedTaboolaProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isTaboolaComponent(
    content: InlineContentTypes,
): content is InlineTaboolaComponent {
    return content.kind === 'inline-taboola'
}
/** This is a point where we can switch if we want Taboola to be rendered in all articles */
const renderInAllArticles = true

/** In case that render all articles is set to false we can specify here in which articles
 Taboola should be rendered. Two topics have been left here as an example */
const sevennewsTargetTopics = ['travel', 'entertainment']

export const getInlinePositionedTaboola = (
    props: GetInlinePositionedTaboolaProps,
): InlinePositionedContent[] => {
    const { publication, inlinePublicationContent } = props
    if (!isArticleLikeType(publication)) {
        return []
    }

    // Don't insert if there are less than 8 content blocks in the story
    if (publication.content.length <= 8) {
        return []
    }
    const sevennewsShouldRender =
        sevennewsTargetTopics.some((topic) =>
            publication.primaryTopic?.id.includes(topic),
        ) || renderInAllArticles

    if (!sevennewsShouldRender) {
        return []
    }

    return inlinePublicationContent
        .filter(isTaboolaComponent)
        .map((content) => ({
            position: content.insertAfter,
            element: <InlineTaboola />,
        }))
}
