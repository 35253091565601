export interface LoginData {
    lastLogin: string
    apiKey: string
    firstLogin: string
    provider: string
    lastLoginPlatform: string
    login_counter: string
    firstLoginPlatform: string
}

export type NewsletterConsent = 'optIn' | 'optOut'

export interface GigyaLoginCredentials {
    email: string
    password: string
}

export interface GigyaNewsletterPreferences {
    breaking_news: boolean
    morning_edition: boolean
    sports_wrap: boolean
    consumer_affairs: boolean
    lifestyle_news: boolean
}

export type NewsletterCommunications =
    | 'BreakingNews_7News_NewsletterPreferences'
    | 'SportsWrap_7News_NewsletterPreferences'
    | 'MorningEdition_7News_NewsletterPreferences'
    | 'ConsumerAffairs_7News_NewsletterPreferences'
    | 'LifestyleNews_7News_NewsletterPreferences'

export type CommunicationSettings = {
    [key in NewsletterCommunications]: {
        status: NewsletterConsent
    }
}

export interface GigyaUser {
    UID: string
    profile: {
        email: string
    }
    seesionInfo?: {
        cookieName: string
        cookieValue: string
    }
    data: {
        logins: LoginData[]
    }
    statusCode: number
    communications?: CommunicationSettings | {}
}

export const postNewsletterLogin = async (email: string, password: string) => {
    const response = await fetch('/newsletter-login', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password,
        }),
    })
    const responseJson = await response.json()
    return responseJson
}

export const postNewsletterPreferences = async (
    userId: string,
    email: string,
    newsletterPreferences: GigyaNewsletterPreferences,
) => {
    const response = await fetch('/newsletter-preferences', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ UID: userId, email, newsletterPreferences }),
    })
    const responseJson = await response.json()
    return responseJson
}

export const getNewsletterPreferences = async (userId: string) => {
    const response = await fetch(
        `/newsletter-preferences?uid=${userId}&r=${Math.random().toString()}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )
    const responseJson = await response.json()
    return responseJson
}
