import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Store,
} from '@news-mono/web-common'
import { PageMetaDTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import { cricketTopicForAdTargeting } from '.'
import { layout } from '../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAdTwo } from '../shared-components/mobile-ads'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
const metaImage = require('./assets/match-centre.jpg')

export const createCricketMatchCentreRoute = ({
    config,
    getAdTargeting,
    location,
    meta,
    competition,
    season,
    match,
    apiData,
    store,
}: {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location
    meta: PageMetaDTO | undefined
    competition: string
    season: string
    match: string
    apiData: any
    store: Store
}): PageType<SevenNewsSection> => {
    const section = 'default'

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'match-centre',
        section,
        cricketTopicForAdTargeting,
    )
    const adTargeting: AdTargeting = {
        pageId: meta?.title ? meta.title : 'sport',
        adUnitPath,
        ssAdUnits,
        topics,
    }
    const canonicalUrl = config.publicUrl + location.pathname + location.search
    const isV2LeaderBoardBillboardToggleEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-2-top-leaderboard-ad',
    )

    return {
        kind: 'page',
        heading: meta?.title ? meta.title : '',
        pageMeta: {
            title: meta?.title ? meta.title : '',
            description: meta?.description ? meta.description : '',
            meta: [
                {
                    property: 'og:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:image',
                    content: metaImage,
                },
                {
                    property: 'og:image:height',
                    content: '720',
                },
                {
                    property: 'og:image:width',
                    content: '1280',
                },
            ],
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        socialMeta: {
            title: meta?.title ? meta.title : '',
            description: meta?.description ? meta.description : '',
        },
        hideHeading: true,
        noMetaTitleTemplate: true,
        pageType: 'static',
        refreshOnInactive: true,
        adTargeting,
        section,
        compositions: [
            ...(isV2LeaderBoardBillboardToggleEnabled ? [] : [headerAd()]),
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['md', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'fixtures-scoreboard',
                        feature: '7-news-cricket-fixture-scorecard',
                        props: {},
                    }),
                ],
            }),
            breakingNews,
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'cricket-match-centre-scoreboard',
                        props: {
                            gameId: match,
                            apiData,
                        },
                    }),
                ],
            }),

            layout.composition({
                type: 'tab-v2',
                props: {
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    headerData: [
                        {
                            position: 'one',
                            title: 'Summary',
                        },
                        {
                            position: 'two',
                            title: 'Scorecard',
                        },
                        {
                            position: 'three',
                            title: 'Commentary',
                        },
                        {
                            position: 'four',
                            title: 'Videos',
                        },
                        {
                            position: 'five',
                            title: 'Stats',
                        },
                        {
                            position: 'six',
                            title: 'Fixtures',
                        },
                        {
                            position: 'seven',
                            title: 'News',
                        },
                        {
                            position: 'eight',
                            title: 'Standings',
                        },
                    ],
                },
                contentAreas: {
                    one: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Scorecard',
                                },
                            },
                        }),
                        layout.nestedComposition({
                            type: 'doublet',
                            props: {
                                containerWidth:
                                    metrics.sevennews.siteMetrics
                                        .sevenEntMainContentWidth,
                                verticalGutters: ['unset', 'md'],
                            },
                            contentAreas: {
                                leftContent: [
                                    layout.component({
                                        type: 'latest-deliveries-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'score-card-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                            isSummaryTab: true,
                                        },
                                    }),
                                ],
                                rightContent: [
                                    layout.component({
                                        type: 'match-action-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'wagon-wheel-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'manhattan-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'doublet',
                            props: {
                                containerWidth:
                                    metrics.sevennews.siteMetrics
                                        .sevenEntMainContentWidth,
                                verticalGutters: ['unset', 'md'],
                            },
                            contentAreas: {
                                leftContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Commentary',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'commentary-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                ],
                                rightContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Player Compare',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'player-compare-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),

                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Competition Stats',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'competition-stats-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                    two: [
                        layout.component({
                            type: 'score-card-widget',
                            props: {
                                competition: competition,
                                season: season,
                                match: match,
                            },
                        }),
                    ],
                    three: [
                        layout.component({
                            type: 'commentary-widget',
                            props: {
                                competition: competition,
                                season: season,
                                match: match,
                            },
                        }),
                    ],
                    four: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Cricket Videos',
                                    link: 'https://7news.com.au/video/sport/cricket',
                                },
                            },
                        }),
                        layout.component({
                            type: 'video-hub-topic',
                            props: {
                                adUnitPath: adUnitPath,
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['sport/cricket'],
                                    paging: {
                                        page: 1,
                                        pageSize: 10,
                                    },
                                },
                            },
                        }),
                    ],
                    five: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Match Action',
                                },
                            },
                        }),
                        layout.component({
                            type: 'match-action-widget',
                            props: {
                                competition: competition,
                                season: season,
                                match: match,
                            },
                        }),
                        layout.nestedComposition({
                            type: 'doublet',
                            props: {
                                containerWidth:
                                    metrics.sevennews.siteMetrics
                                        .sevenEntMainContentWidth,
                                verticalGutters: ['unset', 'md'],
                            },
                            contentAreas: {
                                leftContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Manhattan',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'manhattan-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),

                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Player Compare',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'player-compare-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),
                                ],
                                rightContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Wagon Wheel',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'wagon-wheel-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                            match: match,
                                        },
                                    }),
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Competition Stats',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'competition-stats-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                    six: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Cricket Fixtures',
                                },
                            },
                        }),
                        layout.component({
                            type: 'fixtures-widget',
                            props: {},
                        }),
                    ],
                    seven: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Cricket News',
                                    link: '/sport/cricket',
                                },
                            },
                        }),
                        layout.component({
                            type: 'six-card-collection',
                            props: {
                                verticalSpacing: 'xl',
                                loadMoreEnabled: true,
                                isSubtopicPage: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/cricket'],
                                    paging: {
                                        page: 1,
                                        pageSize: 6,
                                    },
                                },
                            },
                        }),
                    ],
                    eight: [
                        layout.component({
                            type: 'standings-widget',
                            props: {
                                competition: competition,
                                season: season,
                            },
                        }),
                        layout.nestedComposition({
                            type: 'doublet',
                            props: {
                                containerWidth:
                                    metrics.sevennews.siteMetrics
                                        .sevenEntMainContentWidth,
                                verticalGutters: ['unset', 'md'],
                            },
                            contentAreas: {
                                leftContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Player Ranking',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'player-ranking-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),
                                ],
                                rightContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Team Ranking',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'team-ranking-widget',
                                        props: {
                                            competition: competition,
                                            season: season,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                    nine: [],
                },
            }),

            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'promo-cards-seven',
                        props: {
                            verticalSpacing: 'lg',
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'cricket-promo-cards',
                                offset: 0,
                                pageSize: 3,
                            },
                        },
                    }),

                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'above-center',
                            hiddenUntilLoaded: false,
                            padding: [metrics.sevennews.margins.md, 0, 0, 0],
                            slot: {
                                id: 'leaderboard-billboard-two',
                                size: 'leaderboardBillboard',
                            },
                            adType: 'inline',
                        },
                    }),
                    mobileAdTwo,
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                            isFourColumns: isTabletOrMobileViewport()
                                ? false
                                : true,
                        },
                    }),
                ],
            }),
        ],
    }
}
