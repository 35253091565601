import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../../helpers/get-v2-topic-page'

export const getSpotlightPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Spotlight',
        id: 'spotlight',
        seoTitle: resolution.resolution?.topic.seoTitle || 'Spotlight',
        seoDescription: resolution.resolution?.topic.seoDescription,
        metadata: {},
    }

    const topicShowPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            name: 'spotlight-featured',
        },
        dataDefinitionArgsOverrideSixCard: {
            name: 'spotlight-featured',
        },
        contentToFormat: [
            {
                pillMenuName: 'Entertainment',
                pillMenuLink: '/entertainment',
                collectionTopics: ['entertainment'],
            },
            {
                pillMenuName: 'Exclusives',
                pillMenuLink: '',
                collectionTopics: [],
                dataDefinitionArgsOverride: {
                    type: 'curation',
                    name: 'spotlight-exclusives',
                    offset: 0,
                    pageSize: 4,
                },
            },
        ],
        promoCardName: 'spotlight-promotion-cards',
        getAdTargeting,
        features,
    })

    return topicShowPage
}
