import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { ThemeMargins } from '../../../__styling'
import { CollectionRouteProps } from '../../../collections'
import { PromoCardsV2 } from './PromoCards'

export interface PromoCardsProps extends CollectionRouteProps {
    tbRegionName?: string
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const PromoCardsRegistration = createRegisterableComponentWithData(
    'promo-cards-seven',
    ContentDataDefinitionLoader,
    (props: PromoCardsProps, data, services) => {
        if (
            !data.loaded ||
            (data.loaded && data.result.publications.length <= 0)
        ) {
            return null
        }

        return (
            <PromoCardsV2
                items={
                    data.loaded
                        ? { loaded: true, result: data.result.publications }
                        : { loaded: false }
                }
                verticalGutters={props.verticalGutters}
                onEvent={services.onEvent}
                tbRegionName={props.tbRegionName}
            />
        )
    },
)
