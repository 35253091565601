import React from 'react'
import { useOptaWidgetStart } from '../useOptaWidgetStart'
import { TeamRankingWidgetContainer } from './TeamRankingWidget.styled'

export interface TeamRankingWidgetProps {
    competition: string
    season: string
}
export const TeamRankingWidget = ({
    competition,
    season,
}: TeamRankingWidgetProps) => {
    useOptaWidgetStart()
    return (
        <TeamRankingWidgetContainer>
            <opta-widget
                widget="team_ranking"
                template="normal"
                competition={competition}
                season={season}
                navigation="tabs_more"
                default_nav="1"
                show_crests="false"
                show_ranks="true"
                visible_categories="runs_scored,catches,dropped_catches,fours,sixes"
                limit="10"
                hide_zeroes="true"
                team_naming="full"
                show_logo="false"
                show_title="true"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </TeamRankingWidgetContainer>
    )
}
