import React from 'react'

const LinkOutIcon = () => (
    <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_14553_78512)">
            <path
                opacity="0.8"
                d="M4.85417 0.34375V1.48958H1.98958V9.51042H10.0104V6.64583H11.1562V10.0833C11.1562 10.2353 11.0959 10.381 10.9884 10.4884C10.881 10.5959 10.7353 10.6562 10.5833 10.6562H1.41667C1.26472 10.6562 1.119 10.5959 1.01155 10.4884C0.904111 10.381 0.84375 10.2353 0.84375 10.0833V0.916667C0.84375 0.76472 0.904111 0.618996 1.01155 0.511553C1.119 0.404111 1.26472 0.34375 1.41667 0.34375H4.85417ZM9.26964 3.04047L6 6.3101L5.1899 5.5L8.45953 2.23036L6.57292 0.34375H11.1562V4.92708L9.26964 3.04047Z"
                fill="#292A33"
            />
        </g>
        <defs>
            <clipPath id="clip0_14553_78512">
                <rect
                    width="11"
                    height="11"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
)

export default LinkOutIcon
