import React, { useEffect, useRef, useState } from 'react'
import { useGigyaService } from '../authentication/GigyaAuthenticationProvider'
import {
    StyledCheckboxContainer,
    StyledDescription,
    StyledHeading,
    StyledHorizontalLine,
    StyledInputCheckbox,
    StyledInputContainer,
    StyledLabelText,
    StyledNewsletterImage,
    StyledNewsletterLabel,
    StyledNewsletterPreferencesContainer,
    StyledPreferencesForm,
    StyledPreferencesFormContainer,
    StyledPreferencesHeroDesktop,
    StyledPreferencesHeroMobile,
    StyledSubmitButton,
    StyledSubmitMessage,
    StyledSubmitSuccessMessage,
    StyledVisualCheckbox,
} from './NewsletterPreferences.styled'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import SwiperClass from 'swiper'
import {
    CommunicationSettings,
    getNewsletterPreferences,
} from '../authentication/helpers'
import { LoadingEllipsis } from '../../../LoadingEllipsis/LoadingElipsis'

interface FormState {
    breaking_news: boolean
    morning_edition: boolean
    sports_wrap: boolean
    consumer_affairs: boolean
    lifestyle_news: boolean
}

type FormCheckboxName =
    | 'breaking_news'
    | 'morning_edition'
    | 'sports_wrap'
    | 'consumer_affairs'
    | 'lifestyle_news'

interface NewsletterTheme {
    name: FormCheckboxName
    label: string
    image: string
}

const desktopHero = require('../SignIn/assets/hero_desktop.png')
const mobileHero = require('../SignIn/assets/hero_mobile.png')

const newsletters: NewsletterTheme[] = [
    {
        name: 'breaking_news',
        label: 'Get breaking news, delivered straight to your inbox.',
        image: require('./assets/breaking_news.png'),
    },
    {
        name: 'morning_edition',
        label: 'A concise briefing of top stories, videos and insights. Wake up informed - subscribe now for your daily dose.',
        image: require('./assets/morning_edition.png'),
    },
    {
        name: 'sports_wrap',
        label: 'Score big with 7NEWS sports newsletter! Your dose of sports highlights, scores, match centres and exclusive insights.',
        image: require('./assets/sports_wrap.png'),
    },
    {
        name: 'consumer_affairs',
        label: 'Expertly curated content for informed choices. Elevate your decisions - subscribe now for best picks and exclusive deals.',
        image: require('./assets/consumer_affairs.png'),
    },
    {
        name: 'lifestyle_news',
        label: 'Trending tips, cultural insights, and exclusive features. Elevate your everyday - subscribe now for a curated life.',
        image: require('./assets/lifestyle_news.png'),
    },
]

const Checkbox = ({
    name,
    label,
    image,
    isSelectedState,
    onCheckboxChange,
    isDisabled,
}: {
    name: string
    label: string
    image: string
    isSelectedState: boolean
    onCheckboxChange: (e: React.FormEvent<HTMLInputElement>) => void
    isDisabled: boolean
}) => {
    const [isSelected, setIsSelected] = useState(isSelectedState)
    useEffect(() => {
        setIsSelected(isSelectedState)
    }, [isSelectedState])

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setIsSelected(e.currentTarget.checked)
        onCheckboxChange(e)
    }

    return (
        <StyledCheckboxContainer>
            <StyledNewsletterLabel>
                <StyledNewsletterImage src={image} />
                <StyledVisualCheckbox isSelected={isSelected} />
                <StyledInputCheckbox
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    autoComplete={'off'}
                    disabled={isDisabled}
                    checked={isSelected}
                />
                <StyledLabelText>{label}</StyledLabelText>
            </StyledNewsletterLabel>
        </StyledCheckboxContainer>
    )
}

const mapNewsletterPreferencesToFormState = (
    newsletterPreferences: CommunicationSettings,
    formState: FormState,
) => {
    formState.breaking_news =
        newsletterPreferences.BreakingNews_7News_NewsletterPreferences &&
        newsletterPreferences.BreakingNews_7News_NewsletterPreferences
            .status === 'optIn'
            ? true
            : false

    formState.morning_edition =
        newsletterPreferences.MorningEdition_7News_NewsletterPreferences &&
        newsletterPreferences.MorningEdition_7News_NewsletterPreferences
            .status === 'optIn'
            ? true
            : false

    formState.sports_wrap =
        newsletterPreferences.SportsWrap_7News_NewsletterPreferences &&
        newsletterPreferences.SportsWrap_7News_NewsletterPreferences.status ===
            'optIn'
            ? true
            : false

    formState.consumer_affairs =
        newsletterPreferences.ConsumerAffairs_7News_NewsletterPreferences &&
        newsletterPreferences.ConsumerAffairs_7News_NewsletterPreferences
            .status === 'optIn'
            ? true
            : false

    formState.lifestyle_news =
        newsletterPreferences.LifestyleNews_7News_NewsletterPreferences &&
        newsletterPreferences.LifestyleNews_7News_NewsletterPreferences
            .status === 'optIn'
            ? true
            : false

    return formState
}

export const NewsletterPrefences = () => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    )
    const [formState, setFormState] = useState<FormState>({
        breaking_news: false,
        morning_edition: false,
        sports_wrap: false,
        consumer_affairs: false,
        lifestyle_news: false,
    })
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const swiperRef = useRef<SwiperClass>()

    const authService = useGigyaService()

    useEffect(() => {
        ;(async function () {
            if (authService?.user.UID) {
                setIsLoading(true)
                const existingPreferences = await getNewsletterPreferences(
                    authService?.user.UID,
                )
                const updatedFormState = mapNewsletterPreferencesToFormState(
                    existingPreferences,
                    formState,
                )
                setFormState({ ...updatedFormState })
                setIsLoading(false)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            formState.breaking_news === true ||
            formState.morning_edition === true ||
            formState.sports_wrap === true ||
            formState.consumer_affairs === true ||
            formState.lifestyle_news === true
        ) {
            setIsSubmitDisabled(false)
        } else {
            setIsSubmitDisabled(true)
        }
    }, [formState])

    const onCheckboxChange = (e: React.FormEvent<HTMLInputElement>) => {
        setErrorMessage(undefined)
        formState[e.currentTarget.name as FormCheckboxName] =
            e.currentTarget.checked
        setFormState({ ...formState })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const submitPreferencesResult =
            await authService?.onSubmitNewsletterPreferences(
                formState,
                setIsLoading,
            )
        if (
            !submitPreferencesResult ||
            (submitPreferencesResult &&
                submitPreferencesResult.statusCode !== 200)
        ) {
            console.error('Error submitting newsletter preferences')
        }
        setIsSubmitSuccessful(true)
        setIsSubmitDisabled(true)
    }

    return (
        <StyledNewsletterPreferencesContainer>
            <StyledPreferencesHeroMobile
                src={mobileHero}
                alt={'Newsletter preference page hero image'}
            />
            <StyledPreferencesHeroDesktop
                src={desktopHero}
                alt={'Newsletter preference page hero image'}
            />
            <StyledPreferencesFormContainer>
                <StyledHeading>Newsletters</StyledHeading>
                <StyledDescription>
                    Below are your current 7NEWS Newsletter preferences. To
                    opt-in, select from the options below and submit:
                </StyledDescription>
                <StyledHorizontalLine />
                <StyledPreferencesForm
                    name={'newsletter_preferences'}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <StyledInputContainer enableOpacity={isSubmitSuccessful}>
                        <Swiper
                            slidesPerView={'auto'}
                            watchOverflow={true}
                            allowSlideNext={true}
                            allowSlidePrev={true}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper
                            }}
                        >
                            {newsletters.map((newsletter, i) => (
                                <SwiperSlide key={i} virtualIndex={i}>
                                    <Checkbox
                                        name={newsletter.name}
                                        label={newsletter.label}
                                        image={newsletter.image}
                                        isSelectedState={
                                            formState[newsletter.name]
                                        }
                                        onCheckboxChange={onCheckboxChange}
                                        isDisabled={
                                            isLoading || isSubmitSuccessful
                                        }
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </StyledInputContainer>
                    <StyledSubmitButton
                        type="submit"
                        disabled={isSubmitDisabled}
                    >
                        {isLoading ? <LoadingEllipsis /> : 'Submit'}
                    </StyledSubmitButton>
                    {!isSubmitSuccessful && (
                        <StyledSubmitMessage>
                            *Please select an option, then submit.
                        </StyledSubmitMessage>
                    )}
                </StyledPreferencesForm>
                {isSubmitSuccessful && !isLoading && (
                    <StyledSubmitSuccessMessage>
                        Your preferences have been updated.
                    </StyledSubmitSuccessMessage>
                )}
            </StyledPreferencesFormContainer>
        </StyledNewsletterPreferencesContainer>
    )
}
