import { SevenNewsSection, metrics } from '@news-mono/component-library'
import {
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'

const metaImage = require('../assets/meta.jpg')

export const getAppPage = (
    getAdTargeting: GetRouteAdTargeting,
): PageType<SevenNewsSection> => {
    const title = 'Download the 7NEWS app today'
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        'default',
        {
            title: title,
            id: 'default',
            seoTitle: title,
            metadata: {},
        },
    )
    const adTargeting: AdTargeting = {
        pageId: title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    return {
        kind: 'page',
        heading: title,
        hideHeading: true,
        pageType: 'static',
        pageMeta: {
            title,
            description:
                'Get breaking news and videos to your phone from Australia and around the world via the 7NEWS app',
            meta: [
                {
                    property: 'og:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:image',
                    content: metaImage,
                },
                {
                    property: 'og:image:height',
                    content: '720',
                },
                {
                    property: 'og:image:width',
                    content: '1280',
                },
            ],
        },
        adTargeting,
        section: 'default',
        compositions: [
            //TODO if needed add breaking news here
            layout.composition({
                type: 'box',
                props: {
                    verticalGutters: ['unset', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenV2MainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: '7news-app-page',
                            props: {},
                        }),
                    ],
                },
            }),
        ],
    }
}
