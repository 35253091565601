import { isTabletOrMobileViewport, metrics } from '@news-mono/component-library'
import {
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
    StaticRouteResolution,
    Topic,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../../App.routing'
import { breakingNewsComponent } from '../../shared-components/ad-and-breaking-news'
import { mobileAdTwo } from '../../shared-components/mobile-ads'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { adsLayoutComponent } from '../helpers/get-v2-topic-page'

interface MetaExposedPageProps {
    getAdTargeting: GetRouteAdTargeting
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>
}

export const getMetaExposedPage = ({
    getAdTargeting,
    resolution,
}: MetaExposedPageProps): PageType<'default'> => {
    const topic =
        resolution && resolution.resolution?.topic
            ? resolution.resolution?.topic
            : {
                  title: 'Meta Exposed',
                  id: 'technology/meta-exposed',
                  seoTitle: 'Meta Exposed',
                  seoDescription: 'The latest in Meta Exposed',
                  metadata: {},
              }
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        'default',
        topic,
    )

    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const additionalCollections = [
        {
            pillMenuName: 'Science',
            pillMenuLink: '/technology/science',
            collectionTopics: ['technology/science'],
        },
        {
            pillMenuName: 'Smartphones',
            pillMenuLink: '/technology/smartphones',
            collectionTopics: ['technology/smartphones'],
        },
        {
            pillMenuName: 'Space',
            pillMenuLink: '/technology/space',
            collectionTopics: ['technology/space'],
        },
        {
            pillMenuName: 'Reviews',
            pillMenuLink: '/technology/reviews',
            collectionTopics: ['technology/reviews'],
        },
        {
            pillMenuName: 'Gaming',
            pillMenuLink: '/technology/gaming',
            collectionTopics: ['technology/gaming'],
        },
        {
            pillMenuName: 'Gadgets',
            pillMenuLink: '/technology/gadgets',
            collectionTopics: ['technology/gadgets'],
        },
    ]

    const content: CheckedComponentInformation[] = [
        breakingNewsComponent,

        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'unset',
                verticalGutters: ['lg', 'unset'],
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'video-hub-topic',
                        verticalSpacing: 'lg',
                        verticalGutters: ['md', 'md'],
                        props: {
                            adUnitPath: adUnitPath,
                            dataDefinitionArgs: {
                                type: 'video-listing',
                                topics: [topic.id],
                                paging: {
                                    page: 1,
                                    pageSize: 10,
                                },
                            },
                        },
                    }),
                    layout.component({
                        type: 'six-card-collection',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: [topic.id],
                                paging: {
                                    page: 1,
                                    pageSize: 6,
                                },
                            },
                        },
                    }),
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'unset',
                            verticalGutters: ['unset', 'md'],
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,
                                        slot: {
                                            id: `leaderboard-billboard-two`,
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                                mobileAdTwo,
                            ],
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {},
                    }),
                    layout.component({
                        type: 'promo-cards-seven',
                        props: {
                            verticalGutters: ['lg', 'lg'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: '7news-tech-promo-cards',
                                offset: 0,
                                pageSize: 3,
                            },
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {},
                    }),
                    ...additionalCollections
                        .map((sectionValues, index) => {
                            const adComponent = adsLayoutComponent(index)

                            if (!('collectionTopics' in sectionValues)) {
                                //check if layout.component
                                return [sectionValues, ...adComponent]
                            }

                            const {
                                pillMenuName,
                                pillMenuLink,
                                collectionTopics,
                            } = sectionValues

                            return [
                                layout.component({
                                    type: 'horizontal-pill-menu',
                                    props: {
                                        data: {
                                            type: 'single',
                                            name: pillMenuName,
                                            link: pillMenuLink,
                                        },
                                    },
                                }),
                                ...(index % 2 === 0
                                    ? [
                                          layout.component({
                                              type: 'five-card-collection',
                                              props: {
                                                  verticalSpacing: 'unset',
                                                  verticalGutters: [
                                                      'unset',
                                                      'md',
                                                  ],
                                                  dataDefinitionArgs: {
                                                      type: 'listing',
                                                      topics: collectionTopics,
                                                      paging: {
                                                          page: 0,
                                                          pageSize: 5,
                                                      },
                                                  },
                                              },
                                          }),
                                      ]
                                    : [
                                          layout.component({
                                              type: 'four-card-collection',
                                              props: {
                                                  verticalSpacing: 'unset',
                                                  verticalGutters: [
                                                      'unset',
                                                      'md',
                                                  ],
                                                  dataDefinitionArgs: {
                                                      type: 'listing',
                                                      topics: collectionTopics,
                                                      includeSubTopics: false,
                                                      paging: {
                                                          page: 1,
                                                          pageSize: 4,
                                                      },
                                                  },
                                              },
                                          }),
                                      ]),
                                ...adComponent,
                            ]
                        })
                        .flat(),
                ],
            },
        }),
    ]

    const pageComposition = layout.composition({
        type: 'box',
        props: {
            verticalGutters: ['unset', 'unset'],
            horizontalGutters: 'md',
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            breakpoints: {
                sm: {
                    horizontalGutters: 'lg',
                },
                md: {
                    horizontalGutters: 'xl',
                },
            },
        },
        contentAreas: {
            main: [
                ...content,
                layout.component({
                    type: 'taboola',
                    props: {
                        taboolaContainerId: 'taboola-below-category-thumbnails',
                        locationType: 'category',
                        isFourColumns: isTabletOrMobileViewport()
                            ? false
                            : true,
                    },
                }),
            ],
        },
    })

    return {
        kind: 'page',
        heading: topic.title,
        hideHeading: true,
        pageType: 'topic',
        pageMeta: {
            title: topic.seoTitle || topic.title,
            description:
                topic.seoDescription || `The latest in ${topic.seoTitle}`,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content:
                        `${topic.seoTitle} | 7NEWS` || `${topic.title} | 7NEWS`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
            ],
        },
        adTargeting,
        section: 'default',
        compositions: [
            layout.composition({
                type: 'box',
                props: {},
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'page-header-v2',
                            props: {
                                sectionHeader: {
                                    heading: topic.title,
                                },
                                topic: topic,
                                type: 'topic-heading',
                            },
                        }),
                    ],
                },
            }),
            pageComposition,
        ],
    }
}
