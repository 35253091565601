import {
    AllEvents,
    ImageSourceWithCaptions,
    RenderTargetContext,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import React, { useContext, useEffect, useState } from 'react'
import { formatBreadcrumb } from '../breadcrumb/helpers'
import { isTabletOrMobileViewport } from '../../__helpers'
import {
    StyeldShareWrapper,
    StyledBreadcrumbDesktopWrapper,
    StyledControlsWrapper,
    StyledEstimatedTime,
    StyledFontSizeWrapper,
    StyledHeroContainer,
    StyledInformationWrapper,
    StyledShareWrapper,
    StyledTextToSpeachWrapper,
    StyledTextWrap,
    StyledTextWrapHeader,
    StyledImageCounter,
    StyledDescriptionAuthor,
    StyledScrollButtonWrapper,
    StyledCaption,
    StyledMobileInfoContainer,
    StyledDesktopInfoContainer,
    ResponsivePictureGallery,
    StyledSwiperContainer,
    StyledArticleTimeContainer,
    StyledPublishedTimeWrapper,
    StyledLastUpdatedTimeWrapper,
    StyledEstimatedaTimeToRead,
    StyledFullScreenIconContainer,
} from './GalleryCarousel.styled'

import { Share } from '../HeroCard/Share/Share'
import { AmpSharing } from '../../buttons/Sharing/Sharing.amp'
import { ShareIcon } from '../HeroCard/ArticleHeroCard/assets/ShareIcon'
import {
    getPublishedAt,
    getUpdatedAt,
} from '../HeroCard/ArticleHeroCard/utils/formatTimeSince'
import { TextToSpeachIcon } from '../HeroCard/ArticleHeroCard/assets/TextToSpeachIcon'
import { FontSizeIcon } from '../HeroCard/ArticleHeroCard/assets/FontSizeIcon'
import { Breadcrumb } from '../breadcrumb/BreadCrumb'
import { useHistory, useLocation } from 'react-router'
import { GalleryModal } from './GalleryModal'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { Swiper as SwiperType } from 'swiper'
import { ResponsivePictureLayout } from '../../content'

export interface GalleryCarouselProps {
    onEvent: (event: AllEvents) => void
    article: ArticleLikeV4DTO
    loading?: boolean
    totalLoaded: number
    gallery: ImageSourceWithCaptions[]
    setIsGalleryOpen: (v: boolean) => void
}
export interface DynamicData {
    link: string
    headline: string
    teaser: string
    lastUpdated: string | undefined
    publicationDate: string | undefined
}

export const GalleryCarousel: React.FC<GalleryCarouselProps> = (
    props: GalleryCarouselProps,
) => {
    const { gallery, setIsGalleryOpen } = props
    const [index, setIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showSharePopup, setShowSharePopup] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(
        null,
    )

    const { renderTarget } = useContext(RenderTargetContext)

    const arrowIcon = require('./assets/arrowIcon.png')

    const data: DynamicData = {
        link: '',
        headline: props.article.heading,
        teaser: props.article.homepageTeaser,
        lastUpdated: props.article.lastUpdated,
        publicationDate: props.article.publicationDate,
    }

    const estimatedTimeToRead =
        props.article.kind === 'article' &&
        props.article.readTimeMinutes &&
        Math.ceil(props.article.readTimeMinutes)

    //just flag for displaying icon, need to implement text to speach feature
    const isTextToSpeachEnabled = false

    const isFontSizeChangeable = false

    const breadcrumbItems = formatBreadcrumb(props.article.topics.primary)

    const isMobileOrTablet = isTabletOrMobileViewport()

    const isGallery = props.article.kind === 'gallery'

    const fullScreenIcon = require('./assets/fullscreenIcon.png')

    const handleShare = async () => {
        if (isMobileOrTablet) {
            if (navigator && typeof navigator.share !== 'undefined') {
                try {
                    await navigator.share({
                        url: props.article._self,
                        text: props.article.heading,
                    })
                    setShowSharePopup(false)
                } catch (e) {
                    setShowSharePopup(false)
                    console.error(e, 'Error while sharing')
                }
            } else {
                setShowSharePopup((prev) => !prev)
            }
        } else {
            setShowSharePopup((prev) => !prev)
        }
    }

    const openModal = (index: number) => {
        setIndex(index)
        if (swiperInstance) {
            swiperInstance.slideTo(index, 0)
        }
        setIsModalOpen(true)
    }

    const closeModal = (index: number) => {
        setIsModalOpen(false)
        setIndex(index)
    }

    const handleNext = () => {
        setIndex((prevIndex) => {
            if (prevIndex < gallery.length - 1) {
                const newIndex = prevIndex + 1
                swiperInstance && swiperInstance.slideNext()
                return newIndex
            }
            return prevIndex
        })
    }
    const handlePrev = () => {
        setIndex((prevIndex) => {
            if (prevIndex === 0) {
                setIsGalleryOpen(false)
                history.replace(location.pathname)
                return prevIndex
            } else {
                const newIndex =
                    (prevIndex - 1 + gallery.length) % gallery.length
                swiperInstance && swiperInstance.slidePrev()
                return newIndex
            }
        })
    }

    useEffect(() => {
        const hash = location.hash.slice(1)

        const slideIndex = parseInt(hash, 10) - 1

        if (!isNaN(slideIndex) && swiperInstance) {
            swiperInstance.slideTo(slideIndex)
            setIndex(slideIndex)
        }
    }, [swiperInstance, setIndex, location.hash])

    useEffect(() => {
        const handleHashChange = () => {
            const hash = location.hash.slice(1)

            const slideIndex = parseInt(hash, 10) - 1

            if (!isNaN(slideIndex) && swiperInstance) {
                swiperInstance.slideTo(slideIndex)
                setIndex(slideIndex)
            }
        }

        window.addEventListener('hashchange', handleHashChange)

        return () => {
            window.removeEventListener('hashchange', handleHashChange)
        }
    }, [swiperInstance, setIndex, location.hash])

    useEffect(() => {
        if (swiperInstance) {
            const handleSlideChange = () => {
                const currentIndex = swiperInstance.realIndex
                history.replace(`#${currentIndex + 1}`)
            }
            swiperInstance.on('slideChange', handleSlideChange)

            return () => {
                swiperInstance.off('slideChange', handleSlideChange)
            }
        }
    }, [swiperInstance, history])

    return (
        <>
            <StyledBreadcrumbDesktopWrapper>
                <Breadcrumb items={breadcrumbItems} />
            </StyledBreadcrumbDesktopWrapper>
            <StyledHeroContainer renderTarget={renderTarget}>
                <StyledTextWrap>
                    <div>
                        <StyledTextWrapHeader>
                            <StyeldShareWrapper isOpened={showSharePopup}>
                                <Share
                                    article={props.article}
                                    onEvent={props.onEvent}
                                />
                            </StyeldShareWrapper>

                            <StyledInformationWrapper>
                                <StyledShareWrapper
                                    isOpened={showSharePopup}
                                    onClick={handleShare}
                                >
                                    {renderTarget === 'amp' ? (
                                        <AmpSharing
                                            type="system"
                                            height={13}
                                            width={18}
                                            data-param-url={props.article._self}
                                            data-param-text={
                                                props.article.heading
                                            }
                                        />
                                    ) : (
                                        <ShareIcon />
                                    )}
                                </StyledShareWrapper>
                                <StyledArticleTimeContainer>
                                    <StyledPublishedTimeWrapper>
                                        Published:
                                        {getPublishedAt(data.publicationDate)}
                                    </StyledPublishedTimeWrapper>
                                    <StyledLastUpdatedTimeWrapper
                                        isShareOpened={showSharePopup}
                                    >
                                        Updated:
                                        {getUpdatedAt(data.lastUpdated)}
                                    </StyledLastUpdatedTimeWrapper>
                                    {estimatedTimeToRead && (
                                        <StyledEstimatedaTimeToRead
                                            isShareOpened={showSharePopup}
                                        >
                                            {estimatedTimeToRead} min read
                                        </StyledEstimatedaTimeToRead>
                                    )}
                                </StyledArticleTimeContainer>
                                {estimatedTimeToRead && (
                                    <StyledEstimatedTime>
                                        {estimatedTimeToRead} min read
                                    </StyledEstimatedTime>
                                )}
                            </StyledInformationWrapper>
                            <StyledControlsWrapper>
                                {isTextToSpeachEnabled && (
                                    <StyledTextToSpeachWrapper>
                                        <TextToSpeachIcon />
                                    </StyledTextToSpeachWrapper>
                                )}
                                {isFontSizeChangeable && (
                                    <StyledFontSizeWrapper>
                                        <FontSizeIcon />
                                    </StyledFontSizeWrapper>
                                )}
                            </StyledControlsWrapper>
                        </StyledTextWrapHeader>
                        <StyledDesktopInfoContainer>
                            <StyledImageCounter isGallery={isGallery}>{`${
                                index + 1
                            } / ${gallery.length}`}</StyledImageCounter>
                            <StyledCaption>
                                {gallery[index].captionText}
                            </StyledCaption>
                            <StyledDescriptionAuthor>
                                {gallery[index].byline || gallery[index].credit
                                    ? ' Picture: '
                                    : null}
                                {gallery[index].byline}
                                {gallery[index].byline &&
                                    gallery[index].credit && <span>&#47;</span>}
                                {gallery[index].credit}
                            </StyledDescriptionAuthor>
                        </StyledDesktopInfoContainer>
                    </div>
                </StyledTextWrap>

                <StyledSwiperContainer>
                    <Swiper
                        onSwiper={setSwiperInstance}
                        pagination={{ clickable: true }}
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={(swiper) => {
                            setIndex(swiper.realIndex)
                        }}
                        initialSlide={index}
                        style={{
                            width: '100%',
                            height: '100%',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                        }}
                    >
                        {gallery.map((image, i) => (
                            <SwiperSlide key={i}>
                                <ResponsivePictureGallery
                                    layout={ResponsivePictureLayout.None}
                                    image={image}
                                    fixedRatio="16:9"
                                    allowOriginalCrop
                                    imageWidths={{
                                        mobile: '100vw',
                                        tablet: '100vw',
                                        desktop: `650px`,
                                        fallbackWidth: 650,
                                    }}
                                />
                                <StyledFullScreenIconContainer
                                    onClick={() => openModal(i)}
                                >
                                    <img
                                        src={fullScreenIcon}
                                        alt="full-screen-icon"
                                    />
                                </StyledFullScreenIconContainer>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <StyledScrollButtonWrapper
                        position="left"
                        onClick={handlePrev}
                        isActive={true}
                    >
                        <img src={arrowIcon} alt="Scroll" />
                    </StyledScrollButtonWrapper>
                    <StyledScrollButtonWrapper
                        position="right"
                        onClick={handleNext}
                        isActive={index !== gallery.length - 1}
                    >
                        <img src={arrowIcon} alt="Scroll" />
                    </StyledScrollButtonWrapper>
                </StyledSwiperContainer>

                <StyledMobileInfoContainer>
                    <StyledImageCounter isGallery={isGallery}>{`${
                        index + 1
                    } / ${gallery.length}`}</StyledImageCounter>
                    <StyledCaption>{gallery[index].captionText}</StyledCaption>
                    <StyledDescriptionAuthor>
                        {gallery[index].byline || gallery[index].credit
                            ? ' Picture: '
                            : null}
                        {gallery[index].byline}
                        {gallery[index].byline && gallery[index].credit && (
                            <span>&#47;</span>
                        )}
                        {gallery[index].credit}
                    </StyledDescriptionAuthor>
                </StyledMobileInfoContainer>
            </StyledHeroContainer>
            {isModalOpen && (
                <GalleryModal
                    onClose={closeModal}
                    gallery={gallery}
                    index={index}
                    setIndex={setIndex}
                />
            )}
        </>
    )
}
