import {
    createRegisterableComposition,
    RenderTargetContext,
    StickyContext,
    StickySizeProps,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { StickyContainer } from '../../content/StickyContainer/StickyContainer'

export interface StickyRouteProps {
    id?: string // ID will cause it to register its offset so that other compositions can listen
    offsetBy?: Array<string> // An array of IDs
    verticalOffset?: number // Vertical offset will be added to offsetBy
    hideAt?: number
    showOnScrollUp?: number

    hideOnPreview?: boolean
}

export const StickyRegistration = createRegisterableComposition<'main'>()(
    'sticky',
    (contentAreas, props: StickyRouteProps) => {
        const { renderTarget } = useContext(RenderTargetContext)

        if (renderTarget === 'preview' && props.hideOnPreview) {
            return null
        }
        const { hideAt, showOnScrollUp, offsetBy, id } = props

        // Content wrapper not required due to one being in the PageResolver
        return (
            <StickyContext.Consumer>
                {({ getOffset, updateOffset }) => {
                    const onResize = id
                        ? (size: StickySizeProps) => updateOffset(id, size)
                        : undefined
                    const staticOffset = props.verticalOffset
                        ? props.verticalOffset
                        : 0
                    const verticalOffset = offsetBy
                        ? getOffset(offsetBy) + staticOffset
                        : staticOffset

                    const stickyProps = {
                        hideAt,
                        showOnScrollUp,
                        offsetBy,
                        verticalOffset,
                        onResize,
                    }
                    return (
                        <StickyContainer {...stickyProps}>
                            {contentAreas.main}
                        </StickyContainer>
                    )
                }}
            </StickyContext.Consumer>
        )
    },
)
