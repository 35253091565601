import {
    BreadcrumbTopicRouteProps,
    metrics,
} from '@news-mono/component-library'
import { layout } from '../../App.routing'

export const breadcrumb = (props: BreadcrumbTopicRouteProps) =>
    layout.composition({
        type: 'box',
        props: {
            containerWidth: metrics.sevennews.siteMetrics.mainContentWidth,
            horizontalGutters: 'outerMargin',
            verticalGutters: ['outerMargin', 'unset'],
            hasBackgroundFill: true,
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'breadcrumb',
                    props,
                }),
            ],
        },
    })
