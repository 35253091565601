import { SevenNewsFooterNetworkLinks } from '@news-mono/component-library'
import { SevenNewsHeaderV2 } from 'libs/component-library/src/content/SevenNewsHeaderV2/SevenNewsHeaderV2'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import version from '../../server/version'
import { footerLinks } from '../footer'
import { navigationV2 } from '../navigationV2'
import { BaseSevenNewsProps } from './BaseSevenNews'
import { BaseSevenNewsTemplate } from './BaseSevenNews.web'

export const BaseSevenNewsPreview: React.FC<BaseSevenNewsProps> = ({
    location,
    section,
    additionalPageProperties,
    pageContents,
    pageType,
}) => (
    <BaseSevenNewsTemplate
        header={
            <SevenNewsHeaderV2
                secondaryNavLinks={[]}
                navLinks={navigationV2}
                onEvent={() => {}}
            />
        }
        content={
            <PageProps
                pageProperties={{
                    ...additionalPageProperties,
                    section,
                    pageType,
                    codebase: {
                        version: version.buildNumber,
                        sha: version.sha,
                    },
                }}
            >
                {pageContents}
            </PageProps>
        }
        footerAd={<div />}
        footerNetworkLinks={
            <SevenNewsFooterNetworkLinks
                networkLinks={footerLinks.footerCompanyLinks}
                partnerLinks={footerLinks.footerPartnerLinks}
            />
        }
    />
)
BaseSevenNewsPreview.displayName = 'BaseSevenNewsPreview'
