import styled from '@emotion/styled'
import { breakpoint, calcRem, colors, easing, fonts } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { WebLink } from '@news-mono/web-common'

export const StyledSevenAppHeroWrapper = styled('div')({
    marginTop: calcRem(10),
    display: 'flex',
    gap: calcRem(18),
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    [breakpoint('md')]: {
        marginTop: calcRem(24),
        flexDirection: 'row',
    },
})
export const StyledSevenAppHeroContent = styled('div')({
    flex: 2,
    width: '100%',
    [breakpoint('md')]: {
        maxWidth: calcRem(460),
    },
})
export const StyledSevenAppHeroContentHeader = styled('div')({
    display: 'none',
    [breakpoint('md')]: {
        height: calcRem(39),
        marginBottom: calcRem(24),
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.black20}`,
    },
})
export const StyledSevenAppHeroContentHeaderMobile = styled('div')({
    marginBottom: calcRem(16),
    marginTop: calcRem(16),
    height: calcRem(26),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.black20}`,
    [breakpoint('sm')]: {
        height: calcRem(34),
    },
    [breakpoint('md')]: {
        display: 'none',
    },
})

interface StyledShareProps {
    isOpened: boolean
}
export const StyledShareWrapper = styled('div')<StyledShareProps>(
    ({ isOpened }) => ({
        position: 'absolute',
        left: calcRem(18),
        top: 0,
        bottom: 0,
        background: colors.white,
        width: isOpened ? '100%' : 0,
        maxWidth: calcRem(230),
        borderBottom: `3px solid ${colors.sevennews.red}`,
        overflow: 'hidden',
        transition: `width ${easing.easeOut.med}`,
    }),
)

export const StyledShareIconWrapper = styled('div')<StyledShareProps>(
    ({ isOpened }) => ({
        width: calcRem(18),
        height: '100%',
        cursor: 'pointer',
        marginRight: calcRem(18),
        borderBottom: `${calcRem(3)} solid ${colors.sevennews.red}`,
        '&:hover': {
            '& svg path': {
                fill: colors.sevennews.red,
            },
        },
        '& svg path': {
            fill: isOpened ? colors.sevennews.red : undefined,
        },
    }),
)

export const StyledLogo = styled('img')({
    display: 'none',
    [breakpoint('md')]: {
        height: calcRem(49),
        marginBottom: calcRem(24),
        display: 'block',
    },
})

export const StyledLogoMobile = styled('img')({
    height: calcRem(32),
    [breakpoint('md')]: {
        display: 'none',
    },
})
export const StyledCopy = styled('h1')({
    marginBottom: calcRem(16),
    marginTop: 0,
    fontSize: calcRem(20),
    lineHeight: calcRem(29),
    fontWeight: 500,
    color: tokens.sevennews.colors.palette.nero,
    [breakpoint('sm')]: {
        fontSize: calcRem(24),
        lineHeight: calcRem(33),
    },
    [breakpoint('md')]: {
        marginBottom: calcRem(24),
    },
})

export const StyledAppDetailsWrapper = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: calcRem(10),
    marginTop: calcRem(16),
})
export const StyledDetailsParagraph = styled('p')({
    color: colors.black,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 400,
    margin: 0,
})

export const StyledDetailsParagrapMobile = styled('p')({
    color: colors.black,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 400,
    margin: 0,
    [breakpoint('md')]: {
        display: 'none',
    },
})
export const StyledEmphasizedSpan = styled('span')({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 'bold',
    color: colors.sevennews.red,
})
export const Styled7NewsSupportLink = styled('a')({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontWeight: 400,
    textDecoration: 'underline',
    color: colors.black,
})

export const StyledDownload = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})
export const StyledDownloadMobile = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [breakpoint('md')]: {
        display: 'none',
    },
})
export const StyledDownloadMobileButtons = styled('div')({
    display: 'flex',
    gap: calcRem(8),
})

export const StyledLink = styled(WebLink)({})

export const StyledStoreImage = styled('img')({})

export const StyledSevenAppHeroMedia = styled('div')({
    position: 'relative',
    flex: 3,
    width: '100%',

    [breakpoint('md')]: {
        maxWidth: calcRem(690),
        padding: calcRem(0, 35),
    },
})

export const StyledAppImage = styled('img')({
    width: '100%',
    [breakpoint('md')]: {
        maxWidth: calcRem(618),
    },
})
