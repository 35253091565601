import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { SectionHeaderProps } from '../../section-header/SectionHeader/SectionHeader'
import { SubHeadingElements } from '../../typography/Subhead/Subhead'
import { HeaderV2 } from './HeaderV2'

export type LogoTypes = 'theNightly'
export interface HeaderV2Props extends ResponsiveContainer {
    sectionHeader: SectionHeaderProps
    textCenter?: boolean
    specificHeaderSize?: SubHeadingElements
    specificFontSize?: number
    borderWidth?: number
    headerLogo?: LogoTypes
}

export const HeaderV2Registration = createRegisterableComponent(
    'header-v2',

    (props: HeaderV2Props) => {
        return <HeaderV2 {...props} />
    },
)
