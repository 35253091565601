import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const PlayerCompareWidgetContainer = styled('div')({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
    },

    '& .Opta_C_PC_N > h2': {
        display: 'none',
    },

    // team selection dropdown
    '& .Opta .Opta_C_PC_N .Opta-Cf.Opta-Comparison .Opta-Selects': {
        '& .Opta-Dropdown-Label-Text': {
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: calcRem(14),
            color: tokens.sevennews.colors.palette.white,
        },
        '& > div': {
            width: '100%',
            margin: 0,
            marginBottom: calcRem(6),

            [breakpoint('sm')]: {
                marginBottom: 0,
            },
        },

        '& .Opta-Dropdown': {
            label: {
                height: calcRem(40),
                '& .Opta-Dropdown-Label-Text': {
                    height: calcRem(40),
                    lineHeight: calcRem(40),
                },
                '& .Opta-Dropdown-Label-Button': {
                    height: calcRem(40),
                },
            },
        },
        '& .Opta-Dropdown-Half': {
            width: '50%',
            marginRight: 0,
        },
    },
    '& .Opta .Opta_C_PC_N .Opta-Selects .Opta-js-team': {
        marginBottom: 0,
        '& .Opta-Dropdown-Label': {
            backgroundColor: tokens.sevennews.colors.palette.red,
            '&:hover': {
                backgroundColor: '#B6001E80',

                '& .Opta-Dropdown-Label-Text': {
                    backgroundColor: '#B6001E80',
                },
                '& .Opta-Dropdown-Label-Button': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '& .Opta-Dropdown-Label-Text': {
            backgroundColor: tokens.sevennews.colors.palette.red,
        },
        '& .Opta-Dropdown-Label-Button': {
            backgroundColor: 'transparent',
        },
    },
    // player selection dropdown
    '& .Opta .Opta_C_PC_N .Opta-Selects .Opta-js-player': {
        [breakpoint('sm')]: {
            marginBottom: calcRem(6),
        },

        '& .Opta-Dropdown-Label': {
            '&:hover': {
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                '& .Opta-Dropdown-Label-Button': {
                    backgroundColor: 'transparent',
                },
                '& .Opta-Dropdown-Label-Text': {
                    backgroundColor: 'rgba(230, 0, 30, 0.2)',
                },
            },
        },
        '& .Opta-Dropdown-Label-Text': {
            backgroundColor: 'rgba(230, 0, 30, 0.2)',
        },
        '& .Opta-Dropdown-Label-Button': {
            backgroundColor: 'transparent',
        },
    },

    // Batting/Bowling buttons
    '& .Opta .Opta_C_PC_N .Opta-Tabs .Opta-Nav': {
        [breakpoint('sm')]: {
            backgroundColor: 'transparent',
        },
        '& ul': {
            [breakpoint('sm')]: {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
            },
        },
        '& ul li': {
            width: '50%',

            [breakpoint('sm')]: {
                width: calcRem(104),
            },
        },
        '& ul li a': {
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: calcRem(12),
            color: tokens.sevennews.colors.palette.white,
            backgroundColor: 'rgba(230, 0, 30, 0.2)',
            width: '100%',
            textAlign: 'center',
        },
        '& ul li.Opta-On a': {
            backgroundColor: tokens.sevennews.colors.palette.red,
        },
        '& .Opta-Overflowed': {
            backgroundColor: 'rgba(230, 0, 30, 0.2)',
        },
    },

    // player stat filter dropdown
    '& .Opta .Opta_C_PC_N .Opta-Tabs .Opta-TabbedContent .Opta-js-stat-bowling, & .Opta .Opta_C_PC_N .Opta-Tabs .Opta-TabbedContent .Opta-js-stat-batting':
        {
            '& .Opta-Dropdown-Label': {
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                '&:hover': {
                    backgroundColor: 'rgba(230, 0, 30, 0.2)',
                },
            },
            '& .Opta-Dropdown-Label-Text': {
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                color: tokens.sevennews.colors.palette.white,
                fontWeight: 700,
                textTransform: 'uppercase',
                fontSize: calcRem(12),
            },
            '& .Opta-Dropdown-Label-Button': {
                backgroundColor: 'transparent',
            },
        },

    '& .Opta .Opta_C_PC_N .Opta-Tabs .Opta-TabbedContent': {
        marginTop: calcRem(8),
        '& .Opta-Player-Name': {
            '& div:first-of-type': {
                fontWeight: 600,
                fontSize: calcRem(16),
            },
            '& .Opta-Team-Name': {
                fontWeight: 300,
                fontSize: calcRem(12),
            },
        },

        // player list
        '& .Opta-Table-Scroll': {
            '& > div': {
                width: '100%',
                margin: 0,
                color: tokens.sevennews.colors.palette.bauhausBlack,
            },
            '& .Opta-Player-List': {
                backgroundColor: '#F9F9F9',
                marginBottom: calcRem(8),
            },
            '& .Opta-Image-Player-Small': {
                maxHeight: calcRem(54),
                height: '100%',
                maxWidth: 'unset',
            },
            '& .Opta-Player-Card': {
                backgroundColor: '#F9F9F9',
                borderLeft: '4px solid #FFF',
                borderRight: '4px solid #FFF',
                '& .Opta-Player-Name': {
                    color: tokens.sevennews.colors.palette.bauhausBlack,
                },
                '& .Opta-Close': {
                    backgroundPosition: '-52px -95px',
                },
            },
            '& .Opta-Player-Cards': {
                tbody: {
                    td: {
                        color: tokens.sevennews.colors.palette.bauhausBlack,
                        fontSize: calcRem(16),
                        fontWeight: 300,
                    },
                    tr: {
                        height: calcRem(40),
                    },
                },
            },
        },
    },
})
