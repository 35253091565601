import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../../helpers/get-v2-topic-page'

export const getTheMorningShowPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'The Morning Show',
        id: 'the-morning-show',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'The Morning Show',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicShowPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            name: 'the-morning-show-featured',
        },
        dataDefinitionArgsOverrideSixCard: {
            name: 'the-morning-show-featured',
        },
        contentToFormat: [
            {
                pillMenuName: 'Recipes',
                pillMenuLink: '/the-morning-show/recipes',
                collectionTopics: ['the-morning-show/recipes'],
            },
            {
                pillMenuName: 'On The Show',
                pillMenuLink: '/the-morning-show/story-info',
                collectionTopics: ['the-morning-show/story-info'],
            },
        ],
        promoCardName: 'the-morning-show-promotion-cards',
        getAdTargeting,
        features,
    })

    return topicShowPage
}
