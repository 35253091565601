import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const StandingsWidgetContainer = styled('div')({
    display: 'block',
    '& .Opta': {
        margin: '0 auto',
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
        '& .Opta_W h2': {
            backgroundColor: tokens.sevennews.colors.palette.white,
            fontSize: calcRem(20),
            fontWeight: 700,
            textTransform: 'uppercase',
            color: tokens.sevennews.colors.palette.black,
            [breakpoint('sm')]: {
                fontSize: calcRem(24),
            },
        },
        '& thead th': {
            backgroundColor: tokens.sevennews.colors.palette.white,
        },
        '& abbr': {
            fontSize: calcRem(16),
            fontWeight: 600,
        },
        '& tbody tr td': {
            color: tokens.sevennews.colors.palette.black,
            fontSize: calcRem(14),
            fontWeight: 300,
            [breakpoint('sm')]: {
                fontSize: calcRem(16),
            },
        },
    },
})
