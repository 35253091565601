import { SevenNewsSection } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import {
    createV2ShowSitePage,
    createV2SitePage,
} from './v2-static-content-routes'

import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { getNewsletterPage } from '../custom/newsletter/get-newsletter-page-route'
import { SectionHeroCardProps } from 'libs/component-library/src/SevenNewsV2/HeroCard/StaticHeroCard/StaticHeroCard'
import { getAppPage } from '../custom/app/get-app-page-route'
import { getElectionWidgetPage } from '../custom/static-pages/get-election-widget-page-route'

const sevenContactUsHero = require('./images/7news-contact-us-hero.png')
const sevenContactUsHeroSpotlight = require('./images/7news-contact-us-spotlight.jpg')
const spotTheBucket = require('./images/spot-the-bucket.png')
const tmsMainHero = require('./images/tms-hero.jpg')
const sunriseMainHero = require('./images/sunrise-hero.jpg')
const aboutUsHero = require('./images/about-us-hero.png')
const editorialPolicyHero = require('./images/editorial-policy-hero.jpg')
const couponsImage = require('./images/7news-coupons.jpg')

export const staticContentRoutes: StaticRoutes<SevenNewsSection> = {
    '/contact-us': ({ getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description:
                'Do you have a question, comment or story suggestion for 7NEWS.com.au? We’d love to hear it!',
            byLine: {
                title: 'By Digital Staff',
                linkText: '7NEWS',
                linkUrl: '/',
            },
            share: {
                url: 'https://7news.com.au/contact-us',
                text: '7NEWS',
            },
            title: 'Contact Us',
            primaryTopic: {
                id: 'contact-us',
                title: 'Contact us',
                seoTitle: '',
                metadata: {},
            },
        }

        return createV2SitePage(
            'Contact Us',
            '7NEWS is trusted Australian news including local and breaking stories. Get the latest sport, weather and entertainment, as well as best in lifestyle from Sunrise and The Morning Show and original investigations from Spotlight.',
            layout.component({
                type: 'static-content',
                props: {
                    dataDefinitionArgs: {
                        // This page was renamed, but we can't rename static content in Cue easily
                        id: 'news-tips',
                    },
                    horizontalSpacing: 'md',
                    verticalSpacing: 'xl',
                },
            }),
            'default',
            getAdTargeting,
            heroCardProps,
            'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=news-contact-us',
            location.pathname,
        )
    },
    '/spotthebucket': ({ getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: spotTheBucket,
            description:
                'Big Brother KFC Spot the Bucket Competition Terms and Conditions',
            byLine: {
                title: 'By Digital Staff',
                linkText: '7NEWS',
                linkUrl: '/',
            },
            share: {
                url: 'https://7news.com.au/spotthebucket',
                text: '7NEWS',
            },
            title: 'Channel 7',
            primaryTopic: {
                id: 'spotthebucket',
                title: 'Spotthebucket',
                seoTitle: '',
                metadata: {},
            },
        }
        return createV2SitePage(
            'Spot The Bucket',
            '7NEWS is trusted Australian news including local and breaking stories. Get the latest sport, weather and entertainment, as well as best in lifestyle from Sunrise and The Morning Show and original investigations from Spotlight.',
            layout.component({
                type: 'spot-the-bucket',
                props: {},
            }),
            'default',
            getAdTargeting,
            heroCardProps,
            'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=news-contact-us',
            location.pathname,
        )
    },
    '/about-us': ({ getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: aboutUsHero,
            description:
                '7NEWS is the broadcast news service of the Seven Network and, as of 2022, the highest-rating in Australia.',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'About Us',
                linkUrl: '/about-us',
            },
            share: {
                url: 'https://7news.com.au/about-us',
                text: '7NEWS',
            },
            title: 'About Us',
            primaryTopic: {
                id: 'about-us',
                title: 'About us',
                seoTitle: '',
                metadata: {},
            },
        }
        return createV2SitePage(
            'About Us',
            '7NEWS is the top-rated news service in Australia, broadcasting national bulletins from Sydney and local bulletins from various cities. 7NEWS also provide news updates for the Gold Coast and regional markets in Queensland, New South Wales, Victoria and Western Australia.',
            layout.component({
                type: 'static-content',
                props: {
                    dataDefinitionArgs: {
                        id: 'about-us',
                    },
                    horizontalSpacing: 'md',
                    verticalSpacing: 'xl',
                },
            }),
            'default',
            getAdTargeting,
            heroCardProps,
            'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=footer-button&utm_campaign=about-us',
            location.pathname,
        )
    },
    '/editorial-policy': ({ getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: editorialPolicyHero,
            description:
                '7NEWS.com.au is a leading online news destination in Australia and operates under the The Seven West Media Group Editorial Policy.',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'Editorial Policy',
                linkUrl: '/editorial-policy',
            },
            share: {
                url: 'https://7news.com.au/editorial-policy',
                text: '7NEWS.com.au is a leading online news destination in Australia and operates under the The Seven West Media Group Editorial Policy.',
            },
            title: 'Editorial Policy',
            primaryTopic: {
                id: 'editorial-policy',
                title: 'Editorial policy',
                seoTitle: '',
                metadata: {},
            },
        }
        return createV2SitePage(
            'Editorial Policy',
            'This Editorial Policy outlines the 7NEWS editorial values and the standards that we apply to all our content across multiple platforms and streams.',
            layout.component({
                type: 'static-content',
                props: {
                    dataDefinitionArgs: {
                        id: '43b9afef-0d7f-4a59-9b6b-14a655b50162',
                    },
                    horizontalSpacing: 'md',
                    verticalSpacing: 'xl',
                },
            }),
            'default',
            getAdTargeting,
            heroCardProps,
            'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=footer-button&utm_campaign=editorial-policy',
            location.pathname,
        )
    },
    '/sunrise/contact-us': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sunriseMainHero,
            description:
                'Do you have a question, comment or story suggestion for Sunrise? We’d love to hear it!',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'Sunrise',
                linkUrl: '/sunrise',
            },
            share: {
                url: 'https://7news.com.au/sunrise/contact-us!',
                text: '7NEWS',
            },
            title: 'Contact Us',
            primaryTopic: {
                id: 'sunrise/contact-us',
                title: 'Sunrise',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'contact-us',
                    title: 'Contact us',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'contact-us',
            sectionTitle: 'Contact Us | Sunrise',
            description:
                'Do you have a question, comment or story suggestion for Sunrise? We’d love to hear it!',
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=sunrise-contact-us',
            pathName: location.pathname,
            staticContentOverriderId: 'sunrise--contact-us',
        })
    },
    '/sunrise/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sunriseMainHero,
            description: '',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'Sunrise',
                linkUrl: '/sunrise',
            },
            share: {
                url: 'https://7news.com.au/sunrise/the-team',
                text: 'Sunrise',
            },
            title: 'Meet the Team',
            primaryTopic: {
                id: 'sunrise/the-team',
                title: 'The team',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'contact-us',
                    title: 'Contact us',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }
        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle:
                'Sunrise Team - The Presenters, Cast & Hosts of Sunrise | Sunrise',
            description: `Meet Matt Shirvington, Natalie Barr, Mark Beretta, Edwina Bartholomew & Sam Mac. Australia's most popular brekky show - Weekdays from 5:30am, weekends from 7am on Channel 7.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=sunrise-the-team',
            pathName: location.pathname,
            staticContentOverriderId: 'sunrise--the-team',
        })
    },
    '/sunrise/newsletter': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sunriseMainHero,
            description:
                'Sign-up for the Sunrise Family Newsletter and to receive the latest news, videos, behind-the-scenes moments and access to competitions delivered right to your inbox!',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'Sunrise',
                linkUrl: '/sunrise',
            },
            share: {
                url: 'https://7news.com.au/sunrise/newsletter',
                text: 'Sunrise',
            },
            title: 'Newsletter',
            primaryTopic: {
                id: 'sunrise/newsletter',
                title: 'Newsletter',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'sunrise',
                    title: 'Sunrise',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        const isNewsletterSignUpIframeEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            '7-news-sunrise-newsletter-iframe',
        )

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'newsletter',
            sectionTitle: 'Newsletter | Sunrise',
            description:
                'Sign-up for the Sunrise Family Newsletter to receive the latest news, videos, behind-the-scenes moments and access to competitions delivered right to your inbox!',
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=sunrise-newsletter',
            pathName: location.pathname,
            staticContentOverriderId: 'sunrise--newsletter',
            additionalBodyContent: [
                ...(isNewsletterSignUpIframeEnabled
                    ? [
                          layout.component({
                              type: 'iframe',
                              props: {
                                  link: 'https://cloud.mail.7news.com.au/sunrise-newsletter',
                                  height: 1364,
                              },
                          }),
                      ]
                    : []),
            ],
        })
    },
    '/spotlight/contact-us': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHeroSpotlight,
            description:
                'Do you have a question, comment or story suggestion for Spotlight? We’d love to hear it!',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'Spotlight',
                linkUrl: '/spotlight',
            },
            share: {
                url: 'https://7news.com.au/spotlight/contact-us',
                text: 'Spotlight',
            },
            title: 'Contact Us',
            primaryTopic: {
                id: 'spotlight/contact-us',
                title: 'Contact us',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'spotlight',
                    title: 'Spotlight',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }
        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'contact-us',
            sectionTitle: 'Contact Us | Spotlight',
            description: 'Contact Us',
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/7news-spotlight?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=spotlight-contact-us',
            pathName: location.pathname,
            staticContentOverriderId: 'spotlight--contact-us',
        })
    },
    '/the-morning-show/contact-us': ({
        config,
        getAdTargeting,
        location,
        store,
    }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: tmsMainHero,
            description:
                'Do you have a question, comment or story suggestion for The Morning Show? We’d love to hear it!',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'The Morning Show',
                linkUrl: '/the-morning-show',
            },
            share: {
                url: 'https://7news.com.au/the-morning-show/contact-us',
                text: 'The morning show',
            },
            title: 'Contact Us',
            primaryTopic: {
                id: 'the-morning-show/contact-us',
                title: 'Contact us',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'the-morning-show',
                    title: 'The morning show',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }
        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'contact-us',
            sectionTitle: 'Contact Us | The Morning Show',
            description:
                'Do you have a question, comment or story suggestion for The Morning Show? We’d love to hear it!',
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/the-morning-show?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=tms-contact-us',
            pathName: location.pathname,
            staticContentOverriderId: 'the-morning-show--contact-us',
        })
    },
    '/the-morning-show/be-part-of-the-show': ({
        config,
        getAdTargeting,
        location,
        store,
    }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: tmsMainHero,
            description:
                'We love giving The Morning Show viewers an opportunity to be a part of our show. Find out more below on how to register your interest for your segment of choice!',
            byLine: {
                title: 'By Digital Staff',
                linkText: 'The Morning Show',
                linkUrl: '/the-morning-show',
            },
            share: {
                url: 'https://7news.com.au/the-morning-show/be-part-of-the-showe',
                text: 'The morning show',
            },
            title: 'Be Part of the show',
            primaryTopic: {
                id: 'the-morning-show/be-part-of-the-show',
                title: 'Be part of the show',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'the-morning-show',
                    title: 'The morning show',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }
        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'be-part-of-the-show',
            sectionTitle: 'Be Part of the Show | The Morning Show',
            description:
                'We love giving The Morning Show viewers an opportunity to be a part of our show. Find out more below on how to register your interest for your segment of choice! Weekdays from 9am across Australia - The Morning Show brings you a mix of news, entertainment, lifestyle and human interest stories.',
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink:
                'https://7plus.com.au/the-morning-show?utm_source=7NEWS&utm_medium=7plus-article-button&utm_campaign=tms-bepartofshow',
            pathName: location.pathname,
            staticContentOverriderId: 'the-morning-show--be-part-of-the-show',
        })
    },
    '/news/wa/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description: 'Meet the 7NEWS Perth team',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/news/wa/the-team',
                text: 'Meet the 7NEWS Perth team',
            },
            title: '7NEWS Perth',
            primaryTopic: {
                id: 'news/wa',
                title: 'WA',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle: 'Meet the 7NEWS Perth team',
            description: `The who's who of Perth news with Susannah Carr and Rick Ardon, plus Basil Zempilas, Adrian Barich, Samantha Jolly and Angela Tsun.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink: '',
            pathName: location.pathname,
            staticContentOverriderId: 'news--wa--the-team',
        })
    },
    '/news/sa/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description: 'Meet the 7NEWS Adelaide team',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/news/sa/the-team',
                text: 'Meet the 7NEWS Adelaide team',
            },
            title: '7NEWS Adelaide',
            primaryTopic: {
                id: 'news/sa',
                title: 'SA',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle: 'Meet the 7NEWS Adelaide team',
            description: `The who's who of Perth news with Susannah Carr and Rick Ardon, plus Basil Zempilas, Adrian Barich, Samantha Jolly and Angela Tsun.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink: '',
            pathName: location.pathname,
            staticContentOverriderId: 'news--sa--the-team',
        })
    },
    '/news/qld/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description: 'Meet the 7NEWS Brisbane team',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/news/qld/the-team',
                text: 'Meet the 7NEWS Brisbane team',
            },
            title: '7NEWS Brisbane',
            primaryTopic: {
                id: 'news/qld',
                title: 'QLD',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle: 'Meet the 7NEWS Brisbane team',
            description: `The who's who of Perth news with Susannah Carr and Rick Ardon, plus Basil Zempilas, Adrian Barich, Samantha Jolly and Angela Tsun.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink: '',
            pathName: location.pathname,
            staticContentOverriderId: 'news--qld--the-team',
        })
    },
    '/news/vic/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description: 'Meet the 7NEWS Melbourne team',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/news/vic/the-team', //to do: change route
                text: 'Meet the 7NEWS Melbourne team',
            },
            title: '7NEWS Melbourne',
            primaryTopic: {
                id: 'news/vic',
                title: 'VIC',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }

        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle: 'Meet the 7NEWS Melbourne team',
            description: `The who's who of Perth news with Susannah Carr and Rick Ardon, plus Basil Zempilas, Adrian Barich, Samantha Jolly and Angela Tsun.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink: '',
            pathName: location.pathname,
            staticContentOverriderId: 'news--vic--the-team',
        })
    },
    '/news/nsw/the-team': ({ config, getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: sevenContactUsHero,
            description: 'Meet the 7NEWS Sydney team',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/news/nsw/the-team',
                text: 'Meet the 7NEWS Sydney team',
            },
            title: '7NEWS Sydney',
            primaryTopic: {
                id: 'news/nsw',
                title: 'NSW',
                seoTitle: '',
                metadata: {},
                parent: {
                    id: 'news',
                    title: 'News',
                    seoTitle: '',
                    metadata: {},
                },
            },
        }
        return createV2ShowSitePage({
            section: 'default',
            contentBlock: 'the-team',
            sectionTitle: 'Meet the 7NEWS Sydney team',
            description: `The who's who of Perth news with Susannah Carr and Rick Ardon, plus Basil Zempilas, Adrian Barich, Samantha Jolly and Angela Tsun.`,
            config,
            getAdTargeting,
            heroCardProps,
            sevenPlusButtonLink: '',
            pathName: location.pathname,
            staticContentOverriderId: 'news--nsw--the-team',
        })
    },

    '/newsletters': ({ config, getAdTargeting, store }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                '7-news-newsletter-route',
            )
        ) {
            return null
        }

        return getNewsletterPage(
            config,
            getAdTargeting,
            store.getState().toggles,
        )
    },
    '/politics/politics-election-qld/seat-count': ({
        config,
        getAdTargeting,
        store,
    }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                '7-news-qld-election-widgets',
            )
        ) {
            return null
        }

        return getElectionWidgetPage(
            config,
            getAdTargeting,
            store.getState().toggles,
        )
    },
    '/coupons-partnership': ({ getAdTargeting, location, store }) => {
        const heroCardProps: SectionHeroCardProps = {
            heroImage: couponsImage,
            description:
                '7NEWS Coupons is partnered with The Savings Group to provide coupon codes, deals, sales and other money-saving offers. The coupon codes are powered by The Savings Group.',
            byLine: {
                title: 'By Digital Staff',
                linkText: '',
                linkUrl: '',
            },
            share: {
                url: 'https://7news.com.au/coupons-partnership',
                text: '7NEWS',
            },
            title: 'The Seven Couponing Team',
            primaryTopic: {
                id: 'coupons-partnership',
                title: 'The Seven Couponing Team',
                seoTitle: '',
                metadata: {},
            },
        }
        return createV2SitePage(
            'Our Partnership with The Savings Group',
            '7NEWS Coupons is partnered with The Savings Group to provide coupon codes, deals, sales and other money-saving offers. The coupon codes are powered by The Savings Group.',
            layout.component({
                type: 'coupons-partnership',
                props: {},
            }),
            'default',
            getAdTargeting,
            heroCardProps,
            'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=footer-button&utm_campaign=coupons-partnership',
            location.pathname,
        )
    },
    '/app': ({ getAdTargeting, store }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                '7-news-app-page',
            )
        ) {
            return null
        }
        return getAppPage(getAdTargeting)
    },
}
