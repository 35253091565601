import { LocationState } from '@news-mono/web-common'

export const renderNewsRoomScript = (path: string, location: LocationState) => {
    const pageDashboard = {
        NSW: 'Sydney, NSW',
        VIC: 'Melbourne, VIC & Hobart, TAS',
        QLD: 'Brisbane & Queensland',
        QLDREG: 'Brisbane & Queensland',
        WA: 'Perth, WA',
        SA: 'Adelaide, SA & NT',
        TAS: 'Melbourne, VIC & Hobart, TAS',
        NT: 'Adelaide, SA & NT',
    }

    if (path === '/') {
        return `
      window._newsroom = window._newsroom || [];
      window._newsroom.push({pageTemplate: 'home'});
      window._newsroom.push({pageDashboard:'${
          pageDashboard[location.userSelectedRegion || 'NSW']
      }'});
      window._newsroom.push('auditClicks');
      window._newsroom.push('trackPage');

      !function (e, f, u) {
          e.async = 1;
          e.src = u;
          f.parentNode.insertBefore(e, f);
      }(document.createElement('script'),
          document.getElementsByTagName('script')[0], '//c2.taboola.com/nr/sevenwestmedia-7news/newsroom.js');
`
    } else {
        return `
      window._newsroom = window._newsroom || [];

      !function (e, f, u) {
      e.async = 1;
      e.src = u;
      f.parentNode.insertBefore(e, f);
      }(document.createElement('script'),
          document.getElementsByTagName('script')[0], '//c2.taboola.com/nr/sevenwestmedia-7news/newsroom.js');
`
    }
}
