import {
    CardBreakpointRatios,
    createCardClickedEvent,
    FixedRatio,
    PublicationCardItem,
    toLinkWithHint,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import {
    StyledCardMedia,
    StyledLink,
    StyledVideoCard,
} from '../../cards/VideoCard/VideoCard.styled'
import {
    applyContainerRatio,
    ResponsiveContainer,
} from '../../content/Picture/responsive'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { isVideoClick } from '../../__helpers/video-card-helpers'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { MediaMode } from '../CardMedia/CardMedia'
import { doesParticularRatioExist } from '../CardMedia/image-helpers'
import { Kicker } from '../Kicker/Kicker'

export interface CardOrientationOptions {
    type: 'Landscape-VideoCard'
    maxBreakpoint?: 'sm' | 'md'
}

interface VideoCardProps extends CommonCardProps, ResponsiveContainer {
    mediaMode?: MediaMode
    isLarge?: boolean
    verticalSpacing?: keyof ThemeMargins
    /**
     * defaults to `4:3`
     */
    fixedRatio?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    fullWidthOnMobile?: boolean
    disableImageLazyLoad?: boolean
    cardNumber: number
    innerRef?: React.RefObject<any>
    type?: string // Type of card

    adUnitPath?: string
    willPlayVideoInline?: boolean

    item: MaybeLoaded<PublicationCardItem>
    getVideoQueue?: GetVideoQueue
}

export const VideoCard: React.FC<VideoCardProps> = (props) => {
    function linkClicked(e: React.MouseEvent<HTMLElement>) {
        if (!props.item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        if (
            props.willPlayVideoInline &&
            props.fixedRatio &&
            doesParticularRatioExist(props.fixedRatio, '16:9') &&
            isVideoClick(e.nativeEvent.target)
        ) {
            e.preventDefault()
            return
        } else {
            props.onEvent(
                createCardClickedEvent(
                    props.onEvent,
                    props.item.result,
                    'VideoCard',
                    'VideoCard',
                    props.cardNumber,
                ),
            )
        }
    }

    const { className, verticalSpacing, isLarge, fixedRatio } = props

    const cardItem = props.item
    const getVideoQueue = !props.item.loaded ? undefined : props.getVideoQueue

    return (
        <StyledVideoCard
            ref={props.innerRef}
            className={className}
            data-topic={
                props.item.loaded
                    ? props.item.result.primaryTopic.id
                    : undefined
            }
            isLarge={isLarge}
            verticalSpacing={verticalSpacing}
            fullWidthOnMobile={true}
        >
            <StyledLink
                to={toLinkWithHint(props.item)}
                onClick={linkClicked}
                isLarge={isLarge}
            >
                {cardItem.loaded && (
                    <Kicker
                        size={'large'}
                        kickerPosition={'topleftinset'}
                        kickerType="block"
                    >
                        {cardItem.result.kicker}
                    </Kicker>
                )}

                <StyledCardMedia
                    item={cardItem}
                    fixedRatio={fixedRatio}
                    imageWidths={applyContainerRatio(
                        props.containerWidthRatios,
                        {
                            mobile: '100vw',
                            tablet: '628px',
                            desktop: '628px',
                            fallbackWidth: 628,
                        },
                    )}
                    onEvent={props.onEvent}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    isLarge={isLarge}
                    getVideoQueue={getVideoQueue}
                    willPlayVideoInline={props.willPlayVideoInline}
                    adUnitPath={props.adUnitPath}
                    mediaMode="default"
                />
            </StyledLink>
        </StyledVideoCard>
    )
}
VideoCard.displayName = 'VideoCard'
