import React from 'react'
import { ThemeMargins } from '../../__styling'
import {
    StyledWidgetContainer,
    StyledWidgetIframe,
} from './QLDElectionHeaderWidget.styled'

export interface QLDElectionHeaderWidgetProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const QLDElectionHeaderWidget = (
    props: QLDElectionHeaderWidgetProps,
) => {
    return (
        <StyledWidgetContainer
            verticalSpacing={props.verticalSpacing}
            verticalGutters={props.verticalGutters}
        >
            <StyledWidgetIframe src="https://stikwall7newswiprod.z26.web.core.windows.net/header/QLD/2024/P?options=enableliveracepolling" />
        </StyledWidgetContainer>
    )
}
