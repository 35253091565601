import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { NewsJustInCarousel } from './NewsJustInCarousel'
import { ThemeMargins } from '../../__styling'
import { NewsJustInCarouselSkeleton } from './NewsJustInCarouselSkeleton'

export interface NewsJustInCarouselRoutingProps extends ResponsiveContainer {
    verticalSpacing: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const NewsJustInCarouselRegistration =
    createRegisterableComponentWithData(
        'just-in-carousel',
        ContentDataDefinitionLoader,
        (props: NewsJustInCarouselRoutingProps, data, services) => {
            if (
                !data.loaded ||
                (data.loaded && data.result.publications.length <= 0)
            ) {
                return (
                    <NewsJustInCarouselSkeleton
                        verticalSpacing={props.verticalSpacing}
                        verticalGutters={props.verticalGutters}
                    />
                )
            }
            return (
                <NewsJustInCarousel
                    items={
                        data.loaded
                            ? { loaded: true, result: data.result.publications }
                            : { loaded: false }
                    }
                    onEvent={services.onEvent}
                    verticalSpacing={props.verticalSpacing}
                    verticalGutters={props.verticalGutters}
                />
            )
        },
    )
