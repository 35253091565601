import styled from '@emotion/styled'
import { calcRem, metrics, ThemeMargins } from '../../__styling'

export interface GridProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const StyledWidgetContainer = styled('div')<GridProps>(
    ({ verticalSpacing, verticalGutters, theme }) => ({
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
        height: calcRem(800),
    }),
)

export const StyledWidgetIframe = styled('iframe')(({}) => [
    {
        width: '100%',
        height: '100%',
        border: 'none',
    },
])
