import { MatchCenterEvents } from '@news-mono/web-common'
import React from 'react'
import { Skeleton } from '../../content'
import { ScoreBoard } from '../../ScoreBoard/ScoreBoard'
import { CricketScoreBoardProps } from './CricketScoreBoard.routing'
import {
    StyledHiddenMainHeader,
    StyledHiddenSecondaryHeader,
    StyledScoreboardSkeletonWrapper,
} from './CricketScoreBoard.styled'
import { useFetchFixtureData } from './useFetchFixtureData'

interface Props extends CricketScoreBoardProps {
    onEvent: (event: MatchCenterEvents) => void
}

export const CricketScoreBoard = ({
    gameId,
    apiData,
    verticalGutters,
    verticalSpacing,
    onEvent,
}: Props) => {
    const { data, error, loading } = useFetchFixtureData(gameId, apiData)

    if (loading) {
        return (
            <StyledScoreboardSkeletonWrapper>
                <Skeleton count={1} dark={false} />
            </StyledScoreboardSkeletonWrapper>
        )
    }
    if (!data) {
        return null
    }

    const hiddenTitle = `${data.homeTeam.name} vs ${data.awayTeam.name}`
    const hiddenTitleSecondary = `${data.competitionName}`

    return (
        <>
            <StyledHiddenMainHeader>{hiddenTitle}</StyledHiddenMainHeader>
            <StyledHiddenSecondaryHeader>
                {hiddenTitleSecondary}
            </StyledHiddenSecondaryHeader>
            <ScoreBoard
                gameId={gameId}
                response={{ type: 'cricket', data: data }}
                verticalGutters={verticalGutters}
                verticalSpacing={verticalSpacing}
                onEvent={onEvent}
            />
        </>
    )
}
