import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, breakpointMax, calcRem, fonts } from '../../__styling'

export const FixturesWidgetContainer = styled('div')({
    display: 'block',
    '& .Opta': {
        maxWidth: '800px',
        maxHeight: '80vh',
        overflowY: 'scroll',
        margin: '0 auto',
        fontFamily: fonts.sevennews.sansSerif,
        textTransform: 'uppercase',
        [breakpoint('md')]: {
            maxHeight: '90vh',
        },

        '& .Opta_W h2': {
            display: 'none',
        },
        '& .Opta-Tabs .Opta-Nav>ul': {
            backgroundColor: 'transparent',
        },

        '& .Opta-Nav ': {
            backgroundColor: 'transparent',
            '& ul': {
                display: 'flex',
                backgroundColor: 'transparent',
                overflow: 'scroll',
                '&::-webkit-scrollbar': { display: 'none' },
            },
            '& ul li': {
                width: '25%',
                border: 'solid 1px transparent',
                [breakpoint('md')]: {
                    width: '20%',
                },
                [breakpoint('sm')]: {
                    width: '16.5%',
                },
            },
            '& ul li a': {
                fontWeight: 700,
                textTransform: 'uppercase',
                fontSize: calcRem(12),
                color: tokens.sevennews.colors.palette.white,
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                width: '100%',
                textAlign: 'center',
            },
            '& ul li.Opta-On a': {
                backgroundColor: tokens.sevennews.colors.palette.red,
            },
            '& .Opta-Overflowed': {
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
            },
        },
        '& .Opta-Even td': {
            background: 'transparent',
        },
        '&.Opta .Opta_C .Opta-Fixture .Opta-Fixture-Header .Opta-TeamName': {
            textAlign: 'center',
            fontSize: calcRem(17),
            fontWeight: 700,
            padding: calcRem(0, 10),
            [breakpointMax('sm')]: {
                fontSize: calcRem(13),
                padding: 0,
            },
        },

        '& .Opta_C .Opta-Fixture .Opta-Fixture-Header .Opta-Match-Result div': {
            backgroundColor: 'transparent',
            fontSize: calcRem(40),
            fontWeight: 700,
            color: '#333333',
            [breakpointMax('sm')]: {
                fontSize: calcRem(31),
            },
        },

        '& .Opta_C .Opta-Fixture td': {
            padding: 0,
        },
        '& .Opta-Matchdata': {
            padding: calcRem(8),
            '& dt, dd': {
                fontSize: calcRem(15),
                color: 'rgba(0, 0, 0, 0.5)',
                marginTop: 0,
                paddingTop: 0,
                [breakpointMax('sm')]: {
                    fontSize: calcRem(11),
                },
            },
        },
        '& .Opta-Match-Link': {
            '& a': {
                width: calcRem(200),
                height: calcRem(35),
                background: 'rgba(51, 51, 51, 0.5)',
                fontSize: calcRem(15),
                fontWeight: 600,
                color: tokens.sevennews.colors.palette.white,
                padding: calcRem(7, 40),
                [breakpointMax('sm')]: {
                    fontSize: calcRem(12),
                },
            },
        },
    },
    '& .css-s85pgw-FixturesWidgetContainer .Opta .Opta-Fixture': {
        border: 'solid 3px red',
        margin: calcRem(20, 0),
    },

    '& .Opta .Opta-H4, .Opta h4': {
        backgroundColor: 'transparent',
        margin: calcRem(12, 0),
        border: 'none',
        '& span': {
            margin: calcRem(8, 0),
            fontSize: calcRem(18),
            [breakpointMax('sm')]: {
                fontSize: calcRem(14),
            },
        },
    },

    '& .Opta .Opta_C .Opta-Fixture tr:last-child td': {
        padding: calcRem(5, 0, 20),
    },
})
