import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getBestPicksPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: `Best Picks`,
        id: 'best-picks',
        seoTitle: resolution.resolution?.topic?.seoTitle || '',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        contentToFormat: [
            {
                pillMenuName: 'Best Fashion Picks',
                pillMenuLink: '/best-picks/fashion',
                collectionTopics: ['best-picks/fashion'],
            },
            {
                pillMenuName: 'Best Beauty Picks',
                pillMenuLink: '/best-picks/beauty',
                collectionTopics: ['best-picks/beauty'],
            },
            {
                pillMenuName: 'Best Health Picks',
                pillMenuLink: '/best-picks/health',
                collectionTopics: ['best-picks/health'],
            },
            {
                pillMenuName: 'Best Fitness Picks',
                pillMenuLink: '/best-picks/fitness',
                collectionTopics: ['best-picks/fitness'],
            },
            {
                pillMenuName: 'Best Kids Picks',
                pillMenuLink: '/best-picks/baby-kids',
                collectionTopics: ['best-picks/baby-kids'],
            },
            {
                pillMenuName: 'Best Electronics Picks',
                pillMenuLink: '/best-picks/appliances',
                collectionTopics: ['best-picks/appliances'],
            },
            {
                pillMenuName: 'Best Travel Picks',
                pillMenuLink: '/best-picks/travel',
                collectionTopics: ['best-picks/travel'],
            },
            {
                pillMenuName: 'Best Home & Garden Picks',
                pillMenuLink: '/best-picks/home-garden',
                collectionTopics: ['best-picks/home-garden'],
            },
            {
                pillMenuName: 'Best Tech Picks',
                pillMenuLink: '/best-picks/tech',
                collectionTopics: ['best-picks/tech'],
            },
        ],
        promoCardName: 'best-picks-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
