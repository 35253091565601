import { CardItem, CollectionEvent } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useRef } from 'react'
import SwiperClass, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { ThemeMargins } from '../../__styling'
import { invertMaybeLoadedItems } from '../../collections/helpers/loading'
import { ContentCard } from '../cards/ContentCard/ContentCard'
import {
    StyledCarouselCardsWrapper,
    StyledJustInTitle,
    StyledNewsJustInCarouselWrapper,
} from './NewsJustInCarousel.styled'
import ArrowUp from './assets/Arrow'

export interface NewsJustInCarouselProps {
    items: MaybeLoaded<CardItem[]>
    onEvent: (event: CollectionEvent) => void
    verticalSpacing: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}
export const NewsJustInCarousel: React.FC<NewsJustInCarouselProps> = ({
    items,
    onEvent,
    verticalSpacing,
    verticalGutters,
}) => {
    const swiperRef = useRef<SwiperClass>()
    const loadedItems = invertMaybeLoadedItems(items, 3)

    return (
        <StyledNewsJustInCarouselWrapper
            verticalSpacing={verticalSpacing}
            verticalGutters={verticalGutters}
        >
            <StyledCarouselCardsWrapper>
                <Swiper
                    slidesPerView={'auto'}
                    watchSlidesProgress={true}
                    spaceBetween={45}
                    loop={false}
                    watchOverflow={false}
                    navigation={{}}
                    slideToClickedSlide={true}
                    allowSlideNext={true}
                    allowSlidePrev={true}
                    modules={[Navigation]}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper
                    }}
                >
                    {loadedItems.map((item, itemIndex) => {
                        return (
                            <SwiperSlide
                                key={itemIndex}
                                virtualIndex={itemIndex}
                            >
                                <ContentCard
                                    item={item}
                                    cardNumber={itemIndex}
                                    cardType="small"
                                    onEvent={onEvent}
                                    displayRightSeparator={
                                        itemIndex < loadedItems.length - 1
                                    }
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </StyledCarouselCardsWrapper>

            <StyledJustInTitle>
                <ArrowUp />
                Just In
            </StyledJustInTitle>
        </StyledNewsJustInCarouselWrapper>
    )
}
