import {
    SevenNewsSection,
    isTabletOrMobileViewport,
    metrics,
} from '@news-mono/component-library'
import { QueryString, StaticPageRoute } from '@news-mono/web-common'
import xss from 'xss'
import { layout } from '../../App.routing'

interface SearchQuery extends QueryString {
    search: string
    filter: string
}

function queryHasSearch(query: QueryString): query is SearchQuery {
    if (query.search && typeof query.search === 'string') {
        return true
    }
    return false
}
function queryHasFilter(query: QueryString): query is SearchQuery {
    if (query.filter && typeof query.filter === 'string') {
        return true
    }
    return false
}
export const getSearchPageRouteInfo =
    (
        query: QueryString,
        seoPage?: number | null,
        currentUrl?: string,
        pathname?: string,
    ): StaticPageRoute<SevenNewsSection> =>
    ({ config, getAdTargeting }) => {
        const searchValue = queryHasSearch(query) ? xss(query.search) : false

        // Ensure we keep the search terms in the canonicalUrl on the search result page
        const canonicalUrl =
            config.publicUrl +
            '/search' +
            (searchValue ? '?search=' + encodeURIComponent(searchValue) : '')

        const adTargeting = getAdTargeting('search', 'default', 'search')

        return {
            kind: 'page',
            heading: `Search: ${
                searchValue ? searchValue : 'You need to provide a search term!'
            }`,
            hideHeading: true,
            pageType: 'search',
            pageMeta: {
                title: 'Search | 7NEWS',
                description:
                    'Search 7NEWS for latest news, sport, lifestyle and entertainment news',
                link: [{ rel: 'canonical', href: canonicalUrl }],
            },
            adTargeting,
            section: 'default',
            compositions: [
                layout.composition({
                    type: 'box',
                    props: {},
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'page-header-v2',
                                props: {
                                    sectionHeader: {
                                        heading: 'Search',
                                    },
                                },
                            }),
                        ],
                    },
                }),
                layout.composition({
                    type: 'box',
                    props: {
                        verticalGutters: ['lg', 'unset'],
                        horizontalGutters: 'md',
                        containerWidth:
                            metrics.sevennews.siteMetrics
                                .sevenEntMainContentWidth,
                        breakpoints: {
                            sm: {
                                horizontalGutters: 'lg',
                            },
                            md: {
                                horizontalGutters: 'xl',
                            },
                        },
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'search',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'listing',
                                        searchTerm: searchValue
                                            ? searchValue
                                            : '',

                                        options: {
                                            paging: {
                                                page: seoPage ? seoPage : 1,
                                                pageSize: 20,
                                            },
                                        },
                                        currentUrl,
                                        pathname,
                                    },
                                },
                            }),

                            layout.component({
                                type: 'taboola',
                                props: {
                                    taboolaContainerId:
                                        'taboola-below-category-thumbnails',
                                    locationType: 'category',
                                    isFourColumns: isTabletOrMobileViewport()
                                        ? false
                                        : true,
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    }
