import React from 'react'
import { ThemeMargins } from '../../__styling'
import {
    StyledWidgetContainer,
    StyledWidgetIframe,
} from './QLDElectionSeatCountWidget.styled'

export interface QLDElectionSeatCountWidgetProps {
    verticalSpacing?: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const QLDElectionSeatCountWidget = (
    props: QLDElectionSeatCountWidgetProps,
) => {
    return (
        <StyledWidgetContainer
            verticalSpacing={props.verticalSpacing}
            verticalGutters={props.verticalGutters}
        >
            <StyledWidgetIframe src="https://stikwall7newswiprod.z26.web.core.windows.net/map/QLD/2024/P?options=enableliveracepolling" />
        </StyledWidgetContainer>
    )
}
