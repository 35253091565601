export function isLocalStorageAvailable() {
    try {
        /**
         * If Chrome has third party cookies disabled, then accessing
         * localStorage on window will throw an error.
         */
        return localStorage !== undefined
    } catch {
        return false
    }
}
