import { metrics, SevenNewsSection } from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { SectionHeroCardProps } from 'libs/component-library/src/SevenNewsV2/HeroCard/StaticHeroCard/StaticHeroCard'
import { layout } from '../../App.routing'
import { stickyWithOffset } from '../shared-components/compositions'
import {
    sidebarAdBespokeNative,
    sidebarAdHalfPageMrec,
    sidebarAdMrecOne,
} from '../shared-components/sidebar-ads'
import { topHeadlines } from '../shared-components/top-headlines'

export const createV2ShowSitePage = (options: {
    section: SevenNewsSection
    contentBlock:
        | 'contact-us'
        | 'the-team'
        | 'newsletter'
        | 'be-part-of-the-show'
    sectionTitle: string
    description: string
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    heroCardProps: SectionHeroCardProps
    sevenPlusButtonLink: string
    pathName?: string
    staticContentOverriderId?: string
    additionalBodyContent?: CheckedComponentInformation[]
}): PageType<SevenNewsSection> => {
    const {
        section,
        contentBlock,
        sectionTitle,
        description,
        getAdTargeting,
        heroCardProps,
        sevenPlusButtonLink,
        pathName,
        staticContentOverriderId,
        additionalBodyContent,
    } = options

    const {
        adUnitPath,
        ssAdUnits,
        topics: adTopics,
    } = getAdTargeting('article', section, {
        title: sectionTitle,
        id: section,
        seoTitle: sectionTitle,
        metadata: {},
    })

    const adTargeting: AdTargeting = {
        pageId: sectionTitle,
        adUnitPath,
        ssAdUnits,
        topics: adTopics,
    }

    return {
        kind: 'page',
        heading: sectionTitle,
        pageType: 'static',
        adTargeting,
        hideHeading: true,
        noMetaTitleTemplate: true,
        pageMeta: {
            description,
        },
        section,
        compositions: [
            layout.composition({
                type: 'article-doublet',
                props: {
                    stretchSelf: true,
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    horizontalGutters: 'md',
                    verticalSpacing: 'lg',
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    header: [
                        layout.component({
                            type: 'static-hero-card',
                            props: heroCardProps,
                        }),
                    ],
                    sidebarLeft: [
                        sidebarAdBespokeNative,
                        stickyWithOffset(
                            [sidebarAdHalfPageMrec],
                            undefined,
                            60,
                        ),
                    ],
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'static-content',
                                        props: {
                                            dataDefinitionArgs: {
                                                id: staticContentOverriderId
                                                    ? staticContentOverriderId
                                                    : `${section}--${contentBlock}`,
                                            },
                                            horizontalSpacing: 'md',
                                            verticalSpacing: 'xl',
                                        },
                                    }),
                                    ...(additionalBodyContent
                                        ? additionalBodyContent
                                        : []),
                                    layout.component({
                                        type: '7plus-article-button',
                                        props: {
                                            URL: sevenPlusButtonLink,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                    sidebarRight: [
                        sidebarAdMrecOne,
                        topHeadlines(undefined, pathName),
                    ],
                    mobileRail: [topHeadlines(undefined, pathName)],
                },
            }),
            layout.composition({
                type: 'box',
                props: {
                    verticalGutters: ['lg', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    main: [
                        layout.component({
                            feature: 'taboola',
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-article-thumbnails',
                                locationType: 'article',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const createV2SitePage = (
    pageTitle: string,
    description: string,
    contentComponentType: CheckedComponentInformation,
    section: SevenNewsSection = 'default',
    getAdTargeting: GetRouteAdTargeting,
    heroCardProps: SectionHeroCardProps,
    sevenPlusButtonLink: string,
    pathName?: string,
): PageType<SevenNewsSection> => {
    const {
        adUnitPath,
        ssAdUnits,
        topics: adTopics,
    } = getAdTargeting('article', section, {
        title: pageTitle,
        id: 'default',
        seoTitle: pageTitle,
        metadata: {},
    })

    const adTargeting: AdTargeting = {
        pageId: pageTitle,
        adUnitPath,
        ssAdUnits,
        topics: adTopics,
    }

    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'static',
        adTargeting,
        hideHeading: true,
        pageMeta: {
            description,
        },
        section,
        compositions: [
            layout.composition({
                type: 'article-doublet',
                props: {
                    stretchSelf: true,
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    horizontalGutters: 'md',
                    verticalSpacing: 'lg',
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    header: [
                        layout.component({
                            type: 'static-hero-card',
                            props: heroCardProps,
                        }),
                    ],
                    sidebarLeft: [
                        sidebarAdBespokeNative,
                        stickyWithOffset(
                            [sidebarAdHalfPageMrec],
                            undefined,
                            60,
                        ),
                    ],
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                            },
                            contentAreas: {
                                main: [
                                    contentComponentType,
                                    layout.component({
                                        type: '7plus-article-button',
                                        props: {
                                            URL: sevenPlusButtonLink,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                    sidebarRight: [
                        sidebarAdMrecOne,
                        topHeadlines(undefined, pathName),
                    ],
                    mobileRail: [topHeadlines(undefined, pathName)],
                },
            }),

            //taboola
            layout.composition({
                type: 'box',
                props: {
                    verticalGutters: ['lg', 'unset'],
                    horizontalGutters: 'md',
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                contentAreas: {
                    main: [
                        layout.component({
                            feature: 'taboola',
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-article-thumbnails',
                                locationType: 'article',
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
