import styled, { CSSObject } from '@emotion/styled'
import { linearGradient } from 'polished'
import { IconChevronLeftAlt } from '../../../../icons/IconChevronLeftAlt/IconChevronLeftAlt'
import { IconChevronRightAlt } from '../../../../icons/IconChevronRightAlt/IconChevronRightAlt'
import { colors } from '../../../../__styling/settings/colors'
import { breakpoint } from '../../../../__styling/style-functions'
import { calcRem } from '../../../../__styling/style-functions/calc-rem'
import { transition } from '../../../../__styling/style-mixins'
import { themedValue } from '../../../../__styling/themed-value'
import { Theme } from '../../../../__styling/themes'

export const fixedPlaylistHeightCompact = 98
export const fixedPlaylistHeight = 180
export const upNextHeight = 28

export const StyledUpNextText = styled('span')(({ theme }) => ({
    fontSize: calcRem(14),
    fontWeight: 700,
    lineHeight: calcRem(20),
    marginBottom: calcRem(5),
    fontFamily: theme.fonts.sansSerifCond,
    color: colors.white,
    textTransform: 'uppercase',
}))

export const StyledPlaylistWrapper = styled('div')<{
    upNextVisible: boolean
    compactMode?: boolean
    hide?: boolean
}>(({ theme, upNextVisible, compactMode, hide }) => {
    let playlistHeight = compactMode
        ? fixedPlaylistHeightCompact
        : fixedPlaylistHeight

    if (!compactMode) {
        playlistHeight = upNextVisible
            ? fixedPlaylistHeight
            : fixedPlaylistHeight - upNextHeight
    }

    return {
        display: hide ? 'none' : 'grid', // display:grid => Hack to fix weird overflow issues between 768 and 800px on ios devices
        height: themedValue(theme, {
            thewest: playlistHeight + 5,
            fallback: playlistHeight, // stops jank, the height never changes
        }),
        position: 'relative',
        backgroundColor: colors.black,
        // These are set as pixels explicitly so there is no rounding which will cause jank
        paddingTop: theme.margins.sm,
        paddingLeft: themedValue(theme, {
            perthnow: theme.margins.sm,
            fallback: undefined,
        }),
        paddingRight: themedValue(theme, {
            perthnow: theme.margins.sm,
            fallback: undefined,
        }),
        [breakpoint('sm')]: {
            paddingBottom: themedValue(theme, {
                thewest: theme.margins.md,
                fallback: theme.margins.sm,
            }),
            paddingLeft: compactMode ? theme.margins.sm : theme.margins.md,
            paddingRight: compactMode ? theme.margins.sm : theme.margins.md,
        },
    }
})

export const StyledVideoPlaylist = styled('ul')({
    display: 'flex',
    position: 'relative',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    scrollSnapType: 'x mandatory',
    scrollSnapStop: 'always',
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
})

export const StyledPlaylistActions = styled('div')({
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    display: 'none',
    [breakpoint('sm')]: {
        display: 'block',
    },
})

const commonRotateButtonStyles = (
    upNextVisible: boolean,
    theme: Theme,
): CSSObject => ({
    position: 'absolute',
    padding: calcRem(0, 22),
    height: calcRem(151),
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: colors.white,
    marginTop: upNextVisible ? calcRem(29) : 0,

    ...transition({ properties: ['opacity'], speed: 'med' }),

    '&:hover, &:active': {
        color: themedValue(theme, {
            sevennews: (section) => section.video.focusColor,
            perthnow: colors.perthnow.pinkThulite,
            thewest: undefined,
            fallback: undefined,
        }),

        '& > svg': {
            left: '50%',
        },
    },

    '&:focus': {
        outline: 'none',
    },
})

const commonButtonBackgroundStyles: CSSObject = {
    content: "''",
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    pointerEvents: 'none',
}

export interface StyledRotateButtonProps {
    visible: boolean
    upNextVisible: boolean
}

export const StyledRotateLeftButton = styled('button')<StyledRotateButtonProps>(
    ({ visible, upNextVisible, theme }) => ({
        ...commonRotateButtonStyles(upNextVisible, theme),
        left: 0,
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'auto' : 'none',

        '&::before': {
            ...commonButtonBackgroundStyles,
            right: '-30%',
            ...linearGradient({
                colorStops: ['transparent', colors.black],
                fallback: 'transparent',
                toDirection: '270deg',
            }),
        },
    }),
)

export const StyledRotateRightButton = styled(
    'button',
)<StyledRotateButtonProps>(({ visible, upNextVisible, theme }) => ({
    ...commonRotateButtonStyles(upNextVisible, theme),
    right: 0,
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? 'auto' : 'none',

    '&::before': {
        ...commonButtonBackgroundStyles,
        left: '-30%',
        ...linearGradient({
            colorStops: ['transparent', colors.black],
            fallback: 'transparent',
            toDirection: '90deg',
        }),
    },
}))

export const StyledIconChevronRight = styled(IconChevronRightAlt)({
    position: 'absolute',
    top: calcRem(79),
    left: '40%',
    width: calcRem(12),
    height: calcRem(20),
    color: 'inherit',
    fill: 'currentColor',
    transform: 'translate(-50%, -50%)',
    ...transition({ properties: ['left', 'color'], speed: 'med' }),
})

export const StyledIconChevronLeft = styled(IconChevronLeftAlt)({
    position: 'absolute',
    top: calcRem(79),
    left: '50%',
    width: calcRem(12),
    height: calcRem(20),
    color: 'inherit',
    fill: 'currentColor',
    transform: 'translate(-50%, -50%)',
    ...transition({ properties: ['left', 'color'], speed: 'med' }),
})

export const StyledVideoPlaylistItem = styled('li')({
    position: 'relative',
    marginRight: calcRem(22),
    flexBasis: calcRem(140),
    flexGrow: 0,
    flexShrink: 0,
    scrollSnapAlign: 'start',
})

export interface StyledVideoPlaylistButtonProps {
    active?: boolean
}

export const StyledVideoPlaylistButton = styled(
    'button',
)<StyledVideoPlaylistButtonProps>(({ active }) => ({
    background: 'none',
    border: 'none',
    padding: 0,
    margin: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    opacity: active ? 1 : 0.9,
    ...transition({ properties: ['opacity'], speed: 'fast' }),

    '&:hover, &:focus': {
        outline: 'none',
        opacity: 1,
        h2: {
            textDecoration: 'underline',
        },
    },
}))

interface CompactModeProps {
    compactMode?: boolean
}

export const StyledPlaylistItemMedia = styled('figure')({
    position: 'relative',
    width: '100%',
    height: calcRem(80),
})

export const StyledPlaylistItemImage = styled('img')({
    position: 'absolute',
    margin: 'auto',
    width: 140, // fixed
    height: 80, // fixed
    left: '50%',
    top: '100%',
    bottom: 0,
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
})

export const StyledPlaylistItemDetails = styled('div')<
    {
        active?: boolean
    } & CompactModeProps
>(({ theme, active }) => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 80,
    padding: calcRem(60, 10, 10),
    color: colors.white,
    fontSize: calcRem(12),
    fontFamily: theme.fonts.sansSerif,
    // Gradiant so playbtn and timestamp can be easily visible over poster images
    ...linearGradient({
        colorStops: ['transparent', colors.sevennews.charade],
        fallback: 'transparent',
        toDirection: '180deg',
    }),

    '&:focus': {
        border: themedValue(theme, {
            sevennews: (section) => `2px solid ${section.video.focusColor}`,
            perthnow: `2px solid ${colors.perthnow.pinkThulite}`,
            fallback: undefined,
        }),
    },

    border: active
        ? themedValue(theme, {
              sevennews: (section) => ` 2px solid ${section.video.focusColor}`,
              perthnow: `2px solid ${colors.perthnow.pinkThulite}`,
              fallback: undefined,
          })
        : undefined,
}))

export const StyledPlaylistItemPlayIcon = styled('div')({
    display: 'inline-block',
    position: 'relative',
    width: calcRem(18),

    '&::before': {
        position: 'absolute',
        content: `''`,
        bottom: calcRem(-2),
        display: 'block',
        width: 0,
        height: 0,
        borderWidth: `${calcRem(6)} 0 ${calcRem(6)} ${calcRem(10)}`,
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderLeftColor: colors.white,
    },
})

export const StyledPlaylistItemTitle = styled('h2')<CompactModeProps>(
    ({ theme }) => ({
        fontSize: themedValue(theme, {
            sevennews: calcRem(15),
            fallback: calcRem(14),
        }),
        fontWeight: 400,
        color: colors.white,
        fontFamily: themedValue(theme, {
            sevennews: theme.fonts.sansSerifCond,
            fallback: theme.fonts.sansSerif,
        }),
        marginTop: calcRem(8),
        marginBottom: 0,
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        maxHeight: calcRem(50),
        display: '-webkit-box',
        WebkitLineClamp: 4,
        WebkitBoxOrient: 'vertical',
    }),
)

export const StyledCompactDetailsContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    minWidth: calcRem(140),
    maxWidth: calcRem(140),
    top: calcRem(-4),
    right: calcRem(-140 - theme.margins.sm),
}))
