import { createRegisterableComponent } from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { getVideoSeriesEpisodeInfoFromRouteResolution } from '../../templates/Publication/SharedPublication.routing'
import { VideoSeriesEpisodePublication } from './VideoSeriesEpisodePublication'

export const VideoSeriesEpisodeRegistration = createRegisterableComponent(
    'video-series-episode',
    (_: {}, services) => {
        const videoInfo = getVideoSeriesEpisodeInfoFromRouteResolution(services)

        let thisEpisode = videoInfo.episodeMeta.seriesInfo?.episode

        if (thisEpisode === undefined) {
            return null
        }

        if (thisEpisode === 'trailer') {
            thisEpisode = 0
        }

        const playlistEpisodes: VideoMetaDTO[] =
            videoInfo.playlistEpisodes.filter(
                (episode) => episode.seriesInfo?.episode !== 'trailer',
            )

        // Remove previous episodes from the front of the episode list and drop the current one
        const previousEpisodes = playlistEpisodes
            .splice(0, thisEpisode)
            .slice(0, -1)
        const reorderedPlaylist = [...playlistEpisodes, ...previousEpisodes]

        return (
            <VideoSeriesEpisodePublication
                data={videoInfo}
                onEvent={services.onEvent}
                getVideoQueue={() => {
                    return Promise.resolve({
                        items: reorderedPlaylist,
                        type: 'video-series',
                    })
                }}
                adUnitPath={services.adState.targeting.adUnitPath}
            />
        )
    },
)
