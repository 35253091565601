import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getWorldPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'World',
        id: 'news/world',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'World',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        parent: {
            id: 'news',
            title: 'News',
            metadata: {},
            seoTitle: 'News',
        },
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            topics: [topic.id],
            paging: {
                page: 0,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            topics: [topic.id],
            paging: {
                pageOffset: 5,
                page: 1,
                pageSize: 6,
            },
        },
        contentToFormat: [
            {
                pillMenuName: 'Ukraine',
                pillMenuLink: '/news/ukraine',
                collectionTopics: ['news/ukraine'],
            },
            {
                pillMenuName: 'North America',
                pillMenuLink: '/news/north-america',
                collectionTopics: ['news/north-america'],
            },
            {
                pillMenuName: 'Asia Pacific',
                pillMenuLink: '/news/asia',
                collectionTopics: ['news/asia'],
            },
            {
                pillMenuName: 'Europe',
                pillMenuLink: '/news/europe',
                collectionTopics: ['news/europe'],
            },
            {
                pillMenuName: 'Middle East',
                pillMenuLink: '/news/middle-east',
                collectionTopics: ['news/middle-east'],
            },
            {
                pillMenuName: 'Africa',
                pillMenuLink: '/news/africe',
                collectionTopics: ['news/africa'],
            },
            {
                pillMenuName: 'Latin America',
                pillMenuLink: '/news/latin-america',
                collectionTopics: ['news/latin-america'],
            },
        ],
        promoCardName: '7news-world-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
