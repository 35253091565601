import styled from '@emotion/styled'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    metrics,
    themedValue,
} from '../../__styling'

export const StyledStaticPageTitle = styled('h1')(({ theme }) => ({
    paddingBottom: themedValue(theme, {
        sevennews: calcRem(metrics.sevennews.margins.xs),
        fallback: undefined,
    }),
    marginTop: 0,
    marginBottom: themedValue(theme, {
        sevennews: calcRem(metrics.sevennews.margins.xl),
        thenightly: calcRem(metrics.thenightly.margins.m2l),
        fallback: calcRem(theme.margins.xl),
    }),
    fontFamily: themedValue(theme, {
        sevennews: fonts.sevennews.sansSerifCond,
        thenightly: fonts.thenightly.heading,
        fallback: undefined,
    }),
    fontSize: themedValue(theme, {
        sevennews: calcRem(34),
        thenightly: calcRem(40),
        fallback: calcRem(34),
    }),
    borderBottom: themedValue(theme, {
        sevennews: `1px solid ${colors.sevennews.alto}`,
        fallback: undefined,
    }),

    [breakpoint('md')]: {
        marginBottom: themedValue(theme, {
            sevennews: calcRem(metrics.sevennews.margins.xl),
            thenightly: calcRem(metrics.thenightly.margins.xxxl),
            fallback: calcRem(32),
        }),
    },
}))
