import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'

export const StyledHeading = styled('h1')({
    span: {
        fontWeight: 400,
    },
})
export const StyledDescription = styled('div')({
    '&>p:first-child': {
        marginTop: calcRem(4),
    },
})
