import React from 'react'

export const FontSizeIcon = () => {
    return (
        <svg
            width="21"
            height="14"
            viewBox="0 0 21 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.6"
                d="M6.57422 10.1426H2.92969L2.26758 12H0.820312L3.90234 3.64453H5.60156L8.68359 12H7.23633L6.57422 10.1426ZM6.15234 8.94141L4.75195 5.00391L3.35156 8.94141H6.15234Z"
                fill="black"
            />
            <path
                opacity="0.8"
                d="M17.9678 9.67822H13.4121L12.5845 12H10.7754L14.6279 1.55566H16.752L20.6045 12H18.7954L17.9678 9.67822ZM17.4404 8.17676L15.6899 3.25488L13.9395 8.17676H17.4404Z"
                fill="black"
            />
        </svg>
    )
}
