import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { RenderTarget, WebLink } from '@news-mono/web-common'
import {
    colors,
    easing,
    fonts,
    metrics,
    calcRem,
    breakpoint,
} from '../../../__styling'

import { StyledArticleVideoContainer } from '../../../publication/ArticleVideo/ArticleVideo.styled'

interface StyledHeroContainerProps {
    renderTarget?: RenderTarget
}

interface ShareWrapperProps {
    isOpened: boolean
}

export const StyledBreadcrumbDesktopWrapper = styled('div')({
    display: 'block',
    marginBottom: calcRem(16),
})

export const StyledHeroContainer = styled('div')<StyledHeroContainerProps>(
    ({ renderTarget }) => ({
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridRowGap: calcRem(24),
        gridTemplateColumns: '1fr',
        alignItems: 'self-start',
        marginBottom: calcRem(metrics.sevennews.margins.lg),
        textDecoration: 'none',
        color: 'inherit',

        [breakpoint('md')]: {
            gridTemplateColumns: '40% calc(60% - 1rem)',
            gridTemplateRows: 'auto',
            columnGap: calcRem(16),
        },
        '& [class*="StyledFigcaption"]': {
            paddingBottom: 0,
            marginTop: 0,
        },

        paddingBottom: renderTarget === 'amp' ? calcRem(20) : 'inherit',
        [`${StyledArticleVideoContainer}`]: {
            marginBottom: 0,
        },
    }),
)

export const StyledTextWrap = styled('div')(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: 'inherit',
    gridRow: 1,
    [breakpoint('md')]: {
        marginRight: calcRem(30),
        gridRow: 1,
        height: '100%',
        justifyContent: 'space-between',
    },
}))

export const StyledTextWrapHeader = styled('div')(({}) => ({
    marginBottom: calcRem(12),
    height: calcRem(26),
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.black20}`,
    [breakpoint('sm')]: {
        height: calcRem(34),
    },
    [breakpoint('md')]: {
        height: calcRem(39),
    },
}))

export const StyledInformationWrapper = styled('div')({
    display: 'flex',
})

export const StyledShareWrapper = styled('div')<ShareWrapperProps>(
    ({ isOpened }) => ({
        width: calcRem(18),
        height: '100%',
        cursor: 'pointer',
        marginRight: calcRem(18),
        borderBottom: `${calcRem(3)} solid ${colors.sevennews.red}`,
        '&:hover': {
            '& svg path': {
                fill: colors.sevennews.red,
            },
        },
        '& svg path': {
            fill: isOpened ? colors.sevennews.red : undefined,
        },
    }),
)

export const StyledTimingsWrapper = styled('div')(({}) => ({
    fontSize: calcRem(12),
    color: colors.sevennews.nero60,
    fontWeight: 600,
    span: {
        color: colors.sevennews.nero30,
    },
}))

export const StyledBylineWrap = styled('div')(({}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center,',
    marginBottom: calcRem(16),
    [breakpoint('md')]: {
        marginBottom: calcRem(8),
    },
}))

export const StyledBylineSectionContainer = styled('div')({
    marginBottom: 0,
    [breakpoint('sm')]: {
        marginBottom: calcRem(16),
    },
    [breakpoint('md')]: {
        marginBottom: calcRem(metrics.sevennews.margins.lg),
        marginTop: calcRem(24),
    },
})

export const StyledTitle = styled('h1')({
    fontWeight: 400,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.nero,
    fontSize: calcRem(22),
    lineHeight: calcRem(29),
    textAlign: 'left',
    marginTop: calcRem(4),
    marginBottom: calcRem(4),
    [breakpoint('sm')]: {
        fontSize: calcRem(30),
        lineHeight: '39px',
        marginTop: calcRem(8),
        marginBottom: calcRem(8),
    },
    [breakpoint('md')]: {
        fontSize: calcRem(36),
        lineHeight: '39px',
        marginTop: calcRem(16),
        marginBottom: calcRem(16),
    },
})

interface StyledTeaserProps {}

export const StyledTeaser = styled('div')<StyledTeaserProps>(({}) => ({
    margin: 0,
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    color: colors.sevennews.nero,
    fontSize: calcRem(20),
    lineHeight: calcRem(27),
    textAlign: 'left',
    marginBottom: calcRem(metrics.sevennews.margins.lg),
    marginTop: calcRem(20),
    display: 'block',
    [breakpoint('sm')]: {
        marginBottom: calcRem(24),
    },
    [breakpoint('md')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(28),
        marginBottom: 'inherit',
        display: 'block',
    },
}))

const PulseRing = keyframes`
0% {
    transform:  scale(.33);
  }
  80%, 100% {
    opacity: 0
  }
`
const PulseDot = keyframes`
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.8);
    }
`

export const StyledCardMediaWrap = styled('div')(({}) => ({
    gridRow: 2,
    minHeight: calcRem(200),
    [breakpoint('md')]: {
        gridRow: 1,
        alignSelf: 'center',
        display: 'grid',
    },
    '& [class*="StyledMedia"]': {
        marginBottom: 0,
    },
}))

interface StyledShareWrapperProps {
    isOpened: boolean
}
export const StyeldShareWrapper = styled('div')<StyledShareWrapperProps>(
    ({ isOpened }) => ({
        position: 'absolute',
        left: calcRem(18),
        top: 0,
        bottom: 0,
        background: colors.white,
        width: isOpened ? '100%' : 0,
        maxWidth: calcRem(230),
        borderBottom: `3px solid ${colors.sevennews.red}`,
        overflow: 'hidden',
        transition: `width ${easing.easeOut.med}`,
    }),
)
export const StyledStaticMediaImage = styled('img')({
    width: '100%',
})
