import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import {
    ErrorResolution,
    GetRouteAdTargeting,
    noIndexMeta,
    PageType,
    RouteResolution,
    StaticPageRoute,
} from '@news-mono/web-common'
import { SevenNewsSections } from '@west-australian-newspapers/publication-types'
import { layout } from '../App.routing'
import { box } from './shared-components/compositions'
import { mobileAdOne } from './shared-components/mobile-ads'

export const notFoundRoute: StaticPageRoute<SevenNewsSection> = ({
    getAdTargeting,
    resolution,
}) => {
    const section = ((resolution as ErrorResolution).section ||
        SevenNewsSections.default) as SevenNewsSection

    return {
        kind: 'page',
        heading: 'Page Not Found',
        pageMeta: {
            meta: [noIndexMeta],
        },
        // used by `/server/middleware/optimised-ssr` to determine redirect status
        pageType: 'error',
        hideHeading: true,
        adTargeting: getAdTargeting('error', section, 'page-not-found'),
        section,
        compositions: [
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['md', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'sevennews-404-page',
                        props: {},
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {
                            verticalSpacing: 'lg',
                        },
                    }),
                    layout.component({
                        type: 'horizontal-pill-menu',
                        props: {
                            data: {
                                type: 'navigation',
                                topic: 'trending',
                                labelText: 'Trending',
                            },
                        },
                    }),
                    layout.component({
                        type: 'six-card-collection',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                            dataDefinitionArgs: {
                                type: 'geolocation',
                                offset: 0,
                                pageSize: 6,
                            },
                        },
                    }),
                    layout.component({
                        type: 'promo-cards-seven',
                        props: {
                            verticalGutters: ['lg', 'lg'],
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: '7news-home-promo-cards',
                                offset: 0,
                                pageSize: 3,
                            },
                        },
                    }),
                    layout.component({
                        type: 'horizontal-divider',
                        props: {
                            verticalGutters: ['unset', 'lg'],
                        },
                    }),
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            horizontalGutters: 'md',
                            verticalGutters: ['unset', 'lg'],
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        noticePosition: 'above-center',
                                        hiddenUntilLoaded: false,

                                        slot: {
                                            id: 'leaderboard-billboard-two',
                                            size: 'leaderboardBillboard',
                                        },
                                        adType: 'inline',
                                    },
                                }),
                                mobileAdOne,
                            ],
                        },
                    }),
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId: 'taboola-below-home-thumbnails',
                            locationType: 'home',
                            isFourColumns: isTabletOrMobileViewport()
                                ? false
                                : true,
                        },
                    }),
                ],
            }),
        ],
    }
}

export const matchedSomethingWentWrongRoute = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: RouteResolution,
): PageType<SevenNewsSection> =>
    somethingWentWrongContent(getAdTargeting, resolution)

export const staticSomethingWentWrongRoute: StaticPageRoute<SevenNewsSection> =
    ({ getAdTargeting, resolution }) =>
        somethingWentWrongContent(getAdTargeting, resolution)

const somethingWentWrongContent = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: RouteResolution,
): PageType<SevenNewsSection> => {
    const section = ((resolution as ErrorResolution).section ||
        SevenNewsSections.default) as SevenNewsSection

    return {
        kind: 'page',
        heading: 'Something Went Wrong',
        pageMeta: {
            meta: [noIndexMeta],
        },
        pageType: 'error',
        hideHeading: true,
        adTargeting: getAdTargeting('error', section, 'something-went-wrong'),
        section,
        compositions: [
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['md', 'md'],
                },
                main: [
                    layout.component({
                        type: 'sevennews-500-page',
                        props: {},
                    }),
                ],
            }),
        ],
    }
}
