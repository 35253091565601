import React from 'react'
import { StyledStaticPageTitle } from './StaticPageTitle.styled'

export interface StaticPageTitleProps {
    title: string
}

export const StaticPageTitle: React.FC<StaticPageTitleProps> = ({ title }) => {
    return <StyledStaticPageTitle>{title}</StyledStaticPageTitle>
}
