import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AdUnitWrapperProps,
    SevenNewsSection,
    metrics,
} from '@news-mono/component-library'
import {
    AdDefinition,
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import {
    GalleryV4DTO,
    PageMetaDTO,
} from '@west-australian-newspapers/publication-types'
import { topHeadlines } from 'apps/sevennews/src/app/routes/shared-components/top-headlines'
import { layout } from '../../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../../shared-components/ad-and-breaking-news'
import { getNoIndexMeta } from './allowList'
import {
    publicationEndContent,
    doubletLayoutRightColumn,
} from './common-publication-content'

const getTopParentTopic = (topic: Topic): Topic => {
    if (topic.parent) {
        return getTopParentTopic(topic.parent)
    }

    return topic
}

export const createGalleryRoute = ({
    gallery,
    numberGalleryItems,
    getAdTargeting,
    pageMeta,
    section,
    toggles,
}: {
    gallery: GalleryV4DTO
    numberGalleryItems: number
    section: SevenNewsSection
    toggles?: TogglesReduxState
    getAdTargeting: GetRouteAdTargeting
    pageMeta?: PageMetaDTO
}): PageType<SevenNewsSection> => {
    const featureState = toggles && toFeatureState(toggles)

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'article',
        section,
        gallery.topics.primary,
        ...gallery.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: gallery.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const numberOfGalleryAds = Math.ceil((numberGalleryItems - 1) / 3)

    // eslint-disable-next-line prefer-spread
    const adContent = Array.apply(null, Array(numberOfGalleryAds)).map(
        (_: any, i: number) => {
            const adInfo: AdUnitWrapperProps & { slot: AdDefinition } = {
                noticePosition: 'above-center',
                hiddenUntilLoaded: false,
                padding: [
                    metrics.sevennews.margins.md,
                    0,
                    metrics.sevennews.margins.md,
                    0,
                ],
                slot: {
                    id: `gallery-mrec-${i}`,
                    size: 'alwaysMrec',
                },
                adType: 'inline',
            }
            return adInfo
        },
    )

    const isV2LeaderBoardBilbordToggleEnabled = Boolean(
        featureState &&
            isFeatureEnabled(featureState, '7-news-2-top-leaderboard-ad'),
    )

    const twoByTwoAdUnit = layout.component({
        type: 'ad-unit',
        feature: 'outstream',
        props: {
            noticePosition: 'none',
            hiddenUntilLoaded: false,
            padding: [0],
            slot: {
                id: 'outstream2x2',
                size: 'outstream',
                disableLazyLoading: true,
            },
            adType: 'inline',
        },
    })

    const v2Layout = layout.composition({
        type: 'article-doublet',
        props: {
            stretchSelf: true,
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            horizontalGutters: 'md',
            verticalSpacing: 'lg',
            breakpoints: {
                sm: {
                    horizontalGutters: 'xl',
                },
            },
            item: gallery,
        },
        contentAreas: {
            header: [
                twoByTwoAdUnit,
                layout.component({
                    type: 'sevennews-article-hero-card',
                    props: {
                        article: gallery,
                        onEvent: () => {},
                        mediaMode: 'edgeToEdge',
                        adUnitPath: adUnitPath,
                    },
                }),
            ],
            sidebarRight: doubletLayoutRightColumn(gallery.topics.primary),
            mobileRail: [topHeadlines(gallery.topics.primary)],
            main: [
                layout.component({
                    type: 'gallery-thumbnails',
                    props: {
                        meta: {
                            kind: 'gallery',
                            slug: gallery.slug,
                            numberImages: numberGalleryItems,
                            galleryAds: adContent,
                        },
                        section,
                    },
                }),
                ...publicationEndContent(section),
            ],
        },
    })

    return {
        kind: 'page',
        heading: gallery.heading,
        pageMeta: {
            title: gallery.seoHead || gallery.heading,
            description: gallery.homepageTeaser,
            meta: getNoIndexMeta(gallery.source, featureState),
        },
        socialMeta: {
            title: gallery.socialHead || gallery.homepageHead,
            description: gallery.socialTeaser || gallery.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: 'publication',
        adTargeting,
        section,
        compositions: isV2LeaderBoardBilbordToggleEnabled
            ? [breakingNews, v2Layout]
            : [headerAd(), breakingNews, v2Layout],
    }
}
