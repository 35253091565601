/**
 * This array is used for determining card collections to show on video hub topic pages
 */
export const allTopics = [
    {
        id: 'motoring',
        routes: [
            {
                title: 'Food',
                id: 'lifestyle/food',
            },
            {
                title: 'Health and Wellbeing',
                id: 'lifestyle/health-wellbeing',
            },
            {
                title: 'Real Estate',
                id: 'lifestyle/real-estate',
            },
            {
                title: 'Shopping',
                id: 'lifestyle/shopping',
            },
            {
                title: 'Pets',
                id: 'lifestyle/pets',
            },
            {
                title: 'Parenting',
                id: 'lifestyle/parenting',
            },
            {
                title: 'Travel',
                id: 'travel',
            },
            {
                title: 'Personal Finance',
                id: 'lifestyle/personal-finance',
            },
        ],
    },
    {
        id: 'lifestyle',
        routes: [
            {
                title: 'Food',
                id: 'lifestyle/food',
            },
            {
                title: 'Health and Wellbeing',
                id: 'lifestyle/health-wellbeing',
            },
            {
                title: 'Real Estate',
                id: 'lifestyle/real-estate',
            },
            {
                title: 'Shopping',
                id: 'lifestyle/shopping',
            },
            {
                title: 'Pets',
                id: 'lifestyle/pets',
            },
            {
                title: 'Parenting',
                id: 'lifestyle/parenting',
            },
            {
                title: 'Travel',
                id: 'travel',
            },
            {
                title: 'Personal Finance',
                id: 'lifestyle/personal-finance',
            },
        ],
    },
    {
        id: 'entertainment',
        routes: [
            {
                title: 'Sunrise',
                id: 'sunrise',
            },

            {
                title: 'The Morning Show',
                id: 'the-morning-show',
            },

            {
                title: 'Spotlight',
                id: 'spotlight',
            },
            {
                title: 'Home And Away',
                id: 'entertainment/home-and-away',
            },

            {
                title: 'Big Brother',
                id: 'entertainment/big-brother-australia',
            },

            {
                title: 'My Kitchen Rules',
                id: 'entertainment/my-kitchen-rules',
            },

            {
                title: 'TV',
                id: 'entertainment/tv',
            },

            {
                title: 'Celebrity',
                id: 'entertainment/celebrity',
            },

            {
                title: 'Music',
                id: 'entertainment/music',
            },

            {
                title: 'Movies',
                id: 'entertainment/movies',
            },

            {
                title: 'The Voice Australia',
                id: 'entertainment/the-voice-australia',
            },

            {
                title: 'SAS Australia',
                id: 'entertainment/sas-australia',
            },

            {
                title: 'Farmer Wants a Wife',
                id: 'entertainment/farmer-wants-a-wife',
            },
        ],
    },
    {
        id: 'sport',
        routes: [
            {
                title: 'AFL',
                id: 'sport/afl',
            },
            {
                title: 'Cricket',
                id: 'sport/cricket',
            },
            {
                title: 'AFLW',
                id: 'sport/aflw',
            },
            {
                title: 'NRL',
                id: 'sport/rugby-league',
            },
            {
                title: 'Soccer',
                id: 'sport/soccer',
            },
            {
                title: 'Motorsport',
                id: 'sport/motorsport',
            },
            {
                title: 'Horse Racing',
                id: 'sport/horse-racing',
            },
            {
                title: 'Tennis',
                id: 'sport/tennis',
            },
            {
                title: 'UFC',
                id: 'sport/ufc',
            },
        ],
    },
    {
        id: 'politics',
        routes: [
            {
                title: 'Federal Politics',
                id: 'politics/federal-politics',
            },
            {
                title: 'State Politics',
                id: 'politics/state-politics',
            },
            {
                title: 'World Politics',
                id: 'politics/world-politics',
            },
        ],
    },
    {
        id: 'business',
        routes: [
            {
                title: 'Finance',
                id: 'business/finance',
            },
            {
                title: 'Banking',
                id: 'business/banking',
            },
            {
                title: 'Retail',
                id: 'business/retail',
            },
            {
                title: 'Economy',
                id: 'business/economy',
            },
            {
                title: 'Markets',
                id: 'business/markets',
            },
        ],
    },
    {
        id: 'technology',
        routes: [
            {
                title: 'Reviews',
                id: 'technology/reviews',
            },
            {
                title: 'Space',
                id: 'technology/space',
            },
            {
                title: 'Gaming',
                id: 'technology/gaming',
            },
            {
                title: 'Science',
                id: 'technology/science',
            },
            {
                title: 'Smartphones',
                id: 'technology/smartphones',
            },
            {
                title: 'Gadgets',
                id: 'technology/gadgets',
            },
        ],
    },
    {
        id: 'weather',
        routes: [
            {
                title: 'Natural disasters',
                id: 'weather/natural-disasters',
            },
            {
                title: 'BOM',
                id: 'weather/bom',
            },
            {
                title: 'Environment',
                id: 'weather/environment',
            },
        ],
    },
    {
        id: 'news',
        routes: [
            {
                title: 'NSW',
                id: 'news/nsw',
            },
            {
                title: 'QLD',
                id: 'news/qld',
            },
            {
                title: 'World',
                id: 'news/world',
            },
            {
                title: 'WA',
                id: 'news/wa',
            },
            {
                title: 'VIC',
                id: 'news/vic',
            },
            {
                title: 'SA',
                id: 'news/sa',
            },
            {
                title: 'NT',
                id: 'news/nt',
            },
            {
                title: 'TAS',
                id: 'news/tas',
            },
            {
                title: 'ACT',
                id: 'news/act',
            },
        ],
    },
]
