import { FeatureState } from '@etrigan/feature-toggles-client'
import {
    DataLayerEventName,
    isComponentEnabled,
    NavEvent,
    NavEventArgs,
} from '@news-mono/web-common'
import React, { useEffect, useRef, useState } from 'react'
import { sevenNewsSocialLinks } from '../../__styling/settings/sections'
import { HamburgerButtonV2 } from '../../buttons/HamburgerButtonV2/HamburgerButtonV2'
import { DesktopLocationSelectorV2 } from '../../content'
import {
    SevenNewsNavigationV2,
    SevenNewsNavItemV2,
} from '../../routing/route-nav-types'
import {
    Styled7PlusButton,
    Styled7PlusLogo,
    StyledAnimationContainer,
    StyledButtonControlls,
    StyledFooterItem,
    StyledFooterItemLink,
    StyledFooterWrapper,
    StyledHeader,
    StyledLocationLabel,
    StyledLocationWrapper,
    StyledMainNav,
    StyledNav,
    StyledNavigationBackground,
    StyledNavItemText,
    StyledNavList,
    StyledNavScrollArea,
    StyledNavSection,
    StyledSecondaryNav,
    StyledSecondaryNavLink,
    StyledSecondaryNavList,
    StyledSeparator,
    StyledSocialLinksWrapper,
    StyledSVG,
    StyledTvGuideButton,
    StyledTvGuideLogo,
} from './SevenNewsFlyoutNav2.styled'
import { SevenNewsFlyoutNavItem } from './SevenNewsFlyoutNavItem2'
import { NavItem } from '@west-australian-newspapers/publication-types'

export interface SevenNewsFlyoutNavProps {
    navLinks: SevenNewsNavigationV2
    secondaryNavLinks: SevenNewsNavItemV2[]
    onEvent: (event: NavEvent) => void
    isOpen?: boolean
    toggleFlyoutNav?: () => void
    featureState: FeatureState
}
const sevenPlusLogo = require('./assets/7plus-logo.png')
const tvGuideLogo = require('./assets/tv-guide.png')

export const SNMainNavId = 'sn-main-nav'

const connectLinks = [
    {
        name: 'About Us',
        link: 'https://7news.com.au/about-us',
    },
    {
        name: 'Contact Us',
        link: 'https://support.7news.com.au/hc/en-au/requests/new',
    },
    {
        name: 'Advertise With Us',
        link: 'https://7news.com.au/contact-us',
    },
    {
        name: 'FAQs',
        link: 'https://support.7news.com.au/',
    },
    {
        name: 'Privacy Policy',
        link: 'https://www.sevenwestmedia.com.au/privacy-policies/privacy',
    },
    {
        name: 'Editorial Policy',
        link: 'https://7news.com.au/editorial-policy',
    },
    {
        name: 'Terms Of Use',
        link: 'https://support.7news.com.au/terms',
    },
]

export const SevenNewsFlyoutNav2: React.FC<SevenNewsFlyoutNavProps> = ({
    navLinks,
    secondaryNavLinks,
    isOpen,
    onEvent,
    toggleFlyoutNav,
    featureState,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null)

    const [showSubNav, setShowSubNav] = useState(navLinks.map(() => false))
    const [flyoutNavHeightFromTop, setFlyoutNavHeightFromTop] = useState(0)

    const toggleFlyout = () => {
        toggleFlyoutNav && toggleFlyoutNav()
    }

    const handleDiscoverClick = (args: NavEventArgs) => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'Flyout',
            payload: {
                navName: 'Flyout.SevenNews',
                navLocation: 'Flyout',
                navPos: args.navPos,
                navText: args.navText,
                navLink: args.navLink,
                navPath: args.navText,
            },
        })

        toggleFlyout()
    }
    const preventPropagation = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        e.stopPropagation()
    }

    const showSubNavHandler = (index: number, val: boolean) => {
        setShowSubNav(
            showSubNav.map((nav, navIndex) =>
                navIndex === index ? val : false,
            ),
        )
    }

    useEffect(() => {
        const wrapperCurrent = wrapperRef.current

        if (!wrapperCurrent) {
            return
        }

        const handleTransitionEnd = () => {
            if (!isOpen && wrapperRef.current) {
                wrapperRef.current.classList.remove('isOpen')
            }
        }

        if (isOpen) {
            wrapperCurrent.classList.add('isOpen')

            requestAnimationFrame(() => {
                setTimeout(function () {
                    wrapperCurrent &&
                        wrapperCurrent.classList.add('isTransitioning')
                }, 100)
            })

            onEvent({
                type: DataLayerEventName.navAvailable,
                originator: 'Flyout',
                payload: {
                    navName: 'Flyout.SevenNews',
                },
            })
        } else {
            wrapperCurrent.classList.remove('isTransitioning')
        }

        wrapperCurrent.addEventListener('transitionend', handleTransitionEnd)

        return function flyoutTransitionCleanup() {
            if (wrapperCurrent) {
                wrapperCurrent.removeEventListener(
                    'transitionend',
                    handleTransitionEnd,
                )
            }
        }
    }, [isOpen, onEvent])

    return (
        <StyledNavigationBackground isOpen={isOpen} onClick={toggleFlyout}>
            <StyledAnimationContainer
                id={SNMainNavId}
                ref={wrapperRef}
                onClick={(e) => preventPropagation(e)}
            >
                <StyledHeader>
                    <HamburgerButtonV2
                        onClick={() => {
                            toggleFlyoutNav && toggleFlyoutNav()
                        }}
                        isOpen={true}
                    />
                    <StyledLocationWrapper>
                        <StyledLocationLabel>Local news:</StyledLocationLabel>
                        <DesktopLocationSelectorV2
                            toggleFlyoutNav={toggleFlyout}
                        />
                    </StyledLocationWrapper>
                </StyledHeader>

                <StyledNav flyoutNavHeightFromTop={flyoutNavHeightFromTop}>
                    <StyledNavScrollArea
                        onScroll={(e) => {
                            const viewportOffset =
                                e.currentTarget.getBoundingClientRect()
                            setFlyoutNavHeightFromTop(viewportOffset.top)
                        }}
                    >
                        <StyledMainNav>
                            <StyledNavList role="menu">
                                {navLinks
                                    .filter((item) =>
                                        isItemToggled(featureState, item),
                                    )
                                    .map((navItem, index) => {
                                        const subNavId = `subnav-${navItem.name
                                            .replace(' ', '-')
                                            .toLowerCase()}`

                                        return (
                                            <SevenNewsFlyoutNavItem
                                                navItem={navItem}
                                                key={subNavId}
                                                subNavId={subNavId}
                                                onEvent={onEvent}
                                                toggleFlyoutNav={
                                                    toggleFlyoutNav
                                                }
                                                showSubNav={showSubNav[index]}
                                                setShowSubNav={(val) =>
                                                    showSubNavHandler(
                                                        index,
                                                        val,
                                                    )
                                                }
                                                featureState={featureState}
                                            />
                                        )
                                    })}
                            </StyledNavList>
                        </StyledMainNav>
                        <StyledSecondaryNav>
                            {secondaryNavLinks && (
                                <StyledSecondaryNavList>
                                    {secondaryNavLinks
                                        .filter((item) =>
                                            isItemToggled(featureState, item),
                                        )
                                        .map((secondaryNavItem, index) => {
                                            return (
                                                <li key={index}>
                                                    <StyledSecondaryNavLink
                                                        to={
                                                            secondaryNavItem.link
                                                        }
                                                        onClick={() => {
                                                            handleDiscoverClick &&
                                                                handleDiscoverClick(
                                                                    {
                                                                        navPos: index,
                                                                        navText:
                                                                            secondaryNavItem.name,
                                                                        navLink:
                                                                            secondaryNavItem.link,
                                                                        navPath:
                                                                            secondaryNavItem.name,
                                                                    },
                                                                )
                                                        }}
                                                    >
                                                        <StyledNavItemText>
                                                            {
                                                                secondaryNavItem.name
                                                            }
                                                            {secondaryNavItem.linkOut && (
                                                                <StyledSVG />
                                                            )}
                                                        </StyledNavItemText>
                                                    </StyledSecondaryNavLink>
                                                </li>
                                            )
                                        })}
                                </StyledSecondaryNavList>
                            )}
                        </StyledSecondaryNav>
                        <StyledButtonControlls>
                            <Styled7PlusButton to="https://7plus.com.au/?utm_source=7NEWS&utm_medium=Referral&utm_campaign=header&utm_content=headerlink">
                                <Styled7PlusLogo src={sevenPlusLogo} /> Stream
                                News
                            </Styled7PlusButton>
                            <StyledTvGuideButton to="https://tvguidetonight.com.au/?utm_source=7NEWS&utm_medium=hamburger-nav&utm_campaign=tv-guide">
                                <StyledTvGuideLogo src={tvGuideLogo} />
                            </StyledTvGuideButton>
                        </StyledButtonControlls>
                        {/* footerLinks */}
                        <StyledFooterWrapper>
                            {connectLinks.map((item, idx) => {
                                return (
                                    <StyledFooterItem key={idx}>
                                        <StyledFooterItemLink to={item.link}>
                                            {item.name}
                                        </StyledFooterItemLink>
                                    </StyledFooterItem>
                                )
                            })}
                            <StyledSeparator />
                        </StyledFooterWrapper>
                        <StyledNavSection>
                            <StyledSocialLinksWrapper
                                {...sevenNewsSocialLinks}
                            />
                        </StyledNavSection>
                    </StyledNavScrollArea>
                </StyledNav>
            </StyledAnimationContainer>
        </StyledNavigationBackground>
    )
}

SevenNewsFlyoutNav2.displayName = 'SevenNewsFlyoutNav2'

function isItemToggled(featureState: FeatureState, item: SevenNewsNavItemV2) {
    const { feature, invertFeatureToggle, hideFromFlyoutMenu } = item
    if (feature) {
        if (
            !isComponentEnabled(featureState, {
                feature,
                invertFeatureToggle,
            })
        ) {
            return false
        }
    }
    if (hideFromFlyoutMenu) {
        return false
    }
    return true
}
