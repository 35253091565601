import {
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'

import { StyledPrmoCardsWrapper } from './PromoCards.styled'
import { useProduct } from '../../../__product'
import { ImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { ThemeMargins } from '../../../__styling'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { PromotionalCard } from '../../cards'

interface PromoCardsV2Props {
    items: MaybeLoaded<CardItem[]>
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    onEvent: (event: CollectionEvent) => void
    tbRegionName?: string
}

export function PromoCardsV2(props: PromoCardsV2Props) {
    const { verticalGutters, items, tbRegionName } = props
    const product = useProduct()
    if (!items.loaded) {
        return null
    }
    const loadedItems = invertMaybeLoadedItems(items, 3)

    return (
        <ImpressionAvailable
            loading={!props.items.loaded}
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Promo Card',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <div ref={ref}>
                    <StyledPrmoCardsWrapper
                        data-tb-region={tbRegionName}
                        verticalGutters={verticalGutters}
                    >
                        {loadedItems.map((item, itemIndex) => (
                            <PromotionalCard
                                item={item}
                                cardNumber={itemIndex}
                                onEvent={props.onEvent}
                            />
                        ))}
                    </StyledPrmoCardsWrapper>
                </div>
            )}
        </ImpressionAvailable>
    )
}
