import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SevenNewsApp } from './SevenNewsApp'

export const SevenNewsAppRegistration = createRegisterableComponent(
    '7news-app-page',
    () => {
        return <SevenNewsApp />
    },
)
