import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { FixturesWidget, FixturesWidgetProps } from './FixturesWidget'

export const FixturesWidgetRegistration = createRegisterableComponent(
    'fixtures-widget',
    (props: FixturesWidgetProps, services) => {
        return <FixturesWidget {...props} />
    },
)
