import { SevenNewsSection, metrics } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType, slugify } from '@news-mono/web-common'
import { ResolveVideoSeriesEpisodeV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../../shared-components/ad-and-breaking-news'
import { breadcrumb } from '../../shared-components/breadcrumb'
import { doubletLayoutRightColumn } from './common-publication-content'
import { topHeadlines } from '../../shared-components/top-headlines'

export const createEpisodeRoute = ({
    episodeData,
    section,
    getAdTargeting,
}: {
    episodeData: ResolveVideoSeriesEpisodeV4DTO
    section: SevenNewsSection
    getAdTargeting: GetRouteAdTargeting
}): PageType<SevenNewsSection> => {
    const seriesBaseUrl = `/${section}/${slugify(episodeData.series)}`

    const layoutWide = layout.composition({
        type: 'article-doublet',
        props: {
            stretchSelf: true,
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            horizontalGutters: 'md',
            verticalSpacing: 'lg',
            breakpoints: {
                sm: {
                    horizontalGutters: 'xl',
                },
            },
        },
        contentAreas: {
            header: [],
            main: [
                layout.component({
                    type: 'video-series-episode',
                    props: {},
                }),
            ],
            sidebarRight: doubletLayoutRightColumn(),
            mobileRail: [topHeadlines()],
        },
    })

    return {
        heading: `${episodeData.episodeMeta.name}`,
        hideHeading: true,
        pageMeta:
            {
                ...episodeData.meta,
                title: `${episodeData.series}: ${episodeData.episodeMeta.name}`,
            } || {},
        socialMeta: {
            description: episodeData.meta?.description,
        },
        kind: 'page',
        pageType: 'publication',
        adTargeting: getAdTargeting('home', section, episodeData.series),
        section: section,
        compositions: [
            headerAd(),
            breakingNews,
            breadcrumb({
                items: [
                    {
                        text: 'Spotlight',
                        href: '/spotlight',
                    },
                    {
                        text: episodeData.series,
                        href: seriesBaseUrl,
                    },
                ],
                enableHeaderTag: false,
            }),
            layoutWide,
        ],
    }
}
