import React from 'react'
import { createRegisterableComponent } from '@news-mono/web-common'
import TVGuideMainWidget from './TVGuideMainWidget'

export interface TVGuideMainWidgetProps {
    apiData?: any
}

export const TVGuideMainWidgetRegistration = createRegisterableComponent(
    'tv-guide-main-widget',
    (props: TVGuideMainWidgetProps) => {
        //big tv guide widget
        return <TVGuideMainWidget />
    },
)
