import styled from '@emotion/styled'
import React from 'react'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

interface LocationDropDownProps {
    isOpen: boolean
}
export const StyledDropdownTrigger = styled('button')<LocationDropDownProps>(
    ({}) => ({
        display: 'inline-block',
        height: calcRem(18),
        padding: 0,
        background: 'none',
        border: 'none',
        borderRadius: 'none',
        cursor: 'pointer',
        color: colors.sevennews.red,
        appearance: 'none',
        fontWeight: 600,
        lineHeight: 1,

        '&:focus': {
            outline: 'none',
            textDecoration: 'underline',
        },
    }),
)

interface StyledLocationDropdownProps {
    isOpen: boolean
}

export const StyledLocationDropdown = styled('ul')<StyledLocationDropdownProps>(
    ({ theme, isOpen }) => ({
        position: 'absolute',
        top: '100%',
        left: 0,
        margin: 0,
        padding: 0,
        width: '100%',
        fontWeight: 600,
        zIndex: zIndex.sevennews.globalNavLocationDropDown,
        listStyle: 'none',
        backgroundColor: colors.white,
        color: colors.sevennews.charade,
        textAlign: 'right',
        display: isOpen ? 'inline-block' : 'none',
        border: `${calcRem(2)} solid ${colors.sevennews.nero15}`,
        borderRadius: calcRem(4),
    }),
)

export const StyledLocationDropdownItem = styled('li')({})

interface StyledLocationLinkProps {
    selected: boolean
}

export const StyledLocationDropdownLink = styled('a')<StyledLocationLinkProps>(
    {
        display: 'block',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: calcRem(13),
        padding: calcRem(8, 44),
        '&:not(last-of-type)': {
            borderBottom: `1px solid ${colors.sevennews.nero15}`,
        },
    },
    ({ theme, selected }) => ({
        color: selected ? colors.sevennews.red : colors.sevennews.charade,
    }),
)

export const StyledDropDownCaret = styled('svg')<LocationDropDownProps>(
    {
        fill: 'currentColor',
        stroke: 'none',
        marginLeft: calcRem(10),
        width: 10,
        height: 10,

        [breakpoint('md')]: {
            fill: colors.white,
        },
    },
    ({ isOpen }) => ({
        transform: isOpen ? 'rotate(180deg)' : undefined,
    }),
)

export const StyledLocationSelectorWrapper = styled('div')({
    fontSize: calcRem(12),
    fontFamily: fonts.sevennews.sansSerif,
    display: 'inline-block',
    fontWeight: 600,
    lineHeight: 1.2,
    textDecoration: 'none',
})

export const StyledLocationSelectorRefContainer = styled('div')({
    display: 'block',
    textAlign: 'right',
})

export const LocationDropDownCaret: React.FC<LocationDropDownProps> = ({
    isOpen,
}) => (
    <StyledDropDownCaret
        isOpen={isOpen}
        viewBox="0 0 13 7"
        version="1.1"
        height="7"
        width="13"
        fill="none"
    >
        <path
            d="M1.5 1L6.5 6L11.5 1"
            stroke="#E6001E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledDropDownCaret>
)
