import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts } from '../../../__styling'
import { keyframes } from '@emotion/react'
import { tokens } from '@news-mono/design-tokens'

export const StyledSignInContainer = styled('div')({
    display: 'flex',
    maxWidth: calcRem(1200),
    width: 'auto',
    margin: '0 auto',
})

export const StyledSignInHero = styled('img')({
    width: calcRem(600),
    display: 'none',
    [breakpoint('lg')]: {
        display: 'block',
    },
})

export const StyledSignInHeading = styled('h3')({
    color: tokens.sevennews.colors.palette.bauhausBlack,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: calcRem(0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    marginBottom: calcRem(40),
})

export const StyledSignInHeadingIcon = styled('img')({
    height: calcRem(20),
    margin: `0 ${calcRem(8)}`,
})

export const StyledSignInDescription = styled('div')({
    color: tokens.sevennews.colors.palette.nero,
    textAlign: 'center',
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(14),
    fontStyle: 'normal',
    fontWeight: 400,
    display: 'inline',
    marginTop: calcRem(30),
})

export const StyledSignUpCTA = styled(StyledSignInDescription)({
    fontSize: calcRem(13),
    display: 'inline',
    color: tokens.sevennews.colors.palette.newsletters.lightBlackAlternative,
})

export const StyledSignUpCTALink = styled('a')({
    color: tokens.sevennews.colors.palette.red,
    textAlign: 'center',
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(14),
    fontStyle: 'normal',
    fontWeight: 500,
    textDecoration: 'none',
})

export const StyledSignInInput = styled('input')(() => ({
    height: calcRem(28),
    border: 'none',
    outline: 'none',
    width: '100%',
    borderBottom: `1px solid ${tokens.sevennews.colors.palette.newsletters.borderBlackAlternative}`,
    fontSize: calcRem(16),
    color: tokens.sevennews.colors.palette.charcoal,
    fontWeight: 600,
    '&:placeholder': {
        fontFamily: fonts.sevennews.sansSerif,
        fontSize: calcRem(16),
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
}))

export const StyledSignInEyeIcon = styled('img')({
    height: calcRem(10),
    margin: calcRem(8),
    marginBottom: calcRem(4),
    marginRight: calcRem(4),
})

export const StyledShowHidePasswordToggle = styled('span')({
    position: 'absolute',
    right: '0',
    bottom: -2,
    cursor: 'pointer',
    zIndex: 999,
})

export const StyledSignInFormContainer = styled('div')({
    width: calcRem(600),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    textAlign: 'center',

    [breakpoint('lg')]: {
        border: `2px solid ${tokens.sevennews.colors.palette.newsletters.borderBlack}`,
    },
})

export const StyledSignInFormInnerContainer = styled('div')({
    width: calcRem(330),
    margin: '0 auto',
    paddingBottom: calcRem(80),
})
export const StyledSignInForm = styled('form')(() => ({
    display: 'block',
    marginTop: calcRem(40),
    marginBottom: calcRem(48),
    position: 'relative',
}))

export const StyledSignInLabel = styled('label')(() => ({
    display: 'block',
    fontSize: calcRem(13),
    fontWeight: 700,
    lineHeight: calcRem(16),
    font: fonts.sevennews.sansSerif,
    marginBottom: calcRem(40),
    position: 'relative',
}))

export const StyledSignInError = styled('span')(() => ({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(14),
    fontStyle: 'normal',
    fontWeight: 400,
    position: 'absolute',
    top: calcRem(32),
    left: calcRem(4),
    color: tokens.sevennews.colors.palette.red,
}))

export const StyledResetPasswordLink = styled('a')(() => ({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(13),
    fontStyle: 'normal',
    fontWeight: 400,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: calcRem(-24),
    textDecoration: 'none',
    color: tokens.sevennews.colors.palette.newsletters.lightBlackAlternative,
}))

export const StyledSignInSubmitButton = styled('button')({
    display: 'flex',
    width: calcRem(324),
    padding: `${calcRem(11.5)} ${calcRem(32)}`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: calcRem(10),
    borderRadius: calcRem(4),
    background: tokens.sevennews.colors.palette.red,
    color: tokens.sevennews.colors.palette.white,
    border: 0,
    margin: `${calcRem(80)} auto 0`,
    cursor: 'pointer',
    minHeight: calcRem(40),
    '&:disabled': {
        opacity: 0.5,
        cursor: 'default',
    },
})
