import React from 'react'

export const TextToSpeachIcon = () => {
    return (
        <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.7">
                <path
                    opacity="0.5"
                    d="M0 12.2778V4.72222H6L11 0V17L6 12.2778H0Z"
                    fill="black"
                />
            </g>
            <path
                opacity="0.5"
                d="M14 5C15.0516 5.77309 15.7 6.83199 15.7 8C15.7 9.16801 15.0516 10.2269 14 11"
                stroke="black"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity="0.5"
                d="M15.4004 2C17.607 3.16977 19.1004 5.41826 19.1004 8C19.1004 10.5817 17.607 12.8302 15.4004 14"
                stroke="black"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
