import { SevenNewsNavItemV2 } from '@news-mono/component-library'

export const sevenNewsMainNavigation: SevenNewsNavItemV2[] = [
    {
        name: 'Watch 7NEWS',
        link: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=main-nav-link&utm_campaign=news',
        feature: '7-news-watch-7-plus-nav',
        hideFromFlyoutMenu: true,
        linkOut: true,
    },
    {
        name: 'Local',
        link: '/news/local',
        subNavLinks: [
            {
                name: 'NSW',
                link: '/news/nsw',
                displayInSubNav: true,
            },
            {
                name: 'VIC',
                link: '/news/vic',
                displayInSubNav: true,
            },
            {
                name: 'QLD',
                link: '/news/qld',
                displayInSubNav: true,
            },
            {
                name: 'SA',
                link: '/news/sa',
                displayInSubNav: true,
            },
            {
                name: 'WA',
                link: '/news/wa',
                displayInSubNav: true,
            },
            {
                name: 'ACT',
                link: '/news/act',
                displayInSubNav: true,
            },
            {
                name: 'TAS',
                link: '/news/tas',
                displayInSubNav: true,
            },
            {
                name: 'NT',
                link: '/news/nt',
                displayInSubNav: true,
            },
            {
                name: 'Weather',
                link: '/news/weather',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'Video',
        link: '/video',
        feature: '7-news-navigation-video-hub',
        subNavLinks: [
            {
                name: 'News',
                link: '/video/news',
                displayInSubNav: true,
            },
            {
                name: 'Lifestyle',
                link: '/video/lifestyle',
                displayInSubNav: true,
            },
            {
                name: 'Entertainment',
                link: '/video/entertainment',
                displayInSubNav: true,
            },
            {
                name: 'Sport',
                link: '/video/sport',
                displayInSubNav: true,
            },
            {
                name: 'Politics',
                link: '/video/politics',
                displayInSubNav: true,
            },
            {
                name: 'Business',
                link: '/video/business',
                displayInSubNav: true,
            },
            {
                name: 'Finance',
                link: '/video/business/finance',
                displayInSubNav: true,
            },

            {
                name: 'Technology',
                link: '/video/technology',
                displayInSubNav: true,
            },
            {
                name: 'Travel',
                link: '/video/travel',
                displayInSubNav: true,
            },
            //TODO add sunrise and tms just on this subnav

            {
                name: 'Weather',
                link: '/video/news/weather',
            },
        ],
    },
    {
        name: 'Sport',
        link: '/sport',
        subNavLinks: [
            //TODO add video

            {
                name: 'AFL',
                link: '/sport/afl',
                displayInSubNav: true,
            },
            {
                name: 'AFLW',
                link: '/sport/aflw',
                displayInSubNav: true,
            },

            {
                name: 'AFL Match Centre',
                link: '/sport/afl/fixtures',
            },
            {
                name: 'AFLW Match Centre',
                link: '/sport/aflw/fixtures',
            },
            {
                name: 'Cricket Match Centre',
                link: '/sport/cricket/fixtures',
            },
            {
                name: 'Cricket',
                link: '/sport/cricket',
                displayInSubNav: true,
            },
            {
                name: 'NRL',
                link: '/sport/rugby-league',
                displayInSubNav: true,
            },
            {
                name: 'Tennis',
                link: '/sport/tennis',
                displayInSubNav: true,
            },
            {
                name: 'Motorsport',
                link: '/sport/motorsport',
                displayInSubNav: true,
            },
            {
                name: 'NFL',
                link: '/sport/nfl',
                displayInSubNav: true,
            },

            {
                name: 'Football',
                link: '/sport/soccer',
                displayInSubNav: true,
            },
            {
                name: 'Horse Racing',
                link: '/sport/horse-racing',
                displayInSubNav: true,
            },
            {
                name: 'Golf',
                link: '/sport/golf',
                displayInSubNav: true,
            },
            {
                name: 'UFC',
                link: '/sport/ufc',
                displayInSubNav: true,
            },
            {
                name: 'MMA',
                link: '/sport/mixed-martial-arts',
                displayInSubNav: true,
            },
            {
                name: 'Basketball',
                link: '/sport/basketball',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'Lifestyle',
        link: '/lifestyle',

        subNavLinks: [
            //TODO add video
            {
                name: 'Health & Wellbeing',
                link: '/lifestyle/health-wellbeing',
                displayInSubNav: true,
            },
            {
                name: 'Food',
                link: '/lifestyle/food',
                displayInSubNav: true,
            },
            {
                name: 'Travel',
                link: '/travel',
                displayInSubNav: true,
            },
            {
                name: 'Real Estate',
                link: '/lifestyle/real-estate',
                displayInSubNav: true,
            },
            {
                name: 'Parenting',
                link: '/lifestyle/parenting',
                displayInSubNav: true,
            },
            {
                name: 'Shopping',
                link: '/lifestyle/shopping',
                displayInSubNav: true,
            },
            {
                name: 'Pets',
                link: '/lifestyle/pets',
            },
            {
                name: 'Sponsored',
                link: '/lifestyle/sponsored',
                displayInSubNav: true,
            },
            {
                name: 'Personal Finance',
                link: '/lifestyle/personal-finance',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'Entertainment',
        link: '/entertainment',
        subNavLinks: [
            {
                name: 'Sunrise',
                link: '/sunrise',
                displayInSubNav: true,
            },
            {
                name: 'The Morning Show',
                link: '/the-morning-show',
                displayInSubNav: true,
            },
            {
                name: 'Spotlight',
                link: '/spotlight',
                displayInSubNav: true,
            },
            {
                name: 'TV',
                link: '/entertainment/tv',
                displayInSubNav: true,
            },
            {
                name: 'Australian Idol',
                link: '/entertainment/australian-idol',
            },
            {
                name: 'Home & Away',
                link: '/entertainment/home-and-away',
            },
            {
                name: 'Kitchen Nightmares Australia',
                link: '/entertainment/kitchen-nightmares-australia',
            },
            {
                name: 'Dream Home',
                link: '/entertainment/dream-home',
            },
            {
                name: `Million Dollar Island`,
                link: '/entertainment/million-dollar-island',
            },
            {
                name: 'Heartbreak Island',
                link: '/entertainment/heartbreak-island',
            },
            {
                name: 'Big Brother	',
                link: '/entertainment/big-brother-australia',
            },
            {
                name: 'The Voice',
                link: '/entertainment/the-voice-australia',
            },
            {
                name: 'MKR',
                link: '/entertainment/my-kitchen-rules',
            },
            {
                name: 'SAS Australia',
                link: '/entertainment/sas-australia',
            },
            {
                name: 'Farmer Wants A Wife',
                link: '/entertainment/farmer-wants-a-wife',
            },
            {
                name: 'Dancing with the Stars',
                link: '/entertainment/dancing-with-the-stars',
            },
            {
                name: `Australia's Got Talent`,
                link: '/entertainment/agt',
            },
            {
                name: 'Movies',
                link: '/entertainment/movies',
                displayInSubNav: true,
            },
            {
                name: 'Celebrity',
                link: '/entertainment/celebrity',
                displayInSubNav: true,
            },
            {
                name: 'Music',
                link: '/entertainment/music',
                displayInSubNav: true,
            },
            {
                name: '7plus',
                link: 'https://7plus.com.au/entertainment?utm_source=7NEWS&utm_medium=7plus-link&utm_campaign=hamburger-nav-entertainment',
            },
            {
                name: '7Bravo',
                link: 'https://7plus.com.au/7bravo?utm_source=7NEWS&utm_medium=hamburger-nav-link&utm_campaign=7bravo',
            },
            {
                name: 'TV Guide',
                link: 'https://tvguidetonight.com.au/?utm_source=7NEWS&utm_medium=sub-nav-ent&utm_campaign=tv-guide',
                feature: '7news-tv-guide-navigation',
            },
        ],
    },
    {
        name: 'Tech',
        link: '/technology',
        hideFromMainNav: true,
        subNavLinks: [
            {
                name: 'Smartphones',
                link: '/technology/smartphones',
            },
            {
                name: 'Reviews',
                link: '/technology/reviews',
            },
            {
                name: 'Gaming',
                link: '/technology/gaming',
            },
            {
                name: 'Gadgets',
                link: '/technology/gadgets',
            },
            {
                name: 'Science',
                link: '/technology/science',
            },
            {
                name: 'Space',
                link: '/technology/space',
            },
        ],
    },
    {
        name: 'Politics',
        link: '/politics',
        subNavLinks: [
            {
                name: 'Federal Politics',
                link: '/politics/federal-politics',
                displayInSubNav: true,
            },
            {
                name: 'NSW',
                link: '/politics/nsw',
                displayInSubNav: true,
            },
            {
                name: 'VIC',
                link: '/politics/vic',
                displayInSubNav: true,
            },
            {
                name: 'QLD',
                link: '/politics/qld',
                displayInSubNav: true,
            },
            {
                name: 'SA',
                link: '/politics/sa',
                displayInSubNav: true,
            },
            {
                name: 'WA',
                link: '/politics/wa',
                displayInSubNav: true,
            },
            {
                name: 'ACT',
                link: '/politics/act',
                displayInSubNav: true,
            },
            {
                name: 'TAS',
                link: '/politics/tas',
                displayInSubNav: true,
            },
            {
                name: 'NT',
                link: '/politics/nt',
                displayInSubNav: true,
            },
            {
                name: 'World Politics',
                link: '/politics/world-politics',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'Best Picks',
        link: '/best-picks',
        subNavLinks: [
            {
                name: 'Beauty',
                link: '/best-picks/beauty',
                displayInSubNav: true,
            },
            {
                name: 'Fashion',
                link: '/best-picks/fashion',
                displayInSubNav: true,
            },
            {
                name: 'Electronics',
                link: '/best-picks/appliances',
                displayInSubNav: true,
            },
            {
                name: 'Baby & Kids',
                link: '/best-picks/baby-kids',
                displayInSubNav: true,
            },
            {
                name: 'Fitness',
                link: '/best-picks/fitness',
                displayInSubNav: true,
            },
            {
                name: 'Health',
                link: '/best-picks/health',
                displayInSubNav: true,
            },
            {
                name: 'Home & Garden',
                link: '/best-picks/home-garden',
                displayInSubNav: true,
            },
            {
                name: 'Travel',
                link: '/best-picks/travel',
                displayInSubNav: true,
            },
            {
                name: 'Tech',
                link: '/best-picks/tech',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'Finance',
        link: '/business/finance',
        hideFromMainNav: true,
        subNavLinks: [
            {
                name: 'Personal Finance',
                link: '/lifestyle/personal-finance',
            },
            {
                name: 'Retail',
                link: '/business/retail',
                displayInSubNav: true,
            },
            {
                name: 'Banking',
                link: '/business/banking',
                displayInSubNav: true,
            },
            {
                name: 'Markets',
                link: '/business/markets',
                displayInSubNav: true,
            },
            {
                name: 'Economy',
                link: '/business/economy',
                displayInSubNav: true,
            },
            //TODO add property
        ],
    },
    {
        name: 'World',
        link: '/news/world',
        hideFromMainNav: true,
        subNavLinks: [
            {
                name: 'North America',
                link: '/news/north-america',
                displayInSubNav: true,
            },
            {
                name: 'Asia Pacific',
                link: '/news/asia',
                displayInSubNav: true,
            },
            {
                name: 'Europe',
                link: '/news/europe',
                displayInSubNav: true,
            },
            {
                name: 'Middle East',
                link: '/news/middle-east',
                displayInSubNav: true,
            },
            {
                name: 'Africa',
                link: '/news/africa',
                displayInSubNav: true,
            },
            {
                name: 'Latin America',
                link: '/news/latin-america',
                displayInSubNav: true,
            },
        ],
    },
    {
        name: 'TV Guide',
        link: 'https://tvguidetonight.com.au/?utm_source=7NEWS&utm_medium=main-nav&utm_campaign=tv-guide',
        feature: '7news-tv-guide-navigation',
        hideFromFlyoutMenu: true,
        linkOut: true,
    },
    {
        name: 'Weather',
        link: '/weather',
        hideFromMainNav: true,
        subNavLinks: [
            {
                name: 'BOM',
                link: '/weather/bom',
            },
            {
                name: 'Natural Disasters',
                link: '/weather/natural-disasters',
            },
            {
                name: 'Environment',
                link: '/weather/environment',
            },
        ],
    },
    {
        name: 'Motoring',
        link: '/motoring',

        hideFromMainNav: true,
        subNavLinks: [
            {
                name: 'Videos',
                link: '/video/motoring',
            },
        ],
    },
    {
        name: 'Coupons',
        link: 'https://coupons.7news.com.au/',
        linkOut: true,
        subNavLinks: [
            {
                name: 'HelloFresh',
                link: 'https://coupons.7news.com.au/hellofresh#',
            },
            {
                name: 'Nike',
                link: 'https://coupons.7news.com.au/nike#',
            },
            {
                name: 'Coles',
                link: 'https://coupons.7news.com.au/coles#',
            },
            {
                name: 'Woolworths',
                link: 'https://coupons.7news.com.au/woolworths#',
            },
            {
                name: 'Lee',
                link: 'https://coupons.7news.com.au/lee-jeans#',
            },
            {
                name: 'Norton',
                link: 'https://coupons.7news.com.au/norton#',
            },
            {
                name: 'PrettyLittleThing',
                link: 'https://coupons.7news.com.au/prettylittlething#',
            },
            {
                name: 'Weight Watchers',
                link: 'https://coupons.7news.com.au/weight-watchers#',
            },
            {
                name: 'Target',
                link: 'https://coupons.7news.com.au/target#',
            },
        ],
    },
    {
        name: 'Motoring',
        link: '/motoring',

        hideFromFlyoutMenu: true,
    },
    {
        name: 'Contact Us',
        link: '/contact-us',
        hideFromMainNav: true,
    },
]

export const globalNavigationV2: SevenNewsNavItemV2[] = [
    {
        name: 'TV Guide',
        link: 'https://tvguidetonight.com.au/?utm_source=7NEWS&utm_medium=sub-nav&utm_campaign=tv-guide',
        feature: '7news-tv-guide-navigation',
        linkOut: true,
    },
    {
        name: 'Sunrise',
        link: '/sunrise',
    },
    {
        name: 'The Morning Show',
        link: '/the-morning-show',
    },
    {
        name: 'Spotlight',
        link: '/spotlight',
    },
    {
        name: 'Podcasts',
        link: '/podcasts',
    },
    {
        name: '7NEWS App',
        link: '/app',
        feature: '7-news-app-site-promotion',
    },
    {
        name: 'Newsletters',
        link: '/newsletters',
        feature: '7-news-footer-newsletter',
    },
]

export const navigationV2: SevenNewsNavItemV2[] = sevenNewsMainNavigation
