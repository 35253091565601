import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { SevenNewsSomethingWentWrong } from '../../../errors/SomethingWentWrong/SevenNewsSomethingWentWrong/SevenNewsSomethingWentWrong'

export const SevenNewsSomethingWentWrongRegistration =
    createRegisterableComponent('sevennews-500-page', () => (
        <RouteStatus statusCode={500}>
            <SevenNewsSomethingWentWrong />
        </RouteStatus>
    ))
