import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import { AdTargeting, StaticRoutes, Topic } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAdTwo } from '../shared-components/mobile-ads'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

interface CricketMatchCentreParams {
    competition: string
    season: string
    match: string
}

const fixturesMetaImage = require('./assets/fixtures.jpg')

export const cricketTopicForAdTargeting: Topic = {
    title: 'Cricket',
    id: 'sport/cricket',
    seoTitle: 'Cricket',
    metadata: {},
    parent: {
        title: 'Sport',
        id: 'sport',
        seoTitle: 'Sport',
        metadata: {},
    },
}

export const cricketMatchCentreRoutes: StaticRoutes<SevenNewsSection> = {
    '/sport/cricket/fixtures': ({ getAdTargeting, store }) => {
        const section = 'default'

        const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
            'fixtures',
            section,
            cricketTopicForAdTargeting,
        )
        const pageTitle =
            'Live Cricket Scores Australia - Latest Schedule, Fixtures, Results & Highlights | 7News'
        const adTargeting: AdTargeting = {
            pageId: pageTitle,
            adUnitPath,
            ssAdUnits,
            topics,
        }
        const is7NewsPillMenuEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            '7-news-2-pill-menu',
            false,
        )
        const isV2LeaderBoardBillboardToggleEnabled = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            '7-news-2-top-leaderboard-ad',
        )
        return {
            kind: 'page',
            heading: pageTitle,
            hideHeading: true,
            pageType: 'static',
            pageMeta: {
                title: pageTitle,
                description: `7News brings you the latest cricket scores from Australia and around the world. Stay up to date with the schedules & fixtures for all upcoming cricket matches. Today's Cricket news, live scores & all of the latest highlights from the Ashes, Test Cricket, T20s, ODIs, Big Bash and Sheffield Shield.`,
                meta: [
                    {
                        property: 'og:image',
                        content: fixturesMetaImage,
                    },
                    {
                        name: 'twitter:image',
                        content: fixturesMetaImage,
                    },
                    {
                        property: 'og:image:height',
                        content: '400',
                    },
                    {
                        property: 'og:image:width',
                        content: '400',
                    },
                ],
            },
            noMetaTitleTemplate: true,
            adTargeting,
            section,
            compositions: [
                ...(isV2LeaderBoardBillboardToggleEnabled ? [] : [headerAd()]),
                layout.composition({
                    type: 'box',
                    props: {},
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'page-header-v2',
                                feature: '7-news-2-pill-menu',
                                props: {
                                    sectionHeader: {
                                        heading: 'Cricket Fixtures ',
                                    },
                                },
                            }),
                        ],
                    },
                }),
                ...(is7NewsPillMenuEnabled ? [] : [breakingNews]),
                box({
                    propOverrides: {
                        horizontalGutters: 'md',
                        verticalGutters: ['md', 'md'],
                        containerWidth:
                            metrics.sevennews.siteMetrics
                                .sevenEntMainContentWidth,
                    },
                    main: [
                        layout.component({
                            type: 'breaking-news',
                            feature: '7-news-2-pill-menu',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'breaking-news',
                                    offset: 0,
                                    pageSize: 10,
                                },
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            feature: '7-news-2-pill-menu',
                            props: {
                                data: {
                                    type: 'default',
                                    topic: cricketTopicForAdTargeting,
                                },
                                verticalGutters: ['md', 'md'],
                            },
                        }),
                        layout.component({
                            type: 'fixtures-widget',
                            props: {},
                        }),
                        layout.component({
                            type: 'promo-cards-seven',
                            props: {
                                verticalSpacing: 'lg',
                                dataDefinitionArgs: {
                                    type: 'curation',
                                    name: 'cricket-promo-cards',
                                    offset: 0,
                                    pageSize: 3,
                                },
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                noticePosition: 'above-center',
                                hiddenUntilLoaded: false,
                                padding: [
                                    metrics.sevennews.margins.md,
                                    0,
                                    0,
                                    0,
                                ],
                                slot: {
                                    id: 'leaderboard-billboard-two',
                                    size: 'leaderboardBillboard',
                                },
                                adType: 'inline',
                            },
                        }),
                        mobileAdTwo,
                        layout.component({
                            type: 'taboola',
                            props: {
                                taboolaContainerId:
                                    'taboola-below-category-thumbnails',
                                locationType: 'category',
                                isFourColumns: isTabletOrMobileViewport()
                                    ? false
                                    : true,
                            },
                        }),
                    ],
                }),
            ],
        }
    },
}
