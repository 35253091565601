import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { DataLayerEventName, useToggleState } from '@news-mono/web-common'
import {
    EventPostV4DTO,
    KilledEventPostV4DTO,
} from '@west-australian-newspapers/publication-types'
import { WebsocketServiceMessageWithPayload } from '@west-australian-newspapers/websocket-message-types'
import { nth } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Pubfeed, mappedPublicationPropsToGa4 } from '../../..'
import { useViewport } from '../../../__helpers/use-viewport'
import { SevenPlusArticleButton } from '../../../buttons/SevenPlusArticleButton'
import { TopicList } from '../../../content/TopicList/TopicList'
import { ArticleBlockContent } from '../../../publication/ArticleBlockContent/ArticleBlockContent'
import { SevenFootnote } from '../../../publication/Footnote/Footnote'
import { LiveEvent } from '../../../publication/LiveEvent/LiveEvent'
import { PublicationProps } from '../../../templates/Publication/Publication.props'
import {
    StyledArticleContent,
    StyledCell,
    StyledGrid,
} from '../../../templates/Publication/SevenNewsPublication/SevenNewsPublication.styled'
import { getCanonicalLink } from '../../../templates/Publication/lib/get-canonical-link'
import TVGuideMainWidget from '../../../tv-guide-widgets/TVGuideMainWidget/TVGuideMainWidget'
import { getSevenPlusArticleButtonLink } from './get-seven-plus-article-link'

export const videoContainerClassName = 'sticky-container'

export const SevenNewsPublication: React.SFC<PublicationProps> = (props) => {
    const { isTabletOrMobile } = useViewport()
    const publication = props.data
    const content = publication.content
    const canonicalLink = getCanonicalLink(publication)
    const targetedContentElement =
        props.targetedContent && props.targetedContent.position === 'inline'
            ? props.targetedContent.element
            : undefined

    const togglesState = useToggleState()
    const toggles = toFeatureState(togglesState)
    const publicationKind = publication.kind
    const isEvent = publicationKind === 'event'

    const articleAvailablePayload = mappedPublicationPropsToGa4(publication)
    useEffect(() => {
        if (articleAvailablePayload) {
            props.onEvent({
                type: DataLayerEventName.articleAvailable,
                originator: 'SevenNewsPublication',
                payload: articleAvailablePayload,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSevenPlusArticleButtons = () => {
        const buttonData = getSevenPlusArticleButtonLink(
            publication.primaryTopic.id,
        )
        const isEvent = publication.kind == 'event' ? true : false

        if (!isEvent && buttonData.isEnabled && buttonData.buttonLink) {
            return <SevenPlusArticleButton URL={buttonData.buttonLink} />
        }
    }

    const articleBlockContent = (
        <ArticleBlockContent
            blocks={content}
            showInlineVideoTitles={true}
            targetedContentElement={targetedContentElement}
            inlinePositionedContent={props.inlinePositionedContent}
            onEvent={props.onEvent}
            log={props.log}
            publicationKind={publication.kind}
            publicationPathname={props.location.pathname}
            adUnitPath={props.adState.targeting.adUnitPath}
            getVideoQueue={props.getVideoQueue}
        />
    )

    const renderCanoncialLink = canonicalLink && (
        <StyledCell largeGap={false}>
            <SevenFootnote
                text={[
                    'This article was originally published from ',
                    ' and appears with permission.',
                ]}
                link={canonicalLink}
                source={publication.source}
            />
        </StyledCell>
    )

    const linkbyPubfeedTopics = ['lifestyle/sponsored']

    const isPrimaryTopicIncluded =
        publication.primaryTopic &&
        linkbyPubfeedTopics.includes(publication.primaryTopic.id)

    const isSecondaryTopicSponsored =
        publication.secondaryTopics &&
        publication.secondaryTopics.some(
            (topic) => topic.id === 'lifestyle/sponsored',
        )

    const renderLinkbyPubfeed = (isPrimaryTopicIncluded ||
        isSecondaryTopicSponsored) && (
        <StyledCell largeGap={true}>
            <Pubfeed />
        </StyledCell>
    )

    const showTvGuideWidget =
        isFeatureEnabled(toggles, '7-news-tv-guide-widgets') &&
        isTabletOrMobile &&
        publication.primaryTopic.id.includes('entertainment/tv')

    return (
        <StyledGrid>
            <StyledCell largeGap={true}>
                <div>
                    {content && (
                        <StyledArticleContent>
                            {articleBlockContent}
                            {renderCanoncialLink}
                            {getSevenPlusArticleButtons()}
                        </StyledArticleContent>
                    )}
                    {isEvent && (
                        <LiveEvent
                            publication={publication}
                            log={props.log}
                            adState={props.adState}
                            onEvent={props.onEvent}
                        />
                    )}
                </div>
            </StyledCell>

            {isFeatureEnabled(toggles, '7-news-linkby-pubfeed') &&
                renderLinkbyPubfeed}

            <StyledCell>
                <TopicList
                    primaryTopic={publication.primaryTopic}
                    secondaryTopics={publication.secondaryTopics}
                />
            </StyledCell>
            {showTvGuideWidget && <TVGuideMainWidget />}
        </StyledGrid>
    )
}
SevenNewsPublication.displayName = 'SevenNewsPublication'
