import styled from '@emotion/styled'
import {
    ThemeMargins,
    breakpoint,
    calcRem,
    colors,
    metrics,
} from '../../__styling'
import { StyledContentCard } from '../cards/ContentCard/ContentCard.styled'
import { tokens } from '@news-mono/design-tokens'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isSkeleton?: boolean
}

export const StyledNewsJustInCarouselWrapper = styled('div')<GridProps>(
    ({ verticalSpacing, verticalGutters, theme, isSkeleton }) => ({
        width: '100%',
        position: 'relative',
        display: 'none',
        overflow: isSkeleton ? 'hidden' : undefined,
        [breakpoint('sm')]: {
            display: 'block',
        },
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
    }),
)

export const StyledCarouselCardsWrapper = styled('div')({
    '.swiper': {
        padding: 0,

        '.swiper-slide': {
            border: '3px solid transparent',
            '&:focus-within': {
                border: `3px solid ${colors.sevennews.red}`,
            },
            maxWidth: calcRem(340),
        },
        '&:hover': {
            [breakpoint('md')]: {
                '.swiper-button-prev': {
                    background: colors.sevennews.pillMenu.leftArrowOverlay,
                    justifyContent: 'flex-start',
                    paddingLeft: calcRem(10),
                    left: 0,
                    '&::before': {
                        right: calcRem(-30),
                    },
                },
                '.swiper-button-next': {
                    background: colors.sevennews.pillMenu.rightArrowOverlay,
                    justifyContent: 'flex-end',
                    paddingRight: calcRem(10),
                    right: 0,

                    '&::before': {
                        left: calcRem(-30),
                    },
                },
                '.swiper-button-prev, .swiper-button-next': {
                    display: 'flex',
                    transition: 'background 250ms ease-out',
                    top: 0,
                    bottom: 0,
                    height: '100%',
                    width: calcRem(100),
                    '&::after': {
                        color: colors.sevennews.graniteGray,
                        fontSize: calcRem(18),
                        fontWeight: 900,
                    },
                    '&::before': {
                        position: 'absolute',
                        content: `''`,
                        top: 0,
                        bottom: 0,
                        height: '100%',
                        width: calcRem(30),
                    },
                },
            },
        },
        '.swiper-button-prev, .swiper-button-next': {
            display: 'none',
        },
        '.swiper-button-disabled': {
            display: 'none',
            '&::before, &::after': { display: 'none' },
        },
    },
    [`${StyledContentCard}`]: {
        minHeight: calcRem(120),
        '& [class*="StyledHeadline"]': {
            fontSize: calcRem(14),
            [breakpoint('lg')]: {
                fontSize: calcRem(15),
            },
        },
        '& [class*="StyledPlayIcon"]': {
            top: 'unset',
            left: 'unset',
            bottom: calcRem(10),
            right: calcRem(10),
            width: calcRem(16),
            height: calcRem(16),
            border: `${calcRem(1.92)} solid ${colors.white}`,
            '&::before': {
                left: '39.2%',
                top: '80%',
                backgroundSize: ` ${calcRem(3.5)} ${calcRem(6)}`,
            },
        },
    },
})

export const StyledJustInTitle = styled('span')({
    background: colors.sevennews.darkred,
    position: 'absolute',
    bottom: calcRem(-18),
    color: colors.white,
    padding: calcRem(2, 26, 2, 18),
    borderBottomRightRadius: calcRem(5),
    fontSize: calcRem(14),
    fontWeight: 800,
    svg: {
        marginRight: calcRem(10),
    },
})

export const StyledSkeletonWrapper = styled('div')({
    display: 'flex',
    overflow: 'hidden',
    gap: calcRem(45),
    width: 'fit-content',
})
