import React from 'react'

import { useGigyaService } from '../authentication/GigyaAuthenticationProvider'
import { SignIn } from '../SignIn/SignIn'
import { NewsletterPrefences } from '../NewsletterPreferences/NewsletterPreferences'

export const PreferenceCentre = () => {
    const authService = useGigyaService()

    return <>{authService?.user ? <NewsletterPrefences /> : <SignIn />}</>
}
