import React from 'react'
import { ManhattanWidgetContainer } from './ManhattanWidget.styled'

export interface ManhattanWidgetProps {
    competition: string
    season: string
    match: string
}
export const ManhattanWidget = ({
    competition,
    season,
    match,
}: ManhattanWidgetProps) => {
    return (
        <ManhattanWidgetContainer>
            <opta-widget
                widget="manhattan"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                show_match_header="false"
                show_crests="false"
                show_competition_name="false"
                show_state_of_play="true"
                show_graphs="all"
                side="both"
                show_key="true"
                show_innings="both"
                show_tooltips="true"
                show_officials="on_field"
                show_axis_labels="true"
                show_timecontrols="true"
                team_naming="full"
                player_naming="full"
                show_live="false"
                show_logo="false"
                show_title="false"
                breakpoints="400, 700"
                sport="cricket"
            ></opta-widget>
        </ManhattanWidgetContainer>
    )
}
