import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { createPropFilter, WebLink, WebLinkProps } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    fonts,
    metrics,
    transition,
} from '../../../__styling'
import LinkOutIcon from '../LinkOutIcon'

export const StyledDesktopNavList = styled('ul')({
    display: 'none',
    [breakpoint('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        fontFamily: fonts.sevennews.sansSerif,
        listStyle: 'none',
        margin: '0 auto',
        padding: 0,
    },
})

interface DesktopNavLinkProps {
    isCurrent: boolean
    linkOut?: boolean
}

export const StyledDesktopNavLink = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'isCurrent',
        'linkOut',
    ]),
})<DesktopNavLinkProps>(
    ({ linkOut }) => ({
        background: 'transparent',
        textDecoration: 'none',
        display: linkOut ? 'flex' : ' block',
        gap: calcRem(7),
        alignItems: 'center',
        padding: calcRem(
            metrics.sevennews.margins.sm,
            metrics.sevennews.margins.md,
        ),
        whiteSpace: 'nowrap',
        ...transition({ properties: ['background-color'], speed: 'fast' }),
        svg: {
            path: {
                fill: tokens.sevennews.colors.palette.charade,
            },
        },
        '&:hover': {
            svg: {
                path: {
                    fill: tokens.sevennews.colors.palette.white,
                },
            },
        },
    }),
    ({ isCurrent }) => ({
        fontWeight: 400,
        borderBottom: 'solid 3px transparent',
        borderColor: isCurrent
            ? tokens.sevennews.colors.palette.red
            : 'transparent',
        color: isCurrent
            ? tokens.sevennews.colors.palette.red
            : tokens.sevennews.colors.palette.nero,

        '&:hover': {
            textDecoration: 'none',
        },

        '&:focus': {
            outlineOffset: -1,
        },
        '&:active': {
            backgroundColor: tokens.sevennews.colors.palette.red,
            outline: `1px solid ${tokens.sevennews.colors.palette.red}`,
            outlineOffset: -1,
        },

        '@media (pointer: fine)': {
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: tokens.sevennews.colors.palette.red,
                color: tokens.sevennews.colors.palette.white,
            },
        },
    }),
)

export const StyledSVG = styled(LinkOutIcon)(({}) => ({}))
