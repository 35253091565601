import { cx } from '@emotion/css'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    AppState,
    closeNavigation,
    NavEvent,
    NavigationState,
    openNavigation,
    RenderTargetContext,
    useToggleState,
} from '@news-mono/web-common'
import format from 'date-fns/format'
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HamburgerButtonV2 } from '../../buttons/HamburgerButtonV2/HamburgerButtonV2'
import { SevenNewsFlyoutNav2 } from '../../navigation/SevenNewsFlyoutNavV2/SevenNewsFlyoutNav2'
import { SevenNewsMainNavigationV2 } from '../../navigation/SevenNewsMainNavigationV2'
import {
    SevenNewsNavigationV2,
    SevenNewsNavItemV2,
} from '../../routing/route-nav-types'
import { getLogo } from './logos'
import {
    SearchFormMDesktopContainer,
    SearchFormMobileContainer,
    StyledHeaderHamburgerWrapper,
    StyledHeaderLeftContent,
    StyledHeaderTimestamp,
    StyledLogoLink,
    StyledLogoSRHeading,
    StyledLogoWrapper,
    StyledPrintLogo,
    StyledSevenNewsHeader,
    StyledSiteHeaderContainer,
} from './SevenNewsHeaderV2.styled'
import { useDisableBodyScroll } from './useDisableBodyScroll'
import { SevenNewsFlyoutNav2AMP } from '../../navigation/SevenNewsFlyoutNavV2/SevenNewsFlyoutNav2AMP'
import { SearchForm } from '../../SevenNewsV2/search/SearchForm/SearchForm'

export interface SevenNewsHeaderProps {
    className?: string
    navLinks: SevenNewsNavigationV2
    secondaryNavLinks: SevenNewsNavItemV2[]
    onEvent: (event: NavEvent) => void
    controls?: string
}

export const SevenNewsHeaderV2: React.FC<SevenNewsHeaderProps> = ({
    className,
    controls,
    navLinks,
    secondaryNavLinks,
    onEvent,
}) => {
    const { mainNavOpen } = useSelector<AppState, NavigationState>(
        (state) => state.navigation,
    )
    const dispatch = useDispatch()
    const featureState = useToggleState()
    useDisableBodyScroll(mainNavOpen || false)
    const toggleFlyoutNav = () => {
        mainNavOpen ? dispatch(closeNavigation()) : dispatch(openNavigation())
    }

    const { renderTarget } = React.useContext(RenderTargetContext)

    const now = Date.now()
    const humanReadableDate = format(now, 'EEEE, dd MMMM yyyy')

    const [isSearchMobileActive, setIsSearchMobileActive] = useState(false)

    const toggles = useToggleState()

    const is7NewsSearchBarEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7-news-search-bar',
    )
    return (
        <Fragment>
            <StyledPrintLogo>{getLogo()}</StyledPrintLogo>
            <StyledSevenNewsHeader className={cx(className, 'hide-print')}>
                <StyledSiteHeaderContainer>
                    <StyledHeaderLeftContent
                        isSearchMobileActive={isSearchMobileActive}
                    >
                        {renderTarget !== 'static-error-page' && (
                            <StyledHeaderHamburgerWrapper>
                                <HamburgerButtonV2
                                    isOpen={false}
                                    onClick={toggleFlyoutNav}
                                    controls={controls}
                                />
                            </StyledHeaderHamburgerWrapper>
                        )}
                        {is7NewsSearchBarEnabled && (
                            <SearchFormMobileContainer>
                                <SearchForm
                                    isLarge={false}
                                    isMobile={true}
                                    hide={false}
                                    isSearchMobileActive={isSearchMobileActive}
                                    setIsSearchMobileActive={
                                        setIsSearchMobileActive
                                    }
                                />
                            </SearchFormMobileContainer>
                        )}
                        {renderTarget !== 'static-error-page' && (
                            <StyledHeaderTimestamp
                                className={className}
                                timestamp={humanReadableDate}
                                timestampFormat="dateOnly7News"
                                hide={isSearchMobileActive}
                                id="header-timestamp"
                            />
                        )}

                        {renderTarget !== 'static-error-page' &&
                        renderTarget !== 'amp' ? (
                            <SevenNewsFlyoutNav2
                                navLinks={navLinks}
                                onEvent={onEvent}
                                secondaryNavLinks={secondaryNavLinks}
                                isOpen={mainNavOpen}
                                toggleFlyoutNav={toggleFlyoutNav}
                                featureState={toFeatureState(featureState)}
                            />
                        ) : (
                            <SevenNewsFlyoutNav2AMP
                                navLinks={navLinks}
                                onEvent={onEvent}
                                toggleFlyoutNav={toggleFlyoutNav}
                                featureState={toFeatureState(featureState)}
                            />
                        )}
                    </StyledHeaderLeftContent>

                    <StyledLogoWrapper
                        hide={isSearchMobileActive}
                        id="logo-wrapper"
                    >
                        <StyledLogoLink to={'/'} id={'header-logo'}>
                            {getLogo()}
                        </StyledLogoLink>

                        <StyledLogoSRHeading>7News</StyledLogoSRHeading>
                    </StyledLogoWrapper>

                    {is7NewsSearchBarEnabled && (
                        <SearchFormMDesktopContainer>
                            <SearchForm
                                isLarge={false}
                                isMobile={false}
                                hide={false}
                            />
                        </SearchFormMDesktopContainer>
                    )}
                </StyledSiteHeaderContainer>
                <SevenNewsMainNavigationV2
                    navLinks={navLinks}
                    onEvent={onEvent}
                />
            </StyledSevenNewsHeader>
        </Fragment>
    )
}
SevenNewsHeaderV2.displayName = 'SevenNewsHeaderV2'
