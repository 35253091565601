import React from 'react'
import { ThemeMargins } from '../../../__styling'
import { StyledFiveCardCollection } from './FiveCardCollection.styled'
import { SkeletonCard } from '../../cards'

interface FiveCardCollectionSkeletonProps {
    verticalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

const gridAreasNames: {
    [key: number]: string
} = {
    0: 'one',
    1: 'two',
    2: 'three',
    3: 'four',
    4: 'five',
}

export const FiveCardCollectionSkeleton: React.FC<FiveCardCollectionSkeletonProps> =
    () => {
        return (
            <StyledFiveCardCollection
                itemCount={5}
                isSkeleton={true}
                verticalGutters={['unset', 'lg']}
            >
                {[...new Array(5)].map((_, itemIndex) => {
                    const gridAreaName = gridAreasNames[itemIndex]
                    return (
                        <SkeletonCard
                            showTimeToRead={true}
                            gridAreaName={gridAreaName}
                            textLines={
                                itemIndex === 0 || itemIndex === 1 ? 3 : 2
                            }
                            overrideHeight={
                                itemIndex === 0 || itemIndex === 1
                                    ? 160
                                    : undefined
                            }
                        />
                    )
                })}
            </StyledFiveCardCollection>
        )
    }
