import styled from '@emotion/styled'
import { ThemeMargins, breakpoint, calcRem, metrics } from '../../../__styling'

export interface GridProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isPromoCards?: boolean | false
    topic?: string
}

export const StyledPrmoCardsWrapper = styled('div')<GridProps>(
    ({ verticalGutters }) => [
        {
            margin: '0 auto',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: `1fr`,
            gridColumnGap: calcRem(20),
            paddingTop: verticalGutters
                ? calcRem(metrics.sevennews.margins[verticalGutters[0]])
                : calcRem(16),
            paddingBottom: verticalGutters
                ? calcRem(metrics.sevennews.margins[verticalGutters[1]])
                : calcRem(16),
            gridRowGap: calcRem(16),
            [breakpoint('sm')]: {
                gridTemplateColumns: `repeat(auto-fit, minmax(225px, 1fr) )`,
            },
        },
    ],
)
