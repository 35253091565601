import { StickySizeProps } from '@news-mono/web-common'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { StyledStickyContainer } from '../../content/StickyContainer/StickyContainer.styled'
import { addListener, removeListener } from '../../__helpers/global-dom-events'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'

export interface StickyContainerProps {
    verticalOffset?: number
    hideAt?: number
    showOnScrollUp?: number
    onResize?: (size: StickySizeProps) => void
}

type InternalProps = StickyContainerProps &
    DimensionProps &
    RouteComponentProps<any>

interface State {
    isHidden: boolean
    isTop: boolean
    scrollUp: boolean
}

const STICKY_TRANSITION_DURATION = 300

export class InternalSticky extends React.Component<InternalProps, State> {
    private previousScrollPos = 0
    private rafID = 0

    constructor(props: InternalProps) {
        super(props)

        this.state = {
            isHidden: false,
            isTop: true,
            scrollUp: false,
        }
    }

    /**
     * Checks viewport scroll level.
     * If user has scrolled over scrollTarget in px it sets the component to hidden and it scrolls off the page.
     * Upon scrolling up, it checks if the component is hidden and brings it back into view
     */
    handleScroll = () => {
        const { hideAt } = this.props
        const previousScrollPos = this.previousScrollPos
        const currentScrollPos = window.pageYOffset
        const isHidden = this.state.isHidden
        const shouldHide = hideAt ? currentScrollPos >= hideAt : false
        const userScrollUp = currentScrollPos <= previousScrollPos
        const userScrollDistance = Math.abs(
            currentScrollPos - previousScrollPos,
        )

        /**
         * Dont want the component appearing after small scroll up, so calculate the distance
         * user has scrolled and see if it has met a certain distance threshold
         */
        const { showOnScrollUp } = this.props
        const scrollUpThresholdMet = showOnScrollUp
            ? userScrollDistance >= showOnScrollUp
                ? true
                : false
            : false

        if (currentScrollPos <= (hideAt || 0)) {
            this.setState({
                isHidden: false,
                isTop: true,
            })
        } else if (userScrollUp && scrollUpThresholdMet && isHidden) {
            this.setState({
                isHidden: false,
                isTop: false,
            })
        } else if (shouldHide && !userScrollUp && !isHidden) {
            this.setState({
                isHidden: true,
                isTop: false,
            })
        }

        this.previousScrollPos = currentScrollPos
    }

    componentDidMount() {
        addListener('scroll', this.handleScroll)
        addListener('resize', this.handleScroll)
    }

    componentWillUnmount() {
        removeListener('scroll', this.handleScroll)
        removeListener('resize', this.handleScroll)
    }

    animate(callback: () => void, duration: number) {
        const time = {
            start: performance.now(),
            total: duration,
            elapsed: 0,
        }
        const tick = (now: number) => {
            time.elapsed = now - time.start
            if (time.elapsed < time.total) {
                callback()
                this.rafID = requestAnimationFrame(tick)
            }
        }
        if (this.rafID) {
            cancelAnimationFrame(this.rafID)
        }
        this.rafID = requestAnimationFrame(tick)
    }

    componentDidUpdate(prevProps: InternalProps, prevState: State) {
        const dimensionsChanged =
            prevProps.width !== this.props.width ||
            prevProps.height !== this.props.height
        const visibiltyChanged = prevState.isHidden !== this.state.isHidden

        if (dimensionsChanged || visibiltyChanged) {
            if (this.props.onResize) {
                const size = {
                    width: this.props.width,
                    height: this.props.height,
                }
                this.props.onResize(size)

                // Remeasure during CSS transitions
                // Will cause trigger measure every raf, until STICKY_TRANSITION_DURATION is complete
                this.animate(
                    this.props.triggerMeasure,
                    STICKY_TRANSITION_DURATION + 10,
                )
            }
        }
    }

    render() {
        return (
            <StyledStickyContainer
                isTop={this.state.isTop}
                isHidden={this.state.isHidden}
                verticalOffset={this.props.verticalOffset || 0}
                ref={this.props.innerRef}
                transitionDuration={STICKY_TRANSITION_DURATION}
            >
                {this.props.children}
            </StyledStickyContainer>
        )
    }
}

/** StickyContainer uses CSS position sticky and will not work in IE11 */
export const StickyContainer = withRouter(
    withDimensions<StickyContainerProps & RouteComponentProps<any>>(
        InternalSticky,
    ),
)
StickyContainer.displayName = 'StickyContainer'
