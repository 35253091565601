import React from 'react'

export const InfoIcon: React.FC = () => (
    <svg
        width="55"
        height="56"
        viewBox="0 0 55 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_10714_55967)">
            <path
                d="M29.0781 26.127C28.4805 26.127 28 25.6523 28 25.0605C28 24.4746 28.4805 24 29.0781 24C29.6465 24 30.1328 24.4746 30.1328 25.0605C30.1328 25.6523 29.6465 26.127 29.0781 26.127ZM28.0996 32.9941V26.877H30.0215V32.9941H28.0996Z"
                fill="white"
            />
            <g filter="url(#filter1_b_10714_55967)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 29C19 23.4772 23.4772 19 29 19C34.5228 19 39 23.4772 39 29C39 34.5228 34.5228 39 29 39C23.4772 39 19 34.5228 19 29ZM29 21.1656C24.6732 21.1656 21.1656 24.6732 21.1656 29C21.1656 33.3268 24.6732 36.8344 29 36.8344C33.3268 36.8344 36.8344 33.3268 36.8344 29C36.8344 24.6732 33.3268 21.1656 29 21.1656Z"
                    fill="white"
                    fill-opacity="0.8"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_d_10714_55967"
                x="0.4"
                y="0.4"
                width="57.2"
                height="57.2"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="9.3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_10714_55967"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_10714_55967"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_b_10714_55967"
                x="6.19468"
                y="6.19468"
                width="45.6106"
                height="45.6106"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur
                    in="BackgroundImageFix"
                    stdDeviation="6.40266"
                />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_10714_55967"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_10714_55967"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
InfoIcon.displayName = 'InfoIcon'
