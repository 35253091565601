import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const TeamRankingWidgetContainer = styled('div')({
    display: 'block',
    '& .Opta': {
        margin: '0 auto',
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
        '&  .Opta-Ranking tr td': {
            color: tokens.sevennews.colors.palette.black,
            fontSize: calcRem(14),
            [breakpoint('sm')]: {
                fontSize: calcRem(16),
            },
        },
        '& .Opta_W h2': {
            display: 'none',
        },

        '& .Opta-Nav ': {
            '& ul': {
                display: 'flex',
            },
            '& ul li a': {
                fontWeight: 700,
                textTransform: 'uppercase',
                fontSize: calcRem(12),
                color: tokens.sevennews.colors.palette.white,
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                width: '100%',
                textAlign: 'center',
            },
            '& ul li.Opta-On a': {
                backgroundColor: tokens.sevennews.colors.palette.red,
            },
            '& .Opta-Overflowed': {
                backgroundColor: 'rgba(230, 0, 30, 0.2)',
                '&:hover': {
                    backgroundColor: 'rgba(230, 0, 30, 0.2)',
                },
            },
            '&>ul.Opta-Overflow li.Opta-Overflow': {
                background: '#E6001E',
            },
        },

        '& .Opta-FeatureHeader td': {
            background: '#E6001E',
            color: tokens.sevennews.colors.palette.white,
        },
    },
    '& .Opta .Opta-Tabs .Opta-Nav>ul.Opta-Overflowed': {
        backgroundColor: 'rgba(230, 0, 30, 0.2)',
        '&:hover': {
            backgroundColor: 'rgba(230, 0, 30, 0.2)',
        },
    },
    '& .Opta .Opta_C_TR_N .Opta-Striped tbody tr.Opta-FeatureHeader td': {
        background: '#E6001E',
        color: tokens.sevennews.colors.palette.white,
    },
})
