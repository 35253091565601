import {
    GalleryPublication,
    createRegisterableComponent,
    isArticleLikeType,
    isGallery,
} from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { ThemeOverrideTypes } from '../../../__styling/themes'
import { MediaMode } from '../../../cards/CardMedia/CardMedia'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import {
    getPublicationInfoFromRouteResolution,
    getVideoQueue,
} from '../../../templates/Publication'
import { ThemeOverrider } from '../../../themes/ThemeOverrider/ThemeOverrider'
import { ArticleHeroCard } from './ArticleHeroCard'

export interface ArticleHeroCardProps extends ResponsiveContainer {
    themeOverride?: ThemeOverrideTypes
    onEvent: () => void
    mediaMode?: MediaMode
    article: ArticleLikeV4DTO
    adUnitPath?: string
}

export const ArticleHeroCardRegistration = createRegisterableComponent(
    'sevennews-article-hero-card',

    (props: ArticleHeroCardProps, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)

        if (
            !isArticleLikeType(publicationInfo.publication) &&
            !isGallery(publicationInfo.publication)
        ) {
            throw new Error(
                'Article/Gallery component rendered for non-article/non-gallery',
            )
        }

        return (
            <ThemeOverrider override={props.themeOverride}>
                <ArticleHeroCard
                    adUnitPath={props.adUnitPath}
                    article={props.article}
                    onEvent={services.onEvent}
                    getVideoQueue={() =>
                        getVideoQueue(services, publicationInfo.publication.id)
                    }
                    location={services.location}
                    heroImage={publicationInfo.publication.heroImage}
                    gallery={
                        (publicationInfo.publication as GalleryPublication)
                            .images
                    }
                />
            </ThemeOverrider>
        )
    },
)
