import {
    ExtendedAccessEvent,
    HeroVideo,
    PublicationEvents,
} from '@news-mono/web-common'
import { ResolveVideoSeriesEpisodeV4DTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { GetVideoQueue } from '../../content/Video/PlayerInterface'
import { ArticleHero } from '../../publication/ArticleHero/ArticleHero'
import {
    SevenNewsStyledSharing,
    StyledNestedGrid,
} from '../../templates/Publication/SevenNewsPublication/SevenNewsPublication.styled'
import {
    StyledDescription,
    StyledHeading,
} from './VideoSeriesEpisodePublication.styled'

interface VideoSeriesEpisodePublicationProps {
    data: ResolveVideoSeriesEpisodeV4DTO
    onEvent: (event: PublicationEvents | ExtendedAccessEvent) => void
    getVideoQueue: GetVideoQueue
    adUnitPath: string
}

export const VideoSeriesEpisodePublication: React.FC<VideoSeriesEpisodePublicationProps> =
    (props) => {
        const episode = props.data
        const videoData: HeroVideo = {
            videoId: episode.episodeMeta.id,
            accountId: episode.episodeMeta.accountId,
            heading: `Episode ${episode.episodeMeta.seriesInfo?.episode}: ${episode.episodeMeta.name}`,
            videoType: 'vod',
            seriesInfo: episode.episodeMeta.seriesInfo,
        }

        return (
            <React.Fragment>
                <StyledHeading>
                    <span>{`Episode ${videoData.seriesInfo?.episode}:`}</span>{' '}
                    {episode.episodeMeta.name}
                </StyledHeading>
                <ArticleHero
                    getVideoQueue={props.getVideoQueue}
                    heading={videoData.heading}
                    onEvent={props.onEvent}
                    adUnitPath={props.adUnitPath}
                    heroVideo={videoData}
                />
                <StyledNestedGrid>
                    <aside>
                        <SevenNewsStyledSharing
                            url={
                                episode.meta &&
                                episode.meta?.link &&
                                'href' in episode.meta?.link[0]
                                    ? episode.meta?.link[0].href
                                    : '/'
                            }
                            text={episode.episodeMeta.description}
                            onEvent={props.onEvent}
                            shareOptions={['facebook', 'twitter', 'email']}
                        />
                    </aside>
                    <StyledDescription>
                        <p>{episode.episodeMeta.longDescription}</p>
                    </StyledDescription>
                </StyledNestedGrid>
            </React.Fragment>
        )
    }
VideoSeriesEpisodePublication.displayName = 'VideoSeriesEpisodePublication'
