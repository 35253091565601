import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CollectionRouteProps } from '../../collections'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ExploreMoreCarousel } from './ExploreMoreCarousel'
import { CTAProps } from '../cards/ContentCard/ContentCard'

export interface ExploreMoreCarouselProps
    extends CollectionRouteProps,
        ResponsiveContainer {
    cta?: CTAProps
}

export const ExploreMoreCarouselRegistration =
    createRegisterableComponentWithData(
        'explore-more-carousel',
        ContentDataDefinitionLoader,
        (props: ExploreMoreCarouselProps, data, services) => {
            return (
                <ExploreMoreCarousel
                    items={
                        data.loaded
                            ? { loaded: true, result: data.result.publications }
                            : { loaded: false }
                    }
                    onEvent={services.onEvent}
                    {...props}
                />
            )
        },
    )
