import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { useLocation } from 'react-router'
import { CollectionRouteProps } from '../collections'
import { LoadMoreRouteProps } from '../data-controllers/LoadMore/LoadMore'
import { ResponsiveContainer } from '../content/Picture/responsive'
import { VideoHub } from '../VideoHub'
import { ThemeMargins } from '../__styling'
import { mapTopVideoMeta } from './helpers/mapToVideoMeta'

export interface VideoHubTopicRegistrationProps
    extends CollectionRouteProps,
        LoadMoreRouteProps,
        ResponsiveContainer {
    adUnitPath: string
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    autoplayWhenInView?: boolean
}

export const VideoHubTopicRegistration = createRegisterableComponentWithData(
    'video-hub-topic',
    ContentDataDefinitionLoader,
    (props: VideoHubTopicRegistrationProps, data, services) => {
        const location = useLocation()
        if (!data.loaded) {
            return null
        }

        const videoData = mapTopVideoMeta(data.result.publications)

        return (
            <VideoHub
                item={videoData[0]}
                onEvent={services.onEvent}
                config={services.config}
                adUnitPath={String(props.adUnitPath)}
                currentPath={location.pathname}
                verticalGutters={props.verticalGutters}
                verticalSpacing={props.verticalSpacing}
                sectionHeader={props.sectionHeader}
                getVideoQueue={() => {
                    return new Promise((resolve) =>
                        resolve({
                            items: videoData,
                            type: 'topic-related',
                        }),
                    )
                }}
                autoplayWhenInView={props.autoplayWhenInView}
            />
        )
    },
)
