import React from 'react'
import { ErrorImageRight } from './SevenNewsErrorImage'
import {
    StyledErrorParagraph,
    StyledErrorText,
    StyledErrorTextWrapper,
    StyledHomeButton,
    StyledLink,
    StyledSevenNewsPageByStatusWrapper,
} from './SevenNewsErrorPageByStatus.styled'
import { useFeature } from '@news-mono/web-common'

interface ErrorPageI {
    status: number
}

export const SevenNewsErrorPageByStatus = ({ status }: ErrorPageI) => {
    const isSevenNewsV2 = useFeature('7-news-2-home-page')
    return (
        <StyledSevenNewsPageByStatusWrapper>
            <StyledErrorTextWrapper>
                <StyledErrorText isLarge={true}>{status}</StyledErrorText>
                <StyledErrorText>
                    {status === 404 ? 'Page Not Found' : 'Something went wrong'}
                </StyledErrorText>
                <StyledErrorParagraph>
                    {isSevenNewsV2 ? (
                        <>
                            <strong>Uh-Oh!</strong> This is probably not the
                            page you’re looking for. Sorry about that!
                        </>
                    ) : (
                        <>
                            <strong>Uh-Oh!</strong> This is probably not the
                            page you’re looking for. Sorry about that. Go back
                            to the <StyledLink to={'/'}>Homepage</StyledLink>
                        </>
                    )}
                </StyledErrorParagraph>
                <StyledHomeButton to={'/'}>
                    {!isSevenNewsV2 && '7NEWS '}Home
                </StyledHomeButton>
            </StyledErrorTextWrapper>
            <ErrorImageRight />
        </StyledSevenNewsPageByStatusWrapper>
    )
}
