import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from './get-v2-topic-page'

const promoCardNameHash: { [key: string]: string } = {
    'entertainment/big-brother-australia': 'big-brother-promotional-cards',
    'entertainment/sas-australia': '7news-sas-australia-promo-cards',
    'entertainment/dancing-with-the-stars': 'dwts-promo-cards',
    'entertainment/the-voice-australia': '7news-the-voice-promo-cards',
    'entertainment/home-and-away': '7news-home-and-away-promo-cards',
    'entertainment/my-kitchen-rules': 'mkr-promo-cards',
    'entertainment/my-kitchen-rules/recipes': 'mkr-promo-cards',
    'entertainment/heartbreak-island': 'hia-promo-cards',
    'entertainment/farmer-wants-a-wife': 'fww-promo-cards',
    'entertainment/kitchen-nightmares-australia': 'kna-promo-cards',
    'entertainment/agt': 'agt-promo-cards',
    'entertainment/million-dollar-island': 'dwts-promo-cards',
    'entertainment/australian-idol': 'australian-idol-promo-cards',
    'entertainment/dream-home': 'dream-home-promo-cards',
    'sunrise/cooking-with-coles': 'wfd-promo-cards',
    'sunrise/whats-for-dinner': 'wfd-promo-cards',
    'sunrise/whats-for-dinner/recipes': 'wfd-promo-cards',
}

export const getEntertainmentTopic = (
    topicId: string,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
) => {
    const entertainmentTopicsHash: { [key: string]: Topic } = {
        'entertainment/big-brother-australia': {
            title: 'Big Brother Australia',
            id: 'entertainment/big-brother-australia',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Big Brother Australia 2021: Latest Big Brother News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/sas-australia': {
            title: 'SAS Australia',
            id: 'entertainment/sas-australia',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "SAS Australia: Latest SAS Australia News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/dancing-with-the-stars': {
            title: 'Dancing With The Stars',
            id: 'entertainment/dancing-with-the-stars',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Dancing With The Stars Australia: Latest Dancing With The Stars News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/the-voice-australia': {
            title: 'The Voice Australia',
            id: 'entertainment/the-voice-australia',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "The Voice Australia: The Voice Australia News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/home-and-away': {
            title: 'Home and Away',
            id: 'entertainment/home-and-away',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Home and Away: Home and Away News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/my-kitchen-rules': {
            title: 'My Kitchen Rules',
            id: 'entertainment/my-kitchen-rules',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "My Kitchen Rules: My Kitchen Rules News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/my-kitchen-rules/recipes': {
            title: 'My Kitchen Rules',
            id: 'entertainment/my-kitchen-rules/recipes',
            seoTitle: 'MKR Recipes',
            seoDescription:
                'Get the latest recipes from MKR, as Nigella Lawson joins Manu Feildel to find Australia’s best home cooks.',
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/heartbreak-island': {
            title: 'Heartbreak Island',
            id: 'entertainment/heartbreak-island',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Heartbreak island: Heartbreak Island News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/farmer-wants-a-wife': {
            title: 'Farmer Wants A Wife',
            id: 'entertainment/farmer-wants-a-wife',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Farmer Wants A Wife: Farmer Wants A Wife News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },

        'entertainment/kitchen-nightmares-australia': {
            title: `Kitchen Nightmares Australia`,
            id: 'entertainment/kitchen-nightmares-australia',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Kitchen Nightmares Australia:Kitchen Nightmares Australia News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/agt': {
            title: `Australia's Got Talent`,
            id: 'entertainment/agt',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Australia's Got Talent: Australia's Got Talent News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },

        'entertainment/million-dollar-island': {
            title: `Million Dollar Island`,
            id: 'entertainment/million-dollar-island',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Million Dollar Island: Million Dollar Island News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/australian-idol': {
            title: `Australian Idol`,
            id: 'entertainment/australian-idol',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                `Australian Idol: Australian Idol News, Updates & Today's Headlines`,
            seoDescription:
                resolution.resolution?.topic?.seoDescription ||
                `The latest in Australian Idol: Australian Idol News, Updates & Today's Headlines`,
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'entertainment/dream-home': {
            title: 'Dream Home',
            id: 'entertainment/dream-home',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Dream Home: Dream Home News, Updates & Today's Headlines",
            seoDescription:
                resolution.resolution?.topic?.seoDescription ||
                "The latest in Dream Home: Dream Home News, Updates & Today's Headlines",
            metadata: {},
            parent: {
                title: 'Entertainment',
                id: 'entertainment',
                seoTitle: 'Entertainment',
                metadata: {},
            },
        },
        'sunrise/cooking-with-coles': {
            title: `Cooking with Coles`,
            id: 'sunrise/cooking-with-coles',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "Cooking with Coles: Cooking with Coles News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Sunrise',
                id: 'sunrise',
                seoTitle: 'Sunrise',
                metadata: {},
            },
        },
        'sunrise/whats-for-dinner': {
            title: `What's for Dinner?`,
            id: 'sunrise/whats-for-dinner',
            seoTitle:
                resolution.resolution?.topic?.seoTitle ||
                "What's for Dinner: What's for Dinner News, Updates & Today's Headlines",
            seoDescription: resolution.resolution?.topic?.seoDescription,
            metadata: {},
            parent: {
                title: 'Sunrise',
                id: 'sunrise',
                seoTitle: 'Sunrise',
                metadata: {},
            },
        },
        'sunrise/whats-for-dinner/recipes': {
            title: `What's for Dinner?`,
            id: 'sunrise/whats-for-dinner/recipes',
            seoTitle: "Coles What's For Dinner?",
            seoDescription:
                "Sunrise brings you the latest recipes from Australia's number one breakfast show with Coles and What's For Dinner?",
            metadata: {},
            parent: {
                title: 'Sunrise',
                id: 'sunrise',
                seoTitle: 'Sunrise',
                metadata: {},
            },
        },
    }
    return entertainmentTopicsHash[topicId]
}

interface EntertainmentShowPageProps {
    topicId: string
    getAdTargeting: GetRouteAdTargeting
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>
    features: TogglesReduxState
    isSubTopic?: boolean
    isLoadMoreShowPage?: boolean
}

export const getEntertainmentShowPage = ({
    topicId,
    getAdTargeting,
    resolution,
    features,
    isSubTopic,
    isLoadMoreShowPage,
}: EntertainmentShowPageProps) => {
    const topic = getEntertainmentTopic(topicId, resolution)

    const promoCardName = promoCardNameHash[topicId]
    const listingTopic = topicId.includes('/recipes')
        ? topicId.replace('/recipes', '')
        : topicId
    const entertainmentPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            includeSubTopics: true,
            topics: [listingTopic],
            paging: {
                page: 1,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: [listingTopic],
            paging: {
                pageOffset: 5,
                page: 1,
                pageSize: 6,
            },
        },
        dataDefinitionArgsOverrideFourCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: [listingTopic],
            paging: {
                pageOffset: 11,
                page: 1,
                pageSize: 4,
            },
        },
        contentToFormat: [
            {
                pillMenuName: 'More from Entertainment',
                pillMenuLink: '/entertainment',
                collectionTopics: ['entertainment'],
            },
        ],
        promoCardName,
        getAdTargeting,
        features,
        isSubTopic,
        isLoadMoreShowPage,
    })

    return entertainmentPage
}
