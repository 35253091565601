import {
    initClient,
    setAssetDomain,
    sevennews,
} from '@news-mono/component-library'
import {
    loadUserLocation,
    retrieveLocationQueryString,
} from '@news-mono/web-common'

setAssetDomain()
// We need this to get the dependencies loaded in the correct order
require('../app/app')

const { render, routeCacheRef } = initClient({
    version: { buildNumber: '', sha: '' },
    requireApplication: () => require('../app/app').App,
    theme: sevennews,
    applicationInitialised: ({ store, config }) => {
        if (window.location.search.indexOf('location') !== -1) {
            const region = retrieveLocationQueryString(window.location)
            store.dispatch(loadUserLocation(region))
        }
    },
})

if (module.hot) {
    module.hot.accept('../app/app', () => {
        if (routeCacheRef) {
            routeCacheRef.clear()
        }

        setTimeout(render)
    })
}
