import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { CommentaryWidget, CommentaryWidgetProps } from './CommentaryWidget'

export const CommentaryWidgetRegistration = createRegisterableComponent(
    'commentary-widget',
    (props: CommentaryWidgetProps, services) => {
        return <CommentaryWidget {...props} />
    },
)
