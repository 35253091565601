import { SevenNewsSection } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'

import { getV2HomePage } from './custom/v2-pages/get-v2home-page-route'

export const homepage: StaticRoutes<SevenNewsSection> = {
    '': ({ config, getAdTargeting, store }) => {
        return getV2HomePage(config, getAdTargeting, store.getState().toggles)
    },
}
