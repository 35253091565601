import React, { Component } from 'react'

export interface StickySizeProps {
    width: number
    height: number
}

export interface StickyContextProps {
    getOffset: (offsetIDs: Array<string>) => number
    updateOffset: (offsetID: string, size: StickySizeProps) => void
}

const { Provider, Consumer } = React.createContext<StickyContextProps>({
    getOffset: () => 0,
    updateOffset: () => {},
})

class StickyContextProvider extends Component {
    state: { [offsetID: string]: StickySizeProps } = {}

    updateOffset = (offsetID: string, size: StickySizeProps) => {
        this.setState({ [offsetID]: size })
    }
    getOffset = (offsetIDs: Array<string>) => {
        return offsetIDs
            .map((id) => {
                const container = this.state[id]
                return container ? (container.height as number) : 0
            })
            .reduce((a, b) => a + b, 0) // Add the sum of the array
    }

    render() {
        const { getOffset, updateOffset } = this
        return (
            <Provider value={{ getOffset, updateOffset }}>
                {this.props.children}
            </Provider>
        )
    }
}

export const StickyContext = {
    Provider: StickyContextProvider,
    Consumer,
}
