import {
    CollectionDataLoaded,
    ComponentServices,
    createRegisterableComponentWithData,
    DataLoaderGlobalParams,
    getSearchPublications,
    mapListPublication,
    TopicListingOptions,
} from '@news-mono/web-common'

import { DataDefinition } from 'json-react-layouts-data-loader'
import React, { useState } from 'react'

import { LoadingEllipsis } from '../../../LoadingEllipsis'

import { SearchResults } from './SearchResults'
import { LoadingElipsisWrap } from './SearchResults.styled'
import { SearchInfiniteScroll } from '../../../data-controllers/InfiniteScroll/SearchInfiniteScroll'
import { mapSearchVideoMetaToCard } from '../../../video-series'

export interface SearchDataLoaderConfig7News {
    searchTerm: string
    options: TopicListingOptions
    type: string
    currentUrl?: string
    pathname?: string
}

const loadSearchData = async (
    props: SearchDataLoaderConfig7News,
    services: DataLoaderGlobalParams,
): Promise<CollectionDataLoaded> => {
    if (!props.searchTerm) {
        return {
            loadMorePossible: false,
            kind: 'listing',
            publications: [],
        }
    }

    const searchedPublications = await getSearchPublications(
        services,
        props.searchTerm,
        {
            ...props.options,
            paging: { pageSize: 20 },
            includeVideo: true,
        },
    )

    const mappedListing = searchedPublications.documents.map(
        (listPublication) => mapListPublication(listPublication),
    )

    const videos = searchedPublications.videos
        ? searchedPublications.videos.map((videoMeta) =>
              mapSearchVideoMetaToCard(videoMeta),
          )
        : []

    return {
        // loadMorePossible: searchedPublications.morePublicationsAvailable,
        loadMorePossible: true,
        kind: 'listing',
        publications: mappedListing,
        videos,
    }
}

const searchDataDefinitionLoader: DataDefinition<
    SearchDataLoaderConfig7News,
    CollectionDataLoaded,
    ComponentServices
> = {
    // Requires custom cache key so search terms will update when searching on the search page.
    useRuntimeParams: (props) => ({
        searchTerm: JSON.stringify(props.searchTerm),
    }),
    loadData: (props, services) => loadSearchData(props, services),
}

export const SearchRegistrationV2 = createRegisterableComponentWithData(
    'search',
    searchDataDefinitionLoader,
    (_, data, services) => {
        const [currentPage, setCurrentPage] = useState(
            data.dataDefinitionArgs?.options.paging?.page,
        )

        const cleanedSearchTerm = data.dataDefinitionArgs.searchTerm.replace(
            /"/g,
            '',
        )

        if (data.loaded) {
            return (
                <SearchInfiniteScroll
                    dataDefinition={data.dataDefinitionArgs}
                    data={data}
                    currentPage={currentPage}
                    services={services}
                    render={(loadMoreData) => {
                        return (
                            <SearchResults
                                data={loadMoreData}
                                searchTerm={cleanedSearchTerm}
                                onEvent={services.onEvent}
                            />
                        )
                    }}
                    setCurrentPage={setCurrentPage}
                    currentUrl={data.dataDefinitionArgs.currentUrl}
                    pathname={data.dataDefinitionArgs.pathname}
                    searchTerm={cleanedSearchTerm}
                />
            )
        }

        return (
            <LoadingElipsisWrap>
                <LoadingEllipsis color="grey" />
            </LoadingElipsisWrap>
        )
    },
)
