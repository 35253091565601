import { metrics, ThemeMargins } from '@news-mono/component-library'
import { layout } from '../../App.routing'

export const mobileAd = (options: {
    id: string
    mrec?: boolean
    native?: boolean
    bottomPadding?: keyof ThemeMargins
    topPadding?: keyof ThemeMargins
}) => {
    const { mrec, native, bottomPadding, topPadding } = options
    const size = mrec
        ? native
            ? 'mobileNativeMrec'
            : 'below768Mrec'
        : 'mobileLeaderboard'
    const id = native
        ? `mobile-native-mrec-${options.id}`
        : mrec
        ? `mobile-mrec-${options.id}`
        : `mobile-leaderboard-${options.id}`

    return layout.component({
        type: 'ad-unit',
        props: {
            noticePosition: 'above-center',
            hiddenUntilLoaded: false,
            padding: [
                topPadding
                    ? metrics.sevennews.margins[topPadding]
                    : metrics.sevennews.margins.lg,
                0,
                bottomPadding
                    ? metrics.sevennews.margins[bottomPadding]
                    : metrics.sevennews.margins.sm,
                0,
            ],
            slot: {
                id,
                size,
            },
            adType: 'inline',
        },
    })
}

export const mobileBespokeNativeAd = mobileAd({
    id: 'one',
    mrec: true,
    native: true,
})
export const mobileAdOne = mobileAd({ id: 'one', mrec: true, topPadding: 'md' })
export const mobileAdTwo = mobileAd({
    id: 'two',
    mrec: true,
    topPadding: 'md',
    bottomPadding: 'unset',
})
export const mobileAdThree = mobileAd({
    id: 'three',
    mrec: true,
    bottomPadding: 'lg',
    topPadding: 'unset',
})
export const mobileAdFour = mobileAd({
    id: 'four',
    mrec: true,
    topPadding: 'md',
})
export const mobileAdFive = mobileAd({
    id: 'five',
    mrec: true,
    topPadding: 'md',
})
export const mobileAdSix = mobileAd({
    id: 'six',
    mrec: true,
    topPadding: 'md',
})
export const mobileLeaderboardAdOne = mobileAd({ id: 'one', mrec: false })
export const mobileLeaderboardAdTwo = mobileAd({ id: 'two', mrec: false })
