import {
    StyledSearchContainer,
    StyledSearchForm,
    StyledSearchInput,
} from './SearchForm.styled'

import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { SearchButton } from './SearchButton'
import { ClearButton } from './ClearButton'
import { RenderTargetContext } from '@news-mono/web-common'

interface RenderProps {
    renderSearchButton: () => React.ReactNode
}

export interface SearchFormProps {
    isLarge?: boolean
    mountsWithValue?: string
    children?: (renderProps: RenderProps) => React.ReactNode
    isMobile?: boolean
    setIsSearchMobileActive?: (v: boolean) => void
    isSearchMobileActive?: boolean
    hide?: boolean
}

const InternalSearchForm: React.FC<SearchFormProps & RouteComponentProps<any>> =
    (props) => {
        const [searchValue, setSearchValue] = useState(
            props.mountsWithValue || '',
        )
        const { renderTarget } = React.useContext(RenderTargetContext)
        const [isActive, setIsActive] = useState(false)
        useEffect(() => {
            props.mountsWithValue && setSearchValue(props.mountsWithValue)
        }, [props.mountsWithValue])

        const searchMinimumLength = (): boolean => {
            return searchValue.length >= 1
        }

        const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.currentTarget.value)
        }

        const handleClear = () => {
            setIsActive(false)
            setSearchValue('')
            props.isMobile &&
                props.setIsSearchMobileActive &&
                props.setIsSearchMobileActive(false)
        }

        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault()
            if (searchMinimumLength()) {
                props.history.push(
                    `/search?search=${encodeURIComponent(searchValue)}`,
                )
                !isLarge && handleClear()
            }
        }

        const searchHasValue = searchValue.length > 0

        const isLarge = props.isLarge || false

        const searchInput = (
            <>
                <StyledSearchContainer
                    hide={props.hide}
                    isLarge={isLarge}
                    isSearchMobileAndInactive={
                        props.isMobile && !props.isSearchMobileActive
                    }
                    id="search-container"
                >
                    <StyledSearchForm
                        onSubmit={handleSubmit}
                        method="get"
                        action="/search"
                        autoComplete="off"
                        role="search"
                        searchHasValue={searchHasValue}
                        isLarge={isLarge}
                        isActive={isActive}
                        isMobile={props.isMobile}
                        target="_top"
                        id="search-form"
                    >
                        <StyledSearchInput
                            name="search"
                            placeholder={
                                isActive || isLarge || renderTarget === 'amp'
                                    ? 'Search ...'
                                    : undefined
                            }
                            type="search"
                            value={searchValue}
                            onChange={handleSearchInput}
                            isMobile={props.isMobile}
                            isActive={isActive}
                            isLarge={isLarge}
                            id="search-input"
                            on="input-debounced:AMP.search-button.toggleClass(class='isInactive')"
                        />
                        <ClearButton
                            onClick={handleClear}
                            hide={!isActive && !isLarge}
                            isLarge={isLarge}
                            aria-label="reset"
                        />

                        <SearchButton
                            onClick={() => {
                                !isActive && setIsActive(true)
                                props.setIsSearchMobileActive &&
                                    props.isMobile &&
                                    props.setIsSearchMobileActive(true)
                            }}
                            isActive={isActive}
                            isLarge={isLarge}
                            searchHasValue={searchHasValue}
                            aria-label="search"
                        />
                    </StyledSearchForm>
                </StyledSearchContainer>
            </>
        )

        if (props.children) {
            return (
                <>
                    {props.children({
                        renderSearchButton: () => searchInput,
                    })}
                </>
            )
        }

        return searchInput
    }

export const SearchForm = withRouter(InternalSearchForm)
SearchForm.displayName = 'SearchForm'
