import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../../helpers/get-v2-topic-page'

export const getSunrisePageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Sunrise',
        id: 'sunrise',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Sunrise',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicShowPage = getV2TopicPage({
        topic,
        dataDefinitionArgsOverrideHero: {
            name: 'sunrise-news-homepage',
        },
        dataDefinitionArgsOverrideSixCard: {
            name: 'sunrise-news-homepage',
        },
        contentToFormat: [
            {
                pillMenuName: 'On The Show',
                pillMenuLink: '/sunrise/on-the-show',
                collectionTopics: ['sunrise/on-the-show'],
            },
            {
                pillMenuName: 'Cooking with Coles',
                pillMenuLink: '/sunrise/cooking-with-coles',
                collectionTopics: ['sunrise/cooking-with-coles'],
            },
        ],
        promoCardName: 'sunrise-promo-cards',
        getAdTargeting,
        features,
    })

    return topicShowPage
}
