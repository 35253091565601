import React from 'react'

export interface ActiveSearchIconProps {
    className?: string
}

export const ActiveSearchIcon: React.FC<ActiveSearchIconProps> = ({
    className,
}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.0659 5.79133C14.7704 4.49586 13.0134 3.76807 11.1813 3.76807C9.34921 3.76807 7.59217 4.49586 6.2967 5.79133C5.00123 7.0868 4.27344 8.84384 4.27344 10.6759C4.27344 12.508 5.00123 14.265 6.2967 15.5605C7.59217 16.856 9.34921 17.5838 11.1813 17.5838C13.0134 17.5838 14.7704 16.856 16.0659 15.5605C17.3613 14.265 18.0891 12.508 18.0891 10.6759C18.0891 8.84384 17.3613 7.0868 16.0659 5.79133Z"
            fill="#272626"
            fill-opacity="0.5"
        />
        <path
            d="M11.1749 2.40753e-08C13.1793 -0.000134569 15.1434 0.564065 16.8422 1.62803C18.541 2.69199 19.9059 4.21274 20.7808 6.01622C21.6557 7.81971 22.0051 9.83307 21.7892 11.8259C21.5732 13.8187 20.8006 15.7105 19.5597 17.2847L24.1465 21.8715C24.3753 22.1084 24.5019 22.4256 24.499 22.7549C24.4962 23.0843 24.3641 23.3993 24.1312 23.6321C23.8984 23.865 23.5833 23.9971 23.254 24C22.9247 24.0028 22.6075 23.8762 22.3706 23.6474L17.7838 19.0606C16.4467 20.1143 14.8771 20.833 13.2058 21.1567C11.5345 21.4804 9.81002 21.3998 8.17624 20.9215C6.54246 20.4432 5.04675 19.5811 3.8139 18.4072C2.58105 17.2333 1.64681 15.7815 1.08912 14.1731C0.531432 12.5647 0.36647 10.8462 0.607995 9.16111C0.84952 7.47598 1.49053 5.87304 2.47754 4.48602C3.46456 3.09901 4.76896 1.96814 6.28191 1.18776C7.79486 0.407392 9.4725 0.000145054 11.1749 2.40753e-08ZM11.1749 2.51194C9.00967 2.51194 6.93317 3.37206 5.40216 4.90307C3.87115 6.43408 3.01104 8.51058 3.01104 10.6758C3.01104 12.8409 3.87115 14.9174 5.40216 16.4484C6.93317 17.9795 9.00967 18.8396 11.1749 18.8396C13.34 18.8396 15.4165 17.9795 16.9475 16.4484C18.4786 14.9174 19.3387 12.8409 19.3387 10.6758C19.3387 8.51058 18.4786 6.43408 16.9475 4.90307C15.4165 3.37206 13.34 2.51194 11.1749 2.51194Z"
            fill="#272626"
            fill-opacity="0.3"
        />
    </svg>
)
