import {
    AppState,
    NavEvent,
    NavItem,
    NavigationState,
} from '@news-mono/web-common'
import React from 'react'
import { DispatchProp, connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { SevenNewsNavigationV2 } from '../../routing/route-nav-types'
import { SevenNewsDesktopNavigationV2 } from './SevenNewsDesktopNavigationV2/SevenNewsDesktopNavigationV2'
import { StyledNav } from './SevenNewsMainNavigationV2.styled'

export interface SevenNewsMainNavigationV2Props {
    navLinks: SevenNewsNavigationV2
    onEvent: (event: NavEvent) => void
}

export type WrappedProps = SevenNewsMainNavigationV2Props &
    RouteComponentProps<any> &
    DispatchProp<any> &
    NavigationState

export type InternalSevenNewsMainNavPropsV2 = WrappedProps

const worldNewsArray = [
    '/news/world',
    '/news/north-america',
    '/news/asia',
    '/news/europe',
    '/news/middle-east',
    '/news/africa',
    '/news/latin-america',
]
export const isCurrent = (item: NavItem, location: string): boolean => {
    //preventing rendering active item indicator in navigation on article pages by checking for article id in pathname
    const articleRegex = new RegExp('c-[0-9]{1,8}', 'i')
    if (articleRegex.test(location)) {
        return false
    }
    // if we display World in main navigation in future need to update this
    if (worldNewsArray.includes(location)) {
        return false
    }

    if (location !== '/' && location.startsWith(item.link)) {
        return true
    }

    if (location.startsWith('/news') && item.link.startsWith('/news')) {
        return true
    }

    return false
}

export const InternalMainNavigationV2: React.FC<InternalSevenNewsMainNavPropsV2> =
    ({ navLinks, location, onEvent }) => {
        return (
            <StyledNav role="navigation">
                <SevenNewsDesktopNavigationV2
                    navItems={navLinks}
                    location={location}
                    onEvent={onEvent}
                />
            </StyledNav>
        )
    }

const mapStateToProps = ({ navigation }: AppState) => ({
    mainNavOpen: navigation.mainNavOpen,
})

type ConnectProps = SevenNewsMainNavigationV2Props &
    RouteComponentProps<any> &
    NavigationState

const ConnectedSevenNewsMainNavigation: React.ComponentType<
    SevenNewsMainNavigationV2Props & RouteComponentProps<any>
> = connect<NavigationState, {}, ConnectProps, AppState>(
    mapStateToProps,
    null,
    null,
    {},
)(InternalMainNavigationV2)
export const SevenNewsMainNavigationV2 = withRouter(
    ConnectedSevenNewsMainNavigation,
)
SevenNewsMainNavigationV2.displayName = 'SevenNewsMainNavigationV2'
