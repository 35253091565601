import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { WagonWheelWidget, WagonWheelWidgetProps } from './WagonWheelWidget'

export const WagonWheelWidgetRegistration = createRegisterableComponent(
    'wagon-wheel-widget',
    (props: WagonWheelWidgetProps, services) => {
        return <WagonWheelWidget {...props} />
    },
)
