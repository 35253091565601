import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { SevenPlusArticleButton } from './SevenPlusArticleButton'

type SevenPlusArticleButtonProps = {
    URL: string
}

export const SevenPlusArticleButtonRegistration = createRegisterableComponent(
    '7plus-article-button',

    (props: SevenPlusArticleButtonProps, services) => {
        return <SevenPlusArticleButton URL={props.URL} />
    },
)
