import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    LatestDeliveriesWidget,
    LatestDeliveriesWidgetProps,
} from './LatestDeliveriesWidget'

export const LatestDeliveriesWidgetRegistration = createRegisterableComponent(
    'latest-deliveries-widget',
    (props: LatestDeliveriesWidgetProps, services) => {
        return <LatestDeliveriesWidget {...props} />
    },
)
