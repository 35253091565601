import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, colors } from '../../../__styling'
import { CardMedia } from '../../../cards/CardMedia/CardMedia'

export const StyledPromotionalCard = styled('aside')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    linearGradient: '(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)',
    transition: 'opacity 0.3s ease',
    '&:hover': {
        opacity: '0.7',
    },
    '&:focus-within': {
        outlineColor: tokens.sevennews.colors.palette.red,
        outlineOffset: '1px',
        outlineStyle: 'auto',
        outlineWidth: '1px',
    },
})

export const StyledCardImage = styled(CardMedia)({
    margin: 0,
    '& [class*="image"]': {
        aspectRatio: '3/2',
        '& img': {
            width: 'auto',
            height: '100%',
            translate: '-8%',
        },
    },
})

export const StyledBoxContainer = styled('div')({
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& [class*="StyledMedia"]': {
        marginBottom: 0,
    },
})

export const StyledPromotionalCardTitleContainer = styled('div')({
    position: 'absolute',
    color: colors.white,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: calcRem(16),
    background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 30.9%, rgba(139, 139, 139, 0.00) 48.9%, rgba(0, 0, 0, 0.54) 98.14%)',
})

export const StyledPromotionalCardTitle = styled('p')({
    fontWeight: 800,
    fontSize: calcRem(20),
    marginRight: calcRem(15),
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'right',

    [breakpoint('sm')]: {
        fontSize: calcRem(17),
    },
    [breakpoint('lg')]: {
        fontSize: calcRem(21),
    },
    [breakpoint('xl')]: {
        fontSize: calcRem(24),
    },
})
