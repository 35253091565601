export const formatTimeSince = (
    publicationDate: string | undefined,
    isUpdated?: boolean | false,
): string => {
    if (publicationDate) {
        const publishDate = new Date(publicationDate)
        const now = new Date()

        const differenceInMilliseconds = now.getTime() - publishDate.getTime()
        const differenceInMinutes = differenceInMilliseconds / 60000

        const timeObject = {
            days: Math.floor(differenceInMinutes / 60 / 24),
            hours: Math.floor(differenceInMinutes / 60),
            minutes: Math.floor(differenceInMinutes),
        }
        const { days, hours, minutes } = timeObject

        const formattedDay = ('0' + publishDate.getDate()).toString().slice(-2)
        const formattedMonth = ('0' + (publishDate.getMonth() + 1))
            .toString()
            .slice(-2)

        if (days > 0) {
            if (days > 31) {
                return ` ${formattedDay}.${formattedMonth}.${publishDate.getFullYear()}`
            }
            if (days > 1) {
                return ` ${days} days ago`
            } else {
                return ` ${days} day ago`
            }
        }
        if (hours > 0) {
            if (hours > 1) {
                return ` ${hours} hrs ago`
            } else {
                return ` ${hours} hr ago`
            }
        }

        if (minutes > 1) {
            return ` ${minutes} mins ago`
        } else {
            return ` ${minutes} min ago`
        }
    } else {
        return ''
    }
}

export const getPublishedAt = (publicationDate: string | undefined): string => {
    if (publicationDate) {
        return formatTimeSince(publicationDate)
    } else {
        return ''
    }
}

export const getUpdatedAt = (lastUpdated: string | undefined): string => {
    if (lastUpdated) {
        return formatTimeSince(lastUpdated, true)
    } else {
        return ''
    }
}
