import React from 'react'
import { BreakpointKeys } from '../../__styling'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { BaseCompositionProps } from '../types'
import {
    StyledMain,
    StyledMobileRail,
    StyledSidebar,
    StyledTriplet,
    StyledTripletWrap,
} from './Triplet.styled'

export interface TripletRawProps extends BaseCompositionProps {
    mainContent: React.ReactElement<any>
    sidebarLeft: React.ReactElement<any>
    header: React.ReactElement<any>
    sidebarRight: React.ReactElement<any>
    mobileRail: React.ReactElement<any>
    /** Stacks sidebar content underneath the main content, also modifies the breakpoint at which the sidebar collapses */
    stackSidebarContent?: boolean
    hasBackgroundFill?: boolean
    containerWidth?: ContainerWidth
}
export type SpacingProps = {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    horizontalGutters?: keyof ThemeMargins
    verticalSpacing?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
}
export type BreakPointProps = {
    [key in BreakpointKeys]?: SpacingProps
}
export interface TripletProps extends TripletRawProps, SpacingProps {
    breakpoints?: BreakPointProps
}

export const Triplet: React.FC<TripletProps> = ({
    mainContent,
    sidebarLeft,
    sidebarRight,
    hasBackgroundFill,
    horizontalGutters,
    verticalGutters,
    verticalSpacing,
    containerWidth,
    header,
    mobileRail,
    stackSidebarContent = false,
    breakpoints,
}) => {
    return (
        <StyledTripletWrap
            stackSidebarContent={stackSidebarContent}
            hasBackgroundFill={hasBackgroundFill}
            horizontalGutters={horizontalGutters}
            verticalGutters={verticalGutters}
            verticalSpacing={verticalSpacing}
            containerWidth={containerWidth}
            breakpoints={breakpoints}
        >
            <div>{header}</div>

            <StyledTriplet stackSidebarContent={stackSidebarContent}>
                <StyledSidebar stackSidebarContent={stackSidebarContent}>
                    {sidebarLeft}
                </StyledSidebar>
                <StyledMain hasBackgroundFill={hasBackgroundFill}>
                    {mainContent}
                </StyledMain>
                <StyledSidebar stackSidebarContent={stackSidebarContent}>
                    {sidebarRight}
                </StyledSidebar>
                <StyledMobileRail stackSidebarContent={stackSidebarContent}>
                    {mobileRail}
                </StyledMobileRail>
            </StyledTriplet>
        </StyledTripletWrap>
    )
}
Triplet.displayName = 'Triplet'
