import React from 'react'
import { PlayerRankingWidgetContainer } from './PlayerRankingWidget.styled'

export interface PlayerRankingWidgetProps {
    competition: string
    season: string
}
export const PlayerRankingWidget = ({
    competition,
    season,
}: PlayerRankingWidgetProps) => {
    return (
        <PlayerRankingWidgetContainer>
            <opta-widget
                widget="player_ranking"
                template="normal"
                competition={competition}
                season={season}
                navigation="tabs_more"
                default_nav="1"
                show_crests="false"
                show_images="false"
                show_ranks="true"
                show_appearances="true"
                show_overs_bowled="true"
                show_bowling_average="true"
                visible_categories="top_scorer,strike_rate,sixes,wickets,keeper_dismissals"
                limit="10"
                hide_zeroes="true"
                show_team_names="true"
                team_naming="full"
                player_naming="full"
                show_logo="false"
                show_title="true"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </PlayerRankingWidgetContainer>
    )
}
