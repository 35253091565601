import styled from '@emotion/styled'
import { colors, fonts, metrics } from '@news-mono/component-library'

export const StyledSite = styled('div')({
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: metrics.sevennews.baseFontSize,
    color: colors.sevennews.charade,
    fontWeight: 500,
    lineHeight: 1.5,
})

export const SiteFullWidthAd = styled('div')({
    width: '100%',
})
