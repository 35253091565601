import React from 'react'
import { CompetitionStatsWidgetContainer } from './CompetitionStatsWidget.styled'

export interface CompetitionStatsWidgetProps {
    competition: string
    season: string
}
export const CompetitionStatsWidget = ({
    competition,
    season,
}: CompetitionStatsWidgetProps) => {
    return (
        <CompetitionStatsWidgetContainer>
            <opta-widget
                widget="competition_stats"
                competition={competition}
                season={season}
                template="normal"
                show_crests="false"
                show_images="false"
                show_ranks="true"
                show_player_rankings="true"
                show_team_rankings="true"
                visible_stats="total_games,total_runs,highest_score,total_hundreds,total_fifties,strike_rate"
                limit="3"
                team_naming="full"
                player_naming="full"
                show_logo="true"
                show_title="true"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </CompetitionStatsWidgetContainer>
    )
}
