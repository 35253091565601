import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts, metrics } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

export interface StyledisV2ArticlePageEnabled {}

export const SevenPlusBtn = styled('a')<StyledisV2ArticlePageEnabled>(({}) => [
    {
        width: '100%',
        height: calcRem(38),
        backgroundColor:
            tokens.sevennews.colors.palette.articlesV2.buttonBackground,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: calcRem(3),
        textDecoration: 'none',
        marginTop: calcRem(metrics.sevennews.margins.lg),
        marginBottom: calcRem(10),
        '&:hover': { cursor: 'pointer' },
        [breakpoint('xs')]: {
            width: '188px',
        },
    },
])

export const SevenPlusBtnHeading = styled('p')<StyledisV2ArticlePageEnabled>(
    ({}) => [
        {
            fontFamily: fonts.sevennews.sansSerif,
            color: tokens.sevennews.colors.palette.black,
            fontWeight: 400,
            fontSize: calcRem(14),
            lineHeight: calcRem(18),
            textDecoration: 'none',
        },
    ],
)

export const SevenPlusIcon = styled('img')({
    width: calcRem(21),
    marginLeft: calcRem(10),
})
