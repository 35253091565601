export interface SevenPlusButtonArticles {
    id: string
    feature?: string
    url: string
}

export function getSevenPlusArticleButtonLink(topic: string): {
    isEnabled: boolean
    buttonLink?: string
} {
    const includedArticles: SevenPlusButtonArticles[] = [
        {
            id: 'entertainment/sas-australia',
            url: 'https://7plus.com.au/sas-australia?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=sas-australia',
        },
        {
            id: 'entertainment/dancing-with-the-stars',
            url: 'https://7plus.com.au/dancing-with-the-stars-all-stars?utm_source=7NEWS&utm_medium=footer-button&utm_campaign=DWTS',
        },
        {
            id: 'entertainment/home-and-away',
            url: 'https://7plus.com.au/home-and-away?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=home-and-away',
        },
        {
            id: 'entertainment/the-voice-australia',
            url: 'https://7plus.com.au/the-voice?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=the-voice',
        },
        {
            id: 'entertainment/big-brother-australia',
            url: 'https://7plus.com.au/big-brother?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=big-brother',
        },
        {
            id: 'entertainment/farmer-wants-a-wife',
            url: 'https://7plus.com.au/farmer-wants-a-wife?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=/farmer-wants-a-wife',
        },
        {
            id: 'entertainment/kitchen-nightmares-australia',
            url: 'https://7plus.com.au/kitchen-nightmares-australia?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=kitchen-nightmares-australia',
        },
        {
            id: 'entertainment/agt',
            url: 'https://7plus.com.au/australias-got-talent?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=australias-got-talent',
        },
        {
            id: 'entertainment/million-dollar-island',
            url: 'https://7plus.com.au/million-dollar-island?utm_source=7NEWS&utm_medium=end-article-button&utm_campaign=million-dollar-island',
        },

        {
            id: 'entertainment/australian-idol',
            url: 'https://7plus.com.au/australian-idol?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=australian-idol',
        },
        {
            id: 'entertainment/heartbreak-island',
            url: 'https://7plus.com.au/heartbreak-island-australia?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=heartbreak-island-aus',
        },
        {
            id: 'entertainment/my-kitchen-rules',
            url: 'https://7plus.com.au/my-kitchen-rules?utm_source=7NEWS&utm_medium=stream-free-button&utm_campaign=mkr-article-page',
        },
        {
            id: 'entertainment/farmer-wants-a-wife',
            url: 'https://7plus.com.au/farmer-wants-a-wife?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=farmer-wants-a-wife',
        },
        {
            id: 'sport/aflw',
            url: 'https://7plus.com.au/aflw?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=aflw-article',
        },
        {
            id: 'sport/afl',
            url: 'https://7plus.com.au/afl?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=afl-article',
        },
        {
            id: 'sport/cricket',
            url: 'https://7plus.com.au/cricket?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=cricket',
        },
        {
            id: 'sport/surfing',
            url: 'https://7plus.com.au/surfing?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=surfing',
        },
        {
            id: 'sport/motorsport',
            url: 'https://7plus.com.au/motorsport?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=motorsport',
        },
        {
            id: 'sport/horse-racing',
            url: 'https://7plus.com.au/horse-racing?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=horse-racing',
        },
        {
            id: 'sport/nfl',
            url: 'https://7plus.com.au/nfl?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=nfl',
        },

        {
            id: 'sport',
            url: 'https://7plus.com.au/sport?utm_source=7NEWS&utm_medium=bottom-7plus-button&utm_campaign=sport-article',
        },

        {
            id: 'sunrise',
            url: 'https://7plus.com.au/sunrise?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=sunrise-articles',
        },
        {
            id: 'entertainment',
            url: 'https://7plus.com.au/shows?utm_source=7NEWS&utm_medium=stream-free-bottom-article-button&utm_campaign=entertainment-articles',
        },
        {
            id: 'the-morning-show',
            url: 'https://7plus.com.au/the-morning-show?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=the-morning-show-articles',
        },
        {
            id: 'technology',
            url: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=tech-articles',
        },
        {
            id: 'news/world',
            url: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=world-articles',
        },
        {
            id: 'news',
            url: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=news-articles',
        },
        {
            id: 'business',
            url: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=business-articles',
        },
        {
            id: 'weather',
            url: 'https://7plus.com.au/news?utm_source=7NEWS&utm_medium=end-of-article-button&utm_campaign=weather-articles',
        },
        {
            id: 'spotlight',
            url: 'https://7plus.com.au/7news-spotlight?utm_source=7NEWS&utm_medium=7plus-footer-button&utm_campaign=spotlight-article-page',
        },
    ]
    const data = includedArticles.find((item) => topic.includes(item.id))
    if (!data) {
        return { isEnabled: false }
    }
    return {
        isEnabled: true,
        buttonLink: data.url,
    }
}
