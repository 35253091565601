import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
    useLocationState,
} from '@news-mono/web-common'
import React from 'react'
import { SevenNewsMultipleBreakingNews } from './SevenNewsBreakingNews'

export interface SevenNewsBreakingNewsRouteProps {
    theme?: string
    title?: string
}

const locationsDefaultValues = [
    'NSW',
    'VIC',
    'QLD',
    'QLDREG',
    'WA',
    'SA',
    'TAS',
    'NT',
]

export const SevenNewsBreakingNewsRegistration =
    createRegisterableComponentWithData(
        'breaking-news',
        CurationAndCurationMetadataLoader,
        (_props: SevenNewsBreakingNewsRouteProps, data, services) => {
            const { region, countryCode } = useLocationState()
            const articles = (data.loaded && data.result.articles) || []
            const metadata = (data.loaded && data.result.metadata) || {}

            const metaDataIndexHash = articles.reduce((acc, article, index) => {
                acc[article.id] = index
                return acc
            }, {} as { [key: string]: number })

            const items = articles.filter((article, index) => {
                let articleRegions =
                    metadata[`breaking-news-location-${index}`] || []
                const isAuRegion = countryCode === 'AU'
                const isLocationArrEmpty = !articleRegions.length

                if (isLocationArrEmpty) {
                    articleRegions = [...locationsDefaultValues]
                }

                return isAuRegion
                    ? articleRegions.includes(region)
                    : isLocationArrEmpty
            })

            // Ensure that data result length is > 0 as it can be either 0 or 1
            if (
                data.loaded &&
                data.result.articles.length > 0 &&
                items.length
            ) {
                return (
                    <SevenNewsMultipleBreakingNews
                        items={items}
                        metaDataIndexHash={metaDataIndexHash}
                        onEvent={services.onEvent}
                        metadata={data.result.metadata}
                    />
                )
            } else {
                return null
            }
        },
    )
