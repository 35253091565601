export const getBannerBackgroundUrls = (topic: string) => {
    let bannerBackgroundImage

    try {
        bannerBackgroundImage = require(`../assets/${topic}-background.png`)
    } catch (error) {
        bannerBackgroundImage = null
    }

    return bannerBackgroundImage
}
