import { metrics, SevenNewsSection } from '@news-mono/component-library'
import { BaseClientConfig, publicationAdPageType } from '@news-mono/web-common'
import { ArticleLikeV4DTO } from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { exploreMoreCarousel } from './common-publication-content'

export const getAmpArticleComposition = (
    article: ArticleLikeV4DTO,
    section: SevenNewsSection,
    config: BaseClientConfig,
    adUnitPath?: string,
) => {
    return layout.composition({
        type: 'box',
        props: {
            containerWidth: metrics.sevennews.siteMetrics.mainContentWidth,
            horizontalGutters: 'unset',
            verticalGutters: ['unset', 'unset'],
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'sevennews-article-hero-card',

                    props: {
                        article: article,
                        onEvent: () => {},
                        mediaMode: 'edgeToEdge',
                        adUnitPath,
                    },
                }),
                layout.component({
                    type: 'amp-sticky-ad-unit',
                    props: {
                        slot: {
                            id: 'amp-article-sticky-footer',
                            size: 'leaderboard',
                            pageType: publicationAdPageType[article.kind],
                        },
                    },
                }),
                layout.component({
                    type: 'amp-google-analytics-four',
                    props: {
                        gaFourMeasureId: config.ampgGaFourMeasureId,
                        article,
                    },
                }),
                layout.component({
                    type: 'article',
                    props: {
                        meta: {
                            slug: article.slug,
                            kind: 'article',
                            inlinePublicationContent: [
                                {
                                    kind: 'ad',
                                    insertAfter: 3,
                                    content: [
                                        {
                                            noticePosition: 'above-center',
                                            hiddenUntilLoaded: true,
                                            slot: {
                                                id: 'amp-article-mrec-one',
                                                size: 'mobileMrec',
                                                pageType:
                                                    publicationAdPageType[
                                                        article.kind
                                                    ],
                                            },
                                            adType: 'inline',
                                        },
                                    ],
                                },
                                {
                                    kind: 'inline-taboola-amp',
                                    insertAfter: 12,
                                },
                                {
                                    kind: 'inline-article-contextual-link',
                                    insertAfter: 4,
                                },
                            ],
                        },
                        dataDefinitionArgs: {
                            publicationId: article.id,
                        },
                        section,
                    },
                }),
                ...exploreMoreCarousel(
                    article.topics.secondary,
                    article.source,
                ),
                layout.component({
                    feature: 'taboola',
                    type: 'taboola',
                    props: {
                        taboolaContainerId: 'taboola-below-article-thumbnails',
                        locationType: 'article',
                    },
                }),
            ],
        },
    })
}
