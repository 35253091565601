import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import {
    breakpoint,
    calcRem,
    colors,
    fonts,
    metrics,
    ThemeMargins,
} from '../../__styling'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}
export const FixturesScoreboardWidgetWrapper = styled('div')<GridProps>(
    ({ verticalSpacing, verticalGutters, theme }) => ({
        display: 'flex',
        alignItems: 'center',
        minHeight: calcRem(170),
        marginTop: verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        marginBottom:
            verticalSpacing && calcRem(theme.margins[verticalSpacing]),
        paddingTop:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[0]]),
        paddingBottom:
            verticalGutters &&
            calcRem(metrics.sevennews.margins[verticalGutters[1]]),
    }),
)

export const FixturesScoreboardWidgetLogo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.sevennews.sansSerif,
    marginRight: calcRem(10),
    [breakpoint('sm')]: {
        marginRight: calcRem(20),
    },
})

export const FixturesScoreboardWidgetLogoTop = styled('span')({
    fontSize: calcRem(23),
    lineHeight: '20px',
    fontWeight: 900,
    textTransform: 'uppercase',
    [breakpoint('sm')]: {
        fontSize: calcRem(29),
        lineHeight: '37px',
    },
})
export const FixturesScoreboardWidgetLogoBottom = styled(WebLink)({
    fontSize: calcRem(11),
    lineHeight: '12px',
    fontWeight: 300,
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    background: colors.sevennews.bauhausBlack,
    color: colors.white,
    padding: calcRem(4, 0),
    width: '100%',
    borderRadius: '1px',
    '&:hover': {
        background: colors.sevennews.red,
    },

    [breakpoint('sm')]: {
        fontSize: calcRem(13),
    },
})

export const FixturesScoreboardWidgetContainer = styled('div')({
    flex: 1,
    '.Opta': {
        fontFamily: fonts.sevennews.sansSerif,

        '.Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Match-Versus':
            {
                display: 'none',
            },
        '.Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Team, Opta-Match-Result, .Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Match-Result ':
            {
                backgroundColor: colors.white,
                fontSize: calcRem(10),
                fontWeight: 400,
            },
        '.Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Team':
            {
                padding: calcRem(0, 4, 0, 0),
            },
        '.Opta-TeamName': {
            fontSize: calcRem(11),
            lineHeight: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        '.Opta_C_F_S .Opta-Scroll.Opta-match-linked .Opta-Scroller': {
            background: colors.white,
        },
        '.Opta_C_F_S .Opta-Scroll .Opta-Window': {
            width: 'calc(100% - 60px)',
            left: calcRem(40),
        },
        '.Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-Match-Result':
            {
                paddingLeft: 0,
                span: {
                    fontWeight: 500,
                },
            },

        '.Opta_C_F_S .Opta-Scroll .Opta-Window .Opta-Room .Opta-fixture .Opta-match-linking':
            {
                width: '80%',
                marginTop: calcRem(10),
                backgroundColor: colors.sevennews.bauhausBlack50,
                textTransform: 'uppercase',
            },
    },
    '.Opta .Opta-Scroll .Opta-Scroller.Opta-Previous.Opta-Active:after': {
        backgroundPositionY: '-58px',
    },
    '.Opta .Opta-Scroll .Opta-Scroller.Opta-Next.Opta-Active:after': {
        backgroundPositionY: '-58px',
    },
})
