import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    PlayerRankingWidget,
    PlayerRankingWidgetProps,
} from './PlayerRankingWidget'

export const PlayerRankingWidgetRegistration = createRegisterableComponent(
    'player-ranking-widget',
    (props: PlayerRankingWidgetProps, services) => {
        return <PlayerRankingWidget {...props} />
    },
)
