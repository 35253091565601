import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TeamRankingWidget, TeamRankingWidgetProps } from './TeamRankingWidget'

export const TeamRankingWidgetRegistration = createRegisterableComponent(
    'team-ranking-widget',
    (props: TeamRankingWidgetProps, services) => {
        return <TeamRankingWidget {...props} />
    },
)
