import React from 'react'
import { createRenderTarget } from '../../render-target'
import {
    StyledDynamicHamburgerIcon,
    StyledHamburgerButton,
    StyledText,
} from '../HamburgerButtonV2/HamburgerButtonV2.styled'

export interface HamburgerButtonProps {
    onClick: () => void
    isOpen?: boolean
    className?: string
    text?: string
    controls?: string
}

const HamburgerButtonWeb: React.FC<HamburgerButtonProps> = ({
    onClick,
    className,
    isOpen,
    text,
    controls,
}) => {
    return (
        <StyledHamburgerButton
            type="button"
            onClick={onClick}
            isOpen={isOpen}
            className={className}
            aria-expanded={isOpen}
            aria-controls={controls}
        >
            <StyledText>{text || 'Click to open navigation'}</StyledText>
            <StyledDynamicHamburgerIcon isOpen={isOpen} />
        </StyledHamburgerButton>
    )
}
HamburgerButtonWeb.displayName = 'HamburgerButtonWeb'

const HamburgerButtonAmp: React.FC<HamburgerButtonProps> = ({
    onClick,
    isOpen,
    text,
}) => {
    return (
        <StyledHamburgerButton
            on="tap:sn-main-nav.toggleClass(class='isOpen'), sn-main-nav.toggleClass(class='isTransitioning'), nav-background.toggleClass(class='isOpen')"
            tabIndex={1}
        >
            <StyledText>{text || 'Click to open navigation'}</StyledText>
            <StyledDynamicHamburgerIcon isOpen={isOpen} />
        </StyledHamburgerButton>
    )
}

HamburgerButtonAmp.displayName = 'HamburgerButtonAmp'

export const HamburgerButtonV2 = createRenderTarget<HamburgerButtonProps>(
    'HamburgerButtonV2',
    {
        web: HamburgerButtonWeb,
        amp: HamburgerButtonAmp,
        rss: null,
        preview: HamburgerButtonWeb,
        app: null,
    },
)
