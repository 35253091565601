import { useTheme } from '@emotion/react'
import {
    CardItem,
    createCardClickedEvent,
    isPublicationCardItem,
    toLinkWithHint,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../../cards/CardItem.Props'

import { StyledLink } from '../../../cards/PromotionalCard/PromotionalCard.styled'
import {
    StyledBoxContainer,
    StyledPromotionalCard,
    StyledPromotionalCardTitle,
    StyledPromotionalCardTitleContainer,
    StyledCardImage,
} from './PromotionalCard.styled'

export type AvailableLayouts = 'landscape' | 'portrait'

const src = require('./img/left-arrow.svg')

interface PromotionalCardProps extends CommonCardProps {
    item: MaybeLoaded<CardItem>
    promotionType?: string
    cardNumber: number
}

export const PromotionalCard: React.FC<PromotionalCardProps> = ({
    className,
    item,
    cardNumber,
    onEvent,
}) => {
    const theme = useTheme()

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!item.loaded) {
            // We don't want the link to do anything
            e.preventDefault()
            return
        }

        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'SevenNewsV2PromotionalCard',
                'SevenNewsV2PromotionalCard',
                cardNumber,
            ),
        )
    }

    if (item.loaded && isPublicationCardItem(item.result)) {
        return (
            <StyledPromotionalCard className={className}>
                <StyledBoxContainer>
                    <StyledLink
                        to={toLinkWithHint(item)}
                        onClick={linkClicked}
                        isLoading={!item.loaded}
                        theme={theme}
                    >
                        <StyledCardImage
                            item={{
                                loaded: true,
                                result: item.result,
                            }}
                            fixedRatio="16:9"
                            imageWidths={{
                                mobile: '100%',
                                tablet: '100%',
                                desktop: '100%',
                                fallbackWidth: 628,
                            }}
                            onEvent={onEvent}
                            disableImageLazyLoad={false}
                            displayNoScriptImage={true}
                        />

                        <StyledPromotionalCardTitleContainer>
                            <StyledPromotionalCardTitle>
                                {item.result.headline
                                    ? item.result.headline
                                    : 'Sample Headline'}
                            </StyledPromotionalCardTitle>
                            <img
                                src={src}
                                alt="left-arrow"
                                style={{
                                    width: '20px',
                                    paddingTop: '5px',
                                    marginLeft: '10px',
                                }}
                                width={20}
                                height={18}
                                loading="lazy"
                            />
                        </StyledPromotionalCardTitleContainer>
                    </StyledLink>
                </StyledBoxContainer>
            </StyledPromotionalCard>
        )
    }

    return null
}
PromotionalCard.displayName = 'PromotionalCard'
