import React from 'react'

export interface ActiveSearchIconProps {
    className?: string
}

export const SearchIcon: React.FC<ActiveSearchIconProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="21"
        viewBox="0 0 18 17"
        fill="none"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.0383 1.58019C9.91114 0.874255 8.60801 0.499911 7.27804 0.5C6.14854 0.500096 5.03543 0.770304 4.03159 1.28808C3.02775 1.80585 2.16229 2.55618 1.50741 3.47646C0.852526 4.39674 0.427218 5.46029 0.266967 6.57837C0.106716 7.69645 0.216168 8.83664 0.586192 9.90382C0.956216 10.971 1.57608 11.9342 2.39407 12.7131C3.21207 13.492 4.20446 14.064 5.28847 14.3813C6.37248 14.6987 7.51666 14.7522 8.62556 14.5374C9.73446 14.3226 10.7759 13.8458 11.663 13.1467L14.7064 16.19C14.8635 16.3418 15.074 16.4258 15.2925 16.4239C15.511 16.422 15.7201 16.3344 15.8746 16.1798C16.0291 16.0253 16.1167 15.8163 16.1186 15.5978C16.1205 15.3793 16.0365 15.1688 15.8847 15.0117L12.8414 11.9683C13.6647 10.9238 14.1773 9.66867 14.3206 8.34644C14.4639 7.02421 14.232 5.68835 13.6516 4.49174C13.0711 3.29514 12.1654 2.28613 11.0383 1.58019ZM3.44788 3.75317C4.4637 2.73735 5.84145 2.16667 7.27804 2.16667C8.71463 2.16667 10.0924 2.73735 11.1082 3.75317C12.124 4.76899 12.6947 6.14674 12.6947 7.58333C12.6947 9.01992 12.124 10.3977 11.1082 11.4135C10.0924 12.4293 8.71463 13 7.27804 13C5.84145 13 4.4637 12.4293 3.44788 11.4135C2.43206 10.3977 1.86138 9.01992 1.86138 7.58333C1.86138 6.14674 2.43206 4.76899 3.44788 3.75317Z"
            fill="#272626"
            fill-opacity="0.3"
        />
    </svg>
)
