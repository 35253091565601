import { layout } from '../../App.routing'

const scientologySynopsis = layout.component({
    type: 'inline-content',
    props: {
        blocks: [
            {
                kind: 'text',
                text:
                    'This is the story about Scientology you have been waiting for.' +
                    'Scientology: Black Ops is a 7NEWS.com.au world exclusive investigation.' +
                    'It exposes the secret operations carried out by the Church of Scientology targeting the biggest names in Hollywood.' +
                    'World-first interviews with the leading names, the biggest stars and a whistle blower that reveal — in detail — the Black Ops plot to ruin their lives.' +
                    'This 10-part documentary series could have wide-ranging and serious consequences for the Church of Scientology.' +
                    'Streaming July 14 on 7NEWS.com.au and 7plus.',
                intentions: [
                    { kind: 'line-break', index: 62, length: 0 },
                    {
                        kind: 'link',
                        index: 90,
                        length: 12,
                        href: 'https://7NEWS.com.au',
                    },
                    { kind: 'line-break', index: 133, length: 0 },
                    { kind: 'line-break', index: 248, length: 0 },
                    { kind: 'line-break', index: 399, length: 0 },
                    { kind: 'line-break', index: 510, length: 0 },
                    { kind: 'important', index: 510, length: 43 },
                    {
                        kind: 'link',
                        index: 530,
                        length: 12,
                        href: 'https://7NEWS.com.au',
                    },
                ],
            },
        ],
    },
})

const familyOfSuspectsSynopsis = layout.component({
    type: 'inline-content',
    props: {
        blocks: [
            {
                kind: 'text',
                text:
                    'On New Year’s Eve, 2005, Michael Griffey was found dead in his garage in Pakenham, Victoria.' +
                    'Murdered by a blow to the back of the head, he was left to rot for days.' +
                    'Almost every single one of the millionaire’s family is a suspect.' +
                    'His wife Diane was charged with murder.' +
                    'His daughter Cassandra confesses.' +
                    'And his son Kenny is arrested.' +
                    'But his murder remains unsolved.' +
                    'Now, for the first time, new evidence will be revealed that could finally close this cold case.',
                intentions: [
                    { kind: 'line-break', index: 92, length: 0 },
                    { kind: 'line-break', index: 164, length: 0 },
                    { kind: 'line-break', index: 229, length: 0 },
                    { kind: 'line-break', index: 268, length: 0 },
                    { kind: 'line-break', index: 301, length: 0 },
                    { kind: 'line-break', index: 331, length: 0 },
                    { kind: 'line-break', index: 363, length: 0 },
                ],
            },
        ],
    },
})

export function getSeriesSynopsis(seriesName: string) {
    switch (seriesName) {
        case 'Scientology: Black Ops':
            return scientologySynopsis
        case 'Family of Suspects':
        default:
            return familyOfSuspectsSynopsis
    }
}
