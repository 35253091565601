import {
    RedirectPathDeterminer,
    SevenNewsSection,
} from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { box } from '../../shared-components/compositions'

const getRedirectPathFromState: RedirectPathDeterminer = ({ geoLocation }) => {
    if (!geoLocation || !geoLocation.userSelectedRegion) {
        return '/news/nsw'
    }
    // DPT-3504 - qldreg is a replica of qld in every way except the presenter image.
    // qld = brisbane QLD, qldreg = Queensland
    if (geoLocation.userSelectedRegion.toLocaleLowerCase() === 'qldreg') {
        return `/news/qld`
    }

    return `/news/${geoLocation.userSelectedRegion.toLowerCase()}`
}

export const geolocationRedirectRoute: StaticRoutes<SevenNewsSection> = {
    '/news/local': () => ({
        kind: 'page',
        heading: 'Local News',
        hideHeading: true,
        pageType: 'static',
        adTargeting: {
            pageId: 'diag',
            adUnitPath: '',
            ssAdUnits: [],
            topics: [],
        },
        section: 'default',
        compositions: [
            box({
                main: [
                    layout.component({
                        type: 'redirector',
                        props: {
                            getRedirectPathFromState,
                            status: 302,
                        },
                    }),
                ],
            }),
        ],
    }),
}
