import { useState } from 'react'
import { GalleryPublicationProps } from '../../templates/Publication/SharedPublication.routing'
import {
    StyledButtonCenterContainer,
    StyledGalleryThumbnailsContainer,
} from './GalleryThumbnails.styled'
import { StyledButton } from '../../buttons/Button/Button.styled'
import { ArticleBlockContent } from '../../publication'
import React from 'react'

import { GalleryThumbnailsPagination } from '../../content/Gallery/components/GalleryThumbnailsPagination'
import { DataLayerEventName } from '@news-mono/web-common'

export const GalleryThumbnails: React.FC<GalleryPublicationProps> = ({
    gallery,
    onEvent,
    adState,
    log,
}) => {
    const [showThumbnails, setShowThumbnails] = useState(false)

    function toggleThumbnails() {
        if (showThumbnails) {
            onEvent({
                type: DataLayerEventName.galleryThumbnailsShow,
                originator: 'Gallery',
                payload: {},
            })
        } else {
            onEvent({
                type: DataLayerEventName.galleryThumbnailsHide,
                originator: 'Gallery',
                payload: {},
            })
        }
        setShowThumbnails(!showThumbnails)
    }

    return (
        <StyledGalleryThumbnailsContainer>
            {showThumbnails && (
                <GalleryThumbnailsPagination
                    images={gallery.images}
                    onEvent={onEvent}
                    scrollTo={() => {}}
                    toggleThumbnails={toggleThumbnails}
                />
            )}
            <StyledButtonCenterContainer>
                <StyledButton
                    buttonWidth="gallery"
                    type="button"
                    onClick={toggleThumbnails}
                >
                    {showThumbnails ? 'Hide thumbnails' : 'Show thumbnails'}
                </StyledButton>
            </StyledButtonCenterContainer>

            <ArticleBlockContent
                blocks={gallery.content}
                showInlineVideoTitles={true}
                onEvent={onEvent}
                log={log}
                publicationKind={'gallery'}
                adUnitPath={adState.targeting.adUnitPath}
            />
        </StyledGalleryThumbnailsContainer>
    )
}
