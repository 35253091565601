import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { CollectionRouteProps } from '../../collections'
import { ThemeMargins } from '../../__styling'
import { CricketScoreBoard } from './CricketScoreBoard'

export interface CricketScoreBoardProps extends CollectionRouteProps {
    gameId: string
    apiData: any
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const CricketScoreBoardRegistration = createRegisterableComponent(
    'cricket-match-centre-scoreboard',
    (props: CricketScoreBoardProps, services) => {
        return <CricketScoreBoard {...props} onEvent={services.onEvent} />
    },
)
