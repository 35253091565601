import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { PreferenceCentre } from './PreferenceCentre'

export const NewsletterPreferenceCentreRegistration =
    createRegisterableComponent(
        'newsletter-preference-centre',
        (props, services) => {
            return <PreferenceCentre {...props} />
        },
    )
