import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { VideoCard } from '../cards/VideoCard/VideoCard'
import { getVideoSeriesInfoFromRouteResolution } from './get-data-for-video-series-landing'
import { mapVideoMetaToCard } from './map-video-meta-to-card'

export interface VideoSeriesProps {}

export const VideoSeriesTrailerRegistration = createRegisterableComponent(
    'video-series-trailer',
    (_props: VideoSeriesProps, services) => {
        const { series, trailer, section, season } =
            getVideoSeriesInfoFromRouteResolution(services)

        if (!trailer) {
            return null
        }
        const { seriesInfo } = trailer
        if (!seriesInfo) {
            return null
        }

        return (
            <VideoCard
                cardNumber={0}
                onEvent={services.onEvent}
                isLarge={true}
                fixedRatio="16:9"
                verticalSpacing="lg"
                willPlayVideoInline={true}
                item={{
                    loaded: true,
                    result: mapVideoMetaToCard(
                        section,
                        series,
                        season,
                        services.config.publicUrl,
                        trailer,
                    ),
                }}
            />
        )
    },
)
