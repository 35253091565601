import React, { useEffect, useRef, useState } from 'react'
import {
    StyeldArticleCaptionText,
    StyeldArticleCaptionWrapper,
    StyeldOpenArticleCaption,
} from './ArticleImageCaption.styled'
import { CloseIcon } from './assets/CloseIcon'
import { InfoIcon } from './assets/InfoIcon'

export interface ArticleImageCaptionProps {
    captionText?: string
}

export const ArticleImageCaption: React.FC<ArticleImageCaptionProps> = (
    props: ArticleImageCaptionProps,
) => {
    const [isCaptionShown, setIsCaptionShown] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsCaptionShown(false)
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])
    return (
        <StyeldArticleCaptionWrapper isCaptionShown={isCaptionShown} ref={ref}>
            {isCaptionShown && (
                <StyeldArticleCaptionText>
                    {props.captionText}
                </StyeldArticleCaptionText>
            )}

            {isCaptionShown ? (
                <StyeldOpenArticleCaption
                    onClick={() => setIsCaptionShown(false)}
                >
                    <CloseIcon />
                </StyeldOpenArticleCaption>
            ) : (
                <StyeldOpenArticleCaption
                    onClick={() => setIsCaptionShown(true)}
                >
                    <InfoIcon />
                </StyeldOpenArticleCaption>
            )}
        </StyeldArticleCaptionWrapper>
    )
}
