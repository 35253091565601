import { metrics, StickyRouteProps } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import { box } from './compositions'

export const headerAd = (propOverrides: StickyRouteProps = {}) =>
    layout.composition({
        type: 'box',
        props: {
            id: '7newsHeaderAd',
        },
        contentAreas: {
            main: [
                layout.nestedComposition(
                    box({
                        main: [
                            layout.component({
                                type: 'ad-unit',
                                props: {
                                    noticePosition: 'above-center',
                                    hiddenUntilLoaded: false,
                                    preventCollapse: true,
                                    padding: [
                                        metrics.sevennews.margins.lg,
                                        0,
                                        metrics.sevennews.margins.sm,
                                        0,
                                    ],
                                    slot: {
                                        id: 'leaderboard-billboard-one',
                                        size: 'leaderboardBillboard',
                                    },
                                    adType: 'inline',
                                },
                            }),
                        ],
                    }),
                ),
            ],
        },
    })

export const breakingNews = box({
    propOverrides: {
        containerWidth: '100%',
        breakpoints: {
            sm: {
                containerWidth:
                    metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                horizontalGutters: 'lg',
            },
            md: {
                horizontalGutters: 'xl',
            },
        },
    },
    main: [
        layout.component({
            type: 'breaking-news',
            props: {
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'breaking-news',
                    offset: 0,
                    pageSize: 10,
                },
            },
        }),
    ],
})

export const breakingNewsComponent = layout.component({
    type: 'breaking-news',
    props: {
        dataDefinitionArgs: {
            type: 'curation',
            name: 'breaking-news',
            offset: 0,
            pageSize: 10,
        },
    },
})
