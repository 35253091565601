import { FeatureState } from '@etrigan/feature-toggles-client'
import { isComponentEnabled, NavEvent } from '@news-mono/web-common'
import React from 'react'
import { sevenNewsSocialLinks } from '../../__styling/settings/sections'
import { HamburgerButtonV2 } from '../../buttons/HamburgerButtonV2/HamburgerButtonV2'

import {
    SevenNewsNavigationV2,
    SevenNewsNavItemV2,
} from '../../routing/route-nav-types'

import {
    StyledAnimationContainer,
    StyledFooterItem,
    StyledFooterItemLink,
    StyledFooterWrapper,
    StyledHeaderAMP,
    StyledMainNav,
    StyledNav,
    StyledNavigationBackgroundAMP,
    StyledNavList,
    StyledNavScrollArea,
    StyledNavSection,
    StyledSeparator,
    StyledSocialLinksWrapper,
} from './SevenNewsFlyoutNav2.styled'
import { SevenNewsFlyoutNavItemAMP } from '../SevenNewsFlyoutNavV2/SevenNewsFlyoutNavItemAMP'

export interface SevenNewsFlyoutNavProps {
    navLinks: SevenNewsNavigationV2
    onEvent: (event: NavEvent) => void
    isOpen?: boolean
    toggleFlyoutNav?: () => void
    featureState: FeatureState
}

export const SNMainNavId = 'sn-main-nav'
export const backgroundNavId = 'nav-background'
const connectLinks = [
    {
        name: 'About Us',
        link: 'https://7news.com.au/about-us',
    },
    {
        name: 'Contact Us',
        link: 'https://support.7news.com.au/hc/en-au/requests/new',
    },
    {
        name: 'Advertise With Us',
        link: 'https://7news.com.au/contact-us',
    },
    {
        name: 'FAQs',
        link: 'https://support.7news.com.au/',
    },
    {
        name: 'Privacy Policy',
        link: 'https://www.sevenwestmedia.com.au/privacy-policies/privacy',
    },
    {
        name: 'Editorial Policy',
        link: 'https://7news.com.au/editorial-policy',
    },
    {
        name: 'Terms Of Use',
        link: 'https://support.7news.com.au/terms',
    },
]

export const SevenNewsFlyoutNav2AMP: React.FC<SevenNewsFlyoutNavProps> = ({
    navLinks,
    featureState,
}) => {
    return (
        <StyledNavigationBackgroundAMP id={backgroundNavId}>
            <StyledAnimationContainer id={SNMainNavId}>
                <StyledHeaderAMP>
                    <HamburgerButtonV2 onClick={() => {}} isOpen={true} />
                </StyledHeaderAMP>

                <StyledNav>
                    <StyledNavScrollArea>
                        <StyledMainNav>
                            <StyledNavList role="menu">
                                {navLinks
                                    .filter((item) =>
                                        isItemToggled(featureState, item),
                                    )
                                    .map((navItem, index) => {
                                        return (
                                            <SevenNewsFlyoutNavItemAMP
                                                navItem={navItem}
                                                key={index}
                                            />
                                        )
                                    })}
                            </StyledNavList>
                        </StyledMainNav>

                        {/* footerLinks */}
                        <StyledFooterWrapper>
                            {connectLinks.map((item, idx) => {
                                return (
                                    <StyledFooterItem key={idx}>
                                        <StyledFooterItemLink to={item.link}>
                                            {item.name}
                                        </StyledFooterItemLink>
                                    </StyledFooterItem>
                                )
                            })}
                            <StyledSeparator />
                        </StyledFooterWrapper>
                        <StyledNavSection>
                            <StyledSocialLinksWrapper
                                {...sevenNewsSocialLinks}
                            />
                        </StyledNavSection>
                    </StyledNavScrollArea>
                </StyledNav>
            </StyledAnimationContainer>
        </StyledNavigationBackgroundAMP>
    )
}

SevenNewsFlyoutNav2AMP.displayName = 'SevenNewsFlyoutNav2AMP'

function isItemToggled(featureState: FeatureState, item: SevenNewsNavItemV2) {
    const { feature, invertFeatureToggle, hideFromFlyoutMenu } = item
    if (feature) {
        if (
            !isComponentEnabled(featureState, {
                feature,
                invertFeatureToggle,
            })
        ) {
            return false
        }
    }
    if (hideFromFlyoutMenu) {
        return false
    }
    return true
}
