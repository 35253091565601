import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { SevenNewsSection } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { breadcrumb } from '../shared-components/breadcrumb'
import { box } from '../shared-components/compositions'

export const cookieSettings: StaticRoutes<SevenNewsSection> = {
    '/cookie-settings': ({ store }) => {
        if (
            !isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'cookie-consent',
            )
        ) {
            return null
        }

        return {
            kind: 'page',
            heading: 'Cookie Settings',
            hideHeading: true,
            pageType: 'static',
            adTargeting: {
                pageId: 'cookie-settings',
                adUnitPath: '',
                ssAdUnits: [],
                topics: [],
            },
            section: 'default',
            compositions: [
                breadcrumb({
                    title: 'Cookie Settings',
                }),
                box({
                    propOverrides: {
                        horizontalGutters: 'md',
                        verticalGutters: ['md', 'md'],
                    },
                    main: [
                        layout.component({
                            type: 'cookie-consent-user-settings-page',
                            props: {},
                        }),
                    ],
                }),
            ],
        }
    },
}
