import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, fonts } from '../__styling'

export const SpotTheBucketContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 300,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(18),
}))

export const SpotTheBucketHeading = styled('h1')(() => ({
    fontWeight: 700,
    fontSize: calcRem(18),
    marginTop: calcRem(20),
    marginBottom: 0,
}))

export const SpotTheBucketMainInfo = styled('h1')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: calcRem(18),
    fontWeight: 300,
}))

export const SpotTheBucketText = styled('p')(() => ({
    margin: `${calcRem(5)} 0`,
    padding: 0,
    strong: {
        fontWeight: 700,
    },
}))
export const SpotTheBucketSubText = styled('p')(() => ({
    margin: `${calcRem(5)} 0`,
    paddingLeft: calcRem(20),
}))
export const SpotTheBucketLink = styled(WebLink)(() => ({
    marginBottom: calcRem(25),
    fontWeight: 700,
}))

export const SpotTheBucketTable = styled('table')(() => ({
    borderCollapse: 'collapse',
    margin: `${calcRem(10)} 0`,
    textAlign: 'center',
    width: '100%',
}))

export const SpotTheBucketTableTr = styled('tr')(() => ({
    padding: calcRem(3),
}))
export const SpotTheBucketTableTh = styled('th')(() => ({
    fontWeight: 700,
    fontSize: calcRem(16),
    border: 'solid 1px black',
    padding: calcRem(3),
}))

export const SpotTheBucketTableTd = styled('td')(() => ({
    border: 'solid 1px black',
    fontSize: calcRem(16),
    padding: calcRem(3),
}))
