import React from 'react'
import { DisclaimerV2Props } from './DisclaimerV2.routing'
import {
    StyledDisclaimerV2,
    StyledDisclaimerV2Wrapper,
} from './DisclaimerV2.styled'

export const DisclaimerV2: React.FC<DisclaimerV2Props> = ({
    disclaimerText,
}) => {
    return (
        <StyledDisclaimerV2Wrapper>
            <StyledDisclaimerV2>{disclaimerText}</StyledDisclaimerV2>
        </StyledDisclaimerV2Wrapper>
    )
}
