import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { transition, visuallyhidden } from '../../__styling/style-mixins'

export interface StyledHamburgerButtonProps {
    isOpen?: boolean
    on?: string
}

export const StyledHamburgerButton = styled(
    'button',
)<StyledHamburgerButtonProps>({
    position: 'relative',
    border: 0,
    cursor: 'pointer',
    ...transition({ properties: ['opacity'] }),
    '&:hover': {
        opacity: 0.8,
    },
    width: calcRem(24),
    height: calcRem(14),
    background: 'transparent',

    '&:focus': {
        outline: `2px solid ${colors.sevennews.red}`,
        outlineOffset: calcRem(5),
    },
})
export const StyledText = styled('span')(visuallyhidden)

export interface StyledDynamicHamburgerIconProps {
    isOpen?: boolean
}

export const StyledDynamicHamburgerIcon = styled(
    'div',
)<StyledDynamicHamburgerIconProps>(
    {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        margin: 'auto',
    },

    ({ isOpen }) => ({
        background: isOpen ? 'transparent' : colors.sevennews.red,
        width: isOpen ? 0 : calcRem(24),
        ...transition({ properties: ['width'] }),

        '&, &::before, &::after': {
            width: calcRem(24),
            height: calcRem(2),
            borderRadius: calcRem(4),
            marginLeft: 0,
        },

        '&::before, &::after': {
            content: `''`,
            position: 'absolute',
            left: 0,
            right: 0,
            transformOrigin: 'center',
            background: colors.sevennews.red,
            ...transition({ properties: ['transform'] }),
        },

        '&::before': {
            transform: isOpen === true ? 'rotate(-45deg)' : 'rotate(0)',
            bottom: isOpen === true ? 0 : calcRem(5),
        },

        '&::after': {
            transform: isOpen === true ? 'rotate(45deg)' : 'rotate(0)',
            top: isOpen === true ? 0 : calcRem(5),
            width: isOpen ? calcRem(24) : calcRem(18),
        },
    }),
)
