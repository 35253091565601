import { RenderTargetContext, Topic } from '@news-mono/web-common'
import React, { useContext, useState } from 'react'
import {
    StyeldShareWrapper,
    StyledBreadcrumbDesktopWrapper,
    StyledBylineSectionContainer,
    StyledBylineWrap,
    StyledCardMediaWrap,
    StyledHeroContainer,
    StyledInformationWrapper,
    StyledShareWrapper,
    StyledStaticMediaImage,
    StyledTeaser,
    StyledTextWrap,
    StyledTextWrapHeader,
    StyledTimingsWrapper,
    StyledTitle,
} from './StaticHeroCard.styled'
import { StaticShare } from '../Share/Share'
import { ShareIcon } from '../ArticleHeroCard/assets/ShareIcon'
import { getUpdatedAt } from '../ArticleHeroCard/utils/formatTimeSince'
import { ByLine } from '../ByLine/ByLine'
import { isTabletOrMobileViewport } from '../../../__helpers'
import { Breadcrumb } from '../../breadcrumb/BreadCrumb'
import { formatBreadcrumb } from '../../breadcrumb/helpers'

export interface SectionHeroCardProps {
    primaryTopic?: Topic
    description: string
    logo?: string
    heroImage: string
    title?: string
    byLine?: {
        title: string
        titleUrl?: string
        linkText: string
        linkUrl: string
    }
    share: { url: string; text: string }
    lastUpdated?: string
}

export const StaticHeroCard: React.FC<SectionHeroCardProps> = ({
    primaryTopic,
    lastUpdated,
    description,
    heroImage,
    share,
    title,
}: SectionHeroCardProps) => {
    const [showSharePopup, setShowSharePopup] = useState(false)
    const { renderTarget } = useContext(RenderTargetContext)
    const isMobileOrTablet = isTabletOrMobileViewport()
    const handleShare = async () => {
        if (isMobileOrTablet) {
            if (navigator && typeof navigator.share !== 'undefined') {
                try {
                    await navigator.share({
                        url: share.url,
                        text: share.text,
                    })
                    setShowSharePopup(false)
                } catch (e) {
                    setShowSharePopup(false)
                    console.error(e, 'Error while sharing')
                }
            } else {
                setShowSharePopup((prev) => !prev)
            }
        } else {
            setShowSharePopup((prev) => !prev)
        }
    }
    const breadcrumbItems = primaryTopic ? formatBreadcrumb(primaryTopic) : []
    return (
        <>
            <StyledBreadcrumbDesktopWrapper>
                <Breadcrumb items={breadcrumbItems} />
            </StyledBreadcrumbDesktopWrapper>
            <StyledHeroContainer renderTarget={renderTarget}>
                <StyledTextWrap>
                    <div>
                        <StyledTextWrapHeader>
                            <StyeldShareWrapper isOpened={showSharePopup}>
                                <StaticShare share={share} onEvent={() => {}} />
                            </StyeldShareWrapper>

                            <StyledInformationWrapper>
                                <StyledShareWrapper
                                    isOpened={showSharePopup}
                                    onClick={handleShare}
                                >
                                    <ShareIcon />
                                </StyledShareWrapper>
                                <StyledTimingsWrapper>
                                    {lastUpdated && getUpdatedAt(lastUpdated)}
                                </StyledTimingsWrapper>
                            </StyledInformationWrapper>
                        </StyledTextWrapHeader>
                        <StyledTitle>{title}</StyledTitle>
                        <StyledTeaser>{description}</StyledTeaser>
                    </div>
                    <StyledBylineSectionContainer>
                        <StyledBylineWrap>
                            <ByLine
                                byline={'Digital Staff'}
                                profiles={undefined}
                                hasBackground
                                source="7NEWS"
                            />
                        </StyledBylineWrap>
                    </StyledBylineSectionContainer>
                </StyledTextWrap>
                <StyledCardMediaWrap>
                    <StyledStaticMediaImage src={heroImage} />
                </StyledCardMediaWrap>
            </StyledHeroContainer>
        </>
    )
}
