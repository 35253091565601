import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    CompetitionStatsWidget,
    CompetitionStatsWidgetProps,
} from './CompetitionStatsWidget'

export const CompetitionStatsWidgetRegistration = createRegisterableComponent(
    'competition-stats-widget',
    (props: CompetitionStatsWidgetProps, services) => {
        return <CompetitionStatsWidget {...props} />
    },
)
