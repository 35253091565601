import React from 'react'
import { StyledTvGuideContainer } from './TVGuideMainWidget.styled'

export default function TVGuideMainWidget() {
    return (
        <StyledTvGuideContainer>
            <iframe
                id="tvguidetonight-widget-channels"
                src="https://tvguidetonight.com.au/widgets/plus-channels.html"
                title=""
            ></iframe>
        </StyledTvGuideContainer>
    )
}
