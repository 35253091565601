import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    QLDElectionHeaderWidget,
    QLDElectionHeaderWidgetProps,
} from './QLDElectionHeaderWidget'

export const QLDElectionHeaderWidgetRegistration = createRegisterableComponent(
    'qld-election-header-widget',
    (props: QLDElectionHeaderWidgetProps, services) => {
        return <QLDElectionHeaderWidget {...props} />
    },
)
