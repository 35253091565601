import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    TogglesReduxState,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { SevenNewsSection, metrics } from '@news-mono/component-library'
import { breakingNewsComponent } from '../../shared-components/ad-and-breaking-news'
import { box } from '../../shared-components/compositions'

export const getNewsletterPage = (
    config: BaseClientConfig,
    getAdTargeting: GetRouteAdTargeting,
    toggles: TogglesReduxState,
): PageType<SevenNewsSection> => {
    const pageTitle = 'Newsletters'
    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        'default',
        {
            title: pageTitle,
            id: 'default',
            seoTitle: pageTitle,
            metadata: {},
        },
    )

    const adTargeting: AdTargeting = {
        pageId: pageTitle,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const pageComposition = layout.composition({
        type: 'box',
        props: {
            verticalGutters: ['unset', 'unset'],
            horizontalGutters: 'md',
            containerWidth:
                metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
            breakpoints: {
                sm: {
                    horizontalGutters: 'lg',
                },
                md: {
                    horizontalGutters: 'xl',
                },
            },
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'newsletter-preference-centre',
                    props: {
                        onEvent: () => {},
                    },
                }),
            ],
        },
    })
    return {
        kind: 'page',
        heading: pageTitle,
        hideHeading: true,
        pageType: 'static',

        pageMeta: {
            title: '7NEWS Updates - Sign Up to the Free Newsletter',
            description:
                'Sign up to the 7NEWS Newsletter and stay up to date with all of the latest sport, lifestyle, consumer affairs and breaking news. 7NEWS brings you the latest local news from Australia and around the world.',
        },
        adTargeting,
        section: 'default',
        compositions: [
            box({
                propOverrides: {
                    verticalGutters: ['md', 'xl'],
                    containerWidth: '100%',
                    breakpoints: {
                        sm: {
                            horizontalGutters: 'lg',
                        },
                        md: {
                            horizontalGutters: 'xl',
                        },
                    },
                },
                main: [
                    layout.component({
                        type: 'breaking-news',
                        props: {
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'breaking-news',
                                offset: 0,
                                pageSize: 10,
                            },
                        },
                    }),
                ],
            }),
            pageComposition,
        ],
    }
}
