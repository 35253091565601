import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { calcRem, fonts } from '../../__styling'

export const CompetitionStatsWidgetContainer = styled('div')({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
    },

    '& .Opta_C_CS_N > h2': {
        display: 'none',
    },

    '& .Opta .Opta_C_CS_N': {
        '& .Opta-Flex': {
            '& .Opta-Label': {
                fontSize: calcRem(16),
                color: tokens.sevennews.colors.palette.bauhausBlack,
                '&::after': {
                    color: tokens.sevennews.colors.palette.bauhausBlack,
                },
            },
            '& .Opta-Value': {
                fontSize: calcRem(32),
                color: tokens.sevennews.colors.palette.bauhausBlack,
            },
        },

        '& .Opta-Striped': {
            'thead th': {
                color: tokens.sevennews.colors.palette.white,
                backgroundColor: tokens.sevennews.colors.palette.red,
                fontSize: calcRem(14),
            },
        },
    },
})
