import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, colors, easing } from '../../__styling'
import { zIndex } from '../../__styling/settings/z-index'

export interface StyledHeaderProps {
    showHeader?: boolean
}

export const StyledHeader = styled('header')<StyledHeaderProps>(
    ({ showHeader }) => ({
        position: 'sticky',
        top: calcRem(-1),
        backfaceVisibility: 'hidden',
        zIndex: zIndex.sevennews.stickySiteHeader,
        background: tokens.sevennews.colors.palette.white,
        transform: showHeader ? `translateY(0)` : `translateY(-440px)`,
        transition: `transform ${easing.easeOut.med}`,
    }),
)

export const StyledAdSevenNews = styled('div')({
    position: 'fixed',
    bottom: 0,
    display: 'block',
    overflow: 'hidden',
    zIndex: zIndex.sevennews.stickyMobileFooterAd,
    left: '50%',
    width: '100%',
    background: colors.white,
    transform: 'translate(-50%, 0)',

    '@media screen and (max-height: 480px)': {
        display: 'none',
    },

    [breakpoint('md')]: {
        display: 'none',
    },
})

export const StyledLeaderBoardBillboardWrapper = styled('div')({
    background: colors.sevennews.softPeach,
})
