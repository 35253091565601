import styled from '@emotion/styled'
import { breakpoint, calcRem, colors, fonts } from '../../../__styling'

import { SearchIcon } from './SearchIcon'
import { ActiveSearchIcon } from './ActiveSearchIcon'
import { CrossIcon } from './CrossIcon'

interface StyledSearchContentProps {
    searchInMainNav?: boolean
    hide?: boolean
    isLarge?: boolean
    isSearchMobileAndInactive?: boolean
}

interface StyledClearButtonProps {
    hide: boolean
    isLarge?: boolean
    on?: string
}

export const StyledSearchContainer = styled('div')<StyledSearchContentProps>(
    ({ hide, isLarge, isSearchMobileAndInactive }) => [
        {
            alignSelf: 'center',
            justifyContent: 'center',
            width: isLarge
                ? '100%'
                : isSearchMobileAndInactive
                ? calcRem(48)
                : calcRem(290),

            height: isLarge ? calcRem(48) : calcRem(38),
            display: hide ? 'none' : 'flex',
            marginLeft: !isLarge && isSearchMobileAndInactive ? calcRem(5) : 0,
            '&.isActive': {
                width: calcRem(290),
            },
            [breakpoint('sm')]: {
                width: isLarge
                    ? '100%'
                    : isSearchMobileAndInactive
                    ? calcRem(28)
                    : calcRem(340),

                marginLeft:
                    !isLarge && isSearchMobileAndInactive ? calcRem(10) : 0,
            },
            [breakpoint('md')]: {
                position: 'relative',
                width: isLarge ? calcRem(604) : calcRem(269),
                marginLeft: 0,
            },
        },
    ],
)

export const StyledSearchIcon = styled(SearchIcon)(({}) => ({
    width: calcRem(14),
    height: calcRem(14),
    display: 'flex',
}))
export const StyledActiveSearchIcon = styled(ActiveSearchIcon)(({}) => ({
    width: calcRem(14),
    height: calcRem(14),
    display: 'flex',
}))

export const StyledCrossIcon = styled(CrossIcon)({
    height: calcRem(10),
    width: calcRem(10),
})

interface StyledSearchFormProps {
    searchHasValue: boolean
    isLarge: boolean
    isActive: boolean
    isMobile?: boolean
}

export const StyledSearchForm = styled('form')<StyledSearchFormProps>(
    ({ isLarge, isActive }) => ({
        display: 'flex',
        flexDirection: 'row-reverse',
        padding:
            isActive || isLarge ? calcRem(0, 16, 0, 16) : calcRem(0, 0, 6, 0),
        fontSize: isLarge ? calcRem(20) : calcRem(18),
        fontWeight: 400,
        lineHeight: calcRem(27),
        margin: !isActive ? calcRem(4, 8, 0, 0) : 0,
        borderRadius: calcRem(4),
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        border: isActive || isLarge ? `1px solid #00000026` : 'none',
        '&.isActive': {
            border: `1px solid #00000026`,
            padding: calcRem(0, 16, 0, 16),
            margin: 0,
        },
        [breakpoint('sm')]: {
            padding: isActive || isLarge ? calcRem(0, 16, 0, 16) : 0,
        },
        [breakpoint('md')]: {
            flexDirection: 'row',
            margin: 0,
        },
    }),
)

interface StyledSearchInputProps {
    isMobile?: boolean
    isActive: boolean
    isLarge: boolean
    on?: string
}
export const StyledSearchInput = styled('input')<StyledSearchInputProps>(
    ({ isMobile, isActive, isLarge }) => ({
        width: '100%',
        border: 'none',
        fontFamily: fonts.sevennews.sansSerif,
        fontWeight: 400,
        background: isMobile ? colors.white : 'transparent',
        zIndex: 1,
        visibility: !isActive && !isLarge ? 'hidden' : 'visible',
        padding: 0,
        '&.isActive': {
            visibility: 'visible',
            fontSize: '100%',
        },
        '&:focus': {
            outline: 'none',
        },

        '&::placeholder': {
            color: 'rgba(39, 38, 38, 0.3)',
            fontWeight: 500,
        },

        // We have our own cancel, so we end up with 2 in chrome and edge.
        '&::-webkit-search-cancel-button': {
            display: 'none',
        },
    }),
)

export const StyledClearButton = styled('button')<StyledClearButtonProps>(
    ({ hide, isLarge }) => [
        {
            background: 'transparent',
            cursor: 'pointer',
            border: 'none',
            lineHeight: 0,
            margin: calcRem(0, 16, 0, 8),
            padding: 0,
            '&.isActive': {
                display: 'block',
            },
            '& svg': {
                width: isLarge ? calcRem(17) : calcRem(15),
                height: isLarge ? calcRem(16) : calcRem(15),
            },
            [breakpoint('md')]: {
                margin: calcRem(0, 8, 0, 16),
            },
        },
        hide && {
            display: 'none',
        },
    ],
)

interface StyledSearchButtonProps {
    isLarge?: boolean
    isActive?: boolean
    on?: string
}
export const StyledSearchButton = styled('button')<StyledSearchButtonProps>(
    ({ isLarge, isActive }) => ({
        border: 'none',
        borderRadius: calcRem(2),
        cursor: 'pointer',
        margin: isLarge || (!isLarge && isActive) ? calcRem(4, 8, 0, 0) : 0,
        outline: 'none',
        backgroundColor: 'transparent',
        width: !isLarge && !isActive ? '100%' : calcRem(21),
        padding: 0,
        '& svg': {
            width: isLarge ? calcRem(23) : calcRem(21),
            height: isLarge ? calcRem(21) : calcRem(20),
        },
        '&.isInactive': {
            display: 'none',
        },

        [breakpoint('md')]: {
            margin:
                isLarge || (!isLarge && isActive)
                    ? calcRem(4, 0, 0, 8)
                    : calcRem(4, 16, 0, 8),
            width: calcRem(21),
        },
        [`${StyledSearchIcon}`]: {
            fill: colors.sevennews.nero,
        },
    }),
)
export const StyledSearchButtonActive = styled(
    'button',
)<StyledSearchButtonProps>(({ isLarge, isActive }) => ({
    border: 'none',
    borderRadius: calcRem(2),
    cursor: 'pointer',
    margin: isLarge || (!isLarge && isActive) ? calcRem(4, 8, 0, 0) : 0,
    outline: 'none',
    backgroundColor: 'transparent',
    width: !isLarge && !isActive ? '100%' : calcRem(21),
    padding: 0,
    '& svg': {
        width: isLarge ? calcRem(23) : calcRem(21),
        height: isLarge ? calcRem(21) : calcRem(20),
    },
    display: 'none',
    '&.isActive': {
        margin: calcRem(4, 8, 0, 0),
        width: calcRem(21),
        display: 'block',
    },
    [breakpoint('md')]: {
        margin:
            isLarge || (!isLarge && isActive)
                ? calcRem(4, 0, 0, 8)
                : calcRem(4, 16, 0, 8),
        width: calcRem(21),
    },
    [`${StyledSearchIcon}`]: {
        fill: colors.sevennews.nero,
    },
}))
