import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const LatestDeliveriesWidgetContainer = styled('div')({
    display: 'block',
    marginBottom: calcRem(10),
    [breakpoint('sm')]: {
        marginBottom: calcRem(20),
    },
    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
        '& .Opta-Cf .Opta-deliveries ul .Opta-header': {
            backgroundColor: '#B6001E',
            color: tokens.sevennews.colors.palette.white,
            '& span': {
                fontSize: calcRem(10),
                fontWeight: 500,
                [breakpoint('sm')]: {
                    fontSize: calcRem(12),
                },
            },
            '&:: ': {
                backgroundColor: '#B6001E',
            },
        },
    },
    '& .Opta_C_C_N > h2': {
        display: 'none',
    },

    '& .Opta_C_LD .Opta-deliveries ul li': {
        fontSize: calcRem(18),
        color: tokens.sevennews.colors.palette.black,
        fontWeight: 300,
        [breakpoint('sm')]: {
            fontSize: calcRem(20),
        },
    },
    '& .Opta_C_LD .Opta-deliveries ul li:nth-child(2) span': {
        fontSize: calcRem(21),
        [breakpoint('sm')]: {
            fontSize: calcRem(24),
        },
    },
})
