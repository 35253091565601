import styled from '@emotion/styled'
import { colors, fonts } from '../../__styling'

export const StyledNav = styled('nav')({
    width: '100%',
    display: 'flex',
    fontFamily: fonts.sevennews.sansSerif,
    background: colors.sevennews.navigationBackground,
    borderBottom: `1px solid ${colors.black20}`,
    color: colors.sevennews.nero,
})
