import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledDisclaimerV2Wrapper = styled('div')({
    borderTop: `${calcRem(1)} solid ${
        tokens.sevennews.colors.palette.articlesV2.horizontalLine
    }`,
    borderBottom: `${calcRem(1)} solid ${
        tokens.sevennews.colors.palette.articlesV2.horizontalLine
    }`,
    padding: 0,
    marginBottom: calcRem(18),
})
export const StyledDisclaimerV2 = styled('p')({
    fontSize: calcRem(12),
    fontWeight: 400,
    color: colors.sevennews.nero70,
    textAlign: 'center',
    lineHeight: 'normal',
    margin: calcRem(6, 11, 6),
    [breakpoint('sm')]: {
        margin: calcRem(6, 94, 6),
    },
    [breakpoint('md')]: {
        fontSize: calcRem(14),
        margin: calcRem(6, 126, 6),
    },
})
