import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { ConsentLevel, Store, useConsentGiven } from '@news-mono/web-common'
import React from 'react'
import { Helmet } from 'react-helmet'
import { renderNewsRoomScript } from './render-newsroom-script'

interface TaboolaNewsRoomProps {
    path: string
    store: Store
}

export const TaboolaNewsRoom: React.FC<TaboolaNewsRoomProps> = ({
    path,
    store,
}) => {
    const { geoLocation, toggles } = store.getState()
    const consentRequiredLevel = ConsentLevel.Analytics
    const consentGiven = useConsentGiven({ consentRequiredLevel })
    const isToggleEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7-news-taboola-newsroom',
        true,
    )
    if (!isToggleEnabled || !consentGiven) {
        return <></>
    }

    return (
        <Helmet>
            <script>{renderNewsRoomScript(path, geoLocation)}</script>
        </Helmet>
    )
}
