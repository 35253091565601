import {
    isTabletOrMobileViewport,
    metrics,
    SevenNewsSection,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    GetRouteAdTargeting,
    PageType,
    Store,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { PageMetaDTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import { layout } from '../../App.routing'
import {
    breakingNews,
    headerAd,
} from '../shared-components/ad-and-breaking-news'
import { box } from '../shared-components/compositions'
import { mobileAdTwo } from '../shared-components/mobile-ads'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
const metaImage = require('./assets/match-centre.jpg')

export const aflTopicForAdTargeting: Topic = {
    title: 'Afl',
    id: 'sport/afl',
    seoTitle: 'Afl',
    metadata: {},
    parent: {
        title: 'Sport',
        id: 'sport',
        seoTitle: 'Sport',
        metadata: {},
    },
}

export const createAflMatchCentreRoute = ({
    config,
    getAdTargeting,
    location,
    meta,
    match,
    competition,
    season,
    apiData,
    store,
}: {
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location
    meta: PageMetaDTO | undefined
    match: string
    competition: string
    season: string
    apiData: any
    store: Store
}): PageType<SevenNewsSection> => {
    const section = 'default'

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'match-centre',
        section,
        aflTopicForAdTargeting,
    )
    const adTargeting: AdTargeting = {
        pageId: meta?.title ? meta.title : 'sport',
        adUnitPath,
        ssAdUnits,
        topics,
    }
    const canonicalUrl = config.publicUrl + location.pathname + location.search
    const isV2LeaderBoardBillboardToggleEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        '7-news-2-top-leaderboard-ad',
    )
    return {
        kind: 'page',
        heading: meta?.title ? meta.title : '',
        pageMeta: {
            title: meta?.title ? meta.title : '',
            description: meta?.description ? meta.description : '',
            meta: [
                {
                    property: 'og:image',
                    content: metaImage,
                },
                {
                    name: 'twitter:image',
                    content: metaImage,
                },
                {
                    property: 'og:image:height',
                    content: '720',
                },
                {
                    property: 'og:image:width',
                    content: '1280',
                },
            ],
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        socialMeta: {
            title: meta?.title ? meta.title : '',
            description: meta?.description ? meta.description : '',
        },
        hideHeading: true,
        noMetaTitleTemplate: true,
        pageType: 'static',
        refreshOnInactive: true,
        adTargeting,
        section,
        compositions: [
            ...(isV2LeaderBoardBillboardToggleEnabled ? [] : [headerAd()]),
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['md', 'unset'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'afl-fixtures-scoreboard',
                        feature: '7-news-afl-fixture-scoreboard',
                        props: {},
                    }),
                ],
            }),
            breakingNews,
            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'afl-match-centre-scoreboard',
                        props: {
                            gameId: match,
                            apiData,
                        },
                    }),
                ],
            }),
            layout.composition({
                type: 'tab-v2',
                props: {
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    headerData: [
                        {
                            position: 'one',
                            title: 'Summary',
                        },
                        {
                            position: 'two',
                            title: 'Timeline',
                        },
                        {
                            position: 'three',
                            title: 'Stats',
                        },
                        {
                            position: 'four',
                            title: 'Squads',
                        },
                        {
                            position: 'five',
                            title: 'Fixtures',
                        },
                        {
                            position: 'six',
                            title: 'Ladder',
                        },
                        {
                            position: 'seven',
                            title: 'Videos',
                        },
                        {
                            position: 'eight',
                            title: 'News',
                        },
                    ],
                },
                contentAreas: {
                    one: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Timeline',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-match-centre-timeline',
                            props: {
                                apiData,
                                matchId: match,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Match Summary',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-match-summary-widget',
                            props: {
                                competition,
                                match,
                                season,
                            },
                        }),
                        layout.nestedComposition({
                            type: 'doublet',
                            props: {
                                containerWidth:
                                    metrics.sevennews.siteMetrics
                                        .sevenEntMainContentWidth,
                                verticalGutters: ['unset', 'md'],
                            },
                            contentAreas: {
                                leftContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Match Stats',
                                            },
                                        },
                                    }),

                                    layout.component({
                                        type: 'afl-match-stats-widget',
                                        props: {
                                            competition,
                                            match,
                                            season,
                                        },
                                    }),
                                ],
                                rightContent: [
                                    layout.component({
                                        type: 'horizontal-pill-menu',
                                        props: {
                                            data: {
                                                type: 'single',
                                                name: 'Player Stats',
                                            },
                                        },
                                    }),
                                    layout.component({
                                        type: 'afl-player-stats',
                                        props: {
                                            apiData,
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Injuries & Suspensions',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-injuries-suspensions-widget',
                            props: {
                                apiData,
                                competition,
                                season,
                            },
                        }),

                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'default',
                                    topic: aflTopicForAdTargeting,
                                },
                            },
                        }),
                        layout.component({
                            type: 'six-card-collection',
                            props: {
                                verticalSpacing: 'xl',
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/afl'],
                                    paging: {
                                        page: 1,
                                        pageSize: 6,
                                    },
                                },
                            },
                        }),
                    ],
                    two: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Timeline',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-match-centre-timeline',
                            props: {
                                matchId: match,
                                apiData,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Match Feed',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-timeline-feed-widget',
                            props: {
                                matchId: match,
                                apiData,
                            },
                        }),
                    ],
                    three: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Match Stats',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-match-stats-widget',
                            props: {
                                competition,
                                match,
                                season,
                            },
                        }),
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Player Stats',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-player-stats',
                            props: {
                                apiData,
                            },
                        }),
                    ],
                    four: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Squads',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-squads',
                            props: {
                                apiData,
                                competition,
                                season,
                            },
                        }),
                    ],
                    five: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'AFL Fixtures',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-fixtures-widget',
                            props: {},
                        }),
                    ],
                    six: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'Ladder',
                                },
                            },
                        }),
                        layout.component({
                            type: 'afl-match-ladder-widget',
                            props: { competition, season },
                        }),
                    ],
                    seven: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'AFL Videos',
                                    link: 'https://7news.com.au/video/sport/afl',
                                },
                            },
                        }),
                        layout.component({
                            type: 'video-hub-topic',
                            props: {
                                adUnitPath: adUnitPath,
                                dataDefinitionArgs: {
                                    type: 'video-listing',
                                    topics: ['sport/afl'],
                                    paging: {
                                        page: 1,
                                        pageSize: 10,
                                    },
                                },
                            },
                        }),
                    ],
                    eight: [
                        layout.component({
                            type: 'horizontal-pill-menu',
                            props: {
                                data: {
                                    type: 'single',
                                    name: 'AFL News',
                                    link: '/sport/afl',
                                },
                            },
                        }),
                        layout.component({
                            type: 'six-card-collection',
                            props: {
                                isSubtopicPage: true,
                                verticalSpacing: 'xl',
                                loadMoreEnabled: true,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: ['sport/afl'],
                                    paging: {
                                        page: 1,
                                        pageSize: 6,
                                    },
                                },
                            },
                        }),
                    ],
                    nine: [],
                },
            }),
            box({
                propOverrides: {
                    horizontalGutters: 'lg',
                    verticalGutters: ['unset', 'lg'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'promo-cards-seven',
                        props: {
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: 'afl-promo-cards',
                                offset: 0,
                                pageSize: 3,
                            },
                        },
                    }),
                ],
            }),

            box({
                propOverrides: {
                    horizontalGutters: 'md',
                    verticalGutters: ['unset', 'md'],
                    containerWidth:
                        metrics.sevennews.siteMetrics.sevenEntMainContentWidth,
                },
                main: [
                    layout.component({
                        type: 'ad-unit',
                        props: {
                            noticePosition: 'above-center',
                            hiddenUntilLoaded: false,
                            padding: [metrics.sevennews.margins.md, 0, 0, 0],
                            slot: {
                                id: 'leaderboard-billboard-two',
                                size: 'leaderboardBillboard',
                            },
                            adType: 'inline',
                        },
                    }),
                    mobileAdTwo,
                    layout.component({
                        type: 'taboola',
                        props: {
                            taboolaContainerId:
                                'taboola-below-category-thumbnails',
                            locationType: 'category',
                            isFourColumns: isTabletOrMobileViewport()
                                ? false
                                : true,
                        },
                    }),
                ],
            }),
        ],
    }
}
