import React from 'react'
import { ThemeMargins } from '../../__styling'
import { SkeletonCard } from '../cards'
import {
    StyledNewsJustInCarouselWrapper,
    StyledSkeletonWrapper,
} from './NewsJustInCarousel.styled'

interface NewsJustInCarouselSkeletonProps {
    verticalSpacing: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
}

export const NewsJustInCarouselSkeleton: React.FC<NewsJustInCarouselSkeletonProps> =
    ({ verticalSpacing, verticalGutters }) => {
        return (
            <StyledNewsJustInCarouselWrapper
                verticalSpacing={verticalSpacing}
                verticalGutters={verticalGutters}
                isSkeleton={true}
            >
                <StyledSkeletonWrapper>
                    {[...new Array(4)].map(() => {
                        return (
                            <SkeletonCard
                                showTimeToRead={true}
                                overrideWidth={340}
                            />
                        )
                    })}
                </StyledSkeletonWrapper>
            </StyledNewsJustInCarouselWrapper>
        )
    }
