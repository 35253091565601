import React from 'react'
import { LatestDeliveriesWidgetContainer } from './LatestDeliveriesWidget.styled'

export interface LatestDeliveriesWidgetProps {
    competition: string
    season: string
    match: string
}
export const LatestDeliveriesWidget = ({
    competition,
    season,
    match,
}: LatestDeliveriesWidgetProps) => {
    return (
        <LatestDeliveriesWidgetContainer>
            <opta-widget
                widget="latest_deliveries"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                fast_data="true"
                limit="19"
                direction="ltr"
                show_innings_breakdown="false"
                show_crests="true"
                team_naming="full"
                update_end_animation="true"
                show_live="false"
                show_logo="false"
                show_title="false"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </LatestDeliveriesWidgetContainer>
    )
}
