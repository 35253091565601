import React from 'react'
import { useOptaWidgetStart } from '../useOptaWidgetStart'
import { CommentaryWidgetContainer } from './CommentaryWidget.styled'

export interface CommentaryWidgetProps {
    competition: string
    season: string
    match: string
}
export const CommentaryWidget = ({
    competition,
    season,
    match,
}: CommentaryWidgetProps) => {
    useOptaWidgetStart()
    return (
        <CommentaryWidgetContainer>
            <opta-widget
                widget="commentary"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                order_by="time_descending"
                show_event_icons="true"
                show_minor_events="true"
                show_live="true"
                show_logo="true"
                show_title="false"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </CommentaryWidgetContainer>
    )
}
