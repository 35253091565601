import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getFinancePageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Finance',
        id: 'finance',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Finance',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        contentToFormat: [
            {
                pillMenuName: 'Retail',
                pillMenuLink: '/business/retail',
                collectionTopics: ['business/retail'],
            },
            {
                pillMenuName: 'Property',
                pillMenuLink: '/business/property',
                collectionTopics: ['business/property'],
            },
            {
                pillMenuName: 'Banking',
                pillMenuLink: '/business/banking',
                collectionTopics: ['business/banking'],
            },
            {
                pillMenuName: 'Economy',
                pillMenuLink: '/business/economy',
                collectionTopics: ['business/economy'],
            },
            {
                pillMenuName: 'Markets',
                pillMenuLink: '/business/markets',
                collectionTopics: ['business/markets'],
            },
        ],
        promoCardName: '7news-finance-promo-cards',
        getAdTargeting,
        features,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            includeSubTopics: true,
            topics: ['business/finance'],
            paging: {
                page: 1,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: ['business/finance'],
            paging: {
                pageOffset: 5,
                page: 1,
                pageSize: 6,
            },
        },
    })

    return topicCurationPage
}
