import styled, { CSSObject } from '@emotion/styled'
import { Product, withClass } from '@news-mono/web-common'
import {
    breakpoint,
    breakpoints,
    Breakpoints,
    calcEm,
    calcRem,
    colors,
    ContainerWidth,
    metrics,
    themedValue,
    ThemeMargins,
} from '../../__styling'
import { BreakPointProps, SpacingProps } from './ArticleDoublet'

export interface StyledArticleDoubletProps {
    hasBackgroundFill?: boolean
    horizontalGutters?: keyof ThemeMargins
    horizontalSpacing?: keyof ThemeMargins
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent: boolean
    breakpoints?: BreakPointProps
}

const getStylesFromProps = (
    props: SpacingProps,
    kind: Product,
): Array<CSSObject | false | undefined> => {
    const margins = metrics[kind].margins

    const horizontalSpacing =
        props.horizontalSpacing && calcRem(margins[props.horizontalSpacing])
    const verticalSpacing =
        props.verticalSpacing && calcRem(margins[props.verticalSpacing])
    const horizontalGutters =
        props.horizontalGutters && calcRem(margins[props.horizontalGutters])
    const topVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[0]])
    const bottomVerticalGutters =
        props.verticalGutters && calcRem(margins[props.verticalGutters[1]])

    return [
        {
            marginLeft: horizontalSpacing,
            marginRight: horizontalSpacing,
            marginBottom: verticalSpacing,
            marginTop: verticalSpacing,
            paddingLeft: horizontalGutters,
            paddingRight: horizontalGutters,
            paddingTop: topVerticalGutters,
            paddingBottom: bottomVerticalGutters,
        },
    ]
}

type BreakpointKey = keyof Breakpoints

export const StyledArticleDoubletWrap = styled(
    'div',
)<StyledArticleDoubletProps>(
    ({}) => ({
        margin: '0 auto',
        width: '100%',
    }),
    ({ breakpoints }) =>
        ({
            theme,
            verticalSpacing,
            horizontalGutters,
            verticalGutters,
            containerWidth,
            hasBackgroundFill,
            horizontalSpacing,
        }) => {
            const breakpointStyles = Object.keys(
                breakpoints || {},
            ).reduce<CSSObject>((styles, key) => {
                const passedBreakpoints = breakpoints as BreakPointProps
                const props = passedBreakpoints[key as BreakpointKey]
                if (props) {
                    styles[breakpoint(key as BreakpointKey)] =
                        getStylesFromProps(props, theme.kind)
                }
                return styles
            }, {})

            return [
                ...getStylesFromProps(
                    {
                        horizontalGutters,
                        verticalGutters,
                        verticalSpacing,
                        horizontalSpacing,
                    },
                    theme.kind,
                ),
                {
                    maxWidth: containerWidth,
                    backgroundColor: themedValue(theme, {
                        thewest: undefined,
                        fallback: hasBackgroundFill ? colors.white : undefined,
                    }),
                },
                breakpointStyles,
            ]
        },
)
const sidebarWidth = 300
export const StyledArticleDoublet = styled('div')<StyledArticleDoubletProps>(
    ({}) => {
        return {
            display: 'grid',
            gridTemplateColumns: '100%',
            [breakpoint('md')]: {
                gridTemplateColumns: `auto ${calcRem(sidebarWidth)}`,
                gridGap: calcRem(70),
            },
            '@media print': {
                display: 'block',
            },
        }
    },

    ({ theme, stackSidebarContent }) =>
        // required to make tablet breakpoint 50/50 width between 768 and 800
        // whens stack sidebar content is true, this functionality is notrequired for IE
        // fallbacks as we wont be seeing alot of tablet sizes on IE
        theme.kind === 'sevennews' &&
        stackSidebarContent === true && {
            //prettier-ignore
            [`@media (min-width: ${calcEm(breakpoints['sm'],)}) and (max-width: ${calcEm(800)})`]: {
                    gridTemplateColumns: '1fr',
                },
        },
)
StyledArticleDoublet.displayName = 'StyledArticleDoublet'

export const StyledMain = styled('div')({})
StyledMain.displayName = 'StyledMain'

export interface StyledSidebarProps extends StyledArticleDoubletProps {}
export const StyledSidebar = withClass('hide-print')(
    styled('div')<StyledArticleDoubletProps>(
        ({ stackSidebarContent, theme }) => {
            return {
                display: stackSidebarContent ? 'block' : 'none',
                transform: 'translateZ(0)',

                [breakpoint(stackSidebarContent ? 'sm' : 'md')]: {
                    display: 'block',
                    boxSizing: 'content-box',
                },
            }
        },
    ),
)
StyledSidebar.displayName = 'StyledSidebar'

export const StyledMobileRail = withClass('hide-print')(
    styled('div')<StyledArticleDoubletProps>(({ stackSidebarContent }) => {
        return {
            display: stackSidebarContent ? 'none' : 'block',

            [breakpoint('md')]: {
                display: 'none',
            },
        }
    }),
)
