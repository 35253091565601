import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    PlayerCompareWidget,
    PlayerCompareWidgetProps,
} from './PlayerCompareWidget'

export const PlayerCompareWidgetRegistration = createRegisterableComponent(
    'player-compare-widget',
    (props: PlayerCompareWidgetProps, services) => {
        return <PlayerCompareWidget {...props} />
    },
)
