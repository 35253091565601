import styled from '@emotion/styled'
import { breakpoint, calcRem, fonts } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledNewsletterPreferencesContainer = styled('div')({
    display: 'flex',
    maxWidth: '100%',
    width: 'auto',
    margin: '0 auto',
    flexDirection: 'column',
    [breakpoint('md')]: {
        flexDirection: 'row',
        maxWidth: calcRem(1200),
    },
})

export const StyledPreferencesHeroDesktop = styled('img')({
    display: 'none',
    height: '100%',
    [breakpoint('md')]: {
        width: '33%',
        display: 'block',
    },
})

export const StyledPreferencesHeroMobile = styled('img')({
    width: '100%',
    [breakpoint('md')]: {
        display: 'none',
    },
})

export const StyledPreferencesFormContainer = styled('div')(() => ({
    width: '100%',
    [breakpoint('md')]: {
        width: '66%',
        paddingLeft: calcRem(32),
    },
}))

export const StyledHeading = styled('h2')(() => ({
    color: tokens.sevennews.colors.palette.charade,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(27),
    fontStyle: 'normal',
    fontWeight: 400,
    display: 'none',
    [breakpoint('md')]: {
        display: 'block',
    },
}))

export const StyledHorizontalLine = styled('hr')(() => ({
    marginBottom: calcRem(16),
    marginTop: 0,
    borderTop: `2px solid ${tokens.sevennews.colors.palette.newsletters.borderBlackAlternative}`,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    [breakpoint('md')]: {
        maxWidth: calcRem(714),
        marginLeft: '0',
    },
}))

export const StyledDescription = styled('div')(() => ({
    color: tokens.sevennews.colors.palette.nero,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    backgroundColor: tokens.sevennews.colors.palette.newsletters.darkWhite,
    padding: calcRem(24),
    textAlign: 'center',

    [breakpoint('md')]: {
        fontSize: calcRem(14),
        padding: 0,
        paddingBottom: calcRem(16),
        backgroundColor: 'initial',
        textAlign: 'left',
    },
}))

export const StyledPreferencesForm = styled('form')(() => ({
    display: 'block',
    position: 'relative',
}))

interface StyledInputContainerProps {
    enableOpacity: boolean
}

export const StyledInputContainer = styled('div')<StyledInputContainerProps>(
    ({ enableOpacity }) => ({
        ...(enableOpacity
            ? {
                  opacity: 0.4,
              }
            : {}),
        '.swiper-slide': {
            width: 'auto',
            marginRight: calcRem(16),
        },
    }),
)

export const StyledCheckboxContainer = styled('div')(() => ({}))

export const StyledNewsletterLabel = styled('label')(() => ({
    display: 'block',
    position: 'relative',
    '&:hover': {
        cursor: 'pointer',
    },
}))

export const StyledNewsletterImage = styled('img')({})

export const StyledLabelText = styled('div')(() => ({
    width: calcRem(130),
    color: tokens.sevennews.colors.palette.nero,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(11),
    fontStyle: 'normal',
    fontWeight: 300,
    marginTop: calcRem(12),
    lineHeight: 'normal',
}))

export const StyledInputCheckbox = styled('input')(() => ({
    display: 'none',
}))

interface StyledVisualCheckboxProps {
    isSelected: boolean
}

export const StyledVisualCheckbox = styled('div')<StyledVisualCheckboxProps>(
    ({ isSelected }) => ({
        display: 'block',
        position: 'relative',
        bottom: calcRem(40),
        left: calcRem(65),
        '&::before': {
            ...(isSelected
                ? {
                      color: tokens.sevennews.colors.palette.white,
                      borderColor: tokens.sevennews.colors.palette.white,
                  }
                : {}),

            borderRadius: '50%',
            top: '.15rem',
            left: '-.5rem',
            width: '1.25rem',
            height: '1.25rem',
            backgroundColor: tokens.sevennews.colors.palette.white,
            position: 'absolute',
            display: 'block',
            content: `""`,
            border: `1px solid ${tokens.sevennews.colors.palette.newsletters.borderBlackAlternative}`,
        },
        '&::after': {
            ...(isSelected
                ? {
                      left: '-0.06rem',
                      top: '0.35rem',
                      width: calcRem(6),
                      height: calcRem(12),
                      border: `solid ${tokens.sevennews.colors.palette.red}`,
                      borderWidth: '0px 3px 3px 0',
                      borderRadius: '20%',
                      transform: 'rotate(45deg)',
                      backgroundColor: tokens.sevennews.colors.palette.white,
                  }
                : {}),

            color: tokens.sevennews.colors.palette.white,
            position: 'absolute',
            display: 'block',
            content: `""`,
            background: '50%/50% 50% no-repeat',
        },
    }),
)

export const StyledSubmitButton = styled('button')({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: calcRem(10),
    borderRadius: calcRem(4),
    background: tokens.sevennews.colors.palette.red,
    color: tokens.sevennews.colors.palette.white,
    border: 0,
    marginTop: calcRem(24),
    cursor: 'pointer',
    minHeight: calcRem(40),
    fontSize: calcRem(16),
    marginBottom: calcRem(10),
    '&:disabled': {
        opacity: 0.4,
        cursor: 'default',
    },
    [breakpoint('md')]: {
        width: calcRem(324),
    },
})

export const StyledSubmitMessage = styled('span')(() => ({
    color: tokens.sevennews.colors.palette.red,
    fontFamily: fonts.sevennews.sansSerif,
    fontSize: calcRem(14),
    fontStyle: 'normal',
    fontWeight: 400,
}))

export const StyledSubmitSuccessMessage = styled(StyledSubmitMessage)(() => ({
    fontSize: calcRem(16),
    fontWeight: 700,
}))
