import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getLifestylePageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Lifestyle',
        id: 'lifestyle',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Lifestyle',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        contentToFormat: [
            {
                pillMenuName: 'Trending',
                pillMenuLink: '',
                collectionTopics: ['lifestyle'],
            },
            {
                pillMenuName: 'Food',
                pillMenuLink: '/lifestyle/food',
                collectionTopics: ['lifestyle/food'],
            },
            {
                pillMenuName: 'Health & Wellbeing',
                pillMenuLink: '/lifestyle/health-wellbeing',
                collectionTopics: ['lifestyle/health-wellbeing'],
            },
            {
                pillMenuName: 'Human Interest',
                pillMenuLink: '/lifestyle/human-interest',
                collectionTopics: ['lifestyle/human-interest'],
            },
            {
                pillMenuName: 'Sponsored',
                pillMenuLink: '/lifestyle/sponsored',
                collectionTopics: ['lifestyle/sponsored'],
            },
            {
                pillMenuName: 'Shopping',
                pillMenuLink: '/lifestyle/shopping',
                collectionTopics: ['lifestyle/shopping'],
            },
            {
                pillMenuName: 'Parenting',
                pillMenuLink: '/lifestyle/parenting',
                collectionTopics: ['lifestyle/parenting'],
            },
        ],
        promoCardName: '7news-lifestyle-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
