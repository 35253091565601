import React from 'react'
import {
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import { CollectionRouteProps } from '../../../collections'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../../data-controllers/LoadMore/LoadMore'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { ThemeMargins, ThemeOverrideTypes } from '../../../__styling'
import { FiveCardCollection } from './FiveCardCollection'
import { useProduct } from '../../../__product'
import { StyledFiveCardCollectionWrapper } from './FiveCardCollection.styled'
import { chunkArray } from '../helpers'
import { FiveCardCollectionSkeleton } from './FiveCardCollectionSkeleton'

export interface FiveCardCollectionRouteProps
    extends CollectionRouteProps,
        LoadMoreRouteProps,
        ResponsiveContainer {
    themeOverride?: ThemeOverrideTypes
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    verticalSpacing?: keyof ThemeMargins
}

export const FiveCardCollectionRegistration =
    createRegisterableComponentWithData(
        'five-card-collection',
        ContentDataDefinitionLoader,
        (props: FiveCardCollectionRouteProps, data, services) => {
            const product = useProduct()

            if (
                !data.loaded ||
                (data.loaded && data.result.publications.length <= 0)
            ) {
                return (
                    <FiveCardCollectionSkeleton
                        verticalGutters={props.verticalGutters}
                        verticalSpacing={props.verticalSpacing}
                    />
                )
            }

            return (
                <StyledFiveCardCollectionWrapper>
                    <LoadMore
                        dataDefinition={data.dataDefinitionArgs}
                        services={services}
                        loadMoreEnabled={!!props.loadMoreEnabled}
                        data={data}
                        onLoadMore={(loadMoreData) => {
                            services.onEvent(
                                createCollectionAvailableEvent(
                                    loadMoreData.result.publications,
                                    'FiveCardCollection',
                                    product,
                                    services.onEvent,
                                ),
                            )
                        }}
                        render={(loadMoreData) => {
                            const additionalCollectionsChunked =
                                loadMoreData.loaded
                                    ? chunkArray(
                                          [
                                              ...loadMoreData.result
                                                  .publications,
                                          ].slice(5),
                                          5,
                                          true,
                                      )
                                    : []
                            return (
                                <>
                                    <FiveCardCollection
                                        items={
                                            data.loaded
                                                ? {
                                                      loaded: true,
                                                      result: data.result
                                                          .publications,
                                                  }
                                                : { loaded: false }
                                        }
                                        onEvent={services.onEvent}
                                        verticalGutters={props.verticalGutters}
                                        verticalSpacing={props.verticalSpacing}
                                    />
                                    {additionalCollectionsChunked.length
                                        ? additionalCollectionsChunked.map(
                                              (fiveCardCollectionContent) => {
                                                  return (
                                                      <FiveCardCollection
                                                          items={
                                                              loadMoreData.loaded
                                                                  ? {
                                                                        loaded: true,
                                                                        result: fiveCardCollectionContent,
                                                                    }
                                                                  : {
                                                                        loaded: false,
                                                                    }
                                                          }
                                                          onEvent={
                                                              services.onEvent
                                                          }
                                                          verticalGutters={
                                                              props.verticalGutters
                                                          }
                                                          verticalSpacing={
                                                              props.verticalSpacing
                                                          }
                                                      />
                                                  )
                                              },
                                          )
                                        : null}
                                </>
                            )
                        }}
                    />
                </StyledFiveCardCollectionWrapper>
            )
        },
    )
