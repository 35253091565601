import { PillItem } from './subtopicList'

export type VideoPillTopics =
    | 'news'
    | 'lifestyle'
    | 'sport'
    | 'entertainment'
    | 'politics'
    | 'business'
    | 'technology'
    | 'weather'
    | 'video'
    | 'travel'
    | 'sunrise'
    | 'the-morning-show'
    | 'spotlight'

type PillNavigation = {
    [K in VideoPillTopics]: PillItem[]
}

export const videoPilsMenu: PillNavigation = {
    video: [
        { link: '/video', name: 'Top Videos', isMainTopic: true },
        { link: '/video/sport', name: 'Sport Videos' },
        { link: '/video/entertainment', name: 'Entertainment Videos' },
        { link: '/video/lifestyle', name: 'Lifestyle Videos' },
        { link: '/video/news', name: 'News Videos' },
        { link: '/video/politics', name: 'Politics Videos' },
        { link: '/video/sunrise', name: 'Sunrise Videos' },
        { link: '/video/the-morning-show', name: 'The Morning Show Videos' },
        { link: '/video/business', name: 'Business Videos' },
        { link: '/video/technology', name: 'Technology Videos' },
        { link: '/video/travel', name: 'Travel Videos' },
        { link: '/video/world', name: 'World Videos' },
    ],
    lifestyle: [
        {
            name: 'Lifestyle',
            link: '/video/lifestyle',
            isMainTopic: true,
        },
        {
            name: 'Food',
            link: '/video/lifestyle/food',
        },
        {
            name: 'Health and Wellbeing',
            link: '/video/lifestyle/health-wellbeing',
        },
        {
            name: 'Real Estate',
            link: '/video/lifestyle/real-estate',
        },
        {
            name: 'Shopping',
            link: '/video/lifestyle/shopping',
        },
        {
            name: 'Pets',
            link: '/video/lifestyle/pets',
        },
        {
            name: 'Parenting',
            link: '/video/lifestyle/parenting',
        },
        {
            name: 'Travel',
            link: '/video/travel',
        },
        {
            name: 'Personal Finance',
            link: '/video/lifestyle/personal-finance',
        },
    ],
    travel: [
        {
            name: 'Travel',
            link: '/video/travel',
            isMainTopic: true,
        },
        {
            name: 'Lifestyle',
            link: '/video/lifestyle',
        },
        {
            name: 'Food',
            link: '/video/lifestyle/food',
        },
        {
            name: 'Health and Wellbeing',
            link: '/video/lifestyle/health-wellbeing',
        },
        {
            name: 'Real Estate',
            link: '/video/lifestyle/real-estate',
        },
        {
            name: 'Shopping',
            link: '/video/lifestyle/shopping',
        },
        {
            name: 'Pets',
            link: '/video/lifestyle/pets',
        },
        {
            name: 'Parenting',
            link: '/video/lifestyle/parenting',
        },

        {
            name: 'Personal Finance',
            link: 'video/lifestyle/personal-finance',
        },
    ],
    entertainment: [
        {
            name: 'Entertainment',
            link: '/video/entertainment',
            isMainTopic: true,
        },
        {
            name: 'Sunrise',
            link: '/video/sunrise',
        },

        {
            name: 'The Morning Show',
            link: '/video/the-morning-show',
        },

        {
            name: 'Spotlight',
            link: '/video/spotlight',
        },
        {
            name: 'Home And Away',
            link: '/video/entertainment/home-and-away',
        },

        {
            name: 'Big Brother',
            link: '/video/entertainment/big-brother-australia',
        },

        {
            name: 'My Kitchen Rules',
            link: '/video/entertainment/my-kitchen-rules',
        },

        {
            name: 'TV',
            link: '/video/entertainment/tv',
        },

        {
            name: 'Celebrity',
            link: '/video/entertainment/celebrity',
        },

        {
            name: 'Music',
            link: '/video/entertainment/music',
        },

        {
            name: 'Movies',
            link: '/video/entertainment/movies',
        },

        {
            name: 'The Voice Australia',
            link: '/video/entertainment/the-voice-australia',
        },

        {
            name: 'SAS Australia',
            link: '/video/entertainment/sas-australia',
        },

        {
            name: 'Farmer Wants a Wife',
            link: '/video/entertainment/farmer-wants-a-wife',
        },
    ],

    sport: [
        {
            name: 'Sport',
            link: '/video/sport',
            isMainTopic: true,
        },
        {
            name: 'AFL',
            link: '/video/sport/afl',
        },
        {
            name: 'Cricket',
            link: '/video/sport/cricket',
        },
        {
            name: 'AFLW',
            link: '/video/sport/aflw',
        },
        {
            name: 'NRL',
            link: '/video/sport/rugby-league',
        },
        {
            name: 'Soccer',
            link: '/video/sport/soccer',
        },
        {
            name: 'Motorsport',
            link: '/video/sport/motorsport',
        },
        {
            name: 'Horse Racing',
            link: '/video/sport/horse-racing',
        },
        {
            name: 'Tennis',
            link: '/video/sport/tennis',
        },
        {
            name: 'UFC',
            link: '/video/sport/ufc',
        },
    ],
    politics: [
        {
            name: 'Politics',
            link: '/video/politics',
            isMainTopic: true,
        },
        {
            name: 'Federal Politics',
            link: '/video/politics/federal-politics',
        },
        {
            name: 'State Politics',
            link: '/video/politics/state-politics',
        },
        {
            name: 'World Politics',
            link: '/video/politics/world-politics',
        },
    ],
    business: [
        {
            name: 'Business',
            link: '/video/business',
            isMainTopic: true,
        },
        {
            name: 'Finance',
            link: '/video/business/finance',
        },
        {
            name: 'Banking',
            link: '/video/business/banking',
        },
        {
            name: 'Retail',
            link: '/video/business/retail',
        },
        {
            name: 'Economy',
            link: '/video/business/economy',
        },
        {
            name: 'Markets',
            link: '/video/business/markets',
        },
    ],
    technology: [
        {
            name: 'Technology',
            link: '/video/technology',
            isMainTopic: true,
        },
        {
            name: 'Reviews',
            link: '/video/technology/reviews',
        },
        {
            name: 'Space',
            link: '/video/technology/space',
        },
        {
            name: 'Gaming',
            link: '/video/technology/gaming',
        },
        {
            name: 'Science',
            link: '/video/technology/science',
        },
        {
            name: 'Smartphones',
            link: '/video/technology/smartphones',
        },
        {
            name: 'Gadgets',
            link: '/video/technology/gadgets',
        },
    ],
    weather: [
        {
            name: 'Weather',
            link: '/video/weather',
            isMainTopic: true,
        },
        {
            name: 'Natural disasters',
            link: '/video/weather/natural-disasters',
        },
        {
            name: 'BOM',
            link: '/video//weather/bom',
        },
        {
            name: 'Environment',
            link: '/video//weather/environment',
        },
    ],
    news: [
        {
            name: 'News',
            link: '/video/news',
            isMainTopic: true,
        },
        {
            name: 'NSW',
            link: '/video/news/nsw',
        },
        {
            name: 'QLD',
            link: '/video/news/qld',
        },
        {
            name: 'World',
            link: '/video/news/world',
        },
        {
            name: 'WA',
            link: '/video/news/wa',
        },
        {
            name: 'VIC',
            link: '/video/news/vic',
        },
        {
            name: 'SA',
            link: '/video/news/sa',
        },
        {
            name: 'NT',
            link: '/video/news/nt',
        },
        {
            name: 'TAS',
            link: '/video/news/tas',
        },
        {
            name: 'ACT',
            link: '/video/news/act',
        },
    ],
    sunrise: [
        {
            name: 'Sunrise',
            link: '/video/sunrise',
            isMainTopic: true,
        },
        {
            name: 'Entertainment',
            link: '/video/entertainment',
        },
        {
            name: 'The Morning Show',
            link: '/video/the-morning-show',
        },

        {
            name: 'Spotlight',
            link: '/video/spotlight',
        },
        {
            name: 'Home And Away',
            link: '/video/entertainment/home-and-away',
        },

        {
            name: 'Big Brother',
            link: '/video/entertainment/big-brother-australia',
        },

        {
            name: 'My Kitchen Rules',
            link: '/video/entertainment/my-kitchen-rules',
        },

        {
            name: 'TV',
            link: '/video/entertainment/tv',
        },

        {
            name: 'Celebrity',
            link: '/video/entertainment/celebrity',
        },

        {
            name: 'Music',
            link: '/video/entertainment/music',
        },

        {
            name: 'Movies',
            link: '/video/entertainment/movies',
        },

        {
            name: 'The Voice Australia',
            link: '/video/entertainment/the-voice-australia',
        },

        {
            name: 'SAS Australia',
            link: '/video/entertainment/sas-australia',
        },

        {
            name: 'Farmer Wants a Wife',
            link: '/video/entertainment/farmer-wants-a-wife',
        },
    ],
    'the-morning-show': [
        {
            name: 'The Morning Show',
            link: '/video/the-morning-show',
            isMainTopic: true,
        },
        {
            name: 'Entertainment',
            link: '/video/entertainment',
        },
        {
            name: 'Sunrise',
            link: '/video/sunrise',
        },
        {
            name: 'Spotlight',
            link: '/video/spotlight',
        },
        {
            name: 'Home And Away',
            link: '/video/entertainment/home-and-away',
        },

        {
            name: 'Big Brother',
            link: '/video/entertainment/big-brother-australia',
        },

        {
            name: 'My Kitchen Rules',
            link: '/video/entertainment/my-kitchen-rules',
        },

        {
            name: 'TV',
            link: '/video/entertainment/tv',
        },

        {
            name: 'Celebrity',
            link: '/video/entertainment/celebrity',
        },

        {
            name: 'Music',
            link: '/video/entertainment/music',
        },

        {
            name: 'Movies',
            link: '/video/entertainment/movies',
        },

        {
            name: 'The Voice Australia',
            link: '/video/entertainment/the-voice-australia',
        },

        {
            name: 'SAS Australia',
            link: '/video/entertainment/sas-australia',
        },

        {
            name: 'Farmer Wants a Wife',
            link: '/video/entertainment/farmer-wants-a-wife',
        },
    ],
    spotlight: [
        {
            name: 'Spotlight',
            link: '/video/spotlight',
            isMainTopic: true,
        },

        {
            name: 'Entertainment',
            link: '/video/entertainment',
        },

        {
            name: 'Sunrise',
            link: '/video/sunrise',
        },

        {
            name: 'The Morning Show',
            link: '/video/the-morning-show',
        },

        {
            name: 'Home And Away',
            link: '/video/entertainment/home-and-away',
        },

        {
            name: 'Big Brother',
            link: '/video/entertainment/big-brother-australia',
        },

        {
            name: 'My Kitchen Rules',
            link: '/video/entertainment/my-kitchen-rules',
        },

        {
            name: 'TV',
            link: '/video/entertainment/tv',
        },

        {
            name: 'Celebrity',
            link: '/video/entertainment/celebrity',
        },

        {
            name: 'Music',
            link: '/video/entertainment/music',
        },

        {
            name: 'Movies',
            link: '/video/entertainment/movies',
        },

        {
            name: 'The Voice Australia',
            link: '/video/entertainment/the-voice-australia',
        },

        {
            name: 'SAS Australia',
            link: '/video/entertainment/sas-australia',
        },

        {
            name: 'Farmer Wants a Wife',
            link: '/video/entertainment/farmer-wants-a-wife',
        },
    ],
}
