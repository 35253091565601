import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { calcRem, colors } from '../../__styling'

export const StyledBreadcrumbContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
})

export const StyledBreadcrumb = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    gap: calcRem(10),
})

export const StyledLink = styled(WebLink)({
    fontSize: calcRem(12),
    fontWeight: 'normal',
    display: 'inline',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none',
    color: colors.sevennews.nero70,
    '&:not(:last-of-type)': {
        '&::after': {
            position: 'absolute',
            content: `''`,
            height: '60%',
            right: calcRem(-6),
            top: '20%',
            width: calcRem(1),
            background: colors.sevennews.nero70,
        },
    },
    '&:hover': {
        textDecoration: 'underline',
    },
})
