import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getPoliticsPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic: Topic = {
        title: 'Politics',
        id: 'politics',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Politics',
        seoDescription: resolution.resolution?.topic?.seoDescription,

        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        contentToFormat: [
            {
                pillMenuName: 'Federal Politics',
                pillMenuLink: '/politics/federal-politics',
                collectionTopics: ['politics/federal-politics'],
            },
            {
                pillMenuName: 'World Politics',
                pillMenuLink: '/politics/world-politics',
                collectionTopics: ['politics/world-politics'],
            },
            {
                pillMenuName: 'NSW',
                pillMenuLink: '/politics/nsw',
                collectionTopics: ['politics/nsw'],
            },
            {
                pillMenuName: 'VIC',
                pillMenuLink: '/politics/vic',
                collectionTopics: ['politics/vic'],
            },
            {
                pillMenuName: 'QLD',
                pillMenuLink: '/politics/qld',
                collectionTopics: ['politics/qld'],
            },
            {
                pillMenuName: 'SA',
                pillMenuLink: '/politics/sa',
                collectionTopics: ['politics/sa'],
            },
            {
                pillMenuName: 'WA',
                pillMenuLink: '/politics/wa',
                collectionTopics: ['politics/wa'],
            },
            {
                pillMenuName: 'ACT',
                pillMenuLink: '/politics/act',
                collectionTopics: ['politics/act'],
            },
            {
                pillMenuName: 'TAS',
                pillMenuLink: '/politics/tas',
                collectionTopics: ['politics/tas'],
            },
            {
                pillMenuName: 'NT',
                pillMenuLink: '/politics/nt',
                collectionTopics: ['politics/nt'],
            },
        ],
        promoCardName: '7news-politics-promo-cards',
        getAdTargeting,
        features,
        dataDefinitionArgsOverrideHero: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                page: 1,
                pageSize: 5,
            },
        },
        dataDefinitionArgsOverrideSixCard: {
            type: 'listing',
            includeSubTopics: true,
            topics: [topic.id],
            paging: {
                pageOffset: 5,
                page: 1,
                pageSize: 6,
            },
        },
    })

    return topicCurationPage
}
