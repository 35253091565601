import styled from '@emotion/styled'
import { calcRem } from '../../__styling'

export const StyledGalleryThumbnailsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: calcRem(24),
}))

export const StyledButtonCenterContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}))
