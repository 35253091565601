import {
    CardItem,
    CollectionDataLoaded,
    CollectionEvent,
    isPublicationCardItem,
} from '@news-mono/web-common'
import React from 'react'

import { SearchForm } from '../SearchForm/SearchForm'
import {
    StyledMessage,
    StyledPillMenuWrapper,
    StyledSearchResultsContainer,
    StyledSearchTerm,
    StyledTopicPill,
} from './SearchResults.styled'

import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { HorizontalDivider } from '../../HorizontalDivider/HorizontalDivider'
import { SearchResultCard } from '../../cards/SearchResultCard/SearchResultCard'
import { frequentlyVisitedTopics } from './frequentlyVisitedTopics'

import { useHistory } from 'react-router'

export interface SearchResultsProps {
    searchTerm: string
    onEvent: (event: CollectionEvent) => void
    data: MaybeLoaded<CollectionDataLoaded>
}

export const SearchResults = ({
    searchTerm,
    onEvent,
    data,
}: SearchResultsProps) => {
    const history = useHistory()
    if (
        data.loaded &&
        (data.result.publications.length > 0 ||
            (data.result.videos && data.result.videos.length > 0))
    ) {
        const items = sortResults(data.result.videos, data.result.publications)
        return (
            <StyledSearchResultsContainer>
                <SearchForm mountsWithValue={searchTerm} isLarge={true} />

                <StyledMessage>
                    Results for{' '}
                    <StyledSearchTerm>{searchTerm}</StyledSearchTerm>
                </StyledMessage>

                {items.map((publication, index) => {
                    return (
                        <>
                            <SearchResultCard
                                item={{
                                    loaded: true,
                                    result: publication,
                                }}
                                cardNumber={index}
                                onEvent={onEvent}
                                isVideo={!!publication.id.match(/^(\d{13})?$/)}
                            />
                            <HorizontalDivider />
                        </>
                    )
                })}
            </StyledSearchResultsContainer>
        )
    } else if (searchTerm) {
        return (
            <StyledSearchResultsContainer>
                <SearchForm mountsWithValue={searchTerm} isLarge={true} />

                <StyledMessage>
                    Sorry, we couldn't find any search results for
                    <StyledSearchTerm> {searchTerm}</StyledSearchTerm>
                </StyledMessage>
            </StyledSearchResultsContainer>
        )
    } else {
        return (
            <StyledSearchResultsContainer>
                <SearchForm mountsWithValue={searchTerm} isLarge={true} />

                <StyledPillMenuWrapper>
                    {frequentlyVisitedTopics.map((topic, index) => (
                        <StyledTopicPill
                            key={index}
                            onClick={() =>
                                history.push(
                                    `/search?search=${encodeURIComponent(
                                        topic.keyword,
                                    )}`,
                                )
                            }
                            isLeadPill={false}
                        >
                            {topic.name}
                        </StyledTopicPill>
                    ))}
                </StyledPillMenuWrapper>
            </StyledSearchResultsContainer>
        )
    }
}
SearchResults.displayName = 'SearchResults'

function sortResults(
    videos: CardItem[] | undefined,
    publications: CardItem[],
): CardItem[] {
    const cardItems = videos ? publications.concat(videos) : publications
    cardItems.sort((a, b) => {
        if (isPublicationCardItem(a) && isPublicationCardItem(b)) {
            if (a.publicationDate && b.publicationDate) {
                return (
                    Date.parse(b.publicationDate) -
                    Date.parse(a.publicationDate)
                )
            }
        }
        return -1
    })
    return cardItems
}
