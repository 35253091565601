import React from 'react'
import { MatchSummaryWidgetContainer } from './MatchSummaryWidget.styled'

export interface MatchSummaryWidgetProps {
    competition: string
    season: string
    match: string
}
export const MatchSummaryWidget = ({
    competition,
    season,
    match,
}: MatchSummaryWidgetProps) => {
    return (
        <MatchSummaryWidgetContainer>
            <opta-widget
                widget="match_summary"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                live="true"
                fast_data="false"
                show_match_header="true"
                show_crests="false"
                show_competition_name="true"
                show_match_description="true"
                show_date="true"
                date_format="dddd D MMMM YYYY HH:mm"
                show_venue="true"
                show_officials="on_field"
                show_toss="true"
                show_innings_breakdown="true"
                show_current_batting="true"
                show_best_batting="1"
                show_best_bowling="1"
                show_state_of_play="true"
                show_lineups="false"
                side_by_side="true"
                team_naming="full"
                player_naming="last_name"
                show_live="false"
                update_end_animation="false"
                show_logo="true"
                show_title="true"
                breakpoints="400, 700"
                sport="cricket"
            ></opta-widget>
        </MatchSummaryWidgetContainer>
    )
}
