import { cx } from '@emotion/css'
import { NavEvent } from '@news-mono/web-common'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SNMainNavId } from '../../navigation/SevenNewsFlyoutNavV2/SevenNewsFlyoutNav2'
import { SevenNewsNavItemV2, SevenNewsNavigationV2 } from '../../routing'
import { SevenNewsHeaderV2 } from '../SevenNewsHeaderV2/SevenNewsHeaderV2'
import {
    StyledAdSevenNews,
    StyledHeader,
    StyledLeaderBoardBillboardWrapper,
} from './SiteHeaderV2.styled'

export interface SevenSiteHeaderV2Props {
    advertisement?: (rendered?: () => void) => JSX.Element | undefined
    v2LeaderBoardAd?: (rendered?: () => void) => JSX.Element | undefined
    className?: string
    onEvent: (event: NavEvent) => void
    navigation: SevenNewsNavigationV2
    globalNavigation: SevenNewsNavItemV2[]
}

export const SevenSiteHeaderV2: React.FC<SevenSiteHeaderV2Props> = ({
    advertisement,
    className,
    onEvent,
    navigation,
    globalNavigation,
    v2LeaderBoardAd,
}) => {
    const adUnit = advertisement ? advertisement() : false
    const adLeaderBordV2 = v2LeaderBoardAd ? v2LeaderBoardAd() : false
    const wrapperRef = useRef<HTMLElement | null>(null)
    const [show, setShow] = useState(true)
    const [lastScrollY, setLastScrollY] = useState(0)

    const headerShowHandler = useCallback(() => {
        if (typeof window !== undefined) {
            if (window.scrollY > 300 && window.scrollY > lastScrollY) {
                setShow(false)
            } else {
                setShow(true)
            }
            setLastScrollY(window.scrollY)
        }
    }, [lastScrollY])

    useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener('scroll', headerShowHandler)
            return () => {
                window.removeEventListener('scroll', headerShowHandler)
            }
        }
    }, [lastScrollY, headerShowHandler])

    return (
        <React.Fragment>
            {adUnit && <StyledAdSevenNews>{adUnit}</StyledAdSevenNews>}
            {adLeaderBordV2 && (
                <StyledLeaderBoardBillboardWrapper>
                    {adLeaderBordV2}
                </StyledLeaderBoardBillboardWrapper>
            )}
            <StyledHeader
                className={cx(className, 'sticky-site-header')}
                ref={wrapperRef}
                showHeader={show}
            >
                <SevenNewsHeaderV2
                    controls={SNMainNavId}
                    navLinks={navigation}
                    onEvent={onEvent}
                    secondaryNavLinks={globalNavigation}
                />
            </StyledHeader>
        </React.Fragment>
    )
}
SevenSiteHeaderV2.displayName = 'SiteHeaderV2'
