import { SevenNewsSection } from '@news-mono/component-library'
import { StaticRoutes } from '@news-mono/web-common'
import { aflMatchCentreRoutes } from './afl-match-centre-routes'
import { aflwFixturesRoute } from './aflw-match-centre-routes/get-aflw-fixtures-page-route'
import { cookieSettings } from './cookie-consent'
import { cricketMatchCentreRoutes } from './cricket-match-centre-routes'
import { customRoutes } from './custom'
import { geolocationRedirectRoute } from './custom/geolocation-redirect/geolocation-redirect-route'
import { homepage } from './home'
import { staticContentRoutes } from './static/static-content-routes'
import { getSearchPageRouteInfo } from './search/get-search-page-route-info'

export const staticRoutes: StaticRoutes<SevenNewsSection> = {
    ...staticContentRoutes,
    ...homepage,
    ...geolocationRedirectRoute,
    ...customRoutes,
    ...cookieSettings,
    ...cricketMatchCentreRoutes,
    ...aflMatchCentreRoutes,
    ...aflwFixturesRoute,
    ...getSearchPageRouteInfo,
}
