import { CardItem, CollectionEvent } from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import {
    StyedCollectionList,
    StyedCollectionListWrapper,
    StyledHeroCollectionWrapper,
} from './HomeHeroCardCollection.styled'
import { ContentCard } from '../../cards/ContentCard/ContentCard'
import { invertMaybeLoadedItems } from '../../../collections/helpers/loading'
import { HomeHeroCard } from '../../HeroCard/HomeHeroCard/HomeHeroCard'
import { HeaderV2 } from '../../HeaderV2'
import { ThemeMargins } from '../../../__styling'
import { GetVideoQueue } from '../../../content/Video/PlayerInterface'

export interface HomeHeroCardCollectionProps {
    items: MaybeLoaded<CardItem[]>
    onEvent: (event: CollectionEvent) => void
    verticalSpacing: keyof ThemeMargins | undefined
    getVideoQueue?: GetVideoQueue
    adUnitPath?: string
}
const HomeHeroCardCollection: React.FC<HomeHeroCardCollectionProps> = ({
    items,
    onEvent,
    adUnitPath,
    getVideoQueue,
    verticalSpacing,
}) => {
    if (!items.loaded || items.result.length === 0) {
        return null
    }
    const loadedItems = invertMaybeLoadedItems(items, 5)
    const heroCard = loadedItems.shift()

    if (!loadedItems || !heroCard) {
        return null
    }

    return (
        <StyledHeroCollectionWrapper verticalSpacing={verticalSpacing}>
            <HomeHeroCard
                item={heroCard}
                onEvent={onEvent}
                getVideoQueue={getVideoQueue}
            />
            <StyedCollectionListWrapper>
                <HeaderV2 sectionHeader={{ heading: 'Top Headlines' }} />

                <StyedCollectionList>
                    {loadedItems.map((item, idx) => {
                        return (
                            <ContentCard
                                item={item}
                                cardNumber={idx}
                                cardType={'small'}
                                onEvent={onEvent}
                            />
                        )
                    })}
                </StyedCollectionList>
            </StyedCollectionListWrapper>
        </StyledHeroCollectionWrapper>
    )
}

export default HomeHeroCardCollection
