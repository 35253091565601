import styled from '@emotion/styled'
import { WebLink, WebLinkProps, createPropFilter } from '@news-mono/web-common'
import {
    ThemeMargins,
    breakpoint,
    calcRem,
    colors,
    metrics,
} from '../../__styling'

interface ScrollButtonProps {
    position: 'left' | 'right'
    isActive?: boolean
}
export interface PillMenuWrapProps {
    verticalGutters?: [keyof ThemeMargins, keyof ThemeMargins]
    isCentered?: boolean
}
export const StyledScrollButtonWrapper = styled('div')<ScrollButtonProps>(
    ({ position, isActive }) => ({
        display: 'flex',
        position: 'relative',
        top: 0,
        bottom: 0,
        alignItems: 'center',
        padding: 0,
        cursor: 'pointer',
        zIndex: 1,
        img: {
            rotate: position === 'left' ? '180deg' : undefined,
            opacity: isActive ? 1 : 0.2,
        },
    }),
)

interface TopicPillProps {
    isLeadPill?: boolean
    withoutHoverState?: boolean
}

export const StyledTopicPill = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['isLeadPill']),
})<TopicPillProps>(({ isLeadPill }) => ({
    textDecoration: 'none',
    color: isLeadPill ? colors.white : colors.sevennews.pillMenu.color,
    background: isLeadPill
        ? colors.sevennews.red
        : colors.sevennews.pillMenu.pillBackground,
    padding: calcRem(8, 22),
    border: 'none',
    borderRadius: calcRem(22),
    fontSize: calcRem(14),
    fontWeight: 400,
    whiteSpace: 'nowrap',
    '&:hover': {
        cursor: 'pointer',
        background: colors.sevennews.red,
        color: colors.white,
    },
}))

export const StyledNoLinkTopicPill = styled('span', {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['isLeadPill']),
})<TopicPillProps>(({ isLeadPill }) => ({
    textDecoration: 'none',
    color: isLeadPill ? colors.white : colors.sevennews.pillMenu.color,
    background: isLeadPill
        ? colors.sevennews.red
        : colors.sevennews.pillMenu.pillBackground,
    padding: calcRem(8, 22),
    border: 'none',
    borderRadius: calcRem(22),
    fontSize: calcRem(14),
    fontWeight: 400,
    whiteSpace: 'nowrap',
    cursor: 'default',
}))

export const StyledTabPill = styled('button', {
    shouldForwardProp: createPropFilter<WebLinkProps>()(['isLeadPill']),
})<TopicPillProps>(
    ({ withoutHoverState }) =>
        !withoutHoverState && {
            '&:hover': {
                cursor: 'pointer',
                background: colors.sevennews.red,
                color: colors.white,
            },
        },
    ({ isLeadPill }) => ({
        textDecoration: 'none',
        color: isLeadPill ? colors.white : colors.sevennews.pillMenu.color,
        background: isLeadPill
            ? colors.sevennews.red
            : colors.sevennews.pillMenu.pillBackground,
        padding: calcRem(8, 22),
        border: 'none',
        borderRadius: calcRem(22),
        fontSize: calcRem(14),
        fontWeight: 400,
        whiteSpace: 'nowrap',
    }),
)

export const StyledSubtopicWrapperWithControlls = styled('div')({
    display: 'flex',
    gap: calcRem(8),
    overflow: 'hidden',
})
export const StyledSubtopicWrapper = styled('div')({
    background: colors.sevennews.pillMenu.background,
    position: 'relative',
    padding: 0,
    borderRadius: calcRem(39),
    overflow: 'hidden',
})

export const StyledSubTopicList = styled('div')({
    background: colors.sevennews.pillMenu.background,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(16),
    padding: calcRem(9),
    borderRadius: calcRem(39),
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
})
export const StyledSubtopiLabelCopy = styled('span')({
    fontSize: calcRem(12),
    lineHeight: calcRem(13),
    color: colors.sevennews.charade,
    whiteSpace: 'nowrap',
    paddingLeft: calcRem(6),
})

export const StyledPillMenuWrapper = styled('div')<PillMenuWrapProps>(
    ({ verticalGutters, isCentered }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: calcRem(8),
        marginLeft: isCentered ? 'auto' : calcRem(0),
        marginRight: isCentered ? 'auto' : calcRem(0),
        marginTop: verticalGutters
            ? calcRem(metrics.sevennews.margins[verticalGutters[0]])
            : calcRem(24),
        marginBottom: verticalGutters
            ? calcRem(metrics.sevennews.margins[verticalGutters[1]])
            : calcRem(24),
        width: '100%',

        '&:hover': {
            [`${StyledScrollButtonWrapper}`]: {
                display: 'flex',
            },
        },
        [breakpoint('sm')]: {
            gap: calcRem(16),
            width: 'unset',
        },
    }),
)
