import styled from '@emotion/styled'
import { breakpoint, calcRem, colors } from '../../__styling'
import { StyledContentCard } from '../cards/ContentCard/ContentCard.styled'

export const StyledExploreMoreCarousellWrapper = styled('div')({
    '.swiper': {
        padding: calcRem(10, 5),
        [breakpoint('md')]: {
            padding: calcRem(5),
        },
        '.swiper-wrapper': {
            maxWidth: calcRem(320),
        },
        '.swiper-slide': {
            border: '3px solid transparent',
            '&:focus-within': {
                border: `3px solid ${colors.sevennews.red}`,
            },
            maxWidth: calcRem(280),
            [breakpoint('sm')]: {
                maxWidth: calcRem(242),
            },
            [breakpoint('md')]: {
                maxWidth: calcRem(300),
            },
        },
        '&:hover': {
            [breakpoint('md')]: {
                '.swiper-button-prev, .swiper-button-next': {
                    transition: 'background 250ms ease-out',
                },
                '.swiper-button-next': {
                    display: 'flex',
                    top: 'unset',
                    bottom: 0,
                    height: '100%',
                    width: calcRem(40),
                    right: 0,
                    background: colors.white,
                    '&::after': {
                        color: colors.sevennews.bauhausBlack50,
                        fontSize: calcRem(26),
                    },
                    '&::before': {
                        position: 'absolute',
                        content: `''`,
                        top: 0,
                        bottom: 0,
                        background: `linear-gradient(270deg, ${colors.sevennews.bauhausBlack20}, transparent)`,
                        height: '100%',
                        width: calcRem(30),
                        left: calcRem(-30),
                    },
                },
                '.swiper-button-prev': {
                    display: 'flex',
                    top: 'unset',
                    bottom: 0,
                    left: 0,
                    height: '100%',
                    width: calcRem(40),
                    background: colors.white,
                    '&::after': {
                        color: colors.sevennews.bauhausBlack50,
                        fontSize: calcRem(26),
                    },
                    '&::before': {
                        position: 'absolute',
                        content: `''`,
                        top: 0,
                        bottom: 0,
                        background: `linear-gradient(90deg, ${colors.sevennews.bauhausBlack20}, transparent)`,
                        height: '100%',
                        width: calcRem(30),
                        right: calcRem(-30),
                    },
                },
                '.swiper-button-disabled': {
                    display: 'none',
                    '&::before, &::after': { display: 'none' },
                },
            },
        },
        '.swiper-button-prev': {
            display: 'none',
        },
        '.swiper-button-next': {
            display: 'none',
        },
    },
    [`${StyledContentCard}`]: {
        '& [class*="StyledHeadlineText"]': {
            minHeight: calcRem(60),
            span: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineClamp: 3,
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
            },
        },
        '& [class*="StyledHeadline"]': {
            fontSize: calcRem(16),
            lineHeight: calcRem(20),
            minHeight: calcRem(60),
        },
        '& [class*="StyledTeaser"] ': {
            fontSize: calcRem(13),
            lineHeight: calcRem(16),
        },
        '&::after': {
            height: '55%',
            right: calcRem(-22),
        },
    },
})

export const StyledExploreMoreCarouselAmpWrapper = styled('div')({
    '& amp-base-carousel > div > div:first-of-type': {
        gap: calcRem(15),
        paddingTop: calcRem(5),
    },
    '& [class*="StyledCardImageContainer"]': {
        marginBottom: 0,
        height: calcRem(160),
    },
    [`${StyledContentCard}`]: {
        maxHeight: calcRem(150),
        '& [class*="StyledHeadlineText"]': {
            minHeight: calcRem(60),
            maxWidth: calcRem(240),
            span: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                lineClamp: 3,
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
            },
        },
        '& [class*="StyledHeadline"]': {
            fontSize: calcRem(16),
            lineHeight: calcRem(20),
            minHeight: calcRem(60),
        },
        '& [class*="StyledTeaser"] ': {
            fontSize: calcRem(13),
            lineHeight: calcRem(16),
        },
        '&::after': {
            height: '55%',
            right: 0,
        },
    },
})
