import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { MatchActionWidget, MatchActionWidgetProps } from './MatchActionWidget'

export const MatchActionWidgetRegistration = createRegisterableComponent(
    'match-action-widget',
    (props: MatchActionWidgetProps, services) => {
        return <MatchActionWidget {...props} />
    },
)
