import {
    CardItem,
    isPublicationCardItem,
    isPublicationCardItemWithVideo,
} from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'

export function mapTopVideoMeta(cards: CardItem[]): VideoMetaDTO[] {
    const data = cards.map((item) => {
        if (
            isPublicationCardItem(item) &&
            isPublicationCardItemWithVideo(item)
        ) {
            return {
                id: item.video.id,
                accountId: item.video.accountId,
                source: item.video.source || null,
                sourceExtra: null,
                duration: Number(item.video.duration),
                name: item.video.heading,
                description: item.video.heading,
                longDescription: item.video.heading,
                byline: null,
                producer: [],
                posterImage: {
                    reference: item.image?.crops['16:9']?.reference || '',

                    src: item.image?.crops['16:9']?.reference || '',
                    width: item.image?.crops['16:9']?.width || 0,
                    height: item.image?.crops['16:9']?.height || 0,
                    dimensions:
                        `${item.image?.crops['16:9']?.width}x${item.image?.crops['16:9']?.height}` ||
                        '',
                    ratio: '16:9',
                    format: '',
                },
                primaryTopic: item.primaryTopic.title,
                secondaryTopics:
                    item.secondaryTopics.length > 0
                        ? item.secondaryTopics.map((item) => item.id)
                        : [''],
                videoType: item.video.videoType,
                videoContentType: null,
                seriesInfo: null,
                expiryDate: null,
                publicationDate: item.publicationDate || '',
                topicMeta: {
                    id: item.primaryTopic.id,
                    title: item.primaryTopic.title,
                    metadata: item.primaryTopic.metadata,
                    seoTitle: item.primaryTopic.seoTitle,
                    seoDescription: item.primaryTopic.seoDescription || '',
                    parent: {
                        id: item.primaryTopic.parent?.id || '',

                        title: item.primaryTopic.parent?.title || '',
                        metadata: item.primaryTopic.parent?.metadata || {},
                        seoTitle: item.primaryTopic.parent?.seoTitle || '',
                        seoDescription:
                            item.primaryTopic.parent?.seoDescription || '',
                    },
                },
            }
        }
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return data
}
