import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem, fonts } from '../../__styling'

export const ManhattanWidgetContainer = styled('div')({
    display: 'block',

    '& .Opta': {
        fontFamily: fonts.sevennews.sansSerif,
        '& .Opta-Error .Opta-Cf p': {
            fontSize: calcRem(20),
        },
        '&  .Opta_C_M_N .Opta-Tooltip .Opta-Tooltip-header': {
            backgroundColor: tokens.sevennews.colors.palette.red,
        },
    },

    '& .Opta_C_M_N > h2, & .Opta_C_M_N .Opta-MatchHeader': {
        display: 'none',
    },

    '& .Opta_C_M_N .Opta-js-main ': {
        '& .Opta-CricketStateOfPlay': {
            div: {
                fontSize: calcRem(14),
                [breakpoint('md')]: {
                    fontSize: calcRem(16),
                },
            },
            '& > div': {
                backgroundColor: '#B6001E',
                color: tokens.sevennews.colors.palette.white,
            },
        },
        '& .Opta-selects': {
            '& .Opta-Heading': {
                backgroundColor: '#B6001E',
                color: tokens.sevennews.colors.palette.white,
            },
            li: {
                color: tokens.sevennews.colors.palette.white,
                backgroundColor: 'rgba(230, 0, 30, 0.3)',
            },
            'li.Opta-On': {
                backgroundColor: '#E6001EB3',
            },
        },
        '& .Opta-Label-AxisY, & .Opta-TimeBox text, & .Opta-GuideLines text': {
            fontSize: calcRem(14),
            [breakpoint('md')]: {
                fontSize: calcRem(16),
            },
        },
        '& .Opta-timecontrols .Opta-Timestrip': {
            '& .Opta-Dragger-start path, & .Opta-Dragger-end path': {
                fill: tokens.sevennews.colors.palette.red,
            },
            '& .Opta-TimeSpan': {
                fill: tokens.sevennews.colors.palette.red,
            },
        },
    },
})
