import React from 'react'
import { Topic, createRegisterableComponent } from '@news-mono/web-common'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { SectionHeaderProps } from '../../section-header/SectionHeader/SectionHeader'
import { PageHeaderV2 } from './PageHeaderV2'
import { getBannerLogoUrls } from './imageUtils/getBannerLogoUrls'
import { getBannerBackgroundUrls } from './imageUtils/getBannerBackgroundUrls'
import { getLogoImageUrl } from './imageUtils/getLogoImageUrl'

export type TopicHeaderType = 'banner-image' | 'logo' | 'topic-heading'
export interface PageHeaderV2Props extends ResponsiveContainer {
    sectionHeader: SectionHeaderProps
    topic?: Topic
    type?: TopicHeaderType
}
interface HeaderBTYB {
    image?: string
    imageAlt?: string
    link?: string
    size?: 'small' | 'large'
}
export const getHeaderLink = (logoName?: string) =>
    `/${logoName?.split('/')[0]}`

export const getHeaderBTYB = (topicId?: string): HeaderBTYB => {
    switch (topicId) {
        case 'motoring':
            return {
                image: require('./assets/motoring-btyb.png'),
                link: 'https://www.carexpert.com.au/?utm_source=7NEWS&utm_medium=motoring-btyb&utm_campaign=7news-car-expert',
                imageAlt: 'Car Expert logo',
                size: 'small',
            }
        case 'entertainment/my-kitchen-rules':
            return {
                image: require('./assets/woolworths-logo.png'),
                link: undefined,
                imageAlt: 'Woolworths logo',
                size: 'large',
            }
        default:
            return {
                image: undefined,
                link: undefined,
                imageAlt: undefined,
                size: undefined,
            }
    }
}

export const PageHeaderV2Registration = createRegisterableComponent(
    'page-header-v2',
    (props: PageHeaderV2Props, services) => {
        const entertainmentTopic = props?.topic?.id.includes('entertainment')
        const topicName =
            props?.topic?.parent && !entertainmentTopic
                ? props.topic.parent.id
                : props?.topic?.id

        const logoName = topicName?.substring(topicName?.lastIndexOf('/') + 1)

        const { image, imageAlt, link, size } = getHeaderBTYB(props?.topic?.id)

        return (
            <PageHeaderV2
                sectionHeader={props.sectionHeader}
                type={props.type}
                headerLink={getHeaderLink(topicName)}
                brandBackgroundImage={getBannerBackgroundUrls(topicName || '')}
                brandLogoImage={getBannerLogoUrls(topicName || '')}
                logoImage={getLogoImageUrl(logoName || '')}
                imageAltText={`${logoName} logo`}
                btybLinkLogoImage={image}
                btybLogoLink={link}
                btybLogoLinkAlt={imageAlt}
                btybSize={size}
            />
        )
    },
)
