import {
    CardItem,
    CollectionEvent,
    ConfigurationContext,
    createCardClickedEvent,
    isPublicationCardItem,
    toLinkWithHint,
    useFeature,
    useToggleState,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React, { useContext, useRef } from 'react'
import {
    StyledCTAButton,
    StyledCardImage,
    StyledCardImageContainer,
    StyledContentCard,
    StyledDotLogo,
    StyledLinkWrapper,
    StyledLiveLabel,
    StyledLiveStatus,
    StyledPublicatioType,
    StyledText,
    StyledTextContainer,
    StyledTimeToRead,
    StyledTimeToReadAndTypeWrap,
    StyledTimeToReadWrapper,
    StyledVideoBadge,
} from './SearchResultCard.styled'
import {
    EditorialType,
    getEditorialType,
} from '../../../templates/Publication/lib/get-editorial-type'
import { useProduct } from '../../../__product/useProduct'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { useLocation } from 'react-router'

export type CardType = 'large' | 'medium' | 'medium-video-hub' | 'small'

interface CTAProps {
    title: string
    link?: string
}

export interface ContentCardProps {
    item: MaybeLoaded<CardItem>
    cardNumber: number
    onEvent: (event: CollectionEvent) => void
    cta?: CTAProps
    isVideo?: boolean
}

export const SearchResultCard: React.FC<ContentCardProps> = ({
    item,
    onEvent,
    cardNumber,
    cta,
    isVideo,
}) => {
    const isCarExpert = item.loaded && item.result.source === 'CarExpert'
    const location = useLocation()
    const config = useContext(ConfigurationContext)
    const linkOutIcon = require('../../assets/link-out.svg')
    function linkClicked(
        e: React.MouseEvent<HTMLElement>,
        item: MaybeLoaded<CardItem>,
        cardNumber: number,
    ) {
        if (!item.loaded) {
            e.preventDefault()
            return
        }
        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'InternalPortrait',
                'Portrait',
                cardNumber,
                'InlineRelatedCollection',
            ),
        )
    }
    const toggles = useToggleState()
    const cardRef = useRef<HTMLDivElement>(null)
    const storyClassification = true
    const editorialType = item.loaded
        ? getEditorialType(item.result, storyClassification)
        : undefined
    //TO DO: do the checking of publicationType when backend is finished
    // const publicationType = 'Video'
    if (item.loaded && isPublicationCardItem(item.result)) {
        const timeToRead = item?.result?.readTimeMinutes
            ? `${Math.ceil(item.result.readTimeMinutes)} min read`
            : ''
        const isCarExpertCanonicalEnabled = isFeatureEnabled(
            toFeatureState(toggles),
            'car-expert-canonical',
        )

        const currentUrl = `${config.publicUrl}${location.pathname}`

        const overrideUrl =
            isCarExpert && isCarExpertCanonicalEnabled
                ? `${item.result.canonicalUrl}?referrer=7news&referrerurl=${currentUrl}`
                : null
        return (
            <StyledLinkWrapper
                to={overrideUrl ?? toLinkWithHint(item)}
                onClick={(event) => {
                    linkClicked(event, item, cardNumber)
                }}
            >
                <StyledContentCard ref={cardRef}>
                    <StyledCardImageContainer>
                        <StyledCardImage
                            item={{
                                loaded: true,
                                result: item.result,
                            }}
                            imageWidths={{
                                mobile: '172px',
                                tablet: '172px',
                                desktop: '172px',
                                fallbackWidth: 740,
                            }}
                            hasBackground={true}
                            onEvent={onEvent}
                            mediaMode={'default'}
                            fixedRatio={'16:9'}
                            disableImageLazyLoad={false}
                            displayNoScriptImage={true}
                        />
                    </StyledCardImageContainer>
                    <StyledTextContainer>
                        <StyledText
                            item={{
                                loaded: true,
                                result: item.result,
                            }}
                            fontScale={1}
                            onEvent={onEvent}
                            teaserMode={'hidden'}
                            kickerMode={'hidden'}
                        />
                        {editorialType &&
                        editorialType === EditorialType.Live ? (
                            <StyledLiveStatus>
                                <StyledDotLogo />
                                <StyledLiveLabel>Live</StyledLiveLabel>
                            </StyledLiveStatus>
                        ) : (
                            <StyledTimeToReadAndTypeWrap>
                                {isVideo ? (
                                    <StyledVideoBadge>Video</StyledVideoBadge>
                                ) : (
                                    timeToRead &&
                                    !cta && (
                                        <StyledTimeToReadWrapper>
                                            <StyledTimeToRead>
                                                {timeToRead}
                                            </StyledTimeToRead>

                                            {overrideUrl && (
                                                <img
                                                    src={linkOutIcon}
                                                    alt={'link out icon'}
                                                />
                                            )}
                                        </StyledTimeToReadWrapper>
                                    )
                                )}
                                <StyledPublicatioType>
                                    {/* TO DO: uncomment the next line: */}
                                    {/* {publicationType} */}
                                </StyledPublicatioType>
                            </StyledTimeToReadAndTypeWrap>
                        )}

                        {cta && <StyledCTAButton>{cta.title}</StyledCTAButton>}
                    </StyledTextContainer>
                </StyledContentCard>
            </StyledLinkWrapper>
        )
    } else {
        return null
    }
}
