export const frequentlyVisitedTopics = [
    {
        name: 'Sunrise',
        keyword: 'sunrise',
    },
    {
        name: 'The Morning Show',
        keyword: 'the morning show',
    },
    {
        name: 'Business',
        keyword: 'business',
    },
    {
        name: 'Travel',
        keyword: 'travel',
    },
    {
        name: 'Technology',
        keyword: 'technology',
    },
    {
        name: 'Weather',
        keyword: 'weather',
    },
    {
        name: 'Crime',
        keyword: 'crime',
    },
    {
        name: 'Health & Wellbeing',
        keyword: 'health wellbeing',
    },
    {
        name: 'Motoring',
        keyword: 'motoring',
    },
    {
        name: 'AFL',
        keyword: 'afl',
    },
    {
        name: 'Food',
        keyword: 'food',
    },
    {
        name: 'Personal Finance',
        keyword: 'personal finance',
    },
]
