import React from 'react'

export interface IconChevronLeftAltProps {
    title?: string
    className?: string
}

export const IconChevronLeftAlt: React.FC<IconChevronLeftAltProps> = ({
    className,
    title,
}) => (
    <svg
        viewBox="0 0 16 29"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title || 'Chevron Left Icon'}</title>
        <path
            transform="rotate(-180 6.599999904632568,14.133500099182129) "
            d="m0.79,0.236l3.5,0l8.12,13.727l-8.11,14.068l-3.51,0l8.149,-14.049l-8.149,-13.746z"
        />
    </svg>
)
