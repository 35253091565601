import { Topic } from '@news-mono/web-common'
import { LinkProps } from './BreadCrumb'

export function formatBreadcrumb(topic: Topic): LinkProps[] {
    const items: LinkProps[] = []
    const parent = topic.parent
    if (parent) {
        items.push({
            text: parent.title,
            href: `/${parent.id}`,
        })
    }
    items.push({
        text: topic.title,
        href: `/${topic.id}`,
    })

    return items
}
