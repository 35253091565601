import React from 'react'
import {
    StyledBreadcrumb,
    StyledBreadcrumbContainer,
    StyledLink,
} from './BreadCrumb.styled'
import { Topic } from '@news-mono/web-common'

export type LinkProps = {
    text: string
    href: string
}

export interface BreadcrumbProps {
    items: LinkProps[]
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    if (!items || items.length === 0) {
        return null
    }
    const numberOfLevels = items.length
    const breadcrumbs = () => {
        return items.map((item, i) => {
            const isLast = i === numberOfLevels - 1
            return (
                <>
                    <StyledLink to={item.href} key={i}>
                        {item.text}
                    </StyledLink>
                </>
            )
        })
    }

    return (
        <StyledBreadcrumbContainer>
            <StyledBreadcrumb>{breadcrumbs()}</StyledBreadcrumb>
        </StyledBreadcrumbContainer>
    )
}
