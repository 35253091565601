import React, { useRef } from 'react'
import { breakpoints, calcRem } from '../../../__styling'
import { createRenderTarget } from '../../../render-target'
import { StyledAmpLogo, StyledLogo } from '../SevenNewsHeaderV2.styled'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'

import { useToggleState } from '@news-mono/web-common'
const sevenLogoRed = require('./7NEWS_Logo_RED_RGB.svg')

export type LogoColour = 'white' | 'black' | 'colour' | 'red' | 'mixed'

interface Props {
    src: { desktop: string; mobile: string }
    alt: string
    heights: {
        desktop: number
        tablet: number
        mobile: number
    }
    /* This allows amp to get the aspect ratio */
    ampInitialSize: { width: number; height: number }
}

const SevenNewsLogoWeb: React.FC<Props> = ({ src, alt, heights }) => {
    const logoRef = useRef<HTMLImageElement | null>(null)
    const toggles = useToggleState()
    const is7NewsSearchBarEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        '7-news-search-bar',
    )
    return (
        <picture>
            <source
                srcSet={src.mobile}
                media={`(max-width: ${breakpoints.md - 1}px )`}
            />
            <source
                srcSet={src.desktop}
                media={`(min-width: ${breakpoints.md}px )`}
            />
            <StyledLogo
                ref={logoRef}
                alt={alt}
                src={src.desktop}
                heights={heights}
                width={logoRef.current?.clientWidth || ''}
                height={logoRef.current?.clientHeight || ''}
                is7NewsSearchBarEnabled={is7NewsSearchBarEnabled}
            />
        </picture>
    )
}

const HeaderLogo = createRenderTarget<Props>('HeaderLogo', {
    amp: ({ src, alt, heights, ampInitialSize }) => {
        const { width, height } = ampInitialSize

        const getAmpWidthFromHeight = (value: number) =>
            value / (height / width)

        return (
            <StyledAmpLogo
                alt={alt}
                src={src.desktop}
                width={width}
                height={height}
                sizes={`(max-width: ${calcRem(breakpoints.xxs)}) ${calcRem(
                    getAmpWidthFromHeight(heights.mobile),
                )}, (min-width: ${calcRem(
                    breakpoints.xxs + 1,
                )}) and (max-width: ${calcRem(breakpoints.sm)}) ${calcRem(
                    getAmpWidthFromHeight(heights.tablet),
                )}, ${calcRem(getAmpWidthFromHeight(heights.desktop))}`}
            />
        )
    },
    web: SevenNewsLogoWeb,
    rss: ({ src, alt }) => <img src={src.desktop} alt={alt} />,
    preview: SevenNewsLogoWeb,
    app: SevenNewsLogoWeb,
})

export const getLogo = () => {
    return (
        <HeaderLogo
            alt="7NEWS.com.au"
            src={{
                desktop: sevenLogoRed,
                mobile: sevenLogoRed,
            }}
            heights={{
                desktop: 38,
                tablet: 30,
                mobile: 18,
            }}
            ampInitialSize={{ width: 376, height: 108 }}
        />
    )
}
