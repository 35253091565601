import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { Lima } from '../collections/Lima/Lima'
import { getVideoSeriesInfoFromRouteResolution } from './get-data-for-video-series-landing'
import { mapVideoMetaToCard } from './map-video-meta-to-card'
// import { getVideoSeriesInfoFromRouteResolution } from './get-data-for-video-series-landing'

export const VideoSeriesEpisodeListRegistration = createRegisterableComponent(
    'video-series-episode-list',
    (_, services) => {
        const { season, section, series, episodes } =
            getVideoSeriesInfoFromRouteResolution(services)

        if (!episodes.length) {
            return null
        }

        return (
            <Lima
                sectionHeader={{
                    heading: 'Watch the episodes',
                }}
                onEvent={services.onEvent}
                expectedCards={episodes.length}
                fixedRatios={['16:9']}
                verticalSpacing="md"
                remainingCardLayout="noTeaserNoPadding"
                containerWidthRatios={{ mobile: 1, tablet: 0.5, desktop: 0.62 }} // currently used on seven only inside zeus
                items={{
                    loaded: true,
                    result: episodes.map((videoMeta) =>
                        mapVideoMetaToCard(
                            section,
                            series,
                            season,
                            services.config.publicUrl,
                            videoMeta,
                        ),
                    ),
                }}
            />
        )
    },
)
