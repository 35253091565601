import { useCallback, useEffect, useRef, useState } from 'react'
import { FixtureData } from './types'

const UPDATE_RESULT_INTERVAL = 1000 * 20

export const useFetchFixtureData = (gameId: string, apiData: any) => {
    const [data, setData] = useState<FixtureData | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const fetchData = useCallback(
        (data: FixtureData | null) => {
            fetch(`/cricket/fixture?game_id=${gameId}`, {
                headers: { 'Content-Type': 'application/json' },
            })
                .then((result) => result.json())
                .then((rawData) => {
                    const fixtureData = formatData(rawData)
                    //update data only if it changed
                    if (JSON.stringify(fixtureData) !== JSON.stringify(data)) {
                        setData(fixtureData)
                    }
                })
                .catch(() => setError(true))
        },
        [gameId],
    )

    useEffect(() => {
        setLoading(true)
        if (apiData) {
            const formatedData = formatData(apiData)
            setData(formatedData)
        } else {
            fetchData(data)
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiData, fetchData])

    useInterval(() => {
        if (data?.gameStatus === '1') {
            fetchData(data)
        }
    }, UPDATE_RESULT_INTERVAL)

    return { data, error, loading }
}

const formatData = (data: any): FixtureData => {
    const status = data.CricketMatchSummary.MatchDetail['@attributes'].status_id
    const { homeScore, awayScore } = formatScore(data)

    return {
        gameStatus: status,
        venue: {
            name: data.CricketMatchSummary.MatchDetail.Venue['@attributes']
                .venue_name,
            city: data.CricketMatchSummary.MatchDetail.Venue['@attributes']
                .venue_city,
            country:
                data.CricketMatchSummary.MatchDetail.Venue['@attributes']
                    .venue_country,
        },
        date: data.CricketMatchSummary.MatchDetail['@attributes']
            .game_date_time,
        description:
            data.CricketMatchSummary.MatchDetail['@attributes'].description,
        competitionName:
            data.CricketMatchSummary.MatchDetail['@attributes']
                .competition_name,
        game_id: data.CricketMatchSummary.MatchDetail['@attributes'].game_ide,
        result:
            status !== '0'
                ? data.CricketMatchSummary.MatchDetail['@attributes'].result
                : null,
        homeTeam: {
            name: data.CricketMatchSummary.MatchDetail['@attributes'].home_team,
            id: data.CricketMatchSummary.MatchDetail['@attributes']
                .home_team_id,
            innings: [...homeScore],
        },
        awayTeam: {
            name: data.CricketMatchSummary.MatchDetail['@attributes'].away_team,
            id: data.CricketMatchSummary.MatchDetail['@attributes']
                .away_team_id,
            innings: [...awayScore],
        },
    }
}

function formatScore(data: any) {
    const status = data.CricketMatchSummary.MatchDetail['@attributes'].status_id
    const innings = data.CricketMatchSummary.Innings
    const homeId =
        data.CricketMatchSummary.MatchDetail['@attributes'].home_team_id
    const awayId =
        data.CricketMatchSummary.MatchDetail['@attributes'].away_team_id
    let homeInnings,
        awayInnings = null

    if (!innings) {
        return {
            homeScore: [
                {
                    overs: null,
                    runsScored: null,
                    wickets: null,
                },
            ],
            awayScore: [
                {
                    overs: null,
                    runsScored: null,
                    wickets: null,
                },
            ],
        }
    }

    //This part needed because if there is just one team have score, respons didn't contain Inings in array format as expected. Reather than octa use just object
    if (status !== '0' && !Array.isArray(innings)) {
        if (
            innings['@attributes'].batting_team_id ===
            data.CricketMatchSummary.MatchDetail['@attributes'].home_team_id
        ) {
            homeInnings = [innings]
        } else {
            awayInnings = [innings]
        }
    } else {
        homeInnings = innings
            .filter(
                (item: any) => item['@attributes'].batting_team_id === homeId,
            )
            .sort(
                (a: any, b: any) =>
                    Number(a['@attributes'].id) - Number(b['@attributes'].id),
            )
        awayInnings = innings
            .filter(
                (item: any) => item['@attributes'].batting_team_id === awayId,
            )
            .sort(
                (a: any, b: any) =>
                    Number(a['@attributes'].id) - Number(b['@attributes'].id),
            )
    }

    const homeScore =
        homeInnings !== undefined && homeInnings !== null
            ? homeInnings?.map((item: any) => {
                  return {
                      overs: item.Total['@attributes'].overs,
                      runsScored: item.Total['@attributes'].runs_scored,
                      wickets:
                          item['@attributes'].declared === '0'
                              ? item.Total['@attributes'].wickets
                              : `${item.Total['@attributes'].wickets}d`,
                  }
              })
            : [
                  {
                      overs: null,
                      runsScored: null,
                      wickets: null,
                  },
              ]

    const awayScore =
        awayInnings !== undefined && awayInnings !== null
            ? awayInnings?.map((item: any) => {
                  return {
                      overs: item.Total['@attributes'].overs,
                      runsScored: item.Total['@attributes'].runs_scored,
                      wickets:
                          item['@attributes'].declared === '0'
                              ? item.Total['@attributes'].wickets
                              : `${item.Total['@attributes'].wickets}d`,
                  }
              })
            : [
                  {
                      overs: null,
                      runsScored: null,
                      wickets: null,
                  },
              ]

    return { homeScore, awayScore }
}

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}
