import styled from '@emotion/styled'
import { calcRem } from '../../__styling'

export const StyledTvGuideContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: calcRem(50),
    '& #tvguidetonight-widget-channels': {
        height: calcRem(417),
        width: '100%',
        border: 'none',
    },
}))
