import React from 'react'

export const CloseIcon: React.FC = () => (
    <svg
        width="55"
        height="56"
        viewBox="0 0 55 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_d_10714_56488)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2803 32.2838C24.9874 32.5767 24.9874 33.0516 25.2803 33.3444C25.5732 33.6373 26.0481 33.6373 26.341 33.3444L29.2498 30.4357L32.2838 33.4697C32.5767 33.7626 33.0516 33.7626 33.3444 33.4697C33.6373 33.1768 33.6373 32.7019 33.3444 32.409L30.3104 29.375L33.4051 26.2803C33.698 25.9874 33.698 25.5126 33.4051 25.2197C33.1122 24.9268 32.6373 24.9268 32.3444 25.2197L29.2498 28.3143L26.2803 25.3449C25.9874 25.052 25.5126 25.052 25.2197 25.3449C24.9268 25.6378 24.9268 26.1127 25.2197 26.4056L28.1891 29.375L25.2803 32.2838Z"
                fill="white"
            />
            <g filter="url(#filter1_b_10714_56488)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 29C19 23.4772 23.4772 19 29 19C34.5228 19 39 23.4772 39 29C39 34.5228 34.5228 39 29 39C23.4772 39 19 34.5228 19 29ZM29 21.1656C24.6732 21.1656 21.1656 24.6732 21.1656 29C21.1656 33.3268 24.6732 36.8344 29 36.8344C33.3268 36.8344 36.8344 33.3268 36.8344 29C36.8344 24.6732 33.3268 21.1656 29 21.1656Z"
                    fill="white"
                    fillOpacity="0.8"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_d_10714_56488"
                x="0.4"
                y="0.4"
                width="57.2"
                height="57.2"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="9.3" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_10714_56488"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_10714_56488"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_b_10714_56488"
                x="6.19468"
                y="6.19468"
                width="45.6106"
                height="45.6106"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur
                    in="BackgroundImageFix"
                    stdDeviation="6.40266"
                />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_10714_56488"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_10714_56488"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)
CloseIcon.displayName = 'CloseIcon'
