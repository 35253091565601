import {
    GetRouteAdTargeting,
    StaticRouteResolution,
    TogglesReduxState,
} from '@news-mono/web-common'
import { ResolveSlugTopicV4DTO } from '@west-australian-newspapers/publication-types'
import { getV2TopicPage } from '../helpers/get-v2-topic-page'

export const getEntertainmentPageV2 = (
    getAdTargeting: GetRouteAdTargeting,
    resolution: StaticRouteResolution<ResolveSlugTopicV4DTO>,
    features: TogglesReduxState,
) => {
    const topic = {
        title: 'Entertainment',
        id: 'entertainment',
        seoTitle: resolution.resolution?.topic?.seoTitle || 'Entertainment',
        seoDescription: resolution.resolution?.topic?.seoDescription,
        metadata: {},
    }

    const topicCurationPage = getV2TopicPage({
        topic,
        contentToFormat: [
            {
                pillMenuName: 'Celebrity',
                pillMenuLink: '/entertainment/celebrity',
                collectionTopics: ['entertainment/celebrity'],
            },
            {
                pillMenuName: 'Royals',
                pillMenuLink: '/entertainment/royal-family',
                collectionTopics: ['entertainment/royal-family'],
            },
            {
                pillMenuName: 'TV & Movies',
                pillMenuLink: '/entertainment/tv',
                collectionTopics: ['entertainment/tv', 'entertainment/movies'],
            },
            {
                pillMenuName: 'Gossip',
                pillMenuLink: '/entertainment/celebrity-gossip',
                collectionTopics: ['entertainment/celebrity-gossip'],
            },
            {
                pillMenuName: 'The Morning Show',
                pillMenuLink: '/the-morning-show',
                collectionTopics: ['the-morning-show'],
            },
            {
                pillMenuName: 'Sunrise',
                pillMenuLink: '/sunrise',
                collectionTopics: ['sunrise'],
            },
        ],
        promoCardName: '7news-entertainment-promo-cards',
        getAdTargeting,
        features,
    })

    return topicCurationPage
}
