import {
    ArticlePublicationContext,
    createRegisterableComponentWithData,
    isArticleLikeType,
} from '@news-mono/web-common'
import { PageProps } from 'page-lifecycle-provider'
import React from 'react'
import { SevenNewsPublication } from '../../../templates/Publication/SevenNewsPublication/SevenNewsPublication'
import {
    getPublicationInfoFromRouteResolution,
    PublicationRouteProps,
} from '../../../templates/Publication/SharedPublication.routing'
import { ArticlePublicationDataDefinitionLoader } from '../ArticlePublicationDataLoader'
import { getVideoQueue } from '../lib/get-video-queue'
import { getPublicationPageProperties } from '../lib/publication-page-properties'
import { getSevenNewsInlinePositionedContent } from './get-sevennews-inline-positioned-content'

export const SevenNewsArticleRegistration = createRegisterableComponentWithData(
    'article',
    ArticlePublicationDataDefinitionLoader,
    (props: PublicationRouteProps, data, services) => {
        const publicationInfo = getPublicationInfoFromRouteResolution(services)
        if (!isArticleLikeType(publicationInfo.publication)) {
            throw new Error('Article component rendered for non-article')
        }

        if (data.loaded) {
            const inlinePositionedContent = getSevenNewsInlinePositionedContent(
                {
                    adState: services.adState,
                    publication: publicationInfo.publication,
                    meta: props.meta,
                    log: services.log,
                    onEvent: services.onEvent,
                    toggles: services.store.getState().toggles,
                },
            )

            return (
                <ArticlePublicationContext.Provider
                    value={publicationInfo.publication}
                >
                    <PageProps
                        pageProperties={getPublicationPageProperties(
                            publicationInfo.publication,
                        )}
                    >
                        <SevenNewsPublication
                            data={publicationInfo.publication}
                            inlinePositionedContent={inlinePositionedContent}
                            onEvent={services.onEvent}
                            log={services.log}
                            getVideoQueue={() =>
                                getVideoQueue(
                                    services,
                                    publicationInfo.publication.id,
                                )
                            }
                            heroOptions={{
                                isVideoStickyEnabled:
                                    props.isVideoStickyEnabled,
                            }}
                            relatedPublications={
                                data.result.relatedPublications
                            }
                            location={services.location}
                            adState={services.adState}
                        />
                    </PageProps>
                </ArticlePublicationContext.Provider>
            )
        }
        return null
    },
)
