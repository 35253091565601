import React from 'react'
import { AmpImage } from '../../content/Picture/Picture.amp'
import { createRenderTarget } from '../../render-target'
import { breakpoints } from '../../__styling'
import {
    StyledErrorImage,
    StyledErrorImageWrapper,
} from './SevenNewsErrorPageByStatus.styled'

const errorImage = require('./assets/404.png')
const errorImageMobile = require('./assets/404-mobile.png')
const errorImageTablet = require('./assets/404-tablet.png')

const ErrorImageWeb: React.FC<{}> = () => (
    <StyledErrorImageWrapper>
        <picture>
            <source
                srcSet={errorImageMobile}
                media={`(max-width: ${breakpoints.sm}px)`}
            />
            <source
                srcSet={errorImageTablet}
                media={`(max-width: ${breakpoints.md}px)`}
            />
            <StyledErrorImage src={errorImage} alt="Page not found" />
        </picture>
    </StyledErrorImageWrapper>
)

export const ErrorImageRight = createRenderTarget<{}>('ErrorImage', {
    web: ErrorImageWeb,
    amp: () => (
        <StyledErrorImageWrapper>
            <AmpImage width={691} height={384} src={errorImageMobile} />
        </StyledErrorImageWrapper>
    ),
    rss: () => <img src={errorImage} alt="7News.com.au" />,
    preview: ErrorImageWeb,
    app: ErrorImageWeb,
})
