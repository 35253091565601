import { metrics } from '@news-mono/component-library'
import { SizePropsKey } from '@news-mono/web-common'
import { layout } from '../../App.routing'

const sidebarAd = (options: {
    id: string
    native?: boolean
    size?: SizePropsKey | undefined
}) => {
    const { id, size = 'above768Mrec' } = options
    return layout.component({
        type: 'ad-unit',
        props: {
            noticePosition: 'above-center',
            padding: [0, 0, metrics.sevennews.margins.md, 0],
            slot: {
                id: `${size}-${id}`,
                size,
            },
            adType: 'inline',
        },
    })
}

export const sidebarAdMrecOne = sidebarAd({ id: 'one' })

/* the bespoke native ad native-one */
export const sidebarAdBespokeNative = sidebarAd({
    id: 'one',
    native: true,
    size: 'nativeMrec',
})
export const sidebarAdHalfPageMrec = sidebarAd({
    id: 'desktop-halfpage-one',
    size: 'above768MrecHalfPage',
})
