import React from 'react'
import { useOptaWidgetStart } from '../useOptaWidgetStart'
import { MatchActionWidgetContainer } from './MatchActionWidget.styled'

export interface MatchActionWidgetProps {
    competition: string
    season: string
    match: string
}
export const MatchActionWidget = ({
    competition,
    season,
    match,
}: MatchActionWidgetProps) => {
    useOptaWidgetStart()
    return (
        <MatchActionWidgetContainer>
            <opta-widget
                widget="match_action"
                competition={competition}
                season={season}
                match={match}
                template="normal"
                show_innings_breakdown="true"
                show_crests="false"
                team_naming="full"
                player_naming="full"
                show_live="false"
                show_logo="false"
                show_title="false"
                breakpoints="400"
                sport="cricket"
            ></opta-widget>
        </MatchActionWidgetContainer>
    )
}
